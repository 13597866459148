import { inject } from '@angular/core';
import { Broadcast, BroadcastApiService, PlayReplayPolicy } from '@teleboy/web.epg';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { RouteDataService } from '../../core/services/route-data.service';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { ErrorCode, TeleboyError } from '@teleboy/web.core';
import { UpsellService } from '../../core/services/upsell.service';

export const replayGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  const broadcastApiService = inject(BroadcastApiService);
  const playReplayPolicy = inject(PlayReplayPolicy);
  const routeDataService = inject(RouteDataService);
  const upsellService = inject(UpsellService);

  let broadcast$: Observable<Broadcast>;

  const broadcastData = routeDataService.getDataForRoute<Broadcast>(state.url);

  if (broadcastData) {
    broadcast$ = of(broadcastData);
  } else if (route.params['broadcastId']) {
    broadcast$ = broadcastApiService.get(route.params['broadcastId']);
  } else {
    return of(false);
  }

  return broadcast$.pipe(
    switchMap((broadcast: Broadcast) => playReplayPolicy.isGranted(broadcast)),
    catchError((err: TeleboyError) => handleError$(err))
  );

  function handleError$(error: TeleboyError): Observable<boolean> {
    switch (error.code) {
      case ErrorCode.USER_AUTHENTICATION_REQUIRED:
      case ErrorCode.USER_PLUS_SUBSCRIPTION_REQUIRED:
        upsellService.openAboUpsell();
        break;
      case ErrorCode.REPLAY_NOT_ACTIVATED:
        upsellService.openReplayActivationUpsell();
        break;
      case ErrorCode.REPLAY_TOO_RECENT:
        upsellService.openReplayTooRecentUpsell();
        break;
      default:
        return throwError(() => error);
    }

    return of(false);
  }
};
