<ng-container *ngIf="clickable; else textOnly">
  <a (click)="onClick()">
    <h2>
      {{ linkText }}
      <tbui-icon iconId="arrow-right"></tbui-icon>
    </h2>
  </a>
</ng-container>

<ng-template #textOnly>
  <h2>{{linkText}}</h2>
</ng-template>

