import { ChangeDetectionStrategy, Component, Inject, Input, NgZone } from '@angular/core';
import { ApiListResponse } from '@teleboy/web.core';
import { Broadcast, CommunityApiService, CommunityRecordParams } from '@teleboy/web.epg';
import { Observable } from 'rxjs';
import { ListSwiperDirective } from '../../../directives/swiper/list-swiper.directive';
import { PopupService } from '../../../../core/services/popup.service';
import { EntityServiceType, ItemsListRouteData, EntityType } from '../../../interfaces/items-list-route-data';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { BroadcastItemComponent } from '../../broadcast-item/broadcast-item.component';

@Component({
  selector: 'app-community-swiper',
  templateUrl: './community-swiper.component.html',
  styleUrls: ['../swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, SharedModule, SwiperModule, NgFor, BroadcastItemComponent, AsyncPipe]
})
export class CommunitySwiperComponent extends ListSwiperDirective<Broadcast> {
  @Input() override params!: CommunityRecordParams;

  constructor(
    @Inject(NgZone) ngZone: NgZone,
    private communityApiService: CommunityApiService,
    private popupService: PopupService
  ) {
    super(ngZone);
  }

  itemLoader$(): Observable<ApiListResponse['data']> {
    return this.communityApiService.queryRecords(this.params);
  }

  openInListTilesView() {
    const routeData: ItemsListRouteData = {
      title: this.headline,
      params: this.params,
      serviceName: EntityServiceType.CommunityApiService,
      broadcastType: EntityType.CommunityRecord
    };

    this.popupService.open('items/list', { outlet: 'details', data: routeData, skipLocationChange: true });
  }
}
