import { Injectable } from '@angular/core';
import { Upsell, UpsellCta } from '../../interfaces/upsell.interface';
import { PopupService } from '../popup.service';
import { HrefService } from '../../../shared/services/href.service';
import { EventTrackingService } from '@teleboy/web.core';

@Injectable({
  providedIn: 'root'
})
export class LoginUpsellService implements Upsell {
  readonly name = 'login';
  readonly icon = 'navi-user';
  readonly cta: UpsellCta[];

  constructor(
    private popupService: PopupService,
    private hrefService: HrefService,
    private eventTrackingService: EventTrackingService
  ) {
    this.cta = this._cta();
  }

  private _cta(): UpsellCta[] {
    return [
      {
        key: 'login',
        click: () => {
          this.goToLogin();
        }
      },
      {
        key: 'register',
        click: () => this.goToRegister()
      }
    ];
  }

  private goToLogin(): void {
    this.popupService.closeModal();
    this.hrefService.goToUrl('/login', { appendTarget: true });
    this.eventTrackingService.trackEvent('loginUpsell.loginButton');
  }

  private goToRegister(): void {
    this.popupService.closeModal();
    this.hrefService.goToUrl('/registrieren');
  }
}
