import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { DarkmodeService } from '@teleboy/web.user';

@Component({
  selector: 'app-navigation-layout',
  templateUrl: './navigation-layout.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class NavigationLayoutComponent {
  private readonly _scrolled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly scrolled$: Observable<boolean> = this._scrolled$.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public darkmodeService: DarkmodeService
  ) {}

  @HostListener('window:scroll', ['$event']) onScrollEvent() {
    let color: string;

    if (window.scrollY < 5) {
      this._scrolled$.next(false);
      color = this.darkmodeService.darkmodeEnabled ? '#1c1c1c' : '#d2d5de';
    } else {
      this._scrolled$.next(true);
      color = this.darkmodeService.darkmodeEnabled ? '#333333' : '#ffffff';
    }
    this.document.querySelector('meta[name=theme-color]')?.setAttribute('content', color);
  }
}
