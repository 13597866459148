import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  SearchApiService,
  SearchHistoryService,
  SearchParams,
  SearchPrefix,
  SearchResponse,
  SearchSort,
  SearchSource,
  StarAlertListParams,
  StarAlertType,
  SuggestionParams
} from '@teleboy/web.epg';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  finalize,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import { DOCUMENT, NgClass, NgIf, NgFor, AsyncPipe, SlicePipe } from '@angular/common';
import { SlideConfig } from '../../providers/swiper.provider';
import { ScreenService } from '../../../core/services/screen.service';
import { EntityType } from '../../interfaces/items-list-route-data';
import { EventTrackingService } from '@teleboy/web.core';
import { SearchingService } from '../../services/searching.service';
import { AuthenticationService } from '@teleboy/web.user';
import { StarAlertService } from '../../services/star-alert.service';
import { LetDirective } from '@ngrx/component';
import { StationItemComponent } from '../station-item/station-item.component';
import { BroadcastItemComponent } from '../broadcast-item/broadcast-item.component';
import { SeriesSwiperComponent } from '../swiper/series-swiper/series-swiper.component';
import { BroadcastsSwiperComponent } from '../swiper/broadcasts-swiper/broadcasts-swiper.component';
import { StarAlertButtonComponent } from '../star-alert/star-alert-button/star-alert-button.component';
import { StaralertSwiperComponent } from '../swiper/staralert-swiper/staralert-swiper.component';
import { SharedModule } from '../../../shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@teleboy/web.ui';

enum SearchMode {
  TITLE,
  EXTENDED
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SearchHistoryService],
  imports: [
    NgClass,
    NgIf,
    NgFor,
    LetDirective,
    StationItemComponent,
    BroadcastItemComponent,
    SeriesSwiperComponent,
    BroadcastsSwiperComponent,
    FormsModule,
    ReactiveFormsModule,
    StarAlertButtonComponent,
    StaralertSwiperComponent,
    SharedModule,
    SwiperModule,
    AsyncPipe,
    SlicePipe,
    TranslateModule,
    IconComponent
  ]
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('inputSearch') inputSearch!: ElementRef;
  history$!: Observable<string[]>;
  isTyping!: boolean;
  isAuthenticated!: boolean;
  results$!: Observable<SearchResponse>;
  suggestions$!: Observable<string[]>;
  swiperOptions = SlideConfig['default'];
  SearchSource = SearchSource;
  isSearchRecordsFound = true;
  searchQueryMode = SearchPrefix.TITLE;
  searchQuery = '';
  staralertListParams: StarAlertListParams = new StarAlertListParams().setType(StarAlertType.EXACT);
  starAlertsExactReloaded$!: Observable<boolean>;

  protected readonly EntityType = EntityType;
  protected readonly SearchMode = SearchMode;
  protected readonly SearchPrefix = SearchPrefix;
  protected readonly StarAlertType = StarAlertType;

  readonly searching$: Subject<boolean> = new Subject<boolean>();
  readonly ghosts = new Array(15);
  readonly form = new UntypedFormGroup({ query: new UntypedFormControl('') });

  private _timeoutInstance = 0;

  private readonly _showSearch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly showSearch$: Observable<boolean> = this._showSearch$.asObservable();
  private inputChange$!: Observable<string>;
  private readonly BROADCASTS_PER_PAGE = 30;
  private readonly destroy$: Subject<void> = new Subject<void>();
  private readonly EVENT_INTERVAL_VALUE = 3000;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authenticationService: AuthenticationService,
    private renderer2: Renderer2,
    private screenService: ScreenService,
    private searchApiService: SearchApiService,
    private searchHistoryService: SearchHistoryService,
    private changeDetectorRef: ChangeDetectorRef,
    private eventTrackingService: EventTrackingService,
    private searchingService: SearchingService,
    private starAlertService: StarAlertService
  ) {}

  get query(): string {
    return this.form.get('query')?.value;
  }

  ngOnInit(): void {
    this.inputChange$ = this._inputChange$();
    this.history$ = this._history$();
    this.results$ = this._results$();
    this.suggestions$ = this._suggestions$();
    this.results$.pipe(takeUntil(this.destroy$)).subscribe();
    this.isAuthenticated = this.authenticationService.isAuthenticated;
    this.searchingService.resetSearch();

    this.searchingService.searchString$.pipe(takeUntil(this.destroy$)).subscribe((words) => {
      this.searchStaralert(words);
    });

    this.searchingService.showSearch$.pipe(takeUntil(this.destroy$)).subscribe((shown) => {
      this._showSearch$.next(shown);
      this.resetSearch();
    });

    this.starAlertsExactReloaded$ = this.starAlertService.starAlertListReloaded$(StarAlertType.EXACT);
  }

  ngOnDestroy(): void {
    this.resetSearch();
    this.destroy$.next();
    this.destroy$.complete();
  }

  openSearch(): void {
    this._showSearch$.next(true);
    this.inputSearch.nativeElement.focus();

    const body = this.document.body;
    const scrollbarWidth = this.screenService.getScrollbarWidth();
    const bodyStyle = body.style;
    if (scrollbarWidth > 0) {
      const actualPadding = parseFloat(window.getComputedStyle(body).paddingRight);
      bodyStyle.paddingRight = `${actualPadding + scrollbarWidth}px`;
    }
    bodyStyle.overflow = 'hidden';
    this.renderer2.addClass(this.document.body, `search-open`);
  }

  closeSearch(): void {
    this._showSearch$.next(false);
    this.resetStaralertParams();
    const bodyStyle = this.document.body.style;
    bodyStyle.paddingRight = '';
    bodyStyle.removeProperty('overflow');
    this.renderer2.removeClass(this.document.body, `search-open`);
  }

  clearHistory(): void {
    this.searchHistoryService.clear();
  }

  onSubmit(): void {
    this.search(this.form.get('query')?.value);
  }

  search(query: string): void {
    this.searchHistoryService.add(query);
  }

  setQueryToInput(query: string): void {
    this.searchHistoryService.add(query);
    this.form.get('query')?.setValue(query);
  }

  private _inputChange$(): Observable<string> {
    const queryInput = this.form.get('query') as AbstractControl;
    return queryInput.valueChanges.pipe(
      tap(() => (this.isTyping = true)),
      distinctUntilChanged(),
      debounceTime(500),
      tap(() => (this.isTyping = false))
    );
  }

  private _history$(): Observable<string[]> {
    return this.searchHistoryService.history$.pipe(startWith(this.searchHistoryService.getCurrentHistory()));
  }

  private _results$(): Observable<SearchResponse> {
    this.searching$.next(true);
    return this.inputChange$.pipe(
      tap(() => this.searching$.next(true)),
      switchMap((query) => {
        this.searchQuery = query;
        if (query.length <= 1) {
          this.isSearchRecordsFound = false;
        } else {
          this.results$ = this.searchResults$(SearchMode.TITLE);
        }
        this.searching$.next(false);
        return of({});
      }),
      startWith({}),
      tap(() => {
        this.history$ = this._history$();
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  changeSearchMode(mode: SearchMode): void {
    this.searchQueryMode = mode === SearchMode.EXTENDED ? SearchPrefix.QUERY : SearchPrefix.TITLE;
    this.results$ = this.searchResults$(mode);
  }

  searchResults$(mode: SearchMode = SearchMode.TITLE): Observable<SearchResponse> {
    const searchParams = new SearchParams(
      this.searchQuery,
      [
        SearchSource.STATION,
        SearchSource.SERIES,
        SearchSource.LIVE,
        SearchSource.REPLAY,
        SearchSource.EPG,
        SearchSource.COMMUNITY,
        SearchSource.PVR
      ],
      this.searchQueryMode
    )
      .setSort(SearchSort.DATE)
      .setLimit(this.BROADCASTS_PER_PAGE);

    if (mode === SearchMode.EXTENDED) {
      searchParams.allStations();
    }

    return this.searchApiService.search(searchParams).pipe(
      tap((data) => this.checkIfResults(data)),
      tap(() => this.sendGTEvent()),
      finalize(() => this.searching$.next(false))
    );
  }

  private sendGTEvent(): void {
    clearTimeout(this._timeoutInstance);
    this._timeoutInstance = setTimeout(() => {
      this.eventTrackingService.trackEvent('search.query', { query: this.searchQuery });
    }, this.EVENT_INTERVAL_VALUE);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private checkIfResults(data: any): void {
    for (const source in SearchSource) {
      if (data[(SearchSource as never)[source]]?.total > 0) {
        this.isSearchRecordsFound = true;
        break;
      }

      this.isSearchRecordsFound = false;
    }
  }

  private _suggestions$(): Observable<string[]> {
    return this.inputChange$.pipe(
      switchMap((query) => {
        if (query.length <= 1) {
          return of([]);
        } else {
          const params = new SuggestionParams(query, [
            SearchSource.REPLAY,
            SearchSource.COMMUNITY,
            SearchSource.PVR,
            SearchSource.STATION
          ]);

          return this.searchApiService.getSuggestions(params);
        }
      })
    );
  }

  private searchStaralert(words: string): void {
    this.form.get('query')?.setValue(words);
  }

  private resetStaralertParams(): void {
    this.staralertListParams = new StarAlertListParams().setType(StarAlertType.EXACT);
  }

  private resetSearch(): void {
    this.searchQuery = '';
    const bodyStyle = this.document.body.style;
    bodyStyle.paddingRight = '';
    bodyStyle.removeProperty('overflow');
    this.renderer2.removeClass(this.document.body, `search-open`);
  }
}
