import { Injectable } from '@angular/core';
import { Upsell, UpsellCta } from '../../interfaces/upsell.interface';
import { PopupService } from '../popup.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdblockerUpsellService implements Upsell {
  readonly name = 'adblocker';
  readonly icon = 'navi-player-live';
  readonly cta: UpsellCta[];

  constructor(
    private popupService: PopupService,
    private router: Router
  ) {
    this.cta = this._cta();
  }

  private _cta(): UpsellCta[] {
    return [
      {
        key: 'close',
        click: () => this.popupService.closeModal()
      },
      {
        key: 'upgrade',
        click: () => this.goToShop()
      }
    ];
  }

  private goToShop(): void {
    this.popupService.closeModal();
    void this.router.navigate(['/tvshop']);
  }
}
