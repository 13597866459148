<div class="search__container" [ngClass]="{'search-container--show': showSearch$ | async}">

  <div class="search__result">

    <ng-container *ngIf="suggestions$ | async as suggestions">
      <div *ngIf="suggestions.length" class="search__suggestions">
        <h3>{{ 'search.results.suggestions.title' | translate }}</h3>
        <div class="scroll-btn-horizontal">
          <ul>
            <li *ngFor="let suggestion of suggestions">
              <button (click)="setQueryToInput(suggestion)" class="btn btn__secondary btn--sm">
                {{ suggestion }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </ng-container>

    <ng-container *ngrxLet="results$ as results; suspenseTpl: loading">

      <div class="search__result-inner" *ngIf="isSearchRecordsFound; else noResults">

        <ng-container *ngIf="!(searching$ | async) && results; else loading">
          <div *ngIf="results.station && results.station.items.length" class="search__stations">
            <h3>{{ 'search.results.stations.title' | translate }}</h3>
            <ul>
              <li *ngFor="let station of results.station.items">
                <app-station-item [station]="station"></app-station-item>
              </li>
            </ul>
          </div>

          <div *ngIf="results.live && results.live.items.length" class="search__live">
            <h3>{{ 'search.results.live.title' | translate }}</h3>
            <div class="grid">
              <app-broadcast-item *ngFor="let broadcast of results.live.items"
                                  [broadcast]="broadcast"></app-broadcast-item>
            </div>
          </div>

          <div *ngIf="results.series && results.series.items.length" class="search__broadcasts">
            <div class="grid">
              <app-series-swiper
                [series]="results.series.items"
                [headline]="'search.results.series.title' | translate"
                [searchValue]="query"
                [searchSource]="SearchSource.SERIES"
                [searchQueryMode]="searchQueryMode"
              ></app-series-swiper>
            </div>
          </div>

          <div *ngIf="results.replay && results.replay.items.length" class="search__broadcasts">
            <app-broadcasts-swiper
              [broadcasts]="results.replay.items"
              [headline]="'search.results.replay.title' | translate"
              [searchValue]="query"
              [searchSource]="SearchSource.REPLAY"
              [searchQueryMode]="searchQueryMode"
            ></app-broadcasts-swiper>

          </div>

          <div *ngIf="results.recordings && results.recordings.items.length" class="search__broadcasts">
            <app-broadcasts-swiper
              [broadcasts]="results.recordings.items"
              [headline]="'search.results.recordings.title' | translate"
              [searchValue]="query"
              [searchSource]="SearchSource.PVR"
              [searchQueryMode]="searchQueryMode"
            ></app-broadcasts-swiper>
          </div>

          <div *ngIf="results.community_record && results.community_record.items.length" class="search__broadcasts">
            <app-broadcasts-swiper
              [broadcasts]="results.community_record.items"
              [headline]="'search.results.community_record.title' | translate"
              [searchValue]="this.query"
              [searchSource]="SearchSource.COMMUNITY"
              [searchQueryMode]="searchQueryMode"
            ></app-broadcasts-swiper>
          </div>

          <div *ngIf="results.epg && results.epg.items.length" class="search__broadcasts">
            <app-broadcasts-swiper
              [broadcasts]="results.epg.items"
              [headline]="'search.results.epg.title' | translate"
              [searchValue]="this.query"
              [searchSource]="SearchSource.EPG"
              [searchQueryMode]="searchQueryMode"
            ></app-broadcasts-swiper>
          </div>
        </ng-container>

        <ng-container *ngIf="history$ | async as history">
          <div *ngIf="history.length" class="search__history">
            <h2>{{ 'search.results.history.title' | translate }}</h2>
            <ul class="btn__list-v">
              <li *ngFor="let historyItem of history | slice : 0 : 10">
                <button (click)="setQueryToInput(historyItem)"
                        class="btn btn__secondary btn--sm">{{historyItem}}</button>
              </li>
            </ul>
            <p>
              <button (click)="clearHistory()"
                      class="btn btn__link-like btn__icon">{{ 'search.results.history.clear' | translate }}</button>
            </p>
          </div>
        </ng-container>

        <div class="search-expand--container full-width"
             *ngIf="isSearchRecordsFound && searchQueryMode === SearchPrefix.QUERY && searchQuery !== ''">
          <span class="info__text" [innerHTML]="'search.results.search.infoText' | translate"></span>
          <div class="mt-base">
            <button class="btn btn__primary btn--sm" (click)="changeSearchMode(SearchMode.TITLE)">
              {{ 'search.results.search.byTitle' | translate }}
            </button>
          </div>
        </div>

        <div class="search-expand--container full-width"
             *ngIf="isSearchRecordsFound && searchQueryMode === SearchPrefix.TITLE && searchQuery !== ''">
          <span class="info__text" [innerHTML]="'search.results.search.notFoundText' | translate"></span>
          <div class="mt-base">
            <button class="btn btn__primary btn--sm" (click)="changeSearchMode(SearchMode.EXTENDED)">
              {{ 'search.results.search.extendSearch' | translate }}
            </button>
          </div>
        </div>

      </div>
    </ng-container>
  </div>

  <div class="search__form">
    <form [formGroup]="form" (submit)="onSubmit()">
      <input type="text" formControlName="query" #inputSearch [placeholder]="'search.form.placeholder' | translate">
    </form>
  </div>

  <div class="search__right-buttons">
    <div class="search__staralert-button" *ngIf="isAuthenticated">
      <app-star-alert-button *ngIf="(showSearch$ | async) && searchQuery"
                             [ngClass]="{'hidden': isTyping}"
                             [words]="searchQuery" [type]="StarAlertType.EXACT">
      </app-star-alert-button>
    </div>

    <div class="search__btn">
      <button class="btn btn__icon btn--circle btn--lg" (click)="openSearch()" *ngIf="!(showSearch$ | async)">
        <tbui-icon iconId="navi-search"></tbui-icon>
      </button>

      <button class="btn btn__icon btn--circle btn--lg" (click)="closeSearch()" *ngIf="showSearch$ | async">
        <tbui-icon iconId="close"></tbui-icon>
      </button>
    </div>
  </div>

</div>

<ng-template #noResults>
  <div class="search__result-inner">

    <div class="staralert--container" *ngIf="!(starAlertsExactReloaded$ | async)">
      <app-staralert-swiper *ngIf="isAuthenticated && (showSearch$ | async)"
                            [params]="staralertListParams"
                            [isLinkClickable]="true"
                            [headline]="'search.savedSearch.title' | translate">
      </app-staralert-swiper>
    </div>

    <ng-container *ngIf="history$ | async as history">
      <div *ngIf="history.length" class="search__history">
        <h2>{{ 'search.results.history.title' | translate }}</h2>
        <ul class="btn__list-v">
          <li *ngFor="let historyItem of history | slice : 0 : 10">
            <button (click)="setQueryToInput(historyItem)"
                    class="btn btn__secondary btn--sm">{{historyItem}}</button>
          </li>
        </ul>
        <p>
          <button (click)="clearHistory()"
                  class="btn btn__link-like btn__icon">{{ 'search.results.history.clear' | translate }}</button>
        </p>
      </div>
    </ng-container>

    <div class="no-results--container full-width" *ngIf="searchQuery.length > 1">
      <div class="search-icon">
        <tbui-icon iconId="navi-search" [inline]="false" [size]="'xxl'" [color]="'brand'"></tbui-icon>
      </div>
      <div>
        <h4 class="no-results--header-text">{{ 'search.results.noResults.title' | translate }}</h4>
      </div>
      <div>
        <h3 class="mt-tiny no-results--text">{{ 'search.results.noResults.text' | translate }}</h3>
      </div>
      <div class="mt-tiny" *ngIf="searchQueryMode === SearchPrefix.TITLE">
        <button class="btn btn__primary btn--sm" (click)="changeSearchMode(SearchMode.EXTENDED)">
          {{ 'search.results.search.all' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="search__loading--container search__broadcasts swiper__container"
       *ngFor="let source of [SearchSource.REPLAY, SearchSource.PVR, SearchSource.COMMUNITY, SearchSource.EPG]">
    <app-expand-link [linkText]="('search.results.' + source + '.title') | translate" [clickable]="false"></app-expand-link>
    <swiper [slidesPerView]="swiperOptions.slidesPerView" [breakpoints]="swiperOptions.breakpoints"
            class="ghost-listing ghost-listing--horizontal">
      <ng-template *ngFor="let ghost of ghosts" swiperSlide>
        <app-broadcast-item [card]="true"></app-broadcast-item>
      </ng-template>
    </swiper>
    <span>&nbsp;</span>
  </div>
</ng-template>
