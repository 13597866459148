import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, NgZone, Output } from '@angular/core';
import { ListSwiperDirective } from '../../../directives/swiper/list-swiper.directive';
import { Broadcast, SearchApiService, SearchParams, SearchSource } from '@teleboy/web.epg';
import { map, Observable } from 'rxjs';
import { ApiListResponse } from '@teleboy/web.core';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { BroadcastItemComponent } from '../../broadcast-item/broadcast-item.component';

@Component({
  selector: 'app-search-swiper',
  templateUrl: './search-swiper.component.html',
  styleUrls: ['../swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, SwiperModule, NgFor, BroadcastItemComponent, AsyncPipe]
})
export class SearchSwiperComponent extends ListSwiperDirective<Broadcast> {
  @Input() override params!: SearchParams;

  @Output() detailClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    @Inject(NgZone) ngZone: NgZone,
    private searchApiService: SearchApiService
  ) {
    super(ngZone);
  }

  itemLoader$(): Observable<ApiListResponse['data']> {
    const source = this.params.httpParams.get('source') as SearchSource;

    if (!source) {
      throw new Error('Please provide a SearchSource');
    }

    return this.searchApiService
      .search(this.params)
      .pipe(map((data) => data[source] as { items: unknown[]; total: number }));
  }

  onBroadcastItemClick(): void {
    this.detailClick.emit();
  }
}
