import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Broadcast, LiveEpgService } from '@teleboy/web.epg';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  Observable,
  startWith,
  Subject,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { PlayerListingService } from './player-listing.service';
import { LocalStorage } from 'ngx-webstorage';
import { PlayerUiService } from '../../../services/player-ui.service';
import { PlayerSize, PlayerSizeService } from '../../../services/player-size.service';
import { ScreenService } from '../../../../core/services/screen.service';
import { ScrollableItemDirective } from '../../../../shared/directives/scrollable-item.directive';
import { AuthenticationService } from '@teleboy/web.user';
import { BitmovinService } from '@teleboy/web.player';
import { Router } from '@angular/router';

export enum ToggleListSource {
  Button,
  Backdrop
}

@Component({
  selector: 'app-player-listing',
  templateUrl: './player-listing.component.html',
  styleUrls: ['player-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PlayerListingComponent implements OnInit, OnDestroy {
  @Input() connectedToPlayerUi = false;
  @Input() isBrandingDay = false;
  @Input() closed = true;

  @LocalStorage('playerListingShown')
  playerListingShown!: boolean;

  @ViewChild('wrapper')
  wrapper!: ElementRef;

  @ViewChildren(ScrollableItemDirective)
  scrollableItems!: QueryList<ScrollableItemDirective>;

  liveEpg$!: Observable<Broadcast[]>;
  playerListIsExpanded = false;
  reloadButton$!: Observable<boolean>;
  playerUiShown$!: Observable<boolean>;

  readonly ToggleListSource = ToggleListSource;

  private readonly destroy$: Subject<void> = new Subject<void>();
  private readonly PLAYER_LISTING_OPEN_CLASS = 'player-listing-open';

  constructor(
    public screenService: ScreenService,
    @Inject(DOCUMENT) private document: Document,
    private authenticationService: AuthenticationService,
    private bitmovinService: BitmovinService,
    private changeDetectorRef: ChangeDetectorRef,
    private liveEpgService: LiveEpgService,
    private playerSizeService: PlayerSizeService,
    private playerListingService: PlayerListingService,
    private playerUiService: PlayerUiService,
    private renderer2: Renderer2,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.reloadButton$ = this.liveEpgService.requestLimitReached$;
    this.playerUiShown$ = this.playerUiService.ui$.pipe(distinctUntilChanged(), startWith(true));
    this.liveEpg$ = this.liveEpgService.epg$;
    this.setupListingUpdater();

    this.playerListingService.playerListingTrackMouseMove = true;

    if (this.playerListingShown === null) {
      // Set listing visibility to true if user doesn't have a subscription
      // nor changed the visilibity state yes
      this.playerListingShown =
        !this.authenticationService.isAuthenticated || this.authenticationService.user.isFreeUser;
    }

    if (this.connectedToPlayerUi) {
      if (!this.closed && this.playerListingShown) {
        this.openList();
      }

      this.playerUiService.ui$
        .pipe(
          distinctUntilChanged(),
          filter(() => this.playerListingService.playerListingTrackMouseMove && this.playerSizeService.isLargePlayer()),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }

    this.bitmovinService.play$
      .pipe(
        withLatestFrom(this.playerListingService.listingActiveIdSet$),
        tap(([_, id]) => {
          const item = this.scrollableItems.find((x) => x.key === id);
          item?.scrollIntoView({ behavior: 'smooth' });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (!this.router.url.startsWith('/live')) {
      this.liveEpgService.stopReload();
    }
    this.renderer2.removeClass(this.document.body, this.PLAYER_LISTING_OPEN_CLASS);
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleList(source = ToggleListSource.Backdrop): void {
    this.playerListIsExpanded = !this.playerListIsExpanded;
    this.playerListingShown = this.playerListIsExpanded;
    this.playerListingService.setTrackMouseMoveFlag(this.playerListIsExpanded, source);

    this.liveEpgService.setPaused(!this.playerListIsExpanded);

    if (this.connectedToPlayerUi) {
      return;
    }

    if (this.playerListIsExpanded) {
      this.renderer2.addClass(this.document.body, this.PLAYER_LISTING_OPEN_CLASS);
    } else {
      this.renderer2.removeClass(this.document.body, this.PLAYER_LISTING_OPEN_CLASS);
    }
  }

  openList(): void {
    this.playerListIsExpanded = true;
    this.changeDetectorRef.markForCheck();
  }

  onMouseEnter() {
    if (!this.connectedToPlayerUi) {
      return;
    }
    this.playerUiService.setCustomControlFocusedState(true);
  }

  onMouseLeave() {
    if (!this.connectedToPlayerUi) {
      return;
    }
    this.playerUiService.setCustomControlFocusedState(false);
  }

  reload() {
    this.liveEpgService.reload(true);
  }

  private setupListingUpdater(): void {
    this.screenService.isInBackground$
      .pipe(
        tap((inBackground: boolean) => this.toggleReload(inBackground)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    const playerSize$ = this.playerSizeService.size$.pipe(distinctUntilChanged(), startWith(PlayerSize.LARGE));
    const play$ = this.bitmovinService.play$.pipe(startWith(false));

    combineLatest([this.playerUiShown$, playerSize$, play$])
      .pipe(
        distinctUntilChanged(),
        tap(([playerUiShown, playerSize, _]) => {
          const paused = !this.listingVisibleOnScreen(playerUiShown);

          if (playerSize === PlayerSize.MINI && !this.router.url.startsWith('/live')) {
            this.liveEpgService.setPaused(true);
            return;
          }

          if (playerSize === PlayerSize.MINI && this.router.url.startsWith('/live')) {
            this.liveEpgService.reload();
            return;
          }

          this.liveEpgService.setPaused(paused);
        }),

        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private listingVisibleOnScreen(playerUiShown: boolean): boolean {
    return playerUiShown && this.playerListIsExpanded;
  }

  private toggleReload(inBackground: boolean) {
    if (this.listingVisibleOnScreen(this.playerUiService.shown) && !inBackground) {
      this.liveEpgService.reload();
    } else {
      this.liveEpgService.stopReload();
    }
  }
}
