import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  signal,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import {
  Broadcast,
  EpgApiService,
  EpgParams,
  EpgRangeType,
  Station,
  StationOrderStorageService
} from '@teleboy/web.epg';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  forkJoin,
  fromEvent,
  interval,
  map,
  Observable,
  scan,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throttleTime
} from 'rxjs';
import { DOCUMENT, NgClass, NgIf, NgFor, NgStyle, AsyncPipe, KeyValuePipe } from '@angular/common';
import { ChangeContext, Options, NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScreenService } from '../../../core/services/screen.service';
import { MetaService } from '../../../core/services/meta.service';
import { DropdownOption } from '../../../shared/components/dropdown/dropdown.component';
import moment, { Moment } from 'moment';
import { SharedModule } from '../../../shared/shared.module';
import { SearchComponent } from '../search/search.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { EpgStationComponent } from './epg-station/epg-station.component';

interface StationEpg {
  station: Station;
  broadcasts: Broadcast[];
}

@Component({
  selector: 'app-epg',
  templateUrl: './epg.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SearchComponent,
    NgIf,
    NgxSliderModule,
    InfiniteScrollDirective,
    NgFor,
    EpgStationComponent,
    NgStyle,
    AsyncPipe,
    KeyValuePipe,
    TranslateModule
  ]
})
export class EpgComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('epgScrollView') epgScrollView!: ElementRef;
  @ViewChild('liveIndicator') liveIndicator!: ElementRef;

  allStationsLoaded = false;
  dropDownoptions: DropdownOption[] = [];
  epg$!: Observable<Record<string, StationEpg>>;
  middleStationLaneIndex = this.screenService.isMobileSizeScreen() ? 7 : 12;
  minutesToLive = moment().diff(moment().startOf('day'), 'minutes');
  now = moment().diff(moment().startOf('day'), 'minutes');
  stations$!: Observable<Station[]>;
  timeline: string[] = [];
  loadingRows: string[] = [];
  sliderValue!: number;
  initialLoad = true;
  scrollOffset!: number;
  today!: Moment;
  tomorrow!: Moment;
  yesterday!: Moment;

  readonly itemsCountDisplayedAboveLaneIndex = this.screenService.isMobileSizeScreen() ? 10 : 24;
  readonly pixelPerMinute = 10;
  readonly pixelPerMinuteMobile = 4;
  readonly primetime = 20;
  readonly activeDay$: BehaviorSubject<Moment> = new BehaviorSubject<Moment>(moment());
  readonly busy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly midnight = signal(false);
  readonly scrolling$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly sliderOptions: Options = {
    floor: this.hourToPixel(1),
    ceil: this.hourToPixel(this.getHoursOfDay(this.activeDay$.value)),
    step: this.hourToPixel(1),
    showTicks: true,
    tickStep: this.hourToPixel(1),
    showTicksValues: true,
    translate: (sliderValue: number): string => {
      return '' + this.pixelToString(sliderValue);
    }
  };

  readonly form = new UntypedFormGroup({
    date: new UntypedFormControl(null)
  });

  // The approximate vertical center position on the screen
  private positionOffset = this.screenService.isMobileSizeScreen() ? 6 : 10;
  private totalStations!: number;
  private viewRefreshTimeout = moment().valueOf();

  private readonly destroy$: Subject<void> = new Subject<void>();
  private readonly paginate$: Subject<void> = new Subject<void>();
  private readonly scrollSubject = new Subject<number>();
  private readonly STATIONS_PER_PAGE = 20;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private changeDetectorRef: ChangeDetectorRef,
    private epgApiService: EpgApiService,
    private metaService: MetaService,
    private translateService: TranslateService,
    private screenService: ScreenService,
    private stationOrderStorageService: StationOrderStorageService
  ) {}

  ngOnInit(): void {
    this.initData();
    this.refreshAfterMidnight();
  }

  ngAfterViewInit() {
    fromEvent(this.epgScrollView.nativeElement, 'scroll')
      .pipe(
        throttleTime(300),
        tap(() => this.scrolling$.next(true)),
        debounceTime(500),
        tap(() => this.scrolling$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private initData(): void {
    this.yesterday = moment().subtract(1, 'day').startOf('day');
    this.today = moment().startOf('day');
    this.tomorrow = moment().add(1, 'day').startOf('day');

    this.stations$ = this.stationOrderStorageService.getUserStations();
    this.document.body.classList.add('view-epg');
    this.getDaysDropdownOptions();

    let skipOffset = 0;

    for (let i = 0; i < this.getHoursOfDay(this.activeDay$.value) + 1; i++) {
      this.timeline.push(`${i < 10 ? '0' : ''}${i}:00`);
    }

    for (let i = 0; i < 10; i++) {
      this.loadingRows.push(`-${i * (Math.floor(Math.random() * (1000 - 1 + 1)) + 1)}px`);
    }

    this.setDropdownValue(this.activeDay$.value);

    this.epg$ = this.activeDay$.pipe(
      // Deny loading the same day twice in a row
      distinctUntilChanged(),
      // Subscribe to pagination within the active day
      switchMap(() => this.skipOffset$()),
      // Slice stations for the current offset and defined limit
      switchMap((skip) => {
        skipOffset = skip;
        return this.slicedStations$(skip);
      }),
      // Load epg for all sliced stations
      switchMap((stations) => this.stationEpgs$(stations)),
      // Emit merged epg objects or a new set if the skip offset has been reset
      scan((accItems, items) => (skipOffset === 0 ? items : { ...accItems, ...items }), {}),
      tap((loadedStations) => {
        if (Object.keys(loadedStations).length >= this.totalStations) {
          this.allStationsLoaded = true;
        }
      })
    );

    this.metaService.setTitle([this.translateService.instant('epg.title')]);

    this.scrollSubject.pipe(takeUntil(this.destroy$)).subscribe((x) => this.updateMiddeStationIndex(x));
  }

  @HostListener('window:scroll', ['$event'])
  onScrollEvent() {
    const itemHeight = this.screenService.isMobileSizeScreen() ? 50 : 80;
    const index = Math.round(window.scrollY / itemHeight) + this.positionOffset;
    this.scrollSubject.next(index);
  }

  // SCROLLING
  setSliderValueToView(changeContext: ChangeContext): void {
    this.scrollViewTo(changeContext.value);
  }

  // HELPERS
  getEpgDropdownLabel(day: Moment): string {
    let stringValue = day.format('dd, DD. MMMM');
    let transKey = '';

    if (day.isSame(moment(), 'day')) {
      transKey = 'epg.nav.today';
    } else if (day.isSame(moment().subtract(1, 'day'), 'day')) {
      transKey = 'epg.nav.yesterday';
    } else if (day.isSame(moment().add(1, 'day'), 'day')) {
      transKey = 'epg.nav.tomorrow';
    }

    if (transKey !== '') {
      this.translateService.get(transKey).subscribe((res: string) => {
        stringValue = res;
      });
    }

    return stringValue;
  }

  scrollView($event: Event): void {
    const element = $event.currentTarget as HTMLInputElement;
    if (moment().valueOf() > this.viewRefreshTimeout + 500) {
      this.viewRefreshTimeout = moment().valueOf();
      this.sliderValue = Math.round(element.scrollLeft + this.scrollOffset);
    }
  }

  scrollViewTo(pixel: number) {
    pixel = pixel - this.scrollOffset;
    this.epgScrollView.nativeElement.scrollLeft = pixel;
  }

  // HELPERS
  isToday() {
    return this.today.isSame(this.activeDay$.value, 'day');
  }

  sortNull(): number {
    return 0;
  }

  trackById: TrackByFunction<Record<string, StationEpg>> = (idx, { station }) => station?.station.id;

  hourToPixel(hour: number) {
    return hour * 60 * this.getPixelsPerMinute();
  }

  private scrollViewInitTo() {
    if (this.today.isSame(this.activeDay$.value, 'day')) {
      this.sliderValue = this.minuteToPixel(this.now);
    } else {
      this.sliderValue = this.hourToPixel(this.primetime);
    }

    this.scrollOffset = this.epgScrollView.nativeElement.offsetWidth / 2;
    this.scrollViewTo(this.sliderValue);
  }

  minuteToPixel(minutes: number) {
    return minutes * this.getPixelsPerMinute();
  }

  private pixelToHour(pixel: number) {
    return pixel / 60 / this.getPixelsPerMinute();
  }

  private getPixelsPerMinute() {
    let pixels = this.pixelPerMinute;
    if (this.screenService.isMobileSizeScreen()) {
      pixels = this.pixelPerMinuteMobile;
    }
    return pixels;
  }

  private pixelToString(pixel: number) {
    return moment().hour(this.pixelToHour(pixel)).format('HH:00');
  }

  // NAVIGATE DAY
  private setDropdownValue(day: Moment): void {
    const formObject = { label: this.getEpgDropdownLabel(day), value: day };
    this.form.patchValue(
      {
        date: formObject
      },
      { emitEvent: false }
    );
  }

  setDayShortcut(day: Moment): void {
    if (day.isSame(this.activeDay$.value, 'day')) {
      return;
    }
    this.setDropdownValue(day);
    this.setDay(day);
  }

  setDay(day: Moment): void {
    if (day.isSame(this.activeDay$.value, 'day')) {
      return;
    }
    this.isLoading$.next(true);
    this.initialLoad = true;
    this.activeDay$.next(day);
    window.scroll(0, 0);

    // The data and UI will be reloaded if button/dropdown is clicked after midnight.
    this.midnight.set(this.tomorrow.isBefore());

    if (this.midnight() && this.activeDay$.value === this.today) {
      this.initData();
      this.midnight.set(false);
    }
  }

  // PAGINATION§
  loadMore(): void {
    if (this.busy$.value) {
      return;
    }

    this.busy$.next(true);
    this.paginate$.next();
  }

  private getHoursOfDay(day: Moment): number {
    const a = moment(day).startOf('day');
    const b = moment(day).add(1, 'day').startOf('day');
    return b.diff(a, 'hours');
  }

  private updateMiddeStationIndex(index: number) {
    this.middleStationLaneIndex = index;
  }

  private skipOffset$(): Observable<number> {
    return this.paginate$.pipe(
      scan((currSkip) => currSkip + this.STATIONS_PER_PAGE, 0),
      startWith(0)
    );
  }

  private slicedStations$(skip: number): Observable<Station[]> {
    return this.stationOrderStorageService.getUserStations().pipe(
      tap((stations) => (this.totalStations = stations.length)),
      map((stations) => stations.slice(skip, this.STATIONS_PER_PAGE + skip))
    );
  }

  private stationEpgs$(stations: Station[]): Observable<Record<string, StationEpg>> {
    const sources: Record<string, Observable<StationEpg>> = {};
    this.busy$.next(true);
    stations.forEach((station) => (sources[station.name] = this.getEpgForStation$(station)));
    return forkJoin(sources).pipe(
      finalize(() => {
        if (this.isToday()) {
          this.liveIndicator.nativeElement.style.left = `${this.minuteToPixel(this.minutesToLive)}px`;
        }
        this.busy$.next(false);
        this.isLoading$.next(false);
        if (this.initialLoad) {
          this.scrollViewInitTo();
          this.initialLoad = false;
        }
      })
    );
  }

  private getEpgForStation$(station: Station): Observable<StationEpg> {
    const params = new EpgParams()
      .setBegin(this.activeDay$.value.clone().startOf('day'))
      .setEnd(this.activeDay$.value.clone().add(1, 'day').startOf('day').add(1, 'hours'))
      .setRangeType(EpgRangeType.INTERSECT)
      .setStation(station.id);

    return this.epgApiService.getBroadcasts(params.httpParams).pipe(
      map((response) => response.items),
      map((broadcasts) => ({ station, broadcasts }))
    );
  }

  private refreshAfterMidnight(): void {
    interval(60000)
      .pipe(
        filter(() => this.tomorrow.isBefore()),
        tap(() => {
          this.midnight.set(true);
          this.updateUIAfterMidnight();

          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private updateUIAfterMidnight(): void {
    this.yesterday = moment().subtract(1, 'day').startOf('day');
    this.today = moment().startOf('day');
    this.tomorrow = moment().add(1, 'day').startOf('day');

    this.minutesToLive = moment().diff(moment().startOf('day'), 'minutes');
    this.now = moment().diff(moment().startOf('day'), 'minutes');

    this.dropDownoptions = [];
    // Today offset is one day after yesterday after midnight
    this.getDaysDropdownOptions();

    this.setDropdownValue(this.yesterday);
    this.sliderOptions.ceil = this.hourToPixel(this.getHoursOfDay(this.activeDay$.value));
  }

  private getDaysDropdownOptions(): void {
    const epgRange = Array.from(Array(14 - -7 + 1).keys()).map((x) => x + -7);

    epgRange.forEach((dayOffset) => {
      const epgDay = moment().startOf('day').add(dayOffset, 'days');
      this.dropDownoptions.push({
        label: this.getEpgDropdownLabel(epgDay),
        value: epgDay
      });
    });
  }

  ngOnDestroy() {
    this.document.body.classList.remove('view-epg');
    this.destroy$.next();
    this.destroy$.complete();
  }
}
