"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VolumeControlButton = void 0;
var container_1 = require("./container");
var volumeslider_1 = require("./volumeslider");
var volumetogglebutton_1 = require("./volumetogglebutton");
var timeout_1 = require("../timeout");
/**
 * A composite volume control that consists of and internally manages a volume control button that can be used
 * for muting, and a (depending on the CSS style, e.g. slide-out) volume control bar.
 *
 * @category Buttons
 */
var VolumeControlButton = /** @class */function (_super) {
  __extends(VolumeControlButton, _super);
  function VolumeControlButton(config) {
    if (config === void 0) {
      config = {};
    }
    var _this = _super.call(this, config) || this;
    _this.volumeToggleButton = new volumetogglebutton_1.VolumeToggleButton();
    _this.volumeSlider = new volumeslider_1.VolumeSlider({
      vertical: config.vertical != null ? config.vertical : true,
      hidden: true
    });
    _this.config = _this.mergeConfig(config, {
      cssClass: 'ui-volumecontrolbutton',
      components: [_this.volumeToggleButton, _this.volumeSlider],
      hideDelay: 500
    }, _this.config);
    return _this;
  }
  VolumeControlButton.prototype.configure = function (player, uimanager) {
    var _this = this;
    _super.prototype.configure.call(this, player, uimanager);
    var volumeToggleButton = this.getVolumeToggleButton();
    var volumeSlider = this.getVolumeSlider();
    this.volumeSliderHideTimeout = new timeout_1.Timeout(this.getConfig().hideDelay, function () {
      volumeSlider.hide();
    });
    /*
     * Volume Slider visibility handling
     *
     * The volume slider shall be visible while the user hovers the mute toggle button, while the user hovers the
     * volume slider, and while the user slides the volume slider. If none of these situations are true, the slider
     * shall disappear.
     */
    var volumeSliderHovered = false;
    volumeToggleButton.getDomElement().on('mouseenter', function () {
      // Show volume slider when mouse enters the button area
      if (volumeSlider.isHidden()) {
        volumeSlider.show();
      }
      // Avoid hiding of the slider when button is hovered
      _this.volumeSliderHideTimeout.clear();
    });
    volumeToggleButton.getDomElement().on('mouseleave', function () {
      // Hide slider delayed when button is left
      _this.volumeSliderHideTimeout.reset();
    });
    volumeSlider.getDomElement().on('mouseenter', function () {
      // When the slider is entered, cancel the hide timeout activated by leaving the button
      _this.volumeSliderHideTimeout.clear();
      volumeSliderHovered = true;
    });
    volumeSlider.getDomElement().on('mouseleave', function () {
      // When mouse leaves the slider, only hide it if there is no slide operation in progress
      if (volumeSlider.isSeeking()) {
        _this.volumeSliderHideTimeout.clear();
      } else {
        _this.volumeSliderHideTimeout.reset();
      }
      volumeSliderHovered = false;
    });
    volumeSlider.onSeeked.subscribe(function () {
      // When a slide operation is done and the slider not hovered (mouse outside slider), hide slider delayed
      if (!volumeSliderHovered) {
        _this.volumeSliderHideTimeout.reset();
      }
    });
  };
  VolumeControlButton.prototype.release = function () {
    _super.prototype.release.call(this);
    this.volumeSliderHideTimeout.clear();
  };
  /**
   * Provides access to the internally managed volume toggle button.
   * @returns {VolumeToggleButton}
   */
  VolumeControlButton.prototype.getVolumeToggleButton = function () {
    return this.volumeToggleButton;
  };
  /**
   * Provides access to the internally managed volume silder.
   * @returns {VolumeSlider}
   */
  VolumeControlButton.prototype.getVolumeSlider = function () {
    return this.volumeSlider;
  };
  return VolumeControlButton;
}(container_1.Container);
exports.VolumeControlButton = VolumeControlButton;