import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { TrackJS } from 'trackjs';
import packageInfo from '../package.json';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs, LogsEvent } from '@datadog/browser-logs';

if (environment.production) {
  enableProdMode();
}

TrackJS.install({
  token: environment.trackJs.token,
  application: environment.trackJs.application,
  version: packageInfo.version,
  network: { enabled: false }
});

datadogRum.init({
  applicationId: packageInfo.datadog.rum.applicationId,
  clientToken: packageInfo.datadog.rum.clientToken,
  service: packageInfo.datadog.rum.service,
  version: packageInfo.version,
  site: 'datadoghq.eu',
  env: environment.datadog.rum.env,
  sessionSampleRate: environment.datadog.rum.sessionSampleRate,
  sessionReplaySampleRate: environment.datadog.rum.sessionReplaySampleRate,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  traceSampleRate: environment.datadog.rum.traceSampleRate,
  allowedTracingUrls: [
    {
      match: environment.api.url,
      propagatorTypes: ['datadog']
    },
    {
      match: environment.webui.url,
      propagatorTypes: ['datadog']
    }
  ]
});

datadogLogs.init({
  clientToken: packageInfo.datadog.logger.clientToken,
  site: 'datadoghq.eu',
  service: packageInfo.datadog.logger.service,
  version: packageInfo.version,
  forwardErrorsToLogs: true,
  forwardConsoleLogs: ['error'],
  sessionSampleRate: environment.datadog.logger.sessionSampleRate,
  telemetrySampleRate: environment.datadog.logger.telemetrySampleRate,
  env: environment.datadog.logger.env,
  beforeSend: (log: LogsEvent) => {
    /**
     * Cancelled requests needs to be filtered here,
     * since they're not catched by the global error handler
     */
    const isCancelledRequest = log.http?.status_code === 0;
    const isTrackJsLog = log.message?.startsWith('TrackJS Caught:');

    return !isCancelledRequest && !isTrackJsLog;
  }
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
