import { ChangeDetectorRef, Directive, HostListener, Input } from '@angular/core';
import { Broadcast } from '@teleboy/web.epg';
import { BroadcastActionService } from '../../services/broadcast-action.service';
import { BroadcastActionDirective } from './broadcast-action.directive';

@Directive({
  selector: 'button[appBroadcastTrailer]'
})
export class BroadcastTrailerDirective extends BroadcastActionDirective {
  @Input('appBroadcastTrailer') broadcast!: Broadcast;

  constructor(
    protected override changeDetectionRef: ChangeDetectorRef,
    private broadcastActionService: BroadcastActionService
  ) {
    super(changeDetectionRef);
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    this.performAction(() => this.broadcastActionService.handleTrailer$(this.broadcast));
  }
}
