import { ChangeDetectionStrategy, Component, signal, WritableSignal } from '@angular/core';
import { DataSwiperDirective } from '../../../../epg/directives/swiper/data-swiper.directive';
import {
  RecordingApiService,
  RecordingGroup,
  RecordingGroupsParams,
  RecordingGroupsSort,
  RecordingGroupType
} from '@teleboy/web.epg';
import { finalize, Observable } from 'rxjs';
import { ApiListResponse } from '@teleboy/web.core';
import { Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { BroadcastItemComponent } from '../../../../epg/components/broadcast-item/broadcast-item.component';
import { SharedModule } from '../../../../shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { PvrTitlegroupPreviewComponent } from '../../previews/pvr-titlegroup-preview/pvr-titlegroup-preview.component';
import { PvrGridButtonComponent } from '../../mics/pvr-grid-button/pvr-grid-button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-pvr-titlegroup-preview-list',
  imports: [
    AsyncPipe,
    BroadcastItemComponent,
    SharedModule,
    SwiperModule,
    PvrTitlegroupPreviewComponent,
    PvrGridButtonComponent,
    TranslateModule
  ],
  templateUrl: './pvr-titlegroup-preview-list.component.html',
  styleUrls: ['./pvr-titlegroup-preview-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PvrTitlegroupPreviewListComponent extends DataSwiperDirective<RecordingGroup> {
  readonly busy: WritableSignal<boolean> = signal(true);

  constructor(
    private recordingApiService: RecordingApiService,
    private router: Router
  ) {
    super();
  }

  openTitleGroupsList(): void {
    void this.router.navigate([{ outlets: { ['landingpage']: 'pvr/titlegroups' } }]);
  }

  override itemLoader$(): Observable<ApiListResponse['data']> {
    const recordingGroupsParams = new RecordingGroupsParams()
      .setGenre(RecordingGroupsParams.SERIES_GENRES)
      .setSort(RecordingGroupsSort.COUNT)
      .setLimit(10);

    return this.recordingApiService
      .group(RecordingGroupType.TITLE, recordingGroupsParams)
      .pipe(finalize(() => this.busy.set(false)));
  }
}
