<ng-container *ngIf="isCommunityMember; then welcome else joinForm"></ng-container>

<ng-template #joinForm>
  <app-close-button (closed)="close()"></app-close-button>
  <div class="popup__body popup__body__scrollable">
      <div class="cpvr-icon">
        <tbui-icon iconId="community" color="brand"></tbui-icon>
      </div>
      <h1 class="h2">{{ 'cpvr.join.title' | translate }}</h1>
      <p>{{ 'cpvr.join.text' | translate }}</p>

      <h2 class="h3">{{ 'cpvr.join.lend.title' | translate }}</h2>
      <ul class="bullet-list">
        <li>{{ 'cpvr.join.lend.li1' | translate }}</li>
        <li>{{ 'cpvr.join.lend.li2' | translate }}</li>
        <li>{{ 'cpvr.join.lend.li3' | translate }}</li>
      </ul>

      <h2 class="h3">{{ 'cpvr.join.lendOut.title' | translate }}</h2>
      <ul class="bullet-list">
        <li>{{ 'cpvr.join.lendOut.li1' | translate }}</li>
        <li>{{ 'cpvr.join.lendOut.li2' | translate }}</li>
      </ul>

      <form (ngSubmit)="join()" [formGroup]="form" class="cpvr-join__form">
        <fieldset>
          <legend class="visually-hidden">{{ 'cpvr.join.lendOut.title' | translate }}</legend>
          <div class="form-item-check">
            <input type="checkbox" id="cpvr-join-shareRecords" formControlName="share_records">
            <label for="cpvr-join-shareRecords">{{ 'cpvr.join.shareRecords.label' | translate }}</label>
            <ng-container *ngTemplateOutlet="validationShareRecords"></ng-container>
          </div>

          <div class="form-item-check">
            <input type="checkbox" id="cpvr-join-autoAccept" formControlName="friendship_auto_accept">
            <label for="cpvr-join-autoAccept">{{ 'cpvr.join.friendshipAutoAccept.label' | translate }}</label>
          </div>
        </fieldset>

        <div class="form-item">
          <label for="cpvr-join-nickname">{{ 'cpvr.join.nickname.label' | translate }}</label>
          <input id="cpvr-join-nickname" type="text" formControlName="nickname" appAutofocus>
          <ng-container *ngTemplateOutlet="validateNickname"></ng-container>
        </div>

        <div class="btn__list-h btn__list-h--center">
          <input type="submit" class="btn btn__primary"
                 [value]="'cpvr.join.submit.label' | translate" [attr.aria-disabled]="busy$ | async">
        </div>
      </form>

      <p class="collapse text-center"><small>{{ 'cpvr.join.disclaimer' | translate }}</small></p>
  </div>
</ng-template>

<ng-template #welcome>
  <app-close-button (closed)="close()"></app-close-button>
  <div class="popup__body">
    <app-info icon="community"
              [title]="'cpvr.join.welcome.title' | translate"
              [text]="'cpvr.join.welcome.text' | translate">
    </app-info>

    <div class="popup__footer btn__list-h btn__list-h--apart">
      <button (click)="close()" class="btn btn__secondary">{{ 'cpvr.join.welcome.cta.close' | translate }}</button>
      <button *ngIf="broadcast" (click)="befriend()" class="btn btn__primary" appAutofocus>
        {{ 'cpvr.join.welcome.cta.befriend' | translate: {nickname: broadcast.community!.provider.nickname} }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #validationShareRecords>
  <div class="invalid-feedback">
    <ng-container *ngIf="this.form.get('share_records')?.hasError('required')">
      {{ 'cpvr.join.shareRecords.error.required' | translate }}
    </ng-container>
  </div>
</ng-template>

<ng-template #validateNickname>
  <div class="invalid-feedback">
    <ng-container *ngIf="form.get('nickname')?.hasError('required')">
      {{ 'cpvr.join.nickname.error.required' | translate }}
    </ng-container>
    <ng-container *ngIf="form.get('nickname')?.hasError('taken')">
      {{ 'cpvr.join.nickname.error.taken' | translate }}
    </ng-container>
    <ng-container *ngIf="form.get('nickname')?.hasError('invalid')">
      {{ 'cpvr.join.nickname.error.invalid' | translate }}
    </ng-container>
  </div>
</ng-template>
