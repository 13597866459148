import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { Broadcast } from '@teleboy/web.epg';
import { Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ScreenService } from '../../../../core/services/screen.service';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';
import { BroadcastDetailDirective } from '../../../directives/broadcast/broadcast-detail.directive';
import { MatTooltip } from '@angular/material/tooltip';
import { BroadcastIconsComponent } from '../../broadcast-icons/broadcast-icons.component';
import { BroadcastDeleteRecordDirective } from '../../../directives/broadcast/broadcast-delete-record.directive';
import { BroadcastToggleRecordDirective } from '../../../directives/broadcast/broadcast-toggle-record.directive';
import { BroadcastPlayDirective } from '../../../directives/broadcast/broadcast-play.directive';
import { BroadcastInfoPipe } from '../../../pipes/broadcast-info.pipe';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-epg-broadcast',
  templateUrl: './epg-broadcast.component.html',
  styleUrls: ['./epg-broadcast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    BroadcastDetailDirective,
    MatTooltip,
    NgIf,
    BroadcastIconsComponent,
    BroadcastDeleteRecordDirective,
    BroadcastToggleRecordDirective,
    BroadcastPlayDirective,
    AsyncPipe,
    BroadcastInfoPipe,
    IconComponent
  ]
})
export class EpgBroadcastComponent implements OnInit {
  @Input() broadcast!: Broadcast;
  @Input() isVisible = true;
  @ViewChild('broadcastItem') broadcastItem!: ElementRef;
  isPlaying$!: Observable<boolean>;
  isDesktop = this.deviceDetector.deviceType.toLowerCase() === 'desktop';
  private readonly pixelPerMinute = 10;
  private readonly pixelPerMinuteMobile = 4;

  constructor(
    private deviceDetector: DeviceDetectorService,
    private screenService: ScreenService
  ) {}

  @HostBinding('style.width')
  get width(): string {
    if (!this.broadcast) {
      return '0px';
    }

    return `${this.broadcast.end.diff(this.broadcast.begin, 'minutes') * this.getPixelsPerMinute()}px`;
  }

  @HostListener('mouseenter') onMouseHover() {
    if (this.isDesktop && this.broadcastItem?.nativeElement) {
      const positionLeft = this.broadcastItem.nativeElement.getBoundingClientRect().left;
      if (positionLeft < 176) {
        this.broadcastItem.nativeElement.style.paddingLeft = `${176 - positionLeft + 20}px`;
      }
    }
  }

  @HostListener('mouseleave') onMouseOut() {
    if (this.isDesktop && this.broadcastItem?.nativeElement) {
      this.broadcastItem.nativeElement.style.paddingLeft = `.75rem`;
    }
  }

  ngOnInit(): void {
    this.isPlaying$ = this.broadcast?.isPlaying$();
  }

  getPixelsPerMinute() {
    let pixels = this.pixelPerMinute;

    if (this.screenService.isMobileSizeScreen()) {
      pixels = this.pixelPerMinuteMobile;
    }

    return pixels;
  }
}
