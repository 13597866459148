import { ChangeDetectionStrategy, Component, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import {
  RecordingApiService,
  RecordingGroup,
  RecordingGroupsParams,
  RecordingGroupsSort,
  RecordingGroupType
} from '@teleboy/web.epg';
import { Router } from '@angular/router';
import { ApiListResponse } from '@teleboy/web.core';
import { DataSwiperDirective } from '../../../../epg/directives/swiper/data-swiper.directive';
import { AsyncPipe } from '@angular/common';
import { SlideConfig } from '../../../../epg/providers/swiper.provider';
import { TranslateModule } from '@ngx-translate/core';
import { PvrGridButtonComponent } from '../../mics/pvr-grid-button/pvr-grid-button.component';
import { PvrGenregroupPreviewComponent } from '../../previews/pvr-genregroup-preview/pvr-genregroup-preview.component';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { ViewToggleType } from '../../../../epg/services/view-toggler.service';
import { BroadcastItemComponent } from '../../../../epg/components/broadcast-item/broadcast-item.component';
import { SharedModule } from '../../../../shared/shared.module';

@Component({
  selector: 'app-pvr-genregroup-preview-list',
  imports: [
    AsyncPipe,
    TranslateModule,
    PvrGridButtonComponent,
    PvrGenregroupPreviewComponent,
    SwiperModule,
    BroadcastItemComponent,
    SharedModule
  ],
  templateUrl: './pvr-genregroup-preview-list.component.html',
  styleUrls: ['./pvr-genregroup-preview-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PvrGenregroupPreviewListComponent extends DataSwiperDirective<RecordingGroup> implements OnInit {
  @ViewChild('swiper') swiperRef!: SwiperComponent;

  protected readonly ListingType = ViewToggleType;

  readonly busy: WritableSignal<boolean> = signal(true);

  constructor(
    private recordingApiService: RecordingApiService,
    private router: Router
  ) {
    super();
  }

  override ngOnInit(): void {
    this.swiperOptions = SlideConfig['teaser'];
    super.ngOnInit();
  }

  openGenregroupList(): void {
    void this.router.navigate([{ outlets: { ['landingpage']: 'pvr/genregroups' } }]);
  }

  itemLoader$(): Observable<ApiListResponse['data']> {
    const recordingGroupsParams = new RecordingGroupsParams().setSort(RecordingGroupsSort.COUNT).setLimit(10);

    return this.recordingApiService
      .group(RecordingGroupType.GENRE, recordingGroupsParams)
      .pipe(finalize(() => this.busy.set(false)));
  }
}
