import { inject } from '@angular/core';
import { Broadcast, BroadcastApiService, PlayCommunityPolicy } from '@teleboy/web.epg';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';
import { RouteDataService } from '../../core/services/route-data.service';
import { ErrorCode, TeleboyError } from '@teleboy/web.core';
import { CpvrService } from '../../epg/services/cpvr.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { UpsellService } from '../../core/services/upsell.service';

export const communityGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const broadcastApiService = inject(BroadcastApiService);
  const cpvrService = inject(CpvrService);
  const routeDataService = inject(RouteDataService);
  const playCommunityPolicy = inject(PlayCommunityPolicy);
  const router = inject(Router);
  const upsellService = inject(UpsellService);

  let broadcast$: Observable<Broadcast>;

  const broadcastData = routeDataService.getDataForRoute<Broadcast>(state.url);

  if (broadcastData) {
    broadcast$ = of(broadcastData);
  } else if (route.params['broadcastId']) {
    broadcast$ = broadcastApiService.get(route.params['broadcastId']);
  } else {
    return of(false);
  }

  return broadcast$.pipe(
    switchMap((broadcast: Broadcast) => {
      router.createUrlTree([{ outlets: { player: null } }]);
      return playCommunityPolicy
        .isGranted(broadcast)
        .pipe(catchError((err: TeleboyError) => handleError$(err, broadcast)));
    })
  );

  function handleError$(error: TeleboyError, broadcast: Broadcast): Observable<boolean> {
    switch (error.code) {
      case ErrorCode.USER_AUTHENTICATION_REQUIRED:
      case ErrorCode.USER_PLUS_SUBSCRIPTION_REQUIRED:
        upsellService.openAboUpsell();
        break;
      case ErrorCode.COMMUNITY_NO_MEMBER:
        cpvrService.joinCommunity(broadcast);
        break;
      case ErrorCode.COMMUNITY_NO_FRIEND:
        cpvrService.requestFriendship(broadcast);
        break;
      case ErrorCode.COMMUNITY_STATUS_SLOTREQUIRED:
        cpvrService.borrowRecording(broadcast);
        break;
      case ErrorCode.COMMUNITY_STATUS_NOSLOT:
        cpvrService.communityNoSlot(broadcast);
        break;
      default:
        return throwError(() => error);
    }

    return fromPromise(router.navigate(['home'])).pipe(map(() => false));
  }
};
