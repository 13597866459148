"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CastToggleButton = void 0;
var togglebutton_1 = require("./togglebutton");
var i18n_1 = require("../localization/i18n");
/**
 * A button that toggles casting to a Cast receiver.
 *
 * @category Buttons
 */
var CastToggleButton = /** @class */function (_super) {
  __extends(CastToggleButton, _super);
  function CastToggleButton(config) {
    if (config === void 0) {
      config = {};
    }
    var _this = _super.call(this, config) || this;
    _this.config = _this.mergeConfig(config, {
      cssClass: 'ui-casttogglebutton',
      text: i18n_1.i18n.getLocalizer('googleCast')
    }, _this.config);
    return _this;
  }
  CastToggleButton.prototype.configure = function (player, uimanager) {
    var _this = this;
    _super.prototype.configure.call(this, player, uimanager);
    this.onClick.subscribe(function () {
      if (player.isCastAvailable()) {
        if (player.isCasting()) {
          player.castStop();
        } else {
          player.castVideo();
        }
      } else {
        if (console) {
          console.log('Cast unavailable');
        }
      }
    });
    var castAvailableHander = function () {
      if (player.isCastAvailable()) {
        _this.show();
      } else {
        _this.hide();
      }
    };
    player.on(player.exports.PlayerEvent.CastAvailable, castAvailableHander);
    // Toggle button 'on' state
    player.on(player.exports.PlayerEvent.CastWaitingForDevice, function () {
      _this.on();
    });
    player.on(player.exports.PlayerEvent.CastStarted, function () {
      // When a session is resumed, there is no CastStart event, so we also need to toggle here for such cases
      _this.on();
    });
    player.on(player.exports.PlayerEvent.CastStopped, function () {
      _this.off();
    });
    // Startup init
    castAvailableHander(); // Hide button if Cast not available
    if (player.isCasting()) {
      this.on();
    }
  };
  return CastToggleButton;
}(togglebutton_1.ToggleButton);
exports.CastToggleButton = CastToggleButton;