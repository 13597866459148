<app-close-button (closed)="close(false)"></app-close-button>
<app-info [title]="'anonymousVerification.title' | translate"
          [text]="'anonymousVerification.text' | translate">
  <ng-container customContent>
    <img [src]="captchaSrc$ | async" alt="">

    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="form-item">
        <label for="anonymous-verification-captcha"
               class="visually-hidden">{{ 'anonymousVerification.form.captcha.label' | translate }}</label>
        <input id="anonymous-verification-captcha" type="text" formControlName="captchaSolveCode" appAutofocus>
        <ng-container *ngTemplateOutlet="invalidCaptchaSolveCode"></ng-container>
      </div>

      <div class="btn__list btn__list-h btn__list-h--apart">
        <a [href]="'/login' | webUiHref: {appendTarget: true}" class="btn btn__secondary" appDataLayer
           dataLayerEventName="anonymous_verification_login">
          {{ 'anonymousVerification.form.login.label' | translate }}
        </a>
        <input type="submit" class="btn btn__primary"
               [value]="'anonymousVerification.form.submit.label' | translate"
               [attr.aria-disabled]="busy$ | async">
      </div>
    </form>
  </ng-container>
</app-info>

<ng-template #invalidCaptchaSolveCode>
  <div class="invalid-feedback">
    <ng-container *ngIf="form.get('captchaSolveCode')?.hasError('required')">
      {{ 'anonymousVerification.form.captcha.error.required' | translate }}
    </ng-container>
    <ng-container *ngIf="form.get('captchaSolveCode')?.hasError('invalid')">
      {{ 'anonymousVerification.form.captcha.error.invalid' | translate }}
    </ng-container>
  </div>
</ng-template>
