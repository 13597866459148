<div class="swiper__container" *ngIf="!(isEmptyResult$() | async)">
  <app-expand-link *ngIf="headline" [linkText]="headline" (clicked)="openInListTilesView()"></app-expand-link>
  <swiper *ngIf="data$ | async as teasers; else loading" #swiper [slidesPerView]="swiperOptions.slidesPerView"
          [breakpoints]="swiperOptions.breakpoints">
    <ng-template *ngFor="let teaser of teasers" swiperSlide>
      <app-teaser-item [teaser]="teaser" [topTeaser]="topTeaser"></app-teaser-item>
    </ng-template>
  </swiper>
</div>

<ng-template #loading>
  <swiper [slidesPerView]="swiperOptions.slidesPerView" [breakpoints]="swiperOptions.breakpoints"
          class="ghost-listing ghost-listing--horizontal">
    <ng-template *ngFor="let ghost of ghosts" swiperSlide>
      <app-teaser-item [topTeaser]="topTeaser"></app-teaser-item>
    </ng-template>
  </swiper>
</ng-template>
