<div class="pvr-memory">

  <h1 class="h2">{{ 'pvr.manage.memory.title' | translate }}</h1>

  @if (statistics$ | async; as statistics) {
    <p class="pvr-memory__bars__label">
      <strong>{{ ((statistics.recordedTime + statistics.scheduledTime) / 60) | duration }}</strong> / {{ (statistics.maxTime / 60) | duration }}
    </p>

    <div class="pvr-memory__bars">
      <div class="bar bar__recorded" [ngStyle]="{'width.%': recordedPercentage()}"></div>
      <div class="bar bar__planned" [ngStyle]="{'width.%': plannedPercentage()}"></div>
      <div class="bar bar__total"></div>
    </div>

    <div class="grid statistics-info">
      <div class="pvr-memory__legend__item">
        <div class="pvr-memory__legend__item__color pvr-memory__legend__item__color__recorded"></div>
        <div>
          <h3 class="pvr-memory__legend__item__label collapse">{{ 'pvr.manage.memory.map.recorded' | translate }}</h3>
          <span class="pvr-memory__legend__item__info">{{ 'pvr.manage.memory.map.used' | translate: { time: (statistics.recordedTime / 60) | duration} }}</span>
        </div>
      </div>

      <div class="pvr-memory__legend__item">
        <div class="pvr-memory__legend__item__color pvr-memory__legend__item__color__planned"></div>
        <div>
          <h3 class="pvr-memory__legend__item__label collapse">{{ 'pvr.manage.memory.map.planned' | translate }}</h3>
          <span class="pvr-memory__legend__item__info">{{ 'pvr.manage.memory.map.used' | translate: { time: (statistics.scheduledTime / 60) | duration} }}</span>
        </div>
      </div>

      <div class="pvr-memory__legend__item">
        <div class="pvr-memory__legend__item__color pvr-memory__legend__item__color__free"></div>
        <div>
          <h3 class="pvr-memory__legend__item__label collapse">{{ 'pvr.manage.memory.map.free' | translate }}</h3>
          <span class="pvr-memory__legend__item__info">{{ 'pvr.manage.memory.map.notUsed' | translate: { time: (statistics.availableTime / 60) | duration} }}</span>
        </div>
      </div>
    </div>
  }
</div>
