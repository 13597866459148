import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BehaviorSubject, catchError, finalize, map, Observable, switchMap, tap } from 'rxjs';
import {
  SearchApiService,
  SearchParams,
  SearchPrefix,
  SearchResponse,
  SearchSort,
  SearchSource,
  StarAlertType
} from '@teleboy/web.epg';
import { SlideConfig } from '../../providers/swiper.provider';
import { ActivatedRoute, Params } from '@angular/router';
import { ViewToggleType } from '../../services/view-toggler.service';
import { AuthenticationService } from '@teleboy/web.user';
import { SharedModule } from '../../../shared/shared.module';
import { StarAlertButtonComponent } from '../star-alert/star-alert-button/star-alert-button.component';
import { BroadcastsSwiperComponent } from '../swiper/broadcasts-swiper/broadcasts-swiper.component';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { BroadcastItemComponent } from '../broadcast-item/broadcast-item.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedModule,
    StarAlertButtonComponent,
    BroadcastsSwiperComponent,
    NgTemplateOutlet,
    SwiperModule,
    BroadcastItemComponent,
    AsyncPipe,
    TranslateModule
  ]
})
export class PersonComponent implements OnInit {
  isAuthenticated!: boolean;
  results$!: Observable<SearchResponse>;
  SearchSource = SearchSource;
  swiperOptions = SlideConfig['default'];

  readonly personSearchQuery$ = new BehaviorSubject<string>('');
  readonly ghosts = new Array(15);
  readonly isLoading$ = new BehaviorSubject<boolean>(true);

  protected readonly SearchPrefix = SearchPrefix;
  protected readonly StarAlertType = StarAlertType;
  protected readonly ViewToggleType = ViewToggleType;

  private readonly BROADCASTS_PER_PAGE = 30;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private changeDetectorRef: ChangeDetectorRef,
    private searchApiService: SearchApiService
  ) {}

  ngOnInit(): void {
    this.results$ = this.activatedRoute.params.pipe(
      map((params: Params) => params['name']),
      tap((person: string) => this.personSearchQuery$.next(person)),
      tap(() => this.changeDetectorRef.detectChanges()),
      switchMap((person: string) => this.searchResults$(person))
    );
    this.isAuthenticated = this.authenticationService.isAuthenticated;
  }

  searchResults$(person: string): Observable<SearchResponse> {
    this.isLoading$.next(true);

    const searchParams = new SearchParams(
      person,
      [SearchSource.REPLAY, SearchSource.EPG, SearchSource.COMMUNITY, SearchSource.PVR],
      SearchPrefix.NAME
    )
      .setSort(SearchSort.DATE)
      .setLimit(this.BROADCASTS_PER_PAGE);

    return this.searchApiService.search(searchParams).pipe(
      catchError(() => []),
      finalize(() => this.isLoading$.next(false))
    );
  }
}
