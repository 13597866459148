import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export enum SnackbarType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  success(message: string, messageParams = {}): void {
    this.openSnackbar(message, SnackbarType.SUCCESS, messageParams);
  }

  error(message: string, messageParams = {}): void {
    this.openSnackbar(message, SnackbarType.ERROR, messageParams);
  }

  openSnackbar(message: string, type?: SnackbarType, messageParams = {}): void {
    const translation$ = this.translateService.get(message, messageParams);
    const classList = ['tb-snackbar'];

    if (type) {
      classList.push(`tb-snackbar--${type}`);
    }

    translation$.subscribe((text) => {
      this.matSnackBar.open(text, '', {
        panelClass: classList,
        duration: 3000
      });
    });
  }
}
