@if (!(isEmptyResult$() | async)) {
  <div class="swiper__container swiper__container--broadcast">
    <app-expand-link [linkText]="headline" (clicked)="openInListTilesView(searchSource)"></app-expand-link>
    @if (data$ | async; as items) {
      <swiper #swiper [slidesPerView]="swiperOptions.slidesPerView"
        [virtual]="false"
        [breakpoints]="swiperOptions.breakpoints">
        @for (broadcast of items; track trackByFn($index, broadcast)) {
          <ng-template swiperSlide>
            <app-broadcast-item [broadcast]="broadcast" [card]="true"></app-broadcast-item>
          </ng-template>
        }
      </swiper>
    } @else {
      <swiper [slidesPerView]="swiperOptions.slidesPerView" [breakpoints]="swiperOptions.breakpoints"
        class="ghost-listing ghost-listing--horizontal">
        @for (ghost of ghosts; track ghost) {
          <ng-template swiperSlide>
            <app-broadcast-item [card]="true"></app-broadcast-item>
          </ng-template>
        }
      </swiper>
    }
  </div>
}

