import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, pairwise } from 'rxjs';
import { DarkmodeService } from '@teleboy/web.user';

export enum PlayerSize {
  MINI,
  LARGE
}

@Injectable({
  providedIn: 'root'
})
export class PlayerSizeService {
  readonly enlarge$: Observable<void>;
  readonly size$: Observable<PlayerSize>;
  private readonly body: HTMLElement;
  private readonly PLAYER_MINI_CLASS = 'player-mini';
  private readonly PLAYER_LARGE_CLASS = 'player-large';
  private readonly _size$: BehaviorSubject<PlayerSize> = new BehaviorSubject<PlayerSize>(PlayerSize.LARGE);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private darkmodeService: DarkmodeService
  ) {
    this.body = this.document.body;
    this.size$ = this._size$.pipe(distinctUntilChanged());
    this.enlarge$ = this._size$.pipe(
      pairwise(),
      filter(([oldSize, newSize]) => oldSize !== newSize && newSize === PlayerSize.LARGE),
      map(() => void 0)
    );
  }

  bodyClassAdd(className: string) {
    this.body.classList.add(className);
  }

  bodyClassRemove(className: string) {
    this.body.classList.remove(className);
  }

  miniPlayer(): void {
    if (this.isMiniPlayer()) {
      return;
    }

    this.body.style.removeProperty('overflow');
    this.bodyClassAdd(this.PLAYER_MINI_CLASS);
    this.bodyClassRemove(this.PLAYER_LARGE_CLASS);

    this.setThemeColor('default');

    this._size$.next(PlayerSize.MINI);
  }

  largePlayer(): void {
    this.body.style.overflow = 'hidden';
    this.bodyClassAdd(this.PLAYER_LARGE_CLASS);
    this.bodyClassRemove(this.PLAYER_MINI_CLASS);

    this.setThemeColor('black');

    this._size$.next(PlayerSize.LARGE);
  }

  isMiniPlayer(): boolean {
    return this._size$.value === PlayerSize.MINI;
  }

  isLargePlayer(): boolean {
    return this._size$.value === PlayerSize.LARGE;
  }

  reset(): void {
    this.body.style.removeProperty('overflow');
    this.bodyClassRemove(this.PLAYER_LARGE_CLASS);
    this.bodyClassRemove(this.PLAYER_MINI_CLASS);
    this.setThemeColor('default');
    this._size$.next(PlayerSize.LARGE);
  }

  private setThemeColor(theme: string): void {
    let color = '#000000';

    if (theme === 'default' && this.darkmodeService.darkmodeEnabled) {
      color = '#1c1c1c';
    } else if (theme === 'default') {
      color = '#d2d5de';
    }

    this.document.querySelector('meta[name=theme-color]')?.setAttribute('content', color);
  }
}
