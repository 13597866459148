import { Pipe, PipeTransform } from '@angular/core';
import { Broadcast } from '@teleboy/web.epg';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'broadcastInfo'
})
export class BroadcastInfoPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(broadcast: Broadcast): string {
    return this.translateService
      .instant('broadcast.info.begin', {
        date: broadcast.begin.format('dddd, D. MMMM YYYY'),
        begin: broadcast.begin.format('HH:mm'),
        end: broadcast.end.format('HH:mm'),
        duration: broadcast.duration
      })
      .replace(new RegExp(/\\n/, 'g'), '\n');
  }
}
