import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class InfoComponent {
  @Input() icon?: string;
  @Input() title?: string;
  @Input() text?: string;
  @Input() collapse = false;
  @Input() size?: 'small';
}
