<div class="broadcast-icons">
  <tbui-icon *ngIf="broadcast.isWatched()" iconId="checkmark-full" color="brand" [inline]="true"
             [matTooltip]="'broadcast.flag.watched' | translate"
             matTooltipPosition="above"
             matTooltipClass="tb-tooltip"
             class="visible-mobile"></tbui-icon>

  <tbui-icon *ngIf="broadcast.flags.is_defective || broadcast.flags.is_broken" iconId="lightning" color="brand"
             [inline]="true"
             [matTooltip]="'broadcast.flag.broken' | translate"
             matTooltipPosition="above"
             matTooltipClass="tb-tooltip"></tbui-icon>

  <tbui-icon *ngIf="broadcast.flags.isRecorded()" iconId="record" color="brand" [inline]="true"
             [matTooltip]="'broadcast.flag.recorded' | translate"
             matTooltipPosition="above"
             matTooltipClass="tb-tooltip"></tbui-icon>

  <tbui-icon *ngIf="broadcast.flags.is_replayable" iconId="replay" color="brand" [inline]="true"
             [matTooltip]="'broadcast.flag.replay' | translate"
             matTooltipPosition="above"
             matTooltipClass="tb-tooltip"></tbui-icon>

  <tbui-icon *ngIf="broadcast.isDualAudio" iconId="dualaudio" color="brand" [inline]="true"
             [matTooltip]="'broadcast.flag.dualaudio' | translate"
             matTooltipPosition="above"
             matTooltipClass="tb-tooltip"></tbui-icon>

  <tbui-icon *ngIf="broadcast.hasDolby" iconId="dolby" color="brand" [inline]="true"
             [matTooltip]="'broadcast.flag.dolby' | translate"
             matTooltipPosition="above"
             matTooltipClass="tb-tooltip"></tbui-icon>

  <tbui-icon *ngIf="broadcast.flags.has_trailer" iconId="trailer" color="brand" [inline]="true"
             [matTooltip]="'broadcast.flag.trailer' | translate"
             matTooltipPosition="above"
             matTooltipClass="tb-tooltip"></tbui-icon>

  <tbui-icon *ngIf="broadcast.hasCaption" iconId="captions" color="brand" [inline]="true"
             [matTooltip]="'broadcast.flag.captions' | translate"
             matTooltipPosition="above"
             matTooltipClass="tb-tooltip"></tbui-icon>
</div>
