import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Broadcast, BroadcastApiService, CommunityApiService } from '@teleboy/web.epg';
import { MediaImageStack } from '@teleboy/web.core';
import { BehaviorSubject, finalize, iif, Observable, of, switchMap } from 'rxjs';
import { SnackbarService, SnackbarType } from '../../../../core/services/snackbar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgIf, AsyncPipe } from '@angular/common';
import { BroadcastItemComponent } from '../../broadcast-item/broadcast-item.component';
import { SharedModule } from '../../../../shared/shared.module';
import { CpvrProfileComponent } from '../cpvr-profile/cpvr-profile.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cpvr-friend',
  templateUrl: './cpvr-friend.component.html',
  styleUrls: ['./cpvr-friend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, BroadcastItemComponent, SharedModule, CpvrProfileComponent, AsyncPipe, TranslateModule]
})
export class CpvrFriendComponent implements OnInit {
  protected broadcast$!: Observable<Broadcast>;
  protected MediaImageStack = MediaImageStack;
  private readonly _busy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly busy$: Observable<boolean> = this._busy$.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) private broadcast: Broadcast,
    private broadcastApiService: BroadcastApiService,
    private communityApiService: CommunityApiService,
    private dialogRef: MatDialogRef<CpvrFriendComponent>,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.broadcast$ = of(this.broadcast).pipe(
      switchMap((broadcast) =>
        iif(() => !broadcast.thumbnail, this.broadcastApiService.get(broadcast.id), of(broadcast))
      )
    );
  }

  requestFriendship(): void {
    this._busy$.next(true);
    if (this.broadcast.community) {
      this.communityApiService
        .addFriend(this.broadcast.community.provider.id)
        .pipe(finalize(() => this._busy$.next(false)))
        .subscribe({
          next: () => this.handleFriendRequestSuccess(),
          error: () => this.handleFriendRequestFailed()
        });
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  private handleFriendRequestSuccess(): void {
    if (this.broadcast.community) {
      this.broadcast.community.provider.befriend();
    }
  }

  private handleFriendRequestFailed(): void {
    this.snackbarService.openSnackbar('cpvr.friend.failed', SnackbarType.ERROR);
  }
}
