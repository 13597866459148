import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  Station,
  StationLogoSize,
  StationOrderStorageService,
  StationStorageService,
  StationLogoComponent
} from '@teleboy/web.epg';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { DarkmodeService } from '@teleboy/web.user';
import { NgClass, NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-stations-dropdown',
  templateUrl: './stations-autocomplete-dropdown.component.html',
  styleUrls: ['stations-autocomplete-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    FormsModule,
    StationLogoComponent,
    NgIf,
    MatFormField,
    MatInput,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
    MatAutocomplete,
    NgFor,
    MatOption,
    AsyncPipe,
    TranslateModule,
    IconComponent
  ]
})
export class StationsAutocompleteDropdownComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() station!: Station;
  @Output() stationClicked$: EventEmitter<Station> = new EventEmitter<Station>();
  @ViewChild('trigger') trigger!: MatAutocompleteTrigger;

  allUserStations$!: Observable<Station[]>;
  darkMode!: boolean;
  dropdownListShown = false;
  filteredStationOptions$!: Observable<Station[]>;
  form!: FormGroup;
  selectedStation!: Station;
  StationLogoSize = StationLogoSize;
  userOrderedStations$!: Observable<Station[]>;
  readonly stationControl = new FormControl<string>('');

  private displayAllStations = false;
  private timeout!: number;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private darkmodeService: DarkmodeService,
    private stationOrderStorageService: StationOrderStorageService,
    private stationStorageService: StationStorageService
  ) {
    this.darkMode = this.darkmodeService.darkmodeEnabled;
  }

  ngOnInit(): void {
    this.userOrderedStations$ = this.getUserOrderedStationsDropdownOptions$();
    this.allUserStations$ = this.getAllUserStationsDropdownOptions$();
    this.filteredStationOptions$ = this._filter('');

    this.form = this.formBuilder.group(this.stationControl);

    this.selectedStation = this.station;

    this.stationControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((change) => {
      const stationDropdownSearchTerm = change ?? '';
      this.displayAllStations = !!stationDropdownSearchTerm;
      this.filteredStationOptions$ = this._filter(stationDropdownSearchTerm);
    });
  }

  ngAfterViewInit(): void {
    this.trigger.closePanel();

    this.trigger.panelClosingActions.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.dropdownListShown) {
        this.dropdownListShown = false;
        this.trigger.closePanel();
      }
    });
  }

  onDropdownClick($event: Event) {
    $event.stopPropagation();

    this.dropdownListShown = !this.dropdownListShown;
    this.togglePanel(this.trigger);
  }

  togglePanel(trigger: MatAutocompleteTrigger) {
    if (this.dropdownListShown) {
      trigger.openPanel();
    } else {
      trigger.closePanel();
    }
  }

  trackByFn(index: number, stationOption: Station): number {
    return stationOption.id;
  }

  stationClicked(station: Station) {
    this.stationControl.reset();
    this.selectedStation = station;
    this.stationClicked$.next(station);
    this.dropdownListShown = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    clearTimeout(this.timeout);
  }

  private _filter(value: string): Observable<Station[]> {
    const filterValue = value;
    const stations = this.displayAllStations ? this.allUserStations$ : this.userOrderedStations$;

    return stations.pipe(
      map((options: Station[]) =>
        options.filter((option) => option.label.toLowerCase().includes(filterValue.toLowerCase()))
      )
    );
  }

  private getUserOrderedStationsDropdownOptions$(): Observable<Station[]> {
    return this.stationOrderStorageService.getUserStations();
  }

  private getAllUserStationsDropdownOptions$(): Observable<Station[]> {
    return this.stationStorageService.getAll().pipe();
  }
}
