import { AfterViewInit, Directive, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, tap } from 'rxjs';
import { ApiListResponse } from '@teleboy/web.core';
import { SwiperComponent } from 'swiper/angular';
import { SwiperOptions } from 'swiper';
import { SlideConfig } from '../../providers/swiper.provider';

@Directive({
  standalone: false
})
export abstract class DataSwiperDirective<T> implements OnInit, AfterViewInit {
  protected _swiper!: SwiperComponent;
  @ViewChild('swiper', { static: false }) set swiper(swiper: SwiperComponent) {
    if (swiper) {
      this._swiper = swiper;
      this.ngAfterViewInit();
    }
  }

  @Input() headline = '';
  @Input() swiperOptions!: {
    slidesPerView: SwiperOptions['slidesPerView'];
    breakpoints: SwiperOptions['breakpoints'];
  };

  @Output() noResults: EventEmitter<void> = new EventEmitter<void>();

  data$!: Observable<T[]>;
  readonly ghosts = new Array(8);

  protected total$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

  abstract itemLoader$(): Observable<ApiListResponse['data']>;

  ngOnInit(): void {
    this.data$ = this.getData$();
    this.swiperOptions = this.swiperOptions || SlideConfig['default'];
  }

  isEmptyResult$(): Observable<boolean> {
    return this.total$.pipe(map((total) => total === 0));
  }

  ngAfterViewInit(): void {
    if (!this._swiper) return;
    this._swiper.navigation = true;
  }

  protected getData$(): Observable<T[]> {
    return this.itemLoader$().pipe(
      tap((data) => {
        this.total$.next(data.total);

        if (data.total === 0) {
          this.noResults.emit();
        }
      }),
      map((data) => data.items),
      catchError((err) => {
        this.total$.next(0);
        throw err;
      })
    );
  }
}
