<span class="cpvr-profile" [ngClass]="{'cpvr-profile--accepted': communityProvider.isFriend$ | async}">
  <tbui-media-image class="cpvr-profile__image" [image]="communityProvider.image" [alt]="communityProvider.nickname"
                    [size]="MediaImageStack.USER_HUGE"></tbui-media-image>
  <span *ngIf="communityProvider.isFriend$ | async" class="cpvr-profile__tick">
    <tbui-icon iconId="watchlist"></tbui-icon>
  </span>
</span>

<ul class="cpvr-profile__genres">
  <li><b>{{ 'cpvr.provider.genres.label' | translate }}</b></li>
  <li class="badge badge__secondary" *ngFor="let genre of genres$ | async">{{genre.name}}</li>
</ul>
