import { ChangeDetectionStrategy, Component, Inject, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StarAlert, StarAlertListParams, StarAlertType } from '@teleboy/web.epg';
import { ApiListResponse } from '@teleboy/web.core';
import { ListSwiperDirective } from '../../../directives/swiper/list-swiper.directive';
import { StarAlertService } from '../../../services/star-alert.service';
import { EntityServiceType, EntityType, ItemsListRouteData } from '../../../interfaces/items-list-route-data';
import { PopupService } from '../../../../core/services/popup.service';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { StaralertItemComponent } from '../../staralert-item/staralert-item.component';

@Component({
  selector: 'app-staralert-swiper',
  templateUrl: './staralert-swiper.component.html',
  styleUrls: ['../swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, SharedModule, SwiperModule, NgFor, StaralertItemComponent, AsyncPipe]
})
export class StaralertSwiperComponent extends ListSwiperDirective<StarAlert> implements OnInit, OnDestroy {
  @Input() card = false;
  @Input() isLinkClickable = true;
  @Input() override params!: StarAlertListParams;

  constructor(
    @Inject(NgZone) ngZone: NgZone,
    private starAlertService: StarAlertService,
    private popupService: PopupService
  ) {
    super(ngZone);
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.params.resetSkip();
  }

  itemLoader$(): Observable<ApiListResponse['data']> {
    return this.starAlertService.getStarAlertsList(this.params);
  }

  openInListTilesView() {
    const routeData: ItemsListRouteData = {
      title: this.headline,
      params: this.params,
      serviceName: EntityServiceType.StarAlertService,
      broadcastType: EntityType.StarAlert,
      enableListTypeToggle: false,
      itemsPerPage: 50,
      showStaralertNotificationsButton: this.params['params']['type'] === StarAlertType.EXACT
    };

    this.popupService.open('items/list', { outlet: 'details', data: routeData, skipLocationChange: true });
  }
}
