@if (!(isEmptyResult$() | async)) {
  <div class="swiper__container">
    <app-expand-link [linkText]="headline" (clicked)="openRecordingList()"></app-expand-link>

    @if (data$ | async; as recordings) {
      <swiper #swiper>
        @for (recording of recordings; track recording.id) {
          <ng-template swiperSlide>
            <app-broadcast-item [broadcast]="recording" [card]="true"></app-broadcast-item>
          </ng-template>
        }

        @let total = (total$ | async);
        @if (total && total > 5) {
          <ng-template swiperSlide>
            <app-pvr-grid-button
              [icon]="'grid'"
              [label]="'pvr.groups.recordings.showAll' | translate"
              [subLabel]="'pvr.groups.recordings.count' | translate: { count: total }"
              (click)="openRecordingList()"></app-pvr-grid-button>
          </ng-template>
        }
      </swiper>
    }

    @if (busy()) {
      <swiper class="ghost-listing ghost-listing--horizontal">
        @for (ghost of ghosts; track $index) {
          <ng-template swiperSlide>
            <app-broadcast-item [card]="true"></app-broadcast-item>
          </ng-template>
        }
      </swiper>
    }
  </div>
}
