import { ChangeDetectionStrategy, Component, input, signal, WritableSignal } from '@angular/core';
import { DataSwiperDirective } from '../../../../epg/directives/swiper/data-swiper.directive';
import {
  RecordingApiService,
  RecordingParams,
  RecordingSort,
  RecordingSortDirection,
  RecordingType
} from '@teleboy/web.epg';
import { ApiListResponse } from '@teleboy/web.core';
import { finalize, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { BroadcastItemComponent } from '../../../../epg/components/broadcast-item/broadcast-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { Recording } from '@teleboy/web.epg/lib/models/recording.model';
import { SharedModule } from '../../../../shared/shared.module';
import { PvrGridButtonComponent } from '../../mics/pvr-grid-button/pvr-grid-button.component';
import { SwiperModule } from 'swiper/angular';

@Component({
  selector: 'app-pvr-recording-preview-list',
  imports: [AsyncPipe, BroadcastItemComponent, SwiperModule, TranslateModule, SharedModule, PvrGridButtonComponent],
  templateUrl: './pvr-recording-preview-list.component.html',
  styleUrls: ['./pvr-recording-preview-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PvrRecordingPreviewListComponent extends DataSwiperDirective<Recording> {
  genreId = input<number>();

  readonly busy: WritableSignal<boolean> = signal(true);

  constructor(
    private recordingApiService: RecordingApiService,
    private router: Router
  ) {
    super();
  }

  openRecordingList(): void {
    void this.router.navigate([{ outlets: { ['details']: 'pvr/recordings' } }], {
      queryParams: { genreId: this.genreId() ?? null }
    });
  }

  public override itemLoader$(): Observable<ApiListResponse['data']> {
    const recordingParams = new RecordingParams()
      .setType(RecordingType.READY)
      .setSort(RecordingSort.DATE)
      .sortDirection(RecordingSortDirection.DESC)
      .setLimit(10);

    const genreId = this.genreId();
    if (genreId) {
      recordingParams.setGenre(genreId);
    }

    return this.recordingApiService.query(recordingParams).pipe(finalize(() => this.busy.set(false)));
  }
}
