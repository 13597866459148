import { ChangeDetectorRef, Directive, HostBinding, HostListener, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, finalize, Observable, Subject, take, takeUntil } from 'rxjs';

@Directive({
  selector: '[appBroadcastAction]'
})
export class BroadcastActionDirective implements OnDestroy {
  @Input() canPerformAction = true;

  protected _title = '';

  protected readonly busy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(protected changeDetectionRef: ChangeDetectorRef) {}

  @HostBinding('attr.aria-disabled')
  get disabled() {
    return this.busy$.value;
  }

  @HostBinding('class.btn--busy')
  get busy() {
    return this.busy$.value;
  }

  @HostBinding('attr.title')
  @HostBinding('attr.aria-label')
  get title(): string {
    return this._title;
  }

  /**
   * Prevents the button to get focus on click,
   * which leads to wrong css styles
   *
   * @see https://stackoverflow.com/a/8736218/1928146
   */
  @HostListener('mousedown', ['$event'])
  onMouseDown($event: PointerEvent) {
    $event.preventDefault();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.busy$.complete();
  }

  protected performAction(broadcastActionFn: () => Observable<unknown>): void {
    if (this.busy$.value) return;

    this.busy$.next(true);

    broadcastActionFn()
      .pipe(
        finalize(() => {
          this.busy$.next(false);
          this.changeDetectionRef.detectChanges();
        }),
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe();
  }
}
