<h2 class="visually-hidden">{{ station.name }}</h2>
<div class="epg__station__logo">
  <a [routerLink]="['', { outlets: { landingpage: ['station', station.id] }}]">
    <tbui-station-logo [station]="station"></tbui-station-logo>
  </a>
</div>
<ul class="epg__station__lane">
  <li *ngFor="let broadcast of broadcasts; index as index; trackBy: trackByFn">
    <div appIntersectionObserver [intersectionDebounce]="50" [intersectionThreshold]="0" (visibilityChange)="onVisibilityChanged(index, $event)" class='flex-grow flex-end content-center self-center text-6xl font-sans'
         [ngSwitch]="visibilityStatus[index]">
      <ng-container *ngSwitchCase="intersectionStatus.Visible">
        <app-epg-broadcast [broadcast]="broadcast"></app-epg-broadcast>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <app-epg-broadcast [broadcast]="broadcast" [isVisible]="false" ></app-epg-broadcast>
      </ng-container>
    </div>

  </li>
  <li *ngIf="!broadcasts.length">
    <app-epg-broadcast></app-epg-broadcast>
  </li>
</ul>
