import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AboLevel,
  AppComebackTimeService,
  AuthenticationService,
  DarkmodeService,
  DatadogRumService,
  LanguageService,
  Settings,
  SettingsApiService,
  SettingsParams,
  UserProfile,
  UserProfileService,
  UserService
} from '@teleboy/web.user';
import { DatadogLoggingService, EventTrackingService, MediaImageStack } from '@teleboy/web.core';
import { environment } from '../../../../environments/environment';
import { UntypedFormBuilder, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  catchError,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throwError
} from 'rxjs';
import { SnackbarService, SnackbarType } from '../../../core/services/snackbar.service';
import { DOCUMENT, AsyncPipe } from '@angular/common';
import { Moment } from 'moment';
import { CommunityApiService, DownloadQualityService } from '@teleboy/web.epg';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SettingsPageService } from '../../services/settings-page.service';
import { DropdownOption } from '../../../shared/components/dropdown/dropdown.component';
import { Router, RouterLinkActive } from '@angular/router';
import {
  ProfileImageSize,
  ProfileImageComponent
} from '../../../shared/components/profile-image/profile-image.component';
import { SearchComponent } from '../search/search.component';
import { IconComponent, StaticAssetPipe } from '@teleboy/web.ui';
import { DataLayerDirective } from '../../../shared/directives/data-layer.directive';
import { SharedModule } from '../../../shared/shared.module';
import { WebUiHrefPipe } from '../../../shared/pipes/web-ui-href.pipe';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SettingsPageService],
  imports: [
    SearchComponent,
    DataLayerDirective,
    ProfileImageComponent,
    RouterLinkActive,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AsyncPipe,
    TranslateModule,
    WebUiHrefPipe,
    StaticAssetPipe,
    IconComponent
  ]
})
export class SettingsComponent implements OnInit, OnDestroy {
  communityLeaveSuccess = false;
  language = this.languageService.getLanguage();
  replayActivated = false;
  settings$!: Observable<Settings>;
  subscriptionLevel?: string;
  user = this.authenticationService.user;
  userProfileImage?: string;
  userProfiles$!: Observable<UserProfile[]>;
  userProfile$!: Observable<UserProfile>;
  isProfileUser = false;

  readonly AboLevel = AboLevel;
  readonly isAuthenticated = this.authenticationService.isAuthenticated;
  readonly isAboUser = this.authenticationService.aboLevel === AboLevel.PLUS;
  readonly downloadQualityOptions: DropdownOption[] = this.settingsService.getDownloadQualityOptions();
  readonly languageOptions: DropdownOption[] = this.settingsService.getLanguageOptions().map((option) => {
    return {
      label: this.translateService.instant(option.label),
      value: option.value
    };
  });
  readonly form = this.formBuilder.group({});
  readonly oneVoucher = environment.voucher.one;
  readonly staralertOptions: DropdownOption[] = this.settingsService.getStaralertOptions();
  readonly watchlistOptions: DropdownOption[] = this.settingsService.getWatchlistOptions();

  protected readonly environment = environment;
  protected readonly ProfileImageSize = ProfileImageSize;

  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appComebackTimeService: AppComebackTimeService,
    private authenticationService: AuthenticationService,
    private communityApiService: CommunityApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private darkmodeService: DarkmodeService,
    private datadogLoggingService: DatadogLoggingService,
    private datadogRumService: DatadogRumService,
    private downloadQualityService: DownloadQualityService,
    private eventTrackingService: EventTrackingService,
    private formBuilder: UntypedFormBuilder,
    private languageService: LanguageService,
    private settingsApiService: SettingsApiService,
    private snackbarService: SnackbarService,
    private translateService: TranslateService,
    private userService: UserService,
    private settingsService: SettingsPageService,
    private router: Router,
    private userProfileService: UserProfileService
  ) {
    if (this.isAuthenticated) {
      this.isAboUser = this.authenticationService.user.isPlusUser;
      this.userProfileImage = this.user.image.getSrc(MediaImageStack.USER);
    }
  }

  ngOnInit(): void {
    this.setupForm();
    this.userProfiles$ = this.getProfiles().pipe(
      map((userProfiles) => userProfiles.filter((profile) => !profile.isCurrent))
    );
    this.userProfile$ = this.userProfileService.getProfileById$(this.user?.id);
  }

  activateReplay(): void {
    this.userService
      .activateReplay()
      .pipe(
        tap(() => {
          this.user = this.authenticationService.user;
          this.replayActivated = true;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: () => this.snackbarService.openSnackbar('settings.replayActivateSuccess', SnackbarType.SUCCESS),
        error: () => this.snackbarService.openSnackbar('settings.replayActivateError', SnackbarType.ERROR)
      });
  }

  getReplayActivationDate(): Moment {
    return this.authenticationService.user.replay;
  }

  isReplayActive(): boolean {
    return this.user.replay !== undefined;
  }

  leaveCommunity(): void {
    this.communityApiService
      .leaveCommunity()
      .pipe(
        tap(() => {
          this.communityLeaveSuccess = true;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: () => this.snackbarService.openSnackbar('settings.leaveCommunitySuccess', SnackbarType.SUCCESS),
        error: () => this.snackbarService.openSnackbar('settings.leaveCommunityError', SnackbarType.ERROR)
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private addCommunityFormControls(): void {
    if (this.user?.isCommunityMember) {
      this.form.addControl('communityFriendshipAutoAccept', new UntypedFormControl());
    }
  }

  private addEmailFormControls(): void {
    this.form.addControl('recordNewNotifyEmail', new UntypedFormControl());
    this.form.addControl('watchlistNotifyEmail', new UntypedFormControl());
    this.form.addControl('newsletterProductinfo', new UntypedFormControl());
    this.form.addControl('newsletterTvDaily', new UntypedFormControl());
    this.form.addControl('communityFriendshipMail', new UntypedFormControl());
    this.form.addControl('streamingEcoMode', new UntypedFormControl());
    this.form.addControl('staralertNotifyEmail', new UntypedFormControl());
  }

  private addGeneralFormControls(): void {
    this.form.addControl('language', new UntypedFormControl(this.language));
    this.form.addControl('darkmode', new UntypedFormControl(this.darkmodeService.darkmodeEnabled));
    this.form.addControl('downloadQuality', new UntypedFormControl(this.downloadQualityService.getDownloadQuality()));
  }

  private getUserSettings(): void {
    this.settings$ = this.settingsApiService.userSettings().pipe(
      catchError((err) => {
        this.snackbarService.openSnackbar('settings.loadingError', SnackbarType.ERROR);
        return throwError(() => err);
      }),
      tap((settings) => {
        this.updateSettingsControlsValues(settings);
      }),
      shareReplay(1)
    );
  }

  private onFormChanges(): void {
    this.form
      .get('language')
      ?.valueChanges.pipe(
        takeUntil(this.destroy$),
        switchMap((formValues) => this.languageService.setLanguage$(formValues))
      )
      .subscribe({
        next: () => this.document.location.reload(),
        error: () => this.snackbarService.openSnackbar('settings.changeLanguageError', SnackbarType.ERROR)
      });

    this.form
      .get('darkmode')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.darkmodeService.toggleDarkmode();
        if (this.darkmodeService.darkmodeEnabled) {
          this.document.querySelector('meta[name=theme-color]')?.setAttribute('content', '#1c1c1c');
        } else {
          this.document.querySelector('meta[name=theme-color]')?.setAttribute('content', '#d2d5de');
        }

        this.eventTrackingService.setDatalayerVariable(
          'appearance',
          this.darkmodeService.darkmodeEnabled ? 'dark' : 'light'
        );
      });

    this.form
      .get('downloadQuality')
      ?.valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((formValues) => this.downloadQualityService.storeDownloadQuality(formValues))
      )
      .subscribe({
        next: () => this.snackbarService.openSnackbar('settings.save.success', SnackbarType.SUCCESS),
        error: () => this.snackbarService.openSnackbar('settings.save.error', SnackbarType.ERROR)
      });

    Object.keys(this.form.controls).forEach((key) => {
      this.form.controls[key].valueChanges
        .pipe(
          filter(() => this.settingsService.getUserSettingsControlNames().includes(key)),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          const currentSettings: Settings = {
            newsletterTvDaily: this.form.get('newsletterTvDaily')?.value,
            newsletterProductinfo: this.form.get('newsletterProductinfo')?.value,
            recordNewNotifyEmail: this.form.get('recordNewNotifyEmail')?.value,
            watchlistNotifyEmail: this.form.get('watchlistNotifyEmail')?.value,
            staralertNotifyEmail: this.form.get('staralertNotifyEmail')?.value,
            communityFriendshipMail: this.form.get('communityFriendshipMail')?.value,
            streamingEcoMode: this.form.get('streamingEcoMode')?.value,
            communityFriendshipAutoAccept: this.form.get('communityFriendshipAutoAccept')?.value
          };

          const settingsParams = this.settingsService.getSettingsParams(currentSettings, this.user.isCommunityMember);
          this.updateSettings(settingsParams);
        });
    });
  }

  private setupForm(): void {
    this.addGeneralFormControls();
    this.addEmailFormControls();
    this.addCommunityFormControls();
    this.onFormChanges();

    if (this.isAuthenticated) {
      this.subscriptionLevel = this.settingsService.getSubscriptionLevel(this.user);
      this.getUserSettings();
    }
  }

  private updateSettings(settingsParams: SettingsParams): void {
    this.settingsApiService.update(settingsParams).subscribe({
      next: () => {
        this.snackbarService.openSnackbar('settings.save.success', SnackbarType.SUCCESS);
      },
      error: () => {
        this.snackbarService.openSnackbar('settings.save.error', SnackbarType.ERROR);
      }
    });
  }

  private updateSettingsControlsValues(settings: Settings): void {
    this.form
      .get('recordNewNotifyEmail')
      ?.patchValue(settings.recordNewNotifyEmail, { onlySelf: true, emitEvent: false });
    this.form
      .get('watchlistNotifyEmail')
      ?.patchValue(settings.watchlistNotifyEmail, { onlySelf: true, emitEvent: false });
    this.form
      .get('newsletterProductinfo')
      ?.patchValue(settings.newsletterProductinfo, { onlySelf: true, emitEvent: false });
    this.form.get('newsletterTvDaily')?.patchValue(settings.newsletterTvDaily, { onlySelf: true, emitEvent: false });
    this.form
      .get('communityFriendshipMail')
      ?.patchValue(settings.communityFriendshipMail, { onlySelf: true, emitEvent: false });
    this.form.get('streamingEcoMode')?.patchValue(settings.streamingEcoMode, { onlySelf: true, emitEvent: false });

    this.form
      .get('staralertNotifyEmail')
      ?.patchValue(settings.staralertNotifyEmail, { onlySelf: true, emitEvent: false });

    if (this.user.isCommunityMember) {
      this.form
        .get('communityFriendshipAutoAccept')
        ?.patchValue(settings.communityFriendshipAutoAccept, { onlySelf: true, emitEvent: false });
    }
  }

  goToProfilesOverview() {
    void this.router.navigateByUrl('/profile-settings-list', { skipLocationChange: false });
  }

  private getProfiles(): Observable<UserProfile[]> {
    if (!this.user?.isProfileUser) {
      return of([]);
    } else {
      return this.userProfileService.profiles$.pipe(tap(() => (this.isProfileUser = true)));
    }
  }

  logoutClear() {
    this.appComebackTimeService.clearProductComebackTime();
    this.datadogRumService.clearUser();
    this.datadogLoggingService.clearUser();
  }
}
