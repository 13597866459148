@if (broadcast$ | async; as broadcast) {
  <app-popup outlet="broadcast" [large]="true">
    <div class="broadcast__header" header>
      <ng-container *ngTemplateOutlet="header;context:{broadcast: broadcast}"></ng-container>
    </div>

    <div class="broadcast__wrapper">
      @if (broadcast.community) {
        <div class="broadcast__cpvr-provider">
          <app-cpvr-provider [provider]="broadcast.community.provider" [broadcast]="broadcast"></app-cpvr-provider>
        </div>
      }

      <div class="broadcast__content">
        <ng-container *ngTemplateOutlet="content;context:{broadcast: broadcast}"></ng-container>
      </div>

      <div class="broadcast__actions">
        <ng-container *ngTemplateOutlet="actionsMobile;context:{broadcast: broadcast}"></ng-container>
        <ng-container *ngTemplateOutlet="actions;context:{broadcast: broadcast}"></ng-container>
      </div>

      <div class="broadcast__persons">
        <ng-container *ngTemplateOutlet="persons;context:{broadcast: broadcast}"></ng-container>
      </div>

      <div class="broadcast__recommendations">
        <ng-container *ngTemplateOutlet="recommendations;context:{broadcast: broadcast}"></ng-container>
      </div>
    </div>
  </app-popup>
}

<ng-template #actions let-broadcast="broadcast">
  <div class="btn__list-v btn__list-v--stretch hidden-tablet">
    @if (broadcast.isPlayable()) {
      <button [appBroadcastPlay]="broadcast" class="btn btn__primary" appAutofocus appDataLayer
              [dataLayerEventName]="'play_' + broadcast.id">
        {{ 'broadcast.action.play.label' | translate }}
      </button>

      @if (broadcast.isPlaying()) {
        <button [appBroadcastPlay]="broadcast" [restart]="true"
                class="btn btn__secondary" appDataLayer
                [dataLayerEventName]="'restart_' + broadcast.id">
          {{ 'broadcast.action.restart.label' | translate }}
        </button>
      }
    }

    <!-- The btn--active class added from the appBroadcastToggleRecord is overriden since it breaks the text color -->
    @if (broadcast.flags.isRecordable()) {
      <button class="btn"
              [ngClass]="{'btn__secondary': !broadcast.flags.isRecorded(), 'btn__primary': broadcast.flags.isRecorded()}"
              [appBroadcastToggleRecord]="broadcast">
        {{ (broadcast.flags.isRecorded() ? 'broadcast.action.recordDelete.label' : 'broadcast.action.record.label') | translate }}
      </button>
    }

    @if (broadcast.flags.isRecorded() && !broadcast.flags.isRecordable()) {
      <button class="btn btn__primary"
              [appBroadcastDeleteRecord]="broadcast" appDataLayer
              [dataLayerEventName]="'delete_' + broadcast.id">
        {{ 'broadcast.action.recordDelete.label' | translate }}
      </button>
    }

    <!-- The btn--active class added from the appBroadcastToggleRecord is overriden since it breaks the text color -->
    @if (broadcast.flags.isWatchlistable() && !broadcast.flags.isRecorded()) {
      <button [appBroadcastWatchlist]="broadcast"
              class="btn"
              [ngClass]="{'btn__secondary': !broadcast.flags.isWatchlisted(), 'btn__primary': broadcast.flags.isWatchlisted()}">
        {{ (broadcast.flags.is_watchlisted ? 'broadcast.action.watchlistDelete.label' : 'broadcast.action.watchlist.label') | translate }}
      </button>
    }
  </div>
</ng-template>

<ng-template #header let-broadcast="broadcast">
  <!-- IMAGE -->
  @if (getImage(broadcast); as image) {
    <tbui-media-image [image]="image" [size]="MediaImageStack.HUGE" [alt]="'Broadcast'"
                      class="ratio ratio__16x9"></tbui-media-image>
  }

  <!-- STATION ICON -->
  @if (getStation$(broadcast) | async; as station) {
    <div class="broadcast__station">
      <tbui-station-logo [station]="station" [size]="StationLogoSize.M"></tbui-station-logo>
    </div>
  }

  <!-- FLAG / CPVR TIPP -->
  <app-broadcast-flag [broadcast]="broadcast" [large]="true" class="hidden-mobile"></app-broadcast-flag>

  <!-- TRAILER -->
  @if (broadcast.flags.has_trailer) {
    <div class="broadcast__trailer fullsize-centered">
      <button class="btn btn__icon btn--circle btn--lg" [appBroadcastTrailer]="broadcast" appDataLayer
              [dataLayerEventName]="'trailer_' + broadcast.id">
        <tbui-icon iconId="trailer"></tbui-icon>
      </button>
      <span><strong>{{ 'broadcast.action.trailer.label' | translate }}</strong></span>
    </div>
  }

  <!-- PROGRESS -->
  @if (broadcast.flags.is_playing) {
    <app-broadcast-progress [broadcast]="broadcast"
                            [large]="true" [bottom]="true"></app-broadcast-progress>
  }
</ng-template>

<ng-template #content let-broadcast="broadcast">
  <!-- DATE & ICONS -->
  <p>
    {{ broadcast.begin.format('dddd, D. MMMM YYYY') }}
    {{
      'broadcast.action.time' | translate: {
        begin: broadcast.begin.format('HH:mm'),
        end: broadcast.end.format('HH:mm')
      }
    }}
  </p>

  <!-- TITLE, SUBTITLE & DESCRIPTION -->
  <h1 class="mb-small">{{ broadcast.title }}</h1>
  <p class="broadcast__subtitle">{{ broadcast | episodeSubtitle }}</p>

  <!-- FLAGS -->
  <section>
    <ul class="broadcast__content__flags">
      <li>
        <tbui-icon iconId="clock" size="m" [inline]="true"></tbui-icon>
        {{ broadcast.duration | duration }}
      </li>

      @if (broadcast.hasCaption) {
        <li>
          <tbui-icon iconId="captions" size="m" [inline]="true"></tbui-icon>
          {{ 'broadcast.flags.captions' | translate }}
        </li>
      }

      @if (broadcast.isDualAudio) {
        <li>
          <tbui-icon iconId="dualaudio" size="m" [inline]="true"></tbui-icon>
          {{ 'broadcast.flags.dualAudio' | translate }}
        </li>
      }

      @if (broadcast.hasDolby) {
        <li>
            <tbui-icon iconId="dolby" size="m" [inline]="true"></tbui-icon>
          {{ 'broadcast.flags.dolby' | translate }}
        </li>
      }

      @if (broadcast.age) {
        <li>
          @switch (broadcast.age) {
            @case (6) {
              <tbui-icon iconId="fsk-6" size="m" [inline]="true"></tbui-icon>
            }
            @case (12) {
              <tbui-icon iconId="fsk-12" size="m" [inline]="true"></tbui-icon>
            }
            @case (16) {
              <tbui-icon iconId="fsk-16" size="m" [inline]="true"></tbui-icon>
            }
            @case (18) {
              <tbui-icon iconId="fsk-18" size="m" [inline]="true"></tbui-icon>
            }
          }

          FSK {{ broadcast.age }}
        </li>
      }

      @if (broadcast.year) {
        <li>
          <tbui-icon iconId="calendar" size="m" [inline]="true"></tbui-icon>
          {{ broadcast.year }}
        </li>
      }

      @if (broadcast.country) {
        <li [matTooltip]="broadcast.country" matTooltipPosition="above" matTooltipClass="tb-tooltip">
          <tbui-icon iconId="flag" size="m" [inline]="true"></tbui-icon>
          {{ broadcast.country | truncate:15 }}
        </li>
      }

      @if (broadcast.hasImdbRating()) {
        <li>
          <tbui-imdb-rating [imdbRating]="broadcast.imdbRating"
                            [numberOfReviews]="broadcast.imdbVoteCount"></tbui-imdb-rating>
        </li>
      }
    </ul>
  </section>

  <!-- GENRE & TAGS -->
  <ul class="broadcast__tags btn__list btn__list-h mt-small">
    @if (getGenre$(broadcast) | async; as genre) {
      <li>
        <a [routerLink]="openGenreLP(genre.id)">
          <span class="badge badge__primary">{{ genre.name }}</span>
        </a>
      </li>
    }

    @for (tag of broadcast.tags; track $index) {
      <li>
        <a [routerLink]="openTagLP(tag)">
          <span class="badge badge__secondary">#{{ tag }}</span>
        </a>
      </li>
    }
  </ul>

  @if (broadcast.description && broadcast.description.length > 550) {
    <details>
      <summary class="broadcast__content--text">{{ broadcast.description | truncate:550 }} <span
        class="read-more">{{ 'broadcast.action.moreText' | translate }}</span></summary>
      <p class="broadcast__content--text">{{ broadcast.description }}</p>
    </details>
  } @else {
    <p class="broadcast__content--text">{{ broadcast.description }}</p>
  }
</ng-template>

<ng-template #actionsMobile let-broadcast="broadcast">
  <ul class="btn__list btn__list-h btn__list-h--evenly text-center visible-tablet">
    @if (broadcast.flags.is_playable) {
      <li>
        <button class="btn btn__icon btn--circle btn__secondary btn--lg" aria-labelledby="broadcast-action-play-label"
                [appBroadcastPlay]="broadcast" appDataLayer [dataLayerEventName]="'play_' + broadcast.id">
          <tbui-icon iconId="play"></tbui-icon>
        </button>
        <br>
        <small id="broadcast-action-play-label">{{ 'broadcast.action.play.label' | translate }}</small>
      </li>
      @if (broadcast.flags.is_playing) {
        <li>
          <button class="btn btn__icon btn--circle btn__secondary btn--lg"
                  aria-labelledby="broadcast-action-restart-label"
                  [appBroadcastPlay]="broadcast" [restart]="true" appDataLayer
                  [dataLayerEventName]="'replay_' + broadcast.id">
            <tbui-icon iconId="replay"></tbui-icon>
          </button>
          <br>
          <small id="broadcast-action-restart-label">{{ 'broadcast.action.restart.label' | translate }}</small>
        </li>
      }
    }

    @if (broadcast.flags.isRecordable()) {
      <li>
        <button class="btn btn__icon btn--circle btn__secondary btn--lg" aria-labelledby="broadcast-action-record-label"
                [appBroadcastToggleRecord]="broadcast" appDataLayer
                [dataLayerEventName]="'record_' + broadcast.id">
          <tbui-icon iconId="record"></tbui-icon>
        </button>
        <br>
        <small id="broadcast-action-record-label">
          {{ (broadcast.flags.isRecorded() ? 'broadcast.action.recordDelete.label' : 'broadcast.action.record.label') | translate }}
        </small>
      </li>
    }

    @if (broadcast.flags.isRecorded() && !broadcast.flags.isRecordable()) {
      <li>
        <button class="btn btn__icon btn--circle btn__secondary btn--lg"
                aria-labelledby="broadcast-action-recordDelete-label"
                [appBroadcastDeleteRecord]="broadcast" appDataLayer
                [dataLayerEventName]="'delete_' + broadcast.id">
          <tbui-icon iconId="bin"></tbui-icon>
        </button>
        <br>
        <small id="broadcast-action-recordDelete-label">
          {{ 'broadcast.action.recordDelete.label' | translate }}
        </small>
      </li>
    }

    @if (broadcast.flags.isWatchlistable() && !broadcast.flags.isRecorded()) {
      <li>
        <button class="btn btn__icon btn--circle btn__secondary btn--lg"
                aria-labelledby="broadcast-action-watchlist-label"
                [appBroadcastWatchlist]="broadcast" appDataLayer
                [dataLayerEventName]="'watchlist_' + broadcast.id">
          <tbui-icon iconId="watchlist"></tbui-icon>
        </button>
        <br>
        <small id="broadcast-action-watchlist-label">
          {{ (broadcast.flags.isWatchlisted() ? 'broadcast.action.watchlistDelete.label' : 'broadcast.action.watchlist.label') | translate }}
        </small>
      </li>
    }
  </ul>
</ng-template>

<!-- PERSONS -->
<ng-template #persons let-broadcast="broadcast">
  @if (broadcast.persons?.director || broadcast.persons?.actor) {
    <div class="card card--rounded card--flat card--gray">
      <div class="card__title"><h2 class="h3">{{ 'broadcast.persons.title' | translate }}</h2></div>
      <div class="card__body">
        @if (broadcast.persons!.director) {
          <h3>{{ 'broadcast.persons.director' | translate }}</h3>
          <ul>
            @for (director of broadcast.persons!.director; track $index) {
              <li>
                <a class="person__link" (click)="openCastLP(director.name)">{{ director.name }}</a>
              </li>
            }
          </ul>
        }

        @if (broadcast.persons!.actor) {
          <h3>{{ 'broadcast.persons.actors' | translate }}</h3>
          <ul>
            @for (actor of broadcast.persons!.actor; track $index) {
              <li>
                <a class="person__link" (click)="openCastLP(actor.name)">{{ actor.name }}</a>
              </li>
            }
          </ul>
        }
      </div>
    </div>
  }
</ng-template>

<ng-template #recommendations let-broadcast="broadcast">
  @if (!broadcast.contents) {
    @if (searchParams) {
      <app-search-swiper [params]="searchParams"
                         [headline]="'broadcast.related.recommendations.title' | translate"
                         [swiperOptions]="SlideConfig"></app-search-swiper>
    }

    @if (replayChartsParams) {
      <app-broadcast-swiper [params]="replayChartsParams"
                            [isLinkClickable]="false"
                            [headline]="'broadcast.related.recommendations.title' | translate"
                            [swiperOptions]="SlideConfig"></app-broadcast-swiper>
    }
  }

  @if (broadcast.contents && episodeParams) {
      <app-episodes-swiper [params]="episodeParams"
                           [seriesId]="broadcast.contents.series_id"
                           [seasonId]="broadcast.contents.season_id"
                           [headline]="broadcast.serieSeason ? ('broadcast.related.episodes.title' | translate: { seasonNumber: broadcast.serieSeason}) :  ('broadcast.related.episodes.titleNoSeasons' | translate)"
                           [swiperOptions]="SlideConfig"></app-episodes-swiper>
  }
</ng-template>
