import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { PopupService } from '../../../core/services/popup.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { ScreenService } from '../../../core/services/screen.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PopupComponent implements OnInit, OnDestroy {
  @ViewChild('backdrop', { static: true }) backdrop!: ElementRef;
  @ViewChild('scrollToTop', { static: true }) scrollToTop!: ElementRef;
  @Input() outlet = 'popup';
  @Input() title = '';
  @Input() large = false;
  @Input() full = false;
  @Output() closeClicked: EventEmitter<void> = new EventEmitter<void>();

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private popupService: PopupService,
    private renderer2: Renderer2,
    private router: Router,
    private screenService: ScreenService
  ) {}

  get backdropClassList() {
    return {
      'popup__backdrop--full': this.full,
      'popup__backdrop--landingpage': this.outlet === 'landingpage',
      'popup__backdrop--popup': this.outlet === 'popup',
      'popup__backdrop--broadcast': this.outlet === 'broadcast',
      'popup__backdrop--details': this.outlet === 'details'
    };
  }

  @HostListener('document:keydown.escape')
  onEscDown() {
    this.back();
  }

  ngOnInit() {
    this.popupService.popupCounter++;
    const body = this.document.body;
    const scrollbarWidth = this.screenService.getScrollbarWidth();
    const bodyStyle = body.style;
    if (scrollbarWidth > 0) {
      const actualPadding = parseFloat(window.getComputedStyle(body).paddingRight);
      bodyStyle.paddingRight = `${actualPadding + scrollbarWidth}px`;
    }
    bodyStyle.overflow = 'hidden';
    this.renderer2.addClass(this.document.body, `popup-open`);
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.router.getCurrentNavigation()?.extractedUrl?.fragment === 'scrollToTop') {
        this.scrollToTop.nativeElement.scrollIntoView();
      }
    });
  }

  ngOnDestroy() {
    this.popupService.popupCounter--;
    if (this.popupService.popupCounter == 0) {
      const bodyStyle = this.document.body.style;
      bodyStyle.paddingRight = '';
      bodyStyle.removeProperty('overflow');
      this.renderer2.removeClass(this.document.body, `popup-open`);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  backdropClick($event: Event): void {
    if ($event.target === this.backdrop.nativeElement) {
      this.back();
    }
  }

  close(): void {
    this.closeClicked.emit();
    this.back();
  }

  back(): void {
    if (this.outlet === 'landingpage') {
      void this.router.navigate([{ outlets: { landingpage: null } }]);
    } else if (this.outlet === 'broadcast') {
      void this.router.navigate([{ outlets: { broadcast: null } }], { queryParamsHandling: 'preserve' });
    } else if (this.outlet === 'details') {
      void this.router.navigate([{ outlets: { details: null } }], { queryParamsHandling: 'preserve' });
    } else if (this.outlet === 'person') {
      void this.router.navigate([{ outlets: { person: null } }], { queryParamsHandling: 'preserve' });
    } else {
      void this.router.navigate([{ outlets: { popup: null } }]);
    }
  }
}
