<a *ngIf="station; else ghost" class="card card--rounded station-item"
   [title]="'station.item.title' | translate: {stationName: station.name}"
   [routerLink]="['', { outlets: { landingpage: ['station', station.id] }}]">
  <tbui-station-logo *ngIf="station" [station]="station" [size]="StationLogoSize.M"></tbui-station-logo>
</a>

<ng-template #ghost>
  <div class="card card--rounded station-item">
    <div [ngStyle]="{width: '80px', height: '80px'}"></div>
  </div>
</ng-template>
