import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SearchParams, SearchPrefix, SearchSource, Serie } from '@teleboy/web.epg';
import { Observable, startWith, Subject } from 'rxjs';
import { ApiItemResponse, ApiListResponse } from '@teleboy/web.core';
import { DataSwiperDirective } from '../../../directives/swiper/data-swiper.directive';
import { EntityServiceType, EntityType, ItemsListRouteData } from '../../../interfaces/items-list-route-data';
import { PopupService } from '../../../../core/services/popup.service';
import { SharedModule } from '../../../../shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { SerieItemComponent } from '../../serie-item/serie-item.component';
import { BroadcastItemComponent } from '../../broadcast-item/broadcast-item.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-series-swiper',
  templateUrl: './series-swiper.component.html',
  styleUrls: ['../swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule, SwiperModule, SerieItemComponent, BroadcastItemComponent, AsyncPipe]
})
export class SeriesSwiperComponent extends DataSwiperDirective<Serie> implements OnInit, OnChanges {
  @Input() series!: Serie[];
  @Input() searchQueryMode!: SearchPrefix;
  @Input() searchSource!: SearchSource;
  @Input() searchValue!: string;

  protected readonly SearchSource = SearchSource;

  private readonly response$: Subject<ApiItemResponse['data']> = new Subject<ApiItemResponse['data']>();

  constructor(private popupService: PopupService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['series']?.firstChange) {
      this.response$.next(this._response$());
    }
  }

  itemLoader$(): Observable<ApiListResponse['data']> {
    return this.response$.pipe(startWith(this._response$()));
  }

  trackByFn(index: number, serie: Serie): number {
    return serie.id;
  }

  openInListTilesView(searchSourceValue: SearchSource): void {
    const searchParams = new SearchParams(this.searchValue, [searchSourceValue], this.searchQueryMode);

    const routeData: ItemsListRouteData = {
      title: this.headline,
      params: searchParams,
      serviceName: EntityServiceType.SearchApiService,
      broadcastType: EntityType.Serie,
      searchSourceValue: searchSourceValue,
      searchValue: this.searchValue,
      searchQueryMode: this.searchQueryMode
    };

    this.popupService.open('items/list', { outlet: 'details', data: routeData, skipLocationChange: true });
  }

  private _response$(): ApiListResponse['data'] {
    return {
      total: this.series.length,
      items: this.series
    };
  }
}
