import { Injectable } from '@angular/core';
import { PopupService } from '../../core/services/popup.service';
import { Broadcast } from '@teleboy/web.epg';
import { CpvrJoinComponent } from '../components/cpvr/cpvr-join/cpvr-join.component';
import { CpvrFriendComponent } from '../components/cpvr/cpvr-friend/cpvr-friend.component';
import { CpvrBorrowComponent } from '../components/cpvr/cpvr-borrow/cpvr-borrow.component';
import { CpvrNoSlotComponent } from '../components/cpvr/cpvr-no-slot/cpvr-no-slot.component';

@Injectable({
  providedIn: 'root'
})
export class CpvrService {
  private readonly PANEL_CLASSES = ['popup__container'];

  constructor(private popupService: PopupService) {}

  joinCommunity(broadcast?: Broadcast): void {
    this.popupService.openAsModal<CpvrJoinComponent>(CpvrJoinComponent, broadcast, {
      panelClass: this.PANEL_CLASSES
    });
  }

  requestFriendship(broadcast: Broadcast): void {
    this.popupService.openAsModal<CpvrFriendComponent>(CpvrFriendComponent, broadcast, {
      panelClass: this.PANEL_CLASSES
    });
  }

  borrowRecording(broadcast: Broadcast): void {
    this.popupService.openAsModal<CpvrBorrowComponent>(CpvrBorrowComponent, broadcast, {
      panelClass: this.PANEL_CLASSES
    });
  }

  communityNoSlot(broadcast: Broadcast): void {
    this.popupService.openAsModal<CpvrNoSlotComponent>(CpvrNoSlotComponent, broadcast, {
      panelClass: this.PANEL_CLASSES,
      maxWidth: '850px'
    });
  }
}
