<div *ngIf="serialgroup; else ghost" class="broadcast-item card">
  <div class="broadcast-item__body card__body">
    <div class="broadcast-item__station-logo">
      <tbui-station-logo [station]="serialgroup.station" [size]="StationLogoSize.S"></tbui-station-logo>
    </div>

    <div class="broadcast-item__info">
      <div class="broadcast-item__title ellipsis">
        <span class="h3 collapse">{{ serialgroup.title }}</span>
      </div>
      <div class="broadcast-item__begin ellipsis">
        <ng-container *ngIf="serialgroup.broadcast; else noBroadcast">
          {{ serialgroup.broadcast.begin.format('dddd, DD. MMMM • HH:mm') }}
        </ng-container>
      </div>
    </div>

    <div class="broadcast-item__actions">
      <button class="btn btn__icon btn--circle btn--bordered" [ngClass]="{'btn--busy': busy$ | async}" (click)="delete()">
        <tbui-icon iconId="bin"></tbui-icon>
      </button>
    </div>
  </div>
</div>

<ng-template #noBroadcast>
  {{ 'pvr.serialgroups.noBroadcast' | translate }}
</ng-template>

<ng-template #ghost>
  <div class="broadcast-item card">
    <div class="broadcast-item__body card__body">
      <div class="broadcast-item__station-logo">
        <div [ngStyle]="{ width: '40px', 'height': '40px'}"></div>
      </div>
      <div class="broadcast-item__info">
        <div class="broadcast-item__title ellipsis">
          <span class="h3 collapse">&nbsp;</span>
        </div>
        <div class="broadcast-item__begin ellipsis">&nbsp;</div>
      </div>

      <div class="broadcast-item__actions">
      </div>
    </div>
  </div>
</ng-template>
