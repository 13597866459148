<app-info class="no-slot--info"
          [title]="'cpvr.borrow.noSlot.title' | translate: { nickname: broadcast.community!.provider.nickname }"
          [text]="'cpvr.borrow.noSlot.text' | translate" icon="community">

  <ng-container customContent>
    <app-search-swiper [swiperOptions]="SlideConfig" (detailClick)="close()"
                       [params]="searchParams"></app-search-swiper>
  </ng-container>

  <div class="popup__footer btn__list-h btn__list-h--center mb-tiny" cta>
    <button (click)="close()" class="btn btn__primary" rel="nofollow">
      {{ 'cpvr.borrow.noSlot.close' | translate }}
    </button>
  </div>
</app-info>
