import { ChangeDetectionStrategy, Component, Inject, Input, NgZone } from '@angular/core';
import { ListSwiperDirective } from '../../../directives/swiper/list-swiper.directive';
import { ContentApiService, Serie, SerieParams } from '@teleboy/web.epg';
import { Observable } from 'rxjs';
import { ApiListResponse } from '@teleboy/web.core';
import { ItemsListRouteData, EntityServiceType, EntityType } from '../../../interfaces/items-list-route-data';
import { PopupService } from '../../../../core/services/popup.service';
import { ViewToggleType } from '../../../services/view-toggler.service';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { SerieItemComponent } from '../../serie-item/serie-item.component';

@Component({
  selector: 'app-serie-swiper',
  templateUrl: './serie-swiper.component.html',
  styleUrls: ['../swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, SharedModule, SwiperModule, NgFor, SerieItemComponent, AsyncPipe]
})
export class SerieSwiperComponent extends ListSwiperDirective<Serie> {
  @Input() override params!: SerieParams;

  constructor(
    @Inject(NgZone) ngZone: NgZone,
    private contentApiService: ContentApiService,
    private popupService: PopupService
  ) {
    super(ngZone);
  }

  itemLoader$(): Observable<ApiListResponse['data']> {
    return this.contentApiService.getSeries(this.params);
  }

  openInListTilesView() {
    const routeData: ItemsListRouteData = {
      title: this.headline,
      params: this.params,
      serviceName: EntityServiceType.ContentApiService,
      broadcastType: EntityType.Serie,
      enableListTypeToggle: false,
      predefinedListingType: ViewToggleType.TILES
    };

    this.popupService.open('items/list', { outlet: 'details', data: routeData, skipLocationChange: true });
  }
}
