<ng-container *ngIf="broadcast$ | async as broadcast">
  <div class="cpvr-friend__broadcast">
    <app-broadcast-item [broadcast]="broadcast" [forceImage]="true" [disableActions]="true"></app-broadcast-item>
  </div>

  <div class="popup__container">
    <app-close-button (closed)="close()"></app-close-button>
    <ng-container *ngIf="broadcast.community!.provider.isFriend$ | async; then friendConfirmed else friendRequest"></ng-container>
  </div>

  <ng-template #friendRequest>
    <app-info [title]="'cpvr.friend.request.title' | translate: {nickname: broadcast.community!.provider.nickname}"
              [text]="'cpvr.friend.request.text' | translate">
    </app-info>

    <div class="text-center">
      <app-cpvr-profile [communityProvider]="broadcast.community!.provider"></app-cpvr-profile>
    </div>

    <div class="popup__footer btn__list-h btn__list-h--apart">
      <button (click)="close()" class="btn btn__secondary">{{ 'cpvr.friend.request.cta.close' | translate }}</button>
      <button class="btn btn__primary" (click)="requestFriendship()" [attr.aria-disabled]="busy$ | async" appAutofocus>
        {{ 'cpvr.friend.request.cta.send' | translate }}
      </button>
    </div>
  </ng-template>

  <ng-template #friendConfirmed>
    <app-info [title]="'cpvr.friend.accepted.title' | translate"
              [text]="'cpvr.friend.accepted.text' | translate: {nickname: broadcast.community!.provider.nickname}">
    </app-info>

    <div class="text-center">
      <app-cpvr-profile [communityProvider]="broadcast.community!.provider"></app-cpvr-profile>
    </div>

    <div class="popup__footer btn__list-h btn__list-h--end">
      <button (click)="close()" class="btn btn__primary" appAutofocus>
        {{ 'cpvr.friend.accepted.cta.close' | translate }}
      </button>
    </div>
  </ng-template>
</ng-container>
