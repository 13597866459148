<div class="swiper__container swiper__container--broadcast" *ngIf="!(isEmptyResult$() | async)">

  <app-expand-link [linkText]="headline" [clickable]="isLinkClickable" (clicked)="openInListTilesView()"></app-expand-link>

    <swiper *ngIf="data$ | async as staralerts; else loading" #swiper [slidesPerView]="swiperOptions.slidesPerView" [breakpoints]="swiperOptions.breakpoints">
      <ng-template *ngFor="let staralert of staralerts" swiperSlide>
        <app-staralert-item [starAlert]="staralert" [card]="card"></app-staralert-item>
      </ng-template>
      <ng-template swiperSlide>
        <app-staralert-item *ngIf="isPaginating$ | async" [card]="card"></app-staralert-item>
      </ng-template>
    </swiper>
</div>

<ng-template #loading>
  <swiper [slidesPerView]="swiperOptions.slidesPerView" [breakpoints]="swiperOptions.breakpoints" class="ghost-listing ghost-listing--horizontal">
    <ng-template *ngFor="let ghost of ghosts" swiperSlide>
      <app-staralert-item [card]="card"></app-staralert-item>
    </ng-template>
  </swiper>
</ng-template>
