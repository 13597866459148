import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { PvrGenregroupPreviewComponent } from '../../previews/pvr-genregroup-preview/pvr-genregroup-preview.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  RecordingApiService,
  RecordingGroup,
  RecordingGroupsParams,
  RecordingGroupsSort,
  RecordingGroupType,
  RecordingSortDirection
} from '@teleboy/web.epg';
import { catchError, EMPTY, map, Observable, switchMap, tap } from 'rxjs';
import { ApiListData } from '@teleboy/web.core';
import { BroadcastItemComponent } from '../../../../epg/components/broadcast-item/broadcast-item.component';
import { SwiperModule } from 'swiper/angular';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { SharedModule } from '../../../../shared/shared.module';
import { SnackbarService, SnackbarType } from '../../../../core/services/snackbar.service';
import { Router } from '@angular/router';
import { PvrGridButtonComponent } from '../../mics/pvr-grid-button/pvr-grid-button.component';
import { PvrEmptyListComponent } from '../../mics/pvr-empty-list/pvr-empty-list.component';

@Component({
  selector: 'app-pvr-genregroup-list',
  imports: [
    PvrGenregroupPreviewComponent,
    TranslateModule,
    BroadcastItemComponent,
    SwiperModule,
    FormsModule,
    InfiniteScrollDirective,
    SharedModule,
    PvrGridButtonComponent,
    PvrEmptyListComponent
  ],
  templateUrl: './pvr-genregroup-list.component.html',
  styleUrls: ['pvr-genregroup-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PvrGenregroupListComponent implements OnInit {
  readonly genreGroups = signal<RecordingGroup[]>([]);

  /**
   * Defines the busy state of the page. Ghost previews are shown while busy
   */
  readonly busy = signal(true);
  /**
   * Empty array which defines the number of ghost previews shown while busy
   */
  readonly ghostPreviews: unknown[] = new Array(20);

  private totalGenreGroups = 0;

  /**
   * The number of genre groups to load and display per page
   */
  private readonly PAGINATION_LIMIT = 40;

  private readonly params = new RecordingGroupsParams();

  constructor(
    private recordingApiService: RecordingApiService,
    private router: Router,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.setParams();
    this.getGenreGroups$().subscribe();
  }

  paginate(): void {
    if (!this.params.canPaginate(this.totalGenreGroups)) {
      return;
    }

    this.params.paginate();
  }

  openRecordingList(): void {
    void this.router.navigate([{ outlets: { ['details']: 'pvr/recordings' } }], {
      queryParams: { sortDirection: RecordingSortDirection.DESC }
    });
  }

  private setParams(): void {
    this.params.setSort(RecordingGroupsSort.COUNT).setLimit(this.PAGINATION_LIMIT);
  }

  private getGenreGroups$(): Observable<RecordingGroup[]> {
    return this.params.skip$.pipe(
      tap(() => this.busy.set(true)),
      switchMap(() => {
        return this.recordingApiService
          .group(RecordingGroupType.GENRE, this.params)
          .pipe(catchError(() => this.handleTitleGroupsResponseError$()));
      }),
      tap((data: ApiListData<RecordingGroup>) => (this.totalGenreGroups = data.total)),
      map((response: ApiListData<RecordingGroup>) => response.items),
      tap((nextRecordingGroups: RecordingGroup[]) => {
        this.genreGroups.update((recordingGroups) =>
          this.params.skip === 0 ? nextRecordingGroups : [...recordingGroups, ...nextRecordingGroups]
        );
      }),
      tap(() => this.busy.set(false))
    );
  }

  private handleTitleGroupsResponseError$(): Observable<never> {
    this.busy.set(false);
    this.snackbarService.openSnackbar('pvr.list.error.loading', SnackbarType.ERROR);

    return EMPTY;
  }
}
