"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListNavigationGroup = exports.ListOrientation = void 0;
var navigationgroup_1 = require("./navigationgroup");
var types_1 = require("./types");
var ListOrientation;
(function (ListOrientation) {
  ListOrientation["Horizontal"] = "horizontal";
  ListOrientation["Vertical"] = "vertical";
})(ListOrientation = exports.ListOrientation || (exports.ListOrientation = {}));
/**
 * @category Components
 */
var ListNavigationGroup = /** @class */function (_super) {
  __extends(ListNavigationGroup, _super);
  function ListNavigationGroup(orientation, container) {
    var components = [];
    for (var _i = 2; _i < arguments.length; _i++) {
      components[_i - 2] = arguments[_i];
    }
    var _this = _super.apply(this, __spreadArray([container], components, false)) || this;
    switch (orientation) {
      case ListOrientation.Vertical:
        _this.listNavigationDirections = [types_1.Direction.UP, types_1.Direction.DOWN];
        break;
      case ListOrientation.Horizontal:
        _this.listNavigationDirections = [types_1.Direction.LEFT, types_1.Direction.RIGHT];
        break;
    }
    return _this;
  }
  ListNavigationGroup.prototype.handleAction = function (action) {
    _super.prototype.handleAction.call(this, action);
    if (action === types_1.Action.SELECT) {
      // close the container when a list entry is selected
      this.handleAction(types_1.Action.BACK);
    }
  };
  ListNavigationGroup.prototype.handleNavigation = function (direction) {
    _super.prototype.handleNavigation.call(this, direction);
    if (!this.listNavigationDirections.includes(direction)) {
      // close the container on navigation inputs that don't align
      // with the orientation of the list
      this.handleAction(types_1.Action.BACK);
    }
  };
  return ListNavigationGroup;
}(navigationgroup_1.NavigationGroup);
exports.ListNavigationGroup = ListNavigationGroup;