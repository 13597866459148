import { Injectable } from '@angular/core';
import { Upsell, UpsellCta } from '../../interfaces/upsell.interface';
import { PopupService } from '../popup.service';
import { Router } from '@angular/router';
import { UserService } from '@teleboy/web.user';
import { BehaviorSubject, finalize, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReplayActivationUpsellService implements Upsell {
  readonly name = 'replayActivation';
  readonly icon = 'replay';
  readonly cta: UpsellCta[];

  private readonly _busy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly busy$: Observable<boolean> = this._busy$.asObservable();

  constructor(
    private popupService: PopupService,
    private router: Router,
    private userService: UserService
  ) {
    this.cta = this._cta();
  }

  private _cta(): UpsellCta[] {
    return [
      {
        key: 'close',
        click: () => this.popupService.closeModal()
      },
      {
        key: 'activate',
        click: () => this.activateReplay()
      }
    ];
  }

  private activateReplay(): void {
    this._busy$.next(true);

    this.userService
      .activateReplay()
      .pipe(
        tap(() => this.popupService.closeModal()),
        finalize(() => this._busy$.next(false))
      )
      .subscribe();
  }
}
