{
  "name": "web.player",
  "version": "1.39.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration vm",
    "test": "ng test",
    "lint": "npx eslint src/app",
    "pwa": "ng build && npx http-server --port 5100 --ssl --cert self-signed-certificate/tv.local.teleboy.ch.pem --key self-signed-certificate/tv.local.teleboy.ch.key -c-1 dist/"
  },
  "private": true,
  "dependencies": {
    "@angular-slider/ngx-slider": "^19.0.0",
    "@angular/animations": "^19.0.6",
    "@angular/cdk": "^19.0.4",
    "@angular/common": "^19.0.6",
    "@angular/compiler": "^19.0.6",
    "@angular/core": "^19.0.6",
    "@angular/forms": "^19.0.6",
    "@angular/localize": "^19.0.6",
    "@angular/material": "^19.0.4",
    "@angular/platform-browser": "^19.0.6",
    "@angular/platform-browser-dynamic": "^19.0.6",
    "@angular/router": "^19.0.6",
    "@angular/service-worker": "^19.0.6",
    "@datadog/browser-logs": "^5.28.1",
    "@datadog/browser-rum": "^5.27.0",
    "@ng-bootstrap/ng-bootstrap": "^18.0.0",
    "@ngrx/component": "^19.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@popperjs/core": "^2.11.2",
    "@teleboy/web.core": "^15.2.1",
    "@teleboy/web.epg": "^18.2.0",
    "@teleboy/web.player": "^13.1.0",
    "@teleboy/web.shop": "^9.3.0",
    "@teleboy/web.ui": "^9.3.0",
    "@teleboy/web.user": "^16.1.1",
    "angular-google-tag-manager": "^1.11.0",
    "bitmovin-player": "^8.161.0",
    "bitmovin-player-ui": "^3.60.0",
    "blueimp-md5": "^2.19.0",
    "install": "^0.13.0",
    "md5": "^2.3.0",
    "moment": "^2.30.1",
    "ng": "^0.0.0",
    "ngx-cookie-service": "^19.0.0",
    "ngx-device-detector": "^9.0.0",
    "ngx-infinite-scroll": "^19.0.0",
    "ngx-webstorage": "^19.0.1",
    "prettier": "^3.2.5",
    "rxjs": "^7.5.5",
    "serve": "^14.1.2",
    "swiper": "^8.1.0",
    "trackjs": "^3.10.1",
    "ts-md5": "^1.2.10",
    "tslib": "^2.3.0",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.7",
    "@angular/cli": "^19.0.7",
    "@angular/compiler-cli": "^19.0.6",
    "@eslint/eslintrc": "^3.1.0",
    "@eslint/js": "^9.14.0",
    "@teleboy/onesky-cli": "^3.0.1",
    "@teleboy/trackjs-kpi": "^1.1.0",
    "@types/node": "^18.11.18",
    "angular-eslint": "^18.4.0",
    "eslint": "^9.14.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "http-server": "^14.1.1",
    "json2typescript": "1.4.1",
    "typescript": "^5.4.5",
    "typescript-eslint": "^8.13.0"
  },
  "oneSky": {
    "projectIds": [
      "385566",
      "392440"
    ],
    "apiKey": "U3moX8L7WPgQRkPl86jP6Vx8XR89ATOl",
    "secret": "CbhHE3VwkYei4be8EXr7R9MRbbuamSVO",
    "filename": "de.json",
    "path": "src/assets/i18n"
  },
  "trackJS": {
    "customerId": "b5de85764b854144a288bdb7f897a34c",
    "apiKey": "0a53d2cc09ee41ce8d5b5c913ff055e1",
    "application": "teleboy-tv"
  },
  "datadog": {
    "rum": {
      "applicationId": "76e4a518-c643-4741-9a85-375360297b0e",
      "clientToken": "puba532517109dc86ff36ebf5edb910b791",
      "service": "web-player"
    },
    "logger": {
      "clientToken": "puba532517109dc86ff36ebf5edb910b791",
      "service": "web-player"
    }
  }
}
