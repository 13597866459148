import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-pvr-grid-button',
  imports: [NgClass, IconComponent],
  templateUrl: './pvr-grid-button.component.html',
  styleUrls: ['./pvr-grid-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PvrGridButtonComponent {
  icon = input<string>('');
  label = input.required<string>();
  rounded = input<boolean>(false);
  size = input<'xl' | 'xxl'>('xxl');
  subLabel = input<string>();
}
