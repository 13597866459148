import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { MediaImageStack } from '@teleboy/web.core';
import { Serie } from '@teleboy/web.epg';
import { Router, RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { MediaImageComponent } from '@teleboy/web.ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-serie-item',
  templateUrl: './serie-item.component.html',
  styleUrls: ['./serie-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MediaImageComponent, RouterLink, TranslateModule]
})
export class SerieItemComponent {
  @Input() serie!: Serie;
  MediaImageStack = MediaImageStack;

  constructor(private router: Router) {}

  getDetailRouterLinkCommand(): unknown[] {
    return ['', { outlets: { broadcast: ['serie', this.serie.id] } }];
  }

  @HostListener('click', ['$event'])
  onClick($event: Event): void {
    $event.preventDefault();
    void this.router.navigate(this.getDetailRouterLinkCommand());
  }
}
