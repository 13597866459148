"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getHtmlElementsFromComponents = void 0;
var container_1 = require("../components/container");
var typeguards_1 = require("./typeguards");
/**
 * Recursively resolves a container and the components contained within them, building a flat list of components.
 *
 * @param container The container to get the contained components from
 */
function resolveAllComponents(container) {
  var childComponents = [];
  container.getComponents().forEach(function (containerOrComponent) {
    if ((0, typeguards_1.isContainer)(containerOrComponent)) {
      childComponents.push.apply(childComponents, resolveAllComponents(containerOrComponent));
    } else if ((0, typeguards_1.isComponent)(containerOrComponent)) {
      childComponents.push(containerOrComponent);
    }
  });
  return childComponents;
}
/**
 * Returns the HTML elements associated to the provided component.
 *
 * @param component The component to get the HTML elements from
 */
function toHtmlElement(component) {
  if ((0, typeguards_1.isListBox)(component)) {
    return [].slice.call(component.getDomElement().get()[0].children);
  } else {
    return component.getDomElement().get().slice(0, 1);
  }
}
/**
 * Takes the provided list of components and flat-maps them to a list of their respective HTML elements. In case a
 * provided component is a container, the children of that container will be resolved recursively. Ignores components
 * that are hidden.
 *
 * @param components The components to map to HTML elements
 */
function getHtmlElementsFromComponents(components) {
  var htmlElements = [];
  components.filter(function (component) {
    return !component.isHidden();
  }).forEach(function (component) {
    var elementsToConsider = component instanceof container_1.Container ? resolveAllComponents(component) : [component];
    elementsToConsider.forEach(function (component) {
      htmlElements.push.apply(htmlElements, toHtmlElement(component));
    });
  });
  return htmlElements;
}
exports.getHtmlElementsFromComponents = getHtmlElementsFromComponents;