<app-popup outlet="landingpage" [full]="true">

  <h1>{{ pageTitle() ||  ('pvr.titleGroups.title' | translate) }}</h1>

  <form class="titlegroup-list mb-base">
    <button (click)="toggleSort()" [disabled]="busy()" class="btn btn__primary">
      {{ ('pvr.titleGroups.filter.sort.' + sort()) | translate }}
    </button>
  </form>

  @if (!busy()) {
    <div class="grid" [ngClass]="{'grid__tiles': titleGroups().length}"
         (scrolled)="paginate()" infiniteScroll
         [infiniteScrollContainer]="'.popup__backdrop--landingpage'"
         [fromRoot]="true"
         [immediateCheck]="true">

      @for (titleGroup of titleGroups(); track $index) {
        <div class="recording-list__batch-delete-container">
          <app-pvr-titlegroup-preview [titleGroup]="titleGroup" [duplicatesPreview]="moviesOnly()"></app-pvr-titlegroup-preview>
        </div>
      } @empty {
        <app-pvr-empty-list></app-pvr-empty-list>
      }
    </div>
  }

  @if (busy()) {
    <div class="grid ghost-listing grid__tiles">
      @for (ghost of ghostPreviews; track $index) {
        <app-broadcast-item [card]="true"></app-broadcast-item>
      }
    </div>
  }

</app-popup>

