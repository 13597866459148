import { APP_INITIALIZER } from '@angular/core';
import { InitService } from '../services/init.service';
import { concat, Observable } from 'rxjs';

export function initFactory(initService: InitService): () => Observable<unknown> {
  return (): Observable<unknown> =>
    concat(initService.initAuthentication(), initService.initGenres(), initService.initSettings());
}

export const APP_INIT_PROVIDER = {
  provide: APP_INITIALIZER,
  useFactory: initFactory,
  deps: [InitService],
  multi: true
};
