<div class="swiper__container swiper__container--content" *ngIf="!(isEmptyResult$() | async)">
  <app-expand-link [linkText]="headline" (clicked)="openSerieBroadcastModal()"></app-expand-link>

  <swiper *ngIf="data$ | async as episodes; else loading" #swiper [slidesPerView]="swiperOptions.slidesPerView"
          [breakpoints]="swiperOptions.breakpoints">
    <ng-template *ngFor="let episode of episodes" swiperSlide>
      <app-broadcast-item [broadcast]="episode?.broadcast" [card]="true" [episode]="true"></app-broadcast-item>
    </ng-template>
    <ng-template swiperSlide>
      <app-broadcast-item *ngIf="isPaginating$ | async" [card]="true" class="ghost-listing"></app-broadcast-item>
    </ng-template>
  </swiper>
</div>

<ng-template #loading>
  <swiper class="ghost-listing ghost-listing--horizontal">
    <ng-template *ngFor="let ghost of ghosts" swiperSlide>
      <app-broadcast-item [card]="true"></app-broadcast-item>
    </ng-template>
  </swiper>
</ng-template>
