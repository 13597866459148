import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeartbeatswiperService {
  readonly fetchHeartbeatLane$!: Observable<boolean>;
  private readonly _fetchHeartbeatLane$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.fetchHeartbeatLane$ = this._fetchHeartbeatLane$.asObservable();
  }

  fetchItems(): void {
    this._fetchHeartbeatLane$.next(true);
  }
}
