import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { PlayerService } from '../../services/player.service';
import { map, merge, Observable, Subject } from 'rxjs';
import { AdInfo, AdInfoService, AdType, BitmovinService } from '@teleboy/web.player';
import { PlayerUiService } from '../../services/player-ui.service';
import { HrefService } from '../../../shared/services/href.service';
import { EventTrackingService } from '@teleboy/web.core';

@Component({
  selector: 'app-ad-ui',
  templateUrl: './ad-ui.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AdUiComponent implements OnInit, OnDestroy {
  adInfo$!: Observable<AdInfo | null>;
  darkBg$!: Observable<boolean>;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private adInfoService: AdInfoService,
    private bitmovinService: BitmovinService,
    private eventTrackingService: EventTrackingService,
    private hrefService: HrefService,
    private playerService: PlayerService,
    private playerUiService: PlayerUiService
  ) {}

  ngOnInit(): void {
    this.adInfo$ = this.adInfoService.info$;
    this.darkBg$ = merge(
      this.bitmovinService.adStarted$.pipe(map(() => false)),
      this.bitmovinService.adFinished$.pipe(map(() => true)),
      this.bitmovinService.adSkipped$.pipe(map(() => true)),
      this.bitmovinService.adError$.pipe(map(() => true))
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closePlayer(): void {
    this.playerService.closePlayer().subscribe();
  }

  gotoSubscriptionUpdate(): void {
    this.eventTrackingService.trackEvent('ad.subscriptionUpsell');
    this.hrefService.goToUrl('/tv', { appendLocale: true, useBlankTarget: true });
  }

  mouseMove(adInfo: AdInfo): void {
    if (!this.allowPlayerUi(adInfo)) {
      return;
    }

    this.playerUiService.showUi();
  }

  allowPlayerUi(adInfo: AdInfo): boolean {
    return adInfo.type === AdType.FREE_PREROLL;
  }
}
