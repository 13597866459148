import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, fromEvent, Observable, of, Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

enum ScreenSize {
  MOBILE_MAX = 480,
  DESKTOP_MAX = 896
}

@Injectable({
  providedIn: 'root'
})
export class ScreenService {
  readonly isInBackground$: Observable<boolean> = of(false);
  readonly isTouchScreenDevice$: Observable<boolean> = of(false);

  private readonly _isInBackground$: Subject<boolean> = new Subject<boolean>();
  private readonly _isTouchScreenDevice$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly window: Window;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private deviceDetector: DeviceDetectorService
  ) {
    this.window = this.document.defaultView as Window;
    this.isInBackground$ = this._isInBackground$.asObservable();
    this.isTouchScreenDevice$ = this._isTouchScreenDevice$.asObservable();

    this.document.onvisibilitychange = () => {
      this._isInBackground$.next(this.document.visibilityState === 'hidden');
    };
  }

  orientationChange$(): Observable<Event> {
    return fromEvent(this.window, 'orientationchange');
  }

  isLandscape(): boolean {
    return this.window.innerWidth > this.window.innerHeight;
  }

  isMobileSizeScreen(): boolean {
    return this.window.innerWidth <= ScreenSize.MOBILE_MAX;
  }

  isDesktopSizeScreen(): boolean {
    return this.window.innerWidth > ScreenSize.DESKTOP_MAX && this.deviceDetector.isDesktop();
  }

  getScrollbarWidth(): number {
    return Math.abs(this.window.innerWidth - this.document.documentElement.clientWidth);
  }

  setIsTouchDevice(isTouch: boolean): void {
    this._isTouchScreenDevice$.next(isTouch);
  }

  isTouchDevice(): boolean {
    return this._isTouchScreenDevice$.value;
  }
}
