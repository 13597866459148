import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrailerPlayerService } from '@teleboy/web.player';
import { catchError, Observable, switchMap, tap } from 'rxjs';
import { PlayerService } from '../../services/player.service';
import { SnackbarService, SnackbarType } from '../../../core/services/snackbar.service';
import { Broadcast } from '@teleboy/web.epg';
import { RouteDataService } from '../../../core/services/route-data.service';

@Component({
  selector: 'app-player-trailer',
  templateUrl: './player-trailer.component.html',
  styleUrls: ['./player-trailer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PlayerTrailerComponent implements AfterViewInit {
  readonly title = this.routeDataService.getDataForRoute<Broadcast>()?.title;

  constructor(
    private activatedRoute: ActivatedRoute,
    private playerService: PlayerService,
    private routeDataService: RouteDataService,
    private snackbarService: SnackbarService,
    private trailerPlayerService: TrailerPlayerService
  ) {}

  ngAfterViewInit(): void {
    this.activatedRoute.params
      .pipe(
        switchMap((params) => this.trailerPlayerService.playTrailer$(params['videoId'])),
        catchError(() => {
          this.snackbarService.openSnackbar('broadcast.action.trailer.failed', SnackbarType.ERROR);
          return this.close();
        }),
        switchMap(() => this.complete$())
      )
      .subscribe();
  }

  private complete$(): Observable<void> {
    return this.trailerPlayerService.complete$().pipe(tap(() => this.close()));
  }

  private close(): Observable<boolean> {
    return this.playerService.closePlayer();
  }
}
