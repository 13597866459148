import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { finalize, map, Observable, takeWhile, timer } from 'rxjs';
import { AuthenticationService, DarkmodeService, SettingsService } from '@teleboy/web.user';
import { StartService } from '../../services/start.service';
import { StaticAssetService } from '@teleboy/web.ui';

@Component({
  selector: 'app-loading-ad',
  templateUrl: './loading-ad.component.html',
  styleUrls: ['./loading-ad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class LoadingAdComponent implements OnInit {
  progress$!: Observable<number>;
  duration!: number;
  logoSrc!: string;
  isUserAuthenticated!: boolean;

  private readonly PROGRESS_STEPS = 5;
  private readonly DEFAULT_DURATION = 5;

  constructor(
    private authenticationService: AuthenticationService,
    private darkmodeService: DarkmodeService,
    private settingsService: SettingsService,
    private startService: StartService,
    private staticAssetService: StaticAssetService
  ) {}

  ngOnInit(): void {
    if (this.darkmodeService.darkmodeEnabled) {
      this.logoSrc = this.staticAssetService.getHref('/shared/logos/logo-teleboy-negative.svg');
    } else {
      this.logoSrc = this.staticAssetService.getHref('/shared/logos/logo-teleboy.svg');
    }

    this.duration = this.settingsService.getSetting('sales.loadingad.duration', this.DEFAULT_DURATION);
    this.progress$ = timer(0, (this.duration * 1000) / this.PROGRESS_STEPS).pipe(
      takeWhile((n) => n < this.PROGRESS_STEPS),
      map((n) => Math.floor(n * (100 / this.PROGRESS_STEPS))),
      finalize(() => this.startService.goToDestination())
    );

    this.isUserAuthenticated = this.authenticationService.isAuthenticated;
  }
}
