<!-- TABBAR -->
<div class="main-tab-nav">
  <nav class="main-tab-nav__container" aria-label="Main Navigation">
      <div class="main-tab-nav__item">
        <a routerLinkActive="main-tab-nav__item--active"
           (click)="navigate('home')"
           routerLink="/home">
          <tbui-icon iconId="navi-player-home"></tbui-icon>
          <span>{{ 'main.nav.home' | translate }}</span>
        </a>
      </div>

      <div class="main-tab-nav__item">
        <a routerLinkActive="main-tab-nav__item--active"
           (click)="navigate('live')"
           routerLink="/live">
          <tbui-icon iconId="navi-player-live"></tbui-icon>
          <span>{{ 'main.nav.live' | translate }}</span>
        </a>
      </div>

      <div class="main-tab-nav__item">
        <a routerLinkActive="main-tab-nav__item--active"
           (click)="navigate('epg')"
           routerLink="/epg">
          <tbui-icon iconId="navi-player-epg"></tbui-icon>
          <span>{{ 'main.nav.epg' | translate }}</span>
        </a>
      </div>

      <div class="main-tab-nav__item">
        <a routerLinkActive="main-tab-nav__item--active"
           (click)="navigate('pvr')"
           routerLink="/pvr">
          <tbui-icon iconId="navi-player-pvr"></tbui-icon>
          <span>{{ 'main.nav.pvr' | translate }}</span>
        </a>
      </div>

      <div class="main-tab-nav__item">
        <a (click)="navigate('settings')"
           routerLink="/settings">
          <img [ngSrc]="(user$ | async)!.image.getSrc(MediaImageStack.USER)" *ngIf="isAuthenticated" class="main-nav__item-user" alt="Profile"
               width="28" height="28">
          <tbui-icon *ngIf="!isAuthenticated" iconId="navi-user"></tbui-icon>
          <span>{{ 'main.nav.settings' | translate }}</span>
        </a>
      </div>
  </nav>
</div>

<!--SIDENAVI-->
<div class="main-nav" id="mainNavPlayer" #mainNav>

  <div class="main-nav__inner">
    <a routerLink="/home"
       (click)="navigate('home')"
       class="main-nav__logo">
      <img [src]="'/shared/logos/logo-teleboy.svg' | staticAsset" alt="Teleboy TV"/>
    </a>

    <nav role="navigation" aria-label="Main">
      <ul>
        <li class="main-nav__item">
          <a class="btn btn__icon btn--opaque btn--circle"
             [attr.aria-label]="'main.nav.home' | translate"
             routerLinkActive="btn--active"
             (click)="navigate('home')"
             routerLink="/home"
             [matTooltip]="'main.nav.home' | translate"
             matTooltipPosition="right"
             matTooltipClass="tb-tooltip tb-tooltip--blue tb-tooltip--right-large">
            <tbui-icon iconId="navi-player-home" size="m"></tbui-icon>
          </a>
        </li>

        <li class="main-nav__item">
          <a class="btn btn__icon btn--opaque btn--circle"
             routerLinkActive="btn--active"
             routerLink="/live"
             [attr.aria-label]="'main.nav.live' | translate"
             (click)="navigate('live')"
             [matTooltip]="'main.nav.live' | translate"
             matTooltipPosition="right"
             matTooltipClass="tb-tooltip tb-tooltip--blue tb-tooltip--right-large">
            <tbui-icon iconId="navi-player-live" size="m"></tbui-icon>
          </a>
        </li>

        <li class="main-nav__item">
          <a routerLink="/epg"
             routerLinkActive="btn--active"
             class="btn btn--opaque btn__icon btn--circle"
             (click)="navigate('epg')"
             [attr.aria-label]="'main.nav.epg' | translate"
             [matTooltip]="'main.nav.epg' | translate"
             matTooltipPosition="right"
             matTooltipClass="tb-tooltip tb-tooltip--blue tb-tooltip--right-large">
            <tbui-icon iconId="navi-player-epg" size="m"></tbui-icon>
          </a>
        </li>

        <li class="main-nav__item">
          <a class="btn btn__icon btn--opaque btn--circle"
             [attr.aria-label]="'main.nav.pvr' | translate"
             routerLinkActive="btn--active"
             (click)="navigate('pvr')"
             routerLink="/pvr"
             [matTooltip]="'main.nav.pvr' | translate"
             matTooltipPosition="right"
             matTooltipClass="tb-tooltip tb-tooltip--blue tb-tooltip--right-large">
            <tbui-icon iconId="navi-player-pvr" size="m"></tbui-icon>
          </a>
        </li>

        <li class="main-nav__item">
          <a class="btn btn__icon btn--opaque btn--circle"
             [attr.aria-label]="'main.nav.settings' | translate"
             (click)="navigate('settings')"
             routerLinkActive="btn--active"
             routerLink="/settings"
             [matTooltip]="'main.nav.settings' | translate"
             matTooltipPosition="right"
             matTooltipClass="tb-tooltip tb-tooltip--blue tb-tooltip--right-large">
              <img [ngSrc]="(user$ | async)!.image.getSrc(MediaImageStack.USER)" *ngIf="isAuthenticated" class="main-nav__item-user" alt="Profile"
                   width="36" height="36">
              <tbui-icon *ngIf="!isAuthenticated" iconId="navi-user" size="m"></tbui-icon>
          </a>
        </li>
      </ul>
    </nav>

    <div class="main-nav__footer">
      <ul>
        <li class="main-nav__item">
          <a [href]="'/cockpit/notifications' | webUiHref"
             [attr.aria-label]="'main.nav.notifications' | translate"
             target="_blank"
             class="btn btn--opaque btn__icon btn--circle"
             [matTooltip]="'main.nav.notifications' | translate"
             matTooltipPosition="right"
             matTooltipClass="tb-tooltip tb-tooltip--blue tb-tooltip--right-large">
            <tbui-icon iconId="navi-notification" size="m"></tbui-icon>
          </a>

          @if (isAuthenticated) {
            <tbui-alert-counter></tbui-alert-counter>
          }
        </li>
      </ul>
    </div>
  </div>
</div>


