{
  "authCookie": "cinergy_s",
  "api": {
    "url": "https://web-api.teleboy.ch"
  },
  "webui": {
    "url": "https://www.teleboy.ch"
  },
  "cockpit": {
    "url": "https://my.teleboy.ch"
  },
  "static": {
    "url": "https://static.teleboy.ch"
  }
}
