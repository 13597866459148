import { Directive, HostListener, Input } from '@angular/core';
import { EventTrackingService } from '@teleboy/web.core';

@Directive({
  selector: '[appDataLayer]',
  standalone: true
})
export class DataLayerDirective {
  @Input() dataLayerEventName?: string;
  @Input() dataLayerPayload?: Record<string, unknown>;

  constructor(private eventTrackingService: EventTrackingService) {}

  @HostListener('click')
  onClick() {
    if (!this.dataLayerEventName) {
      return;
    }
    // No check for the second parameter since the 3rd party script is handling the undefined values.
    this.eventTrackingService.trackEvent(this.dataLayerEventName, this.dataLayerPayload);
  }
}
