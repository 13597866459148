import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Station, StationOrderStorageService } from '@teleboy/web.epg';
import { map, Observable } from 'rxjs';
import { DataSwiperDirective } from '../../../directives/swiper/data-swiper.directive';
import { ApiListResponse } from '@teleboy/web.core';
import { SlideConfig } from '../../../providers/swiper.provider';
import { ItemsListRouteData, EntityServiceType, EntityType } from '../../../interfaces/items-list-route-data';
import { PopupService } from '../../../../core/services/popup.service';
import { ViewToggleType } from '../../../services/view-toggler.service';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { StationItemComponent } from '../../station-item/station-item.component';

@Component({
  selector: 'app-station-swiper',
  templateUrl: './station-swiper.component.html',
  styleUrls: ['../swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, SharedModule, SwiperModule, NgFor, StationItemComponent, AsyncPipe]
})
export class StationSwiperComponent extends DataSwiperDirective<Station> implements OnInit {
  constructor(
    private popupService: PopupService,
    private stationOrderStorageService: StationOrderStorageService
  ) {
    super();
  }

  override ngOnInit() {
    this.swiperOptions = SlideConfig['station'];
    super.ngOnInit();
  }

  itemLoader$(): Observable<ApiListResponse['data']> {
    return this.stationOrderStorageService.getUserStations().pipe(
      map((stations) => {
        return {
          total: stations.length,
          items: stations
        };
      })
    );
  }

  openInListTilesView() {
    const routeData: ItemsListRouteData = {
      title: this.headline,
      serviceName: EntityServiceType.StationOrderStorageService,
      broadcastType: EntityType.Station,
      enableListTypeToggle: false,
      predefinedListingType: ViewToggleType.TILES
    };

    this.popupService.open('items/list', { outlet: 'details', data: routeData, skipLocationChange: true });
  }
}
