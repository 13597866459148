<app-player [listingClosed]="listingClosed" [muted]="muted" [jumpseekEnabled]="jumpseekEnabled!">
  <div class="stream-info" *ngIf="epg$ | async as epg" streamInfo>
    <div class="stream-info--logo">
      <tbui-station-logo *ngIf="epg.current.station$ | async as station"
                         [station]="station" [size]="StationLogoSize.M"></tbui-station-logo>
    </div>
    <div class="stream-info-text">
      <h1 class="collapse ellipsis stream-info-text--current">
        <a [appBroadcastDetail]="epg.current">{{epg.current.title}}</a>
        <span *ngIf="epg.current.subtitle">&bull; {{epg.current.subtitle}}</span>
      </h1>
      <p class="collapse stream-info-text--next" *ngIf="epg.next">
        <a class="ellipsis" [appBroadcastDetail]="epg.next">
                <span class="badge badge__secondary">
                {{ 'player.epg.next' | translate }}
              </span>
          {{epg.next.title}}
        </a>
      </p>
    </div>
  </div>

  <!-- PLAYER NEXT EPISODE -->
  <div *ngIf="epg$ | async as epg" uiContainer>
    <button *ngIf="epg.nextEpisode" (click)="playNextEpisode(epg)"
            class="btn btn--opaque btn__icon btn__next-episode">
      <tbui-icon iconId="play-next"></tbui-icon>
      <span class="lbl__next-episode">{{'player.epg.nextEpisode' | translate}}</span>
    </button>
  </div>

</app-player>
