import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Episode, StationLogoSize, StationLogoComponent } from '@teleboy/web.epg';
import { map, Observable } from 'rxjs';
import { EpgService } from '../../services/epg.service';
import { NgIf, NgClass, NgTemplateOutlet, NgStyle, AsyncPipe } from '@angular/common';
import { BroadcastFlagComponent } from '../broadcast-flag/broadcast-flag.component';
import { CpvrProviderComponent } from '../cpvr/cpvr-provider/cpvr-provider.component';
import { BroadcastIconsComponent } from '../broadcast-icons/broadcast-icons.component';
import { BroadcastPlayDirective } from '../../directives/broadcast/broadcast-play.directive';
import { BroadcastToggleRecordDirective } from '../../directives/broadcast/broadcast-toggle-record.directive';
import { BroadcastWatchlistDirective } from '../../directives/broadcast/broadcast-watchlist.directive';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared/shared.module';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-serie-episode-item',
  templateUrl: './serie-episode-item.component.html',
  styleUrls: ['./serie-episode-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgClass,
    BroadcastFlagComponent,
    CpvrProviderComponent,
    StationLogoComponent,
    BroadcastIconsComponent,
    NgTemplateOutlet,
    BroadcastPlayDirective,
    BroadcastToggleRecordDirective,
    BroadcastWatchlistDirective,
    NgStyle,
    AsyncPipe,
    TranslateModule,
    SharedModule,
    IconComponent
  ]
})
export class SerieEpisodeItemComponent implements OnInit {
  readonly StationLogoSize = StationLogoSize;
  isPlaying$!: Observable<boolean>;

  @Input() episode!: Episode;

  constructor(private epgService: EpgService) {}

  ngOnInit(): void {
    this.isPlaying$ = this._isPlaying$();
  }

  private _isPlaying$(): Observable<boolean> {
    return this.epgService.playingBroadcastId$.pipe(map((broadcastId) => broadcastId === this.episode.broadcast?.id));
  }
}
