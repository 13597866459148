<app-close-button (closed)="close()"></app-close-button>
<form [formGroup]="form">
  <app-info [title]="'anonymousAuth.title' | translate"
            [text]="'anonymousAuth.text' | translate">
    <ng-container customContent>
      <div class="start__anonymous">
        <fieldset class="form-item--inline">
          <legend class="visually-hidden">{{ 'anonymousAuth.form.gender.legend' | translate }}</legend>
          <div class="form-item-check start__anonymous--female">
            <input type="radio" formControlName="gender" id="start-profile-gender-f" value="female">
            <label for="start-profile-gender-f">{{ 'anonymousAuth.form.gender.female' | translate }}</label>
          </div>
          <div class="form-item-check start__anonymous--male">
            <input type="radio" formControlName="gender" id="start-profile-gender-m" value="male">
            <label for="start-profile-gender-m">{{ 'anonymousAuth.form.gender.male' | translate }}</label>
          </div>
        </fieldset>

        <div class="form-item">
          <app-dropdown [options]="ageOptions" formControlName="age" [bordered]="true"></app-dropdown>
        </div>
      </div>
    </ng-container>
  </app-info>

  <div class="popup__footer btn__list-h btn__list-h--apart">
    <a [href]="'/login' | webUiHref: { appendTarget: true }" class="btn btn__secondary" appDataLayer
       dataLayerEventName="anonymous_login">
      {{ 'anonymousAuth.login' | translate }}
    </a>
    <button type="submit" class="btn btn__primary" (click)="onSubmit()">{{ 'anonymousAuth.form.submit' | translate }}
    </button>
  </div>
</form>
