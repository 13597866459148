import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { EventTrackingService, MediaImageStack } from '@teleboy/web.core';
import { Brandingday } from '@teleboy/web.player';
import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, fromEvent, Observable, Subject, switchMap, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-brandingday',
  templateUrl: './brandingday.component.html',
  styleUrls: ['./brandingday.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class BrandingdayComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() brandingday!: Brandingday;
  @Input() impressionTracking$!: Observable<void>;
  @ViewChild('image', { static: false }) image!: ElementRef;

  imgSrc!: string;
  imgSrcSmall!: string;

  private isSmallImage!: boolean;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private eventTrackingService: EventTrackingService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.imgSrc = this.brandingday.image.getSrc(MediaImageStack.RAW);
    this.imgSrcSmall = this.brandingday.imageSmall.getSrc(MediaImageStack.RAW);
  }

  ngAfterViewInit(): void {
    this.imageLoaded$()
      .pipe(
        switchMap(() => this.impressionTracking$),
        switchMap(() => this.trackImpression$()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('click')
  onClick(): void {
    this.openClickTarget();
  }

  private openClickTarget(): void {
    this.eventTrackingService.trackEvent('brandingday_click');

    if (this.isSmallImage && this.brandingday.hrefSmall) {
      window.open(this.brandingday.hrefSmall);
    } else if (this.brandingday.href) {
      window.open(this.brandingday.href);
    }
  }

  private trackImpression$(): Observable<unknown> {
    let trackingUrl = '';

    this.eventTrackingService.trackEvent('brandingday_impression');

    if (this.isSmallImage && this.brandingday.trackingImpressionSmall) {
      trackingUrl = this.brandingday.trackingImpressionSmall;
    } else if (this.brandingday.trackingImpression) {
      trackingUrl = this.brandingday.trackingImpression;
    }

    if (!trackingUrl) {
      return EMPTY;
    }

    return this.httpClient.get(trackingUrl).pipe(catchError(() => EMPTY));
  }

  private imageLoaded$(): Observable<unknown> {
    return fromEvent(this.image.nativeElement, 'load').pipe(
      take(1),
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      tap((event: any) => {
        this.isSmallImage = this.imgSrcSmall === event.currentTarget.currentSrc;
      })
    );
  }
}
