<div class="swiper__container" *ngIf="!(isEmptyResult$() | async)">
  <h2>{{headline}}</h2>
  <swiper *ngIf="data$ | async as searchResults; else loading" #swiper [slidesPerView]="swiperOptions.slidesPerView"
          [breakpoints]="swiperOptions.breakpoints">
    <ng-template *ngFor="let broadcast of searchResults" swiperSlide>
      <app-broadcast-item (detailClick)="onBroadcastItemClick()" [broadcast]="broadcast" [card]="true"></app-broadcast-item>
    </ng-template>
    <ng-template swiperSlide>
      <app-broadcast-item *ngIf="isPaginating$ | async" (detailClick)="onBroadcastItemClick()" [card]="true" class="ghost-listing"></app-broadcast-item>
    </ng-template>
  </swiper>
</div>

<ng-template #loading>
  <swiper class="ghost-listing ghost-listing--horizontal">
    <ng-template *ngFor="let ghost of ghosts" swiperSlide>
      <app-broadcast-item [card]="true"></app-broadcast-item>
    </ng-template>
  </swiper>
</ng-template>
