import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoadingAdComponent } from './components/loading-ad/loading-ad.component';
import { loadingAdGuard } from './guards/loading-ad.guard';

const routes: Routes = [
  {
    path: 'start',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'loading'
      },
      {
        path: 'loading',
        component: LoadingAdComponent,
        canActivate: [loadingAdGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StartRoutingModule {}
