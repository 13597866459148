@if (!(isEmptyResult$() | async)) {
  <div class="swiper__container">
    <app-expand-link [linkText]="headline" (clicked)="openWatchlistList()"></app-expand-link>

    @if (data$ | async; as recordings) {
      <swiper #swiper>
        @for (recording of recordings; track $index) {
          <ng-template swiperSlide>
            <app-broadcast-item [broadcast]="recording" [card]="true"></app-broadcast-item>
          </ng-template>
        }

        @let total = (total$ | async);
        @if (total && total > 5) {
          <ng-template swiperSlide>
            <app-pvr-grid-button
              [icon]="'grid'"
              [label]="'pvr.watchlist.showAll' | translate"
              (click)="openWatchlistList()"></app-pvr-grid-button>
          </ng-template>
        }
      </swiper>
    }
  </div>

  @if (busy()) {
    <swiper class="ghost-listing ghost-listing--horizontal">
      @for (ghost of ghosts; track $index) {
        <ng-template swiperSlide>
          <app-broadcast-item [card]="true"></app-broadcast-item>
        </ng-template>
      }
    </swiper>
  }
}
