import { Inject, Injectable } from '@angular/core';
import {
  Broadcast,
  BroadcastApiService,
  BroadcastStreamType,
  HeartbeatApiService,
  RecordingApiService,
  RecordingResult,
  WatchlistResult,
  WatchlistToggleService
} from '@teleboy/web.epg';
import { Router } from '@angular/router';
import { catchError, filter, from, map, Observable, of, tap } from 'rxjs';
import { ApiItemResponse, EventTrackingService } from '@teleboy/web.core';
import { SnackbarService, SnackbarType } from '../../core/services/snackbar.service';
import { RouteDataService } from '../../core/services/route-data.service';
import { Trailer } from '@teleboy/web.epg/lib/models/trailer.model';
import { DOCUMENT } from '@angular/common';
import { BroadcastRecordService } from './broadcast-record.service';

@Injectable({
  providedIn: 'root'
})
export class BroadcastActionService {
  constructor(
    private broadcastApiService: BroadcastApiService,
    private broadcastRecordService: BroadcastRecordService,
    private eventTrackingService: EventTrackingService,
    @Inject(DOCUMENT) private document: Document,
    private heartbeatApiService: HeartbeatApiService,
    private snackbarService: SnackbarService,
    private recordingApiService: RecordingApiService,
    private routeDataService: RouteDataService,
    private router: Router,
    private watchlistToggleService: WatchlistToggleService
  ) {}

  handlePlay$(
    broadcast: Broadcast,
    position?: number,
    live?: boolean,
    isContinuousReplay?: boolean
  ): Observable<boolean> {
    if (!broadcast.isPlayable()) {
      return of(false);
    }

    let url;
    const queryParams: Record<string, unknown> = { position };

    if (live) {
      url = `live/${broadcast.stationId}`;
    } else {
      switch (broadcast.playableStreamType) {
        case BroadcastStreamType.LIVE:
          url = `live/${broadcast.stationId}`;
          break;
        case BroadcastStreamType.COMMUNITY:
          url = `community/${broadcast.id}`;
          queryParams['providerId'] = broadcast.community?.provider.id;
          break;
        case BroadcastStreamType.RECORDING:
          url = `recording/${broadcast.id}`;
          break;
        case BroadcastStreamType.REPLAY:
          url = `replay/${broadcast.id}`;
          queryParams['isContinuousReplay'] = isContinuousReplay;
          break;
        default:
          url = `replay/${broadcast.id}`;
          break;
      }
    }

    const navigationExtras = { queryParams };
    const urlTree = this.router.createUrlTree(['', { outlets: { player: url } }], navigationExtras);

    this.eventTrackingService.pushTagIfAllowed({
      broadcastId: broadcast.id,
      genreId: broadcast.genreId,
      stationId: broadcast.stationId,
      tags: broadcast.tags
    });
    this.routeDataService.setData<Broadcast>(urlTree.toString(), broadcast);
    return from(this.router.navigateByUrl(urlTree));
  }

  handleRecord$(broadcast: Broadcast): Observable<RecordingResult> {
    return this.broadcastRecordService.toggleRecording$(broadcast);
  }

  handleWatchlist$(broadcast: Broadcast): Observable<ApiItemResponse['data']> {
    return this.watchlistToggleService.toggleWatchlist$(broadcast).pipe(
      tap((updatedBroadcast: WatchlistResult) => {
        this.snackbarService.openSnackbar(
          updatedBroadcast === WatchlistResult.ADDED
            ? 'broadcast.action.watchlist.success'
            : 'broadcast.action.watchlistDelete.success',
          SnackbarType.SUCCESS
        );
      })
    );
  }

  handleTrailer$(broadcast: Broadcast): Observable<Trailer> {
    return this.broadcastApiService.getTrailers(broadcast.id).pipe(
      catchError(() => {
        this.snackbarService.openSnackbar('broadcast.action.trailer.failed', SnackbarType.ERROR);
        return of([]);
      }),
      filter((trailers) => trailers.length > 0),
      map((trailers) => trailers[0]),
      tap((trailer) => {
        const urlTree = this.router.createUrlTree(['', { outlets: { player: `trailer/${trailer.videoId}` } }]);

        this.routeDataService.setData<Broadcast>(urlTree.toString(), broadcast);
        void this.router.navigateByUrl(urlTree);
      })
    );
  }

  handleDownload$(broadcast: Broadcast): Observable<ApiItemResponse['data']> {
    return this.recordingApiService.download(broadcast.id).pipe(
      catchError((err) => {
        this.snackbarService.openSnackbar('broadcast.action.download.failed', SnackbarType.ERROR);
        throw err;
      }),
      map((data) => this.document.location.assign(data.url))
    );
  }

  handleDeleteHeartbeat$(broadcast: Broadcast): Observable<ApiItemResponse['data']> {
    return this.heartbeatApiService.deleteHeartbeat(broadcast.id).pipe(
      catchError((err) => {
        this.snackbarService.openSnackbar('broadcast.action.heartbeatDelete.failed', SnackbarType.ERROR);
        throw err;
      }),
      tap(() => {
        this.snackbarService.openSnackbar('broadcast.action.heartbeatDelete.success', SnackbarType.SUCCESS);
        broadcast.removeHeartbeat();
      })
    );
  }
}
