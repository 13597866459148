<ng-container *ngIf="starAlert$ | async as starAlert; else createStarAlertButton">
  <button class="btn btn__icon btn--active" (click)="deleteStarAlert(starAlert)" [disabled]="isStarAlertBusy$ | async">
    <tbui-icon iconId="watchlist"></tbui-icon>
    {{ 'starAlert.delete.label' | translate }}
  </button>
</ng-container>

<ng-template #createStarAlertButton>
  <button *ngIf="!(isStarAlertLoading$ | async)" class="btn btn__icon"
          (click)="createStarAlert()" [disabled]="isStarAlertBusy$ | async">
    <tbui-icon iconId="add"></tbui-icon>
    {{ 'starAlert.create.label' | translate }}
  </button>
</ng-template>
