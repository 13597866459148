import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ProfilesUpsellComponent } from './components/profiles-upsell/profiles-upsell.component';
import { ProfileRestrictionComponent } from './components/profile-restriction/profile-restriction.component';
import { ProfileImageComponent } from '../shared/components/profile-image/profile-image.component';
import { IconComponent, StaticAssetPipe } from '@teleboy/web.ui';

@NgModule({
  declarations: [ProfilesUpsellComponent, ProfileRestrictionComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgOptimizedImage,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ProfileImageComponent,
    IconComponent,
    StaticAssetPipe
  ]
})
export class ProfilesModule {}
