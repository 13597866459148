import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PlayerLiveComponent } from './components/player-live/player-live.component';
import { PlayerComponent } from './components/player/player.component';
import { PlayerCommunityComponent } from './components/player-community/player-community.component';
import { PlayerReplayComponent } from './components/player-replay/player-replay.component';
import { PlayerRecordingComponent } from './components/player-recording/player-recording.component';
import { PlayerTrailerComponent } from './components/player-trailer/player-trailer.component';
import { EpgModule } from '../epg/epg.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrandingdayComponent } from './components/brandingday/brandingday.component';
import { JumpseekButtonsComponent } from './components/player/jumpseek-buttons/jumpseek-buttons.component';
import { PlayerBroadcastComponent } from './components/player-broadcast/player-broadcast.component';
import { PlayerListingComponent } from './components/player/player-listing/player-listing.component';
import { AdUiComponent } from './components/ad-ui/ad-ui.component';
import { SharedModule } from '../shared/shared.module';
import { AdInfoComponent } from '@teleboy/web.player';
import { StationLogoComponent } from '@teleboy/web.epg';
import { BroadcastItemComponent } from '../epg/components/broadcast-item/broadcast-item.component';
import { BroadcastDetailDirective } from '../epg/directives/broadcast/broadcast-detail.directive';
import { IconComponent } from '@teleboy/web.ui';

@NgModule({
  declarations: [
    PlayerLiveComponent,
    PlayerComponent,
    PlayerListingComponent,
    PlayerCommunityComponent,
    PlayerReplayComponent,
    PlayerRecordingComponent,
    PlayerTrailerComponent,
    JumpseekButtonsComponent,
    BrandingdayComponent,
    AdUiComponent,
    PlayerBroadcastComponent
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    EpgModule,
    TranslateModule,
    SharedModule,
    AdInfoComponent,
    StationLogoComponent,
    BroadcastItemComponent,
    BroadcastDetailDirective,
    IconComponent
  ]
})
export class PlayerModule {}
