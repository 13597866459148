<div *ngIf="broadcast; else ghost" class="card broadcast-item" [ngClass]="{
      'broadcast-item--tipp': isTipp() || forceImage,
      'broadcast-item--card': card,
      'broadcast-item--active': isPlaying$ | async,
      'broadcast-item--clickable': player
    }"
     [appBroadcastPlay]="broadcast" [live]="live" [origin]="origin" [canPerformAction]="player">
  <ng-container *ngIf="showImage(); then broadcastImage;"></ng-container>

  <div class="card__body broadcast-item__body">
    <ng-container *ngIf="showStation(); then stationLogo;"></ng-container>
    <ng-container *ngIf="!hideProgress; then progress;"></ng-container>

    <a class="broadcast-item__info" [appBroadcastDetail]="broadcast" [canPerformAction]="!player" (detailClick)="onDetailClick()">
      <div class="broadcast-item__title">
        <h3 class="collapse">
          <div class="ellipsis">
            {{ !episode ? broadcast.title : ('broadcast.title.episode' | translate: { episodeNumber: broadcast.serieEpisode }) }}
          </div>
          @if (broadcast.isWatched()) {
            <tbui-icon iconId="checkmark-full" color="brand" [inline]="true"
                       [matTooltip]="'broadcast.flag.watched' | translate"
                       matTooltipPosition="above" matTooltipClass="tb-tooltip"
                       class="hidden-mobile"></tbui-icon>
          }
        </h3>
        <p class="broadcast-item__subtitle collapse ellipsis" [title]="subtitle$() | async">
          {{ !episode ? (subtitle$() | async) : broadcast._subtitle }}
        </p>
      </div>

      @if (origin !== 'playList') {
        <div class="broadcast-item__begin ellipsis">
          <span
            [matTooltip]="broadcast | broadcastInfo"
            matTooltipPosition="above"
            matTooltipClass="tb-tooltip">
            @if (badgeLabel != '') {
              <span class="badge badge__secondary">{{ badgeLabel }}</span>
            }
            {{ broadcast.begin.format('ddd DD.MM • HH:mm') }}
          </span>
          <app-broadcast-icons [broadcast]="broadcast" class="broadcast-item__icons"></app-broadcast-icons>
        </div>
      }
    </a>

    <ng-container *ngIf="!card; then actions"></ng-container>
  </div>

  <app-broadcast-flag [broadcast]="broadcast"></app-broadcast-flag>
</div>

<ng-template #stationLogo>
  <div class="broadcast-item__station-logo">
    @if (broadcast.station$ | async; as station) {
      <tbui-station-logo [station]="station" [size]="StationLogoSize.S"></tbui-station-logo>
    }
  </div>
</ng-template>

<ng-template #broadcastImage>
  <div class="broadcast-item__image">
    @if (imageToShow(); as image) {
      <tbui-media-image class="ratio ratio__16x9" [alt]="broadcast.title"
                        [image]="image" [size]="MediaImageStack.MEDIUM"></tbui-media-image>
    }
    <a class="broadcast-item__image-link" [appBroadcastPlay]="broadcast"></a>
    <ng-container *ngTemplateOutlet="stationLogo"></ng-container>
    <ng-container *ngIf="card; then actions"></ng-container>
  </div>
</ng-template>

<ng-template #actions>
  @if (broadcast.community && broadcast.community.provider) {
    <div class="broadcast-item__cpvr-provider">
      @if (broadcast.community && broadcast.community.provider) {
        <app-cpvr-provider [provider]="broadcast.community.provider" [broadcast]="broadcast"
                           [expandable]="true"></app-cpvr-provider>
      }
    </div>
  }

  @if (!disableActions) {
    <div class="broadcast-item__actions"
         [ngClass]="{'broadcast-item__actions--hidden': hideActions}">
      @if (broadcast.flags.is_playable && !player) {
        <button [ngClass]="{'btn--active': (isPlaying$ | async)}"
                class="btn btn__secondary btn__icon btn--circle" [live]="live" [appBroadcastPlay]="broadcast"
                [origin]="origin" appDataLayer [dataLayerEventName]="'play_' + broadcast.id">
          <tbui-icon iconId="play"></tbui-icon>
        </button>
      }

      @if (player) {
        <a class="btn btn__secondary btn__icon btn--circle" [appBroadcastDetail]="broadcast"
           [origin]="'playList'" appDataLayer [dataLayerEventName]="'info_' + broadcast.id">
          <tbui-icon iconId="info"></tbui-icon>
        </a>
      }

      @if (broadcast.flags.is_playing) {
        <button class="btn btn__secondary btn__icon btn--circle"
                [appBroadcastPlay]="broadcast" [restart]="true" [origin]="origin" appDataLayer
                [dataLayerEventName]="'replay_' + broadcast.id">
          <tbui-icon iconId="replay"></tbui-icon>
        </button>
      }

      @if (broadcast.flags.isDownloadable && !player) {
        <button
          class="btn btn__secondary btn__icon btn--circle hidden-mobile" [appBroadcastDownload]="broadcast"
          appDataLayer [dataLayerEventName]="'download_' + broadcast.id">
          <tbui-icon iconId="download"></tbui-icon>
        </button>
      }

      @if (broadcast.flags.isRecorded() && !player) {
        <button
          class="btn btn__secondary btn__icon btn--circle" [appBroadcastDeleteRecord]="broadcast"
          (deleted)="onRecordDeleted(broadcast.id)" appDataLayer [dataLayerEventName]="'bin_' + broadcast.id">
          <tbui-icon iconId="bin"></tbui-icon>
        </button>
      }

      @if (!broadcast.flags.isRecorded() && broadcast.flags.isRecordable() && !player) {
        <button
          class="btn btn__secondary btn__icon btn--circle hidden-mobile" [appBroadcastToggleRecord]="broadcast"
          (deleted)="onRecordDeleted(broadcast.id)">
          <tbui-icon iconId="record"></tbui-icon>
        </button>
      }

      @if (broadcast.hasActualHeartbeat()) {
        <button
          [appBroadcastDeleteRecordHeartbeat]="broadcast" (heartbeatDeleted)="onHeartbeatDeleted()"
          class="btn btn__secondary btn__icon btn--circle hidden-mobile" appDataLayer
          [dataLayerEventName]="'delete_heartbeat_' + broadcast.id">
          <tbui-icon iconId="delete-heartbeat"></tbui-icon>
        </button>
      }

      @if (broadcast.flags.isWatchlistable() && !broadcast.flags.isRecorded() && !player) {
        <button
          class="btn btn__secondary btn__icon btn--circle hidden-mobile" [appBroadcastWatchlist]="broadcast"
          appDataLayer [dataLayerEventName]="'watchlist_' + broadcast.id">
          <tbui-icon iconId="watchlist"></tbui-icon>
        </button>
      }

      @if (broadcast.flags.has_trailer && !player) {
        <button
          class="btn btn__secondary btn__icon btn--circle" [appBroadcastTrailer]="broadcast"
          appDataLayer [dataLayerEventName]="'trailer_' + broadcast.id">
          <tbui-icon iconId="trailer"></tbui-icon>
        </button>
      }
    </div>
  }
</ng-template>

<ng-template #progress>
  @if (broadcast.getProgressPosition(); as progressPosition) {
    <app-broadcast-progress [heartbeat]="progressPosition"
                            [broadcast]="broadcast"></app-broadcast-progress>
  }
</ng-template>

<ng-template #ghost>
  <div class="card broadcast-item" [ngClass]="{
      'broadcast-item--card': card
    }">
    @if (card) {
      <div class="ratio ratio__16x9"></div>
    }

    <div class="card__body broadcast-item__body">
      <div class="broadcast-item__info">
        <div class="broadcast-item__title">
          <h3 class="h3 collapse ellipsis">&nbsp;</h3>
          <p class="broadcast-item__subtitle collapse ellipsis">&nbsp;</p>
        </div>

        <div class="broadcast-item__begin ellipsis">
          <span>&nbsp;</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
