<div class="swiper__container swiper__container--content" *ngIf="!(isEmptyResult$() | async)">
  <app-expand-link [linkText]="headline" (clicked)="openInListTilesView()"></app-expand-link>

  <swiper *ngIf="data$ | async as series; else loading" #swiper>
    <ng-template *ngFor="let serie of series" swiperSlide>
      <app-serie-item [serie]="serie"></app-serie-item>
    </ng-template>
    <ng-template swiperSlide>
      <app-serie-item *ngIf="isPaginating$ | async"></app-serie-item>
    </ng-template>
  </swiper>
</div>

<ng-template #loading>
  <swiper class="ghost-listing ghost-listing--horizontal">
    <ng-template *ngFor="let ghost of ghosts" swiperSlide>
      <app-serie-item></app-serie-item>
    </ng-template>
  </swiper>
</ng-template>
