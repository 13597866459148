import { Injectable } from '@angular/core';
import { AuthenticationService, SessionSource } from '@teleboy/web.user';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';

@Injectable()
export class SessionSourceProvider implements SessionSource {
  constructor(private cookieService: CookieService) {}

  deleteSession(): void {
    this.cookieService.delete(environment.authCookie);
  }

  getSession(): string {
    return this.cookieService.get(environment.authCookie) ?? null;
  }

  hasSession(): boolean {
    return this.cookieService.check(environment.authCookie);
  }

  storeSession(session: string, expires: number): void {
    const cookie: CookieOptions = AuthenticationService.getAuthenticationCookieOptions(expires);

    this.cookieService.set(environment.authCookie, session, cookie);
  }
}

export const SESSION_SOURCE_PROVIDER = {
  provide: 'SESSION_SOURCE',
  useClass: SessionSourceProvider
};
