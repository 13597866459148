import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import moment from 'moment';
import { Broadcast } from '@teleboy/web.epg';
import { NgClass, NgStyle } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-broadcast-progress',
  templateUrl: './broadcast-progress.component.html',
  styleUrls: ['./broadcast-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MatTooltip, NgStyle, TranslateModule]
})
export class BroadcastProgressComponent {
  @Input() broadcast!: Broadcast;
  @Input() heartbeat!: number;
  @Input() large = false;
  @Input() bottom = false;

  progressWidth(): { 'width.%': number } {
    return {
      'width.%': Math.min((100 / this.broadcast.duration) * this.watchedMinutes(), 100)
    };
  }

  private watchedMinutes(): number {
    return Math.round(this.heartbeat / 60) || moment().diff(this.broadcast.begin, 'minutes');
  }

  remainingMinutes(): number {
    return Math.max(this.broadcast.duration - this.watchedMinutes(), 0);
  }
}
