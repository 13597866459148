import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { SerialgroupItemComponent } from '../../../../epg/components/item/serialgroup-item/serialgroup-item.component';
import { SharedModule } from '../../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable, repeat, Subject, tap } from 'rxjs';
import { Serialgroup } from '@teleboy/web.epg/lib/models/serialgroup.model';
import { RecordingApiService } from '@teleboy/web.epg';
import { PvrEmptyListComponent } from '../../mics/pvr-empty-list/pvr-empty-list.component';

@Component({
  selector: 'app-pvr-recording-serial-list',
  imports: [AsyncPipe, SerialgroupItemComponent, SharedModule, TranslateModule, PvrEmptyListComponent],
  templateUrl: './pvr-recording-serial-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PvrRecordingSerialListComponent implements OnInit {
  serialgroups$!: Observable<Serialgroup[]>;
  readonly ghostPreviews = new Array(10);
  readonly busy = signal(true);

  private readonly reload$: Subject<void> = new Subject<void>();

  constructor(private recordingApiService: RecordingApiService) {}

  ngOnInit(): void {
    this.serialgroups$ = this.recordingApiService.querySerialgroups().pipe(
      repeat({ delay: () => this.reload$ }),
      map((data) => data.items),
      tap(() => this.busy.set(false))
    );
  }

  onDeleted(): void {
    this.reload$.next();
  }
}
