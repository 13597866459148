"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MetadataLabel = exports.MetadataLabelContent = void 0;
var label_1 = require("./label");
/**
 * Enumerates the types of content that the {@link MetadataLabel} can display.
 */
var MetadataLabelContent;
(function (MetadataLabelContent) {
  /**
   * Title of the data source.
   */
  MetadataLabelContent[MetadataLabelContent["Title"] = 0] = "Title";
  /**
   * Description fo the data source.
   */
  MetadataLabelContent[MetadataLabelContent["Description"] = 1] = "Description";
})(MetadataLabelContent = exports.MetadataLabelContent || (exports.MetadataLabelContent = {}));
/**
 * A label that can be configured to display certain metadata.
 *
 * @category Labels
 */
var MetadataLabel = /** @class */function (_super) {
  __extends(MetadataLabel, _super);
  function MetadataLabel(config) {
    var _this = _super.call(this, config) || this;
    _this.config = _this.mergeConfig(config, {
      cssClasses: ['label-metadata', 'label-metadata-' + MetadataLabelContent[config.content].toLowerCase()]
    }, _this.config);
    return _this;
  }
  MetadataLabel.prototype.configure = function (player, uimanager) {
    var _this = this;
    _super.prototype.configure.call(this, player, uimanager);
    var config = this.getConfig();
    var uiconfig = uimanager.getConfig();
    var init = function () {
      switch (config.content) {
        case MetadataLabelContent.Title:
          _this.setText(uiconfig.metadata.title);
          break;
        case MetadataLabelContent.Description:
          _this.setText(uiconfig.metadata.description);
          break;
      }
    };
    var unload = function () {
      _this.setText(null);
    };
    // Init label
    init();
    // Clear labels when source is unloaded
    player.on(player.exports.PlayerEvent.SourceUnloaded, unload);
    uimanager.getConfig().events.onUpdated.subscribe(init);
  };
  return MetadataLabel;
}(label_1.Label);
exports.MetadataLabel = MetadataLabel;