import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GenreStorageService, StationOrderStorageService, StationStorageService } from '@teleboy/web.epg';
import { DarkmodeService, LanguageService } from '@teleboy/web.user';
import moment from 'moment';
import 'moment/locale/de.js';
import 'moment/locale/fr.js';
import 'moment/locale/it.js';
import { DOCUMENT } from '@angular/common';
import { Observable, of, switchMap } from 'rxjs';

export interface ClientDataOptions {
  allStations?: boolean;
  userStations?: boolean;
  locale?: boolean;
  genres?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ClientDataService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private darkmodeService: DarkmodeService,
    private genreStorageService: GenreStorageService,
    private languageService: LanguageService,
    private stationOrderStorageService: StationOrderStorageService,
    private stationStorageService: StationStorageService,
    private translateService: TranslateService
  ) {}

  refreshUserData(resetAll: boolean | ClientDataOptions = true, emptyMemory = false): Observable<unknown> {
    this.darkmodeService.detectAndSetDarkmode();
    if (resetAll === true) {
      return this.resetAllClientData();
    } else {
      const reset = resetAll as ClientDataOptions;

      if (reset.allStations) {
        return this.getAllStations(emptyMemory);
      }

      if (reset.userStations) {
        return this.stationOrderStorageService.getUserStations(true);
      }

      if (reset.genres) {
        return this.getAllGenre(emptyMemory);
      }

      if (reset.locale) {
        return this.setLocales();
      }

      return of(null);
    }
  }

  private getAllGenre(emptyMemory: boolean) {
    if (emptyMemory) {
      this.genreStorageService.emptyMemory();
    }

    return this.genreStorageService.getAll();
  }

  private getAllStations(emptyMemory: boolean) {
    if (emptyMemory) {
      this.stationStorageService.emptyMemory();
    }

    return this.stationStorageService.getAll();
  }

  private resetAllClientData() {
    this.stationStorageService.emptyMemory();

    return this.stationStorageService.getAll().pipe(
      switchMap(() => {
        return this.stationOrderStorageService.getUserStations(true);
      }),
      switchMap(() => {
        this.genreStorageService.emptyMemory();
        return this.genreStorageService.getAll();
      }),
      switchMap(() => {
        return this.setLocales();
      })
    );
  }

  private setLocales(): Observable<void> {
    const language = this.languageService.getLanguage();
    this.document.documentElement.lang = language;
    moment.locale(language);

    return this.translateService.use(language);
  }
}
