"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ArrayUtils = void 0;
/**
 * @category Utils
 */
var ArrayUtils;
(function (ArrayUtils) {
  /**
   * Removes an item from an array.
   * @param array the array that may contain the item to remove
   * @param item the item to remove from the array
   * @returns {any} the removed item or null if it wasn't part of the array
   */
  function remove(array, item) {
    var index = array.indexOf(item);
    if (index > -1) {
      return array.splice(index, 1)[0];
    } else {
      return null;
    }
  }
  ArrayUtils.remove = remove;
})(ArrayUtils = exports.ArrayUtils || (exports.ArrayUtils = {}));