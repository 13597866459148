import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EpgRoutingModule } from './epg-routing.module';
import { IconComponent, ImdbRatingComponent } from '@teleboy/web.ui';
import { AlertItemComponent, DurationPipe, MediaImageComponent, StaticAssetPipe } from '@teleboy/web.ui';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SwiperModule } from 'swiper/angular';
import { SWIPER_PROVIDER } from './providers/swiper.provider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { ScrollingModule } from '@angular/cdk/scrolling';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LetDirective } from '@ngrx/component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { WebUiHrefPipe } from '../shared/pipes/web-ui-href.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EpisodeSubtitlePipe } from '@teleboy/web.epg';

@NgModule({
  imports: [
    CommonModule,
    EpgRoutingModule,
    InfiniteScrollDirective,
    BrowserAnimationsModule,
    MatTooltipModule,
    TranslateModule,
    MatGridListModule,
    MatSnackBarModule,
    SwiperModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    SharedModule,
    NgbDropdownModule,
    FormsModule,
    ScrollingModule,
    NgxSliderModule,
    MatAutocompleteModule,
    LetDirective,
    MediaImageComponent,
    WebUiHrefPipe,
    AlertItemComponent,
    DurationPipe,
    EpisodeSubtitlePipe,
    IconComponent,
    ImdbRatingComponent,
    StaticAssetPipe
  ],
  exports: [],
  providers: [SWIPER_PROVIDER]
})
export class EpgModule {}
