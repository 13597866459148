<div class="tabbar tabbar-epg">
  <div class="tabbar__left">
    <h1 class="collapse tabbar__title">{{ 'pvr.overview.title' | translate }}</h1>
  </div>

  <div class="tabbar__right">
    <app-search></app-search>
  </div>
</div>

@if (isAuthenticated() && !isLoading() && hasRecordings()) {
  <div class="grid grid__sliders">

    <!-- LATEST RECORDINGS -->
    <app-pvr-recording-preview-list
      [headline]="'pvr.overview.latest.title' | translate"></app-pvr-recording-preview-list>

    <!-- GENRES -->
    <app-pvr-genregroup-preview-list
      [headline]="'pvr.overview.genres.title' | translate"></app-pvr-genregroup-preview-list>

    <!-- SERIES -->
    <app-pvr-titlegroup-preview-list
      [headline]="'pvr.overview.series.title' | translate"></app-pvr-titlegroup-preview-list>

    <!-- LATEST MOVIES -->
    <app-pvr-recording-preview-list [headline]="'pvr.overview.movies.title' | translate"
                                    [genreId]="1"></app-pvr-recording-preview-list>

    <!-- LATEST DOCS -->
    <app-pvr-recording-preview-list [headline]="'pvr.overview.docs.title' | translate"
                                    [genreId]="2"></app-pvr-recording-preview-list>

    <!-- WATCHLIST -->
    <app-pvr-watchlist-preview-list
      [headline]="'pvr.overview.watchlist.title' | translate"></app-pvr-watchlist-preview-list>

    <section>
      <h1 class="h2">{{ 'pvr.manage.records.title' | translate }}</h1>
      <div class="grid manage-buttons">
        <app-pvr-grid-button class="panel cursor-pointer"
                             [icon]="'calendar'"
                             [size]="'xxl'"
                             [label]="'pvr.manage.records.planned.button' | translate"
                             [rounded]="true"
                             (click)="openPlannedRecordings()"></app-pvr-grid-button>

        <app-pvr-grid-button class="panel cursor-pointer"
                             [icon]="'clock'"
                             [size]="'xxl'"
                             [label]="'pvr.manage.records.oldest.button' | translate"
                             [rounded]="true"
                             (click)="openOldestRecordings()"></app-pvr-grid-button>

        <app-pvr-grid-button class="panel cursor-pointer"
                             [icon]="'copy'"
                             [size]="'xxl'"
                             [label]="'pvr.manage.records.duplicate.button' | translate"
                             [rounded]="true"
                             (click)="openDuplicateRecordings()"></app-pvr-grid-button>

        <app-pvr-grid-button class="panel cursor-pointer"
                             [icon]="'serial'"
                             [size]="'xxl'"
                             [label]="'pvr.manage.records.series.button' | translate"
                             [rounded]="true"
                             (click)="openSerialListRecordings()"></app-pvr-grid-button>
      </div>
    </section>

    <app-pvr-recording-statistics></app-pvr-recording-statistics>
  </div>
} @else {
  @if (teaser$ | async; as teaser) {
    @if( !isLoading()) {
      <div class="upsell">
        <div class="upsell__img-container">
          <tbui-media-image class="upsell__img-container__image" alt="Upsell Image" [image]="teaser.getImage()"
                            [size]="MediaImageStack.RAW" [fileExtension]="MediaImageFileExtension.PNG"
                            [priority]="true"></tbui-media-image>
        </div>

        @if (teaser.title) {
          <h2 class="upsell__title">{{ teaser.title | translate }}</h2>
        }

        @if (teaser.text) {
          <p class="upsell__text">{{ teaser.text | translate }}</p>
        }

        <a class="btn btn__primary mt-base" [href]="teaser.link" target="_blank">{{ teaser.ctaText }}</a>
      </div>
    }
  }
}
