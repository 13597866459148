import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-pvr-empty-list',
  imports: [TranslateModule],
  templateUrl: './pvr-empty-list.component.html',
  styleUrls: ['pvr-empty-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PvrEmptyListComponent {}
