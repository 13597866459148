<div class="info" [ngClass]="{'info--collapse': collapse, 'info--sm': size === 'small' }">
  <p *ngIf="icon" class="info__icon">
    <tbui-icon [iconId]="icon"></tbui-icon>
  </p>
  <h2 *ngIf="title">{{title}}</h2>
  <p *ngIf="text">{{text}}</p>
  <ng-content select="[customContent]"></ng-content>

  <div class="info__cta">
    <ng-content select="[cta]"></ng-content>
  </div>
</div>
