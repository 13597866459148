import { BufferType, MediaType, PlayerConfig } from 'bitmovin-player';
import { environment } from '../../../environments/environment';

const BitmovinConfig: PlayerConfig = {
  key: environment.bitmovin.playerKey,
  playback: {
    autoplay: true,
    muted: false
  },
  adaptation: {
    disableDownloadCancelling: true
  },
  ui: false,
  location: {
    ui: 'https://cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.js',
    ui_css: 'https://cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.css'
  },
  buffer: {
    [MediaType.Video]: {
      [BufferType.ForwardDuration]: 4.8
    },
    [MediaType.Audio]: {
      [BufferType.ForwardDuration]: 4.8
    }
  },
  logs: {
    bitmovin: false,
    level: environment.bitmovin.logLevel
  },
  advertising: {
    placeholders: {
      height: ['__height__'],
      width: ['__width__'],
      random: ['__cb__']
    },
    videoLoadTimeout: 5000,
    trackers: {
      omSdk: {
        partnerName: 'appnexus.com-omid',
        partnerVersion: '1.0.5'
      }
    }
  },
  analytics: {
    key: environment.bitmovin.analyticsKey
  },
  tweaks: {
    max_mpd_retries: 5,
    max_retries: 5
  }
};

export const BITMOVIN_CONFIG_PROVIDER = {
  provide: 'BITMOVIN_CONFIG',
  useValue: BitmovinConfig
};
