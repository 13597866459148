import {
  CommunityRecordParams,
  EpgParams,
  HeartbeatParams,
  RecommendationsParams,
  SerieParams,
  TeaserParams,
  WatchlistParams,
  SearchParams,
  SearchPrefix,
  StarAlertListParams,
  SearchSource
} from '@teleboy/web.epg';
import { ViewToggleType } from '../services/view-toggler.service';

export enum EntityServiceType {
  EpgApiService,
  HeartbeatApiService,
  WatchlistApiService,
  RecommendationsApiService,
  CommunityApiService,
  ContentApiService,
  StationOrderStorageService,
  TeaserApiService,
  SearchApiService,
  StarAlertService
}

export enum EntityType {
  Epg,
  Recommendation,
  Watchlist,
  Heartbeat,
  CommunityRecord,
  Serie,
  Station,
  Teaser,
  Search,
  StarAlert
}

export interface ItemsListRouteData {
  title: string;
  params?:
    | EpgParams
    | RecommendationsParams
    | WatchlistParams
    | HeartbeatParams
    | CommunityRecordParams
    | SerieParams
    | TeaserParams
    | SearchParams
    | StarAlertListParams;
  serviceName: EntityServiceType;
  broadcastType: EntityType;
  enableListTypeToggle?: boolean;
  itemsPerPage?: number;
  predefinedListingType?: ViewToggleType;
  searchValue?: string;
  searchSourceValue?: SearchSource;
  searchQueryMode?: SearchPrefix;
  showStaralertNotificationsButton?: boolean;
}
