import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteDataService } from './route-data.service';
import { Broadcast } from '@teleboy/web.epg';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { BehaviorSubject, Observable } from 'rxjs';

interface PopupOptions {
  outlet?: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data?: any;
  skipLocationChange?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  popupCounter = 0;

  // Modal fields
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  currentPopup!: MatDialogRef<any>;
  currentPopupName!: string;

  readonly modalOpened$: Observable<boolean>;
  private readonly _modalOpened$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private dialog: MatDialog,
    private routeDataService: RouteDataService,
    private router: Router
  ) {
    this.modalOpened$ = this._modalOpened$.asObservable();
  }

  open(url: string, options?: PopupOptions): void {
    const urlTree = this.router.createUrlTree(['', { outlets: { [options?.outlet ?? 'popup']: url } }], {
      queryParamsHandling: 'preserve'
    });

    if (options?.data) {
      this.routeDataService.setData<Broadcast>(urlTree.toString(), options.data);
    }

    void this.router.navigateByUrl(urlTree, { skipLocationChange: options?.skipLocationChange });
  }

  close(outlet = 'popup'): Promise<boolean> {
    return this.router.navigate(['', { outlets: { [outlet]: null } }], { queryParamsHandling: 'preserve' });
  }

  closeAllOpenOutlets(): void {
    void this.router.navigate([
      {
        outlets: {
          landingpage: null,
          person: null,
          details: null,
          broadcast: null,
          popup: null
        }
      }
    ]);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  openAsModal<T, D = any>(
    component: ComponentType<T>,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    data: any = null,
    customDialogConfig: MatDialogConfig<D> | null = null
  ): MatDialogRef<T> {
    if (this.isSameModalAlreadyOpen(component)) {
      return this.currentPopup;
    }

    const defaultConfig = {
      panelClass: 'popup__container',
      backdropClass: 'popup__backdrop',
      maxWidth: 700,
      closeOnNavigation: true,
      position: {
        // Keep the popup position as in the "modal-like" implementation with outlets.
        // To center the popup, set position: undefined;
        top: '0'
      },
      data: data
    };

    const popupConfig = customDialogConfig ? { ...defaultConfig, ...customDialogConfig } : defaultConfig;
    this.currentPopup = this.dialog.open(component, popupConfig);
    this._modalOpened$.next(true);
    this.currentPopupName = component.name;

    this.afterClose();
    return this.currentPopup;
  }

  closeModal(): void {
    if (this.currentPopup) {
      this.currentPopup.close();
    }
  }

  isPopupOpen(): boolean {
    return this.popupCounter > 0 || !!this.currentPopupName;
  }

  private afterClose() {
    this.currentPopup.afterClosed().subscribe(() => {
      this.currentPopupName = '';
    });
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private isSameModalAlreadyOpen(component: ComponentType<any>) {
    return this.currentPopup && this.currentPopupName == component.name;
  }
}
