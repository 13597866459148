import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { StartRoutingModule } from './start-routing.module';
import { SharedModule } from '../shared/shared.module';
import { LoadingAdComponent } from './components/loading-ad/loading-ad.component';
import { ReactiveFormsModule } from '@angular/forms';
import { WebUiHrefPipe } from '../shared/pipes/web-ui-href.pipe';

@NgModule({
  declarations: [LoadingAdComponent],
  imports: [CommonModule, SharedModule, StartRoutingModule, TranslateModule, ReactiveFormsModule, WebUiHrefPipe]
})
export class StartModule {}
