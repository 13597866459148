import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Broadcast, SearchParams, SearchPrefix, SearchSort, SearchSource } from '@teleboy/web.epg';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '@teleboy/web.user';
import { SlideConfig } from '../../../providers/swiper.provider';
import { SharedModule } from '../../../../shared/shared.module';
import { SearchSwiperComponent } from '../../swiper/search-swiper/search-swiper.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cpvr-no-slot',
  templateUrl: './cpvr-no-slot.component.html',
  styleUrl: './cpvr-no-slot.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule, SearchSwiperComponent, TranslateModule]
})
export class CpvrNoSlotComponent implements OnInit {
  searchParams!: SearchParams;
  SlideConfig = SlideConfig['popup'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public broadcast: Broadcast,
    private dialogRef: MatDialogRef<CpvrNoSlotComponent>,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.setSearchResults();
  }

  setSearchResults(): void {
    this.searchParams = new SearchParams(
      `${this.broadcast.title.substring(0, 40)}`,
      [SearchSource.COMMUNITY],
      SearchPrefix.TITLE
    )
      .allStations()
      .setSort(SearchSort.SCORE)
      .setLocale(this.languageService.getLanguage());
  }

  close(): void {
    this.dialogRef.close();
  }
}
