"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CastUIContainer = void 0;
var uicontainer_1 = require("./uicontainer");
var timeout_1 = require("../timeout");
/**
 * The base container for Cast receivers that contains all of the UI and takes care that the UI is shown on
 * certain playback events.
 *
 * @category Containers
 */
var CastUIContainer = /** @class */function (_super) {
  __extends(CastUIContainer, _super);
  function CastUIContainer(config) {
    return _super.call(this, config) || this;
  }
  CastUIContainer.prototype.configure = function (player, uimanager) {
    var _this = this;
    _super.prototype.configure.call(this, player, uimanager);
    var config = this.getConfig();
    /*
     * Show UI on Cast devices at certain playback events
     *
     * Since a Cast receiver does not have a direct HCI, we show the UI on certain playback events to give the user
     * a chance to see on the screen what's going on, e.g. on play/pause or a seek the UI is shown and the user can
     * see the current time and position on the seek bar.
     * The UI is shown permanently while playback is paused, otherwise hides automatically after the configured
     * hide delay time.
     */
    var isUiShown = false;
    var hideUi = function () {
      uimanager.onControlsHide.dispatch(_this);
      isUiShown = false;
    };
    this.castUiHideTimeout = new timeout_1.Timeout(config.hideDelay, hideUi);
    var showUi = function () {
      if (!isUiShown) {
        uimanager.onControlsShow.dispatch(_this);
        isUiShown = true;
      }
    };
    var showUiPermanently = function () {
      showUi();
      _this.castUiHideTimeout.clear();
    };
    var showUiWithTimeout = function () {
      showUi();
      _this.castUiHideTimeout.start();
    };
    var showUiAfterSeek = function () {
      if (player.isPlaying()) {
        showUiWithTimeout();
      } else {
        showUiPermanently();
      }
    };
    player.on(player.exports.PlayerEvent.Play, showUiWithTimeout);
    player.on(player.exports.PlayerEvent.Paused, showUiPermanently);
    player.on(player.exports.PlayerEvent.Seek, showUiPermanently);
    player.on(player.exports.PlayerEvent.Seeked, showUiAfterSeek);
    uimanager.getConfig().events.onUpdated.subscribe(showUiWithTimeout);
  };
  CastUIContainer.prototype.release = function () {
    _super.prototype.release.call(this);
    this.castUiHideTimeout.clear();
  };
  return CastUIContainer;
}(uicontainer_1.UIContainer);
exports.CastUIContainer = CastUIContainer;