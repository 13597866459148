<div *ngIf="episode; else ghost" class="card">
  <div class="card__body episode" [ngClass]="{ 'episode--active': isPlaying$ | async }">
    <app-broadcast-flag *ngIf="episode.broadcast" [broadcast]="episode.broadcast"></app-broadcast-flag>

    <ng-container *ngIf="episode.broadcast && episode.broadcast.community && episode.broadcast.community.provider">
      <div class="episode__cpvr-provider">
        <app-cpvr-provider [provider]="episode.broadcast.community.provider" [broadcast]="episode.broadcast"
                           [expandable]="true"></app-cpvr-provider>
      </div>
    </ng-container>

    <div class="episode__info">
      <div class="episode__time" *ngIf="episode.broadcast as broadcast; else noBroadcast">
        <tbui-station-logo *ngIf="broadcast.station$ | async as station"
                           [station]="station" [size]="StationLogoSize.S"></tbui-station-logo>
        <small>{{ broadcast.begin.format('ddd DD.MM • HH:mm') }}</small>
        <app-broadcast-icons [broadcast]="episode.broadcast"></app-broadcast-icons>
      </div>

      <h3 class="collapse">{{ 'serie.details.episodes.title' | translate: {episodeNumber: episode.episodeNumber} }}</h3>
      <p><small>{{ episode.subtitle }}</small></p>
      <details *ngIf="episode.description && episode.description.length > 120; else fullDescription">
        <summary class="broadcast__content--text">{{episode.description | truncate:120}} <span class="read-more">{{'serie.details.episodes.moreText' | translate}}</span></summary>
        <ng-container *ngTemplateOutlet="fullDescription"></ng-container>
      </details>
      <ng-template #fullDescription>
        <p class="broadcast__content--text">{{episode.description}}</p>
      </ng-template>
    </div>

    <div *ngIf="episode.broadcast as broadcast" class="episode__actions">
      <button *ngIf="broadcast.flags.is_playable"
              [ngClass]="{'btn--active': (isPlaying$ | async)}"
              class="btn btn__icon btn--bordered btn--circle" [appBroadcastPlay]="broadcast">
        <tbui-icon iconId="play"></tbui-icon>
      </button>
      <button *ngIf="broadcast.flags.isRecordable()"
              class="btn btn__icon btn--bordered btn--circle" [appBroadcastToggleRecord]="broadcast">
        <tbui-icon [iconId]="!broadcast.flags.isRecorded() ? 'record' : 'bin'"></tbui-icon>
      </button>

      <button *ngIf="broadcast.flags.isWatchlistable() && !broadcast.flags.isRecorded()"
              class="btn btn__icon btn--bordered btn--circle" [appBroadcastWatchlist]="broadcast">
        <tbui-icon iconId="watchlist"></tbui-icon>
      </button>
    </div>
  </div>
</div>

<ng-template #noBroadcast>
  <small><i>{{ 'serie.details.episodes.noBroadcast' | translate }}</i></small>
</ng-template>

<ng-template #ghost>
  <div class="card">
    <div class="card__body episode">
      <div class="episode__info">
        <div class="episode__time">
          <span [ngStyle]="{ width: '40px', height: '40px', display: 'inline-block'}"></span>
          <small>&nbsp;</small>
        </div>

        <h3 class="collapse">&nbsp;</h3>
        <p><small>&nbsp;</small></p>
        <p class="episode__description collapse">&nbsp;<br>&nbsp;</p>
      </div>
    </div>
  </div>
</ng-template>
