import { ChangeDetectorRef, Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { BroadcastActionDirective } from './broadcast-action.directive';
import { BroadcastActionService } from '../../services/broadcast-action.service';
import { Broadcast } from '@teleboy/web.epg';
import { tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: 'button[appBroadcastDeleteRecordHeartbeat]'
})
export class BroadcastDeleteRecordHeartbeatDirective extends BroadcastActionDirective implements OnInit {
  @Input('appBroadcastDeleteRecordHeartbeat') broadcast!: Broadcast;
  @Output() heartbeatDeleted: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected override changeDetectionRef: ChangeDetectorRef,
    private broadcastActionService: BroadcastActionService,
    private translateService: TranslateService
  ) {
    super(changeDetectionRef);
  }

  ngOnInit(): void {
    this.translateService.get('broadcast.action.heartbeatDelete.label').subscribe((text) => (this._title = text));
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    this.performAction(() =>
      this.broadcastActionService.handleDeleteHeartbeat$(this.broadcast).pipe(tap(() => this.heartbeatDeleted.emit()))
    );
  }
}
