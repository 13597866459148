import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: false
})
export class TruncatePipe implements PipeTransform {
  private readonly DEFAULT_LIMIT = 250;
  private readonly DEFAULT_SUFFIX = '...';

  transform(value: string, limit = this.DEFAULT_LIMIT, suffix = this.DEFAULT_SUFFIX): string {
    const truncatedValue = [value.substring(0, limit)];

    if (value.length > limit) {
      truncatedValue.push(suffix);
    }

    return truncatedValue.join(' ');
  }
}
