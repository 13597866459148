import { ChangeDetectionStrategy, Component, Inject, Input, NgZone } from '@angular/core';
import { ContentApiService, EpisodeParams } from '@teleboy/web.epg';
import { PopupService } from '../../../../core/services/popup.service';
import { Observable } from 'rxjs';
import { ApiListResponse } from '@teleboy/web.core';
import { ItemsListRouteData, EntityServiceType, EntityType } from '../../../interfaces/items-list-route-data';
import { ListSwiperDirective } from '../../../directives/swiper/list-swiper.directive';
import { ViewToggleType } from '../../../services/view-toggler.service';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { BroadcastItemComponent } from '../../broadcast-item/broadcast-item.component';

@Component({
  selector: 'app-episodes-swiper',
  templateUrl: './episodes-swiper.component.html',
  styleUrls: ['../swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, SharedModule, SwiperModule, NgFor, BroadcastItemComponent, AsyncPipe]
})
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export class EpisodesSwiperComponent extends ListSwiperDirective<any> {
  @Input() isLinkClickable = true;
  @Input() override params!: EpisodeParams;
  @Input() listingType!: ViewToggleType;
  @Input() seriesId!: number;
  @Input() seasonId!: number;

  constructor(
    @Inject(NgZone) ngZone: NgZone,
    private contentApiService: ContentApiService,
    private popupService: PopupService
  ) {
    super(ngZone);
  }

  itemLoader$(): Observable<ApiListResponse['data']> {
    return this.contentApiService.getEpisodes(this.seasonId, this.params);
  }

  openSerieBroadcastModal() {
    const routeData: ItemsListRouteData = {
      title: this.headline,
      params: this.params,
      serviceName: EntityServiceType.ContentApiService,
      broadcastType: EntityType.Serie
    };

    this.popupService.open(`serie/${this.seriesId}/${this.seasonId}`, {
      outlet: 'broadcast',
      data: routeData,
      skipLocationChange: false
    });
  }
}
