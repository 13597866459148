"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isAction = exports.isDirection = exports.isListBox = exports.isContainer = exports.isComponent = exports.isSettingsPanel = void 0;
var component_1 = require("../components/component");
var settingspanel_1 = require("../components/settingspanel");
var container_1 = require("../components/container");
var listbox_1 = require("../components/listbox");
var types_1 = require("./types");
function isSettingsPanel(component) {
  return component instanceof settingspanel_1.SettingsPanel;
}
exports.isSettingsPanel = isSettingsPanel;
function isComponent(obj) {
  return obj !== null && obj !== undefined && obj instanceof component_1.Component;
}
exports.isComponent = isComponent;
function isContainer(obj) {
  return obj !== null && obj !== undefined && obj instanceof container_1.Container;
}
exports.isContainer = isContainer;
function isListBox(obj) {
  return obj instanceof listbox_1.ListBox;
}
exports.isListBox = isListBox;
function isDirection(direction) {
  return typeof direction === 'string' && Object.values(types_1.Direction).includes(direction);
}
exports.isDirection = isDirection;
function isAction(action) {
  return typeof action === 'string' && Object.values(types_1.Action).includes(action);
}
exports.isAction = isAction;