import { ChangeDetectionStrategy, Component, Inject, Input, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { Broadcast, EpgApiService, EpgParams } from '@teleboy/web.epg';
import { ApiListResponse } from '@teleboy/web.core';
import { ListSwiperDirective } from '../../../directives/swiper/list-swiper.directive';
import { PopupService } from '../../../../core/services/popup.service';
import { ItemsListRouteData, EntityServiceType, EntityType } from '../../../interfaces/items-list-route-data';
import { ViewToggleType } from '../../../services/view-toggler.service';
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { BroadcastItemComponent } from '../../broadcast-item/broadcast-item.component';

@Component({
  selector: 'app-broadcast-swiper',
  templateUrl: './broadcast-swiper.component.html',
  styleUrls: ['../swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, SharedModule, SwiperModule, NgFor, BroadcastItemComponent, AsyncPipe]
})
export class BroadcastSwiperComponent extends ListSwiperDirective<Broadcast> {
  @Input() isLinkClickable = true;
  @Input() override params!: EpgParams;
  @Input() listingType!: ViewToggleType;
  protected ListingType = ViewToggleType;

  constructor(
    @Inject(NgZone) ngZone: NgZone,
    private epgApiService: EpgApiService,
    private popupService: PopupService
  ) {
    super(ngZone);
  }

  itemLoader$(): Observable<ApiListResponse['data']> {
    return this.epgApiService.getBroadcasts(this.params.httpParams);
  }

  openInListTilesView() {
    const routeData: ItemsListRouteData = {
      title: this.headline,
      params: this.params,
      serviceName: EntityServiceType.EpgApiService,
      broadcastType: EntityType.Epg
    };

    this.popupService.open('items/list', { outlet: 'details', data: routeData, skipLocationChange: true });
  }
}
