<div class="cpvr-provider" [ngClass]="{ 'cpvr-provider--expandable': expandable }">
  <div class="cpvr-provider__image"
       [ngClass]="{ 'cpvr-provider--friend': provider.isFriend$ | async, 'cpvr-provider--self': provider.isSelf }">
    <tbui-media-image class="cpvr-provider__image-provider" [image]="provider.image" [size]="MediaImageStack.USER_HUGE" [alt]="'User'"></tbui-media-image>
  </div>
  <div class="cpvr-provider__content">
    <div>
      <strong>
        <span class="cpvr-provider__nickname">{{ 'cpvr.provider.nickname' | translate }} </span>{{ provider.nickname }}
      </strong>
      <p class="cpvr-provider__friend-info" *ngIf="!(provider.isFriend$ | async)">{{ 'cpvr.provider.befriend.text' | translate: {nickname: provider.nickname} }}</p>
      <p class="cpvr-provider__friend-info" *ngIf="provider.isFriend$ | async">{{ 'cpvr.provider.borrow.text' | translate }}</p>
      <ul class="cpvr-provider__genres">
        <li class="cpvr-provider__genres-label">{{ 'cpvr.provider.genres.label' | translate }}</li>
        <li class="badge badge__secondary" *ngFor="let genre of genres$() | async">{{ genre }}</li>
      </ul>
    </div>
    <div class="cpvr-provider__cta">
      <a href [appBroadcastPlay]="broadcast">
        <span *ngIf="!(provider.isFriend$ | async)">{{ 'cpvr.provider.befriend.btn' | translate }}</span>
        <span *ngIf="provider.isFriend$ | async">{{ 'cpvr.provider.borrow.btn' | translate }}</span>
      </a>
    </div>
  </div>
</div>
