<div class="tabbar tabbar-epg">
  <div class="tabbar__left">
    <h1 class="collapse tabbar__title">{{ 'epg.title' | translate }}</h1>
  </div>

  <div class="tabbar__right">
    <button class="btn hidden-tablet" (click)="setDayShortcut(yesterday)"
            [ngClass]="{ 'busy': isLoading$ | async, 'btn__primary': yesterday.isSame(activeDay$ | async, 'day') }">{{ 'epg.nav.yesterday' | translate }}</button>
    <button class="btn hidden-tablet" (click)="setDayShortcut(today)"
            [ngClass]="{ 'busy': isLoading$ | async, 'btn__primary': today.isSame(activeDay$ | async, 'day') }">{{ 'epg.nav.today' | translate }}</button>
    <button class="btn hidden-tablet" (click)="setDayShortcut(tomorrow)"
            [ngClass]="{ 'busy': isLoading$ | async, 'btn__primary': tomorrow.isSame(activeDay$ | async, 'day') }">{{ 'epg.nav.tomorrow' | translate }}</button>
    <form [formGroup]="form">
      <app-dropdown [options]="dropDownoptions" [highlightSelectedOption]="true" (valueChange)="setDay($event)" formControlName="date"></app-dropdown>
    </form>
    <app-search></app-search>
  </div>
</div>

<div class="epg__slider">
  <div class="epg__slider-inner">
    <ngx-slider *ngIf="!(isLoading$ | async)" [(value)]="sliderValue" [options]="sliderOptions" (userChange)="setSliderValueToView($event)"></ngx-slider>
  </div>
</div>

<div class="epg" infiniteScroll (scrolled)="loadMore()" [alwaysCallback]="true">
  <div class="epg__scroll-view" (scroll)="scrollView($event)" #epgScrollView>
    <div class="epg__timeline__live-btn">
      <button class="btn btn__primary btn--sm" [attr.aria-disabled]="scrolling$ | async" *ngIf="isToday()" (click)="scrollViewTo(minuteToPixel(now))">Live
      </button>
      <button class="btn btn__primary btn--sm" [attr.aria-disabled]="scrolling$ | async" *ngIf="!isToday()" (click)="scrollViewTo(hourToPixel(primetime))">Prime
      </button>
    </div>


    <div class="epg__timeline-wrapper">
      <div class="epg__timeline__live-indicator" *ngIf="isToday() || midnight()" #liveIndicator>
        <span class="epg__timeline__live-indicator__flag">LIVE</span>
      </div>
      <ul class="epg__timeline" id="timelineRow">
        <li *ngFor="let hour of timeline; first as isFirst">
          <span *ngIf="!isFirst">{{hour}}</span>
        </li>
      </ul>
    </div>

    <ng-container *ngIf="epg$ | async as stationsEpg">
      <div *ngFor="let stationEpg of stationsEpg | keyvalue: sortNull;let indexOfStation=index; trackBy: trackById"  class="epg__station" [class]="{'epg__station--loading': (isLoading$ | async)}">
        <app-epg-station
          [index]="indexOfStation"
          [station]="stationEpg.value.station"
          [broadcasts]="stationEpg.value.broadcasts"
          *ngIf="indexOfStation <= middleStationLaneIndex + itemsCountDisplayedAboveLaneIndex && indexOfStation >= middleStationLaneIndex - itemsCountDisplayedAboveLaneIndex"
        ></app-epg-station>

      </div>

    </ng-container>
    <div class="epg__station__loading" *ngIf="(busy$ | async) || (isLoading$ | async)">
      <div class="epg__station__loading__row" *ngFor="let row of loadingRows">
        <div class="epg__station__loading__row-bg" [ngStyle]="{'background-position-x': row}"></div>
        <div class="epg__station__logo"></div>
      </div>
    </div>

  </div>

  <div class="epg__station__info" *ngIf="allStationsLoaded">
    <a href="https://www.teleboy.ch/sender" target="_blank" class="btn btn__primary">
      {{ 'epg.info.station.btn' | translate }}
    </a>
  </div>

</div>
