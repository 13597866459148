import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MediaImage, MediaImageStack } from '@teleboy/web.core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { IconComponent } from '@teleboy/web.ui';

export enum ProfileImageSize {
  Small = 's',
  Medium = 'm',
  Large = 'l'
}

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
  imports: [NgClass, NgOptimizedImage, IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileImageComponent {
  @Output() clicked: EventEmitter<MediaImage> = new EventEmitter<MediaImage>();

  @Input() busy!: boolean;
  @Input() clickable!: boolean;
  @Input() image?: MediaImage;
  @Input() active!: boolean;
  @Input() showEdit!: boolean;
  @Input() size: ProfileImageSize = ProfileImageSize.Medium;

  protected readonly MediaImageStack = MediaImageStack;
  constructor(private deviceDetectorService: DeviceDetectorService) {}

  onProfileImageClick() {
    this.clicked.emit(this.image);
  }

  getBusyClassList(): string | undefined {
    let busyClass = '';

    if (this.busy) {
      const isMobileDevice = this.deviceDetectorService.isMobile();

      switch (this.size) {
        case ProfileImageSize.Large:
          busyClass = isMobileDevice ? 'profile-image--busy-l-mobile' : 'profile-image--busy-l';
          break;
        case ProfileImageSize.Medium:
          busyClass = isMobileDevice ? 'profile-image--busy-m-mobile' : 'profile-image--busy-m';
          break;
        case ProfileImageSize.Small:
          busyClass = isMobileDevice ? 'profile-image--busy-s-mobile' : 'profile-image--busy-s';
          break;
        default:
          busyClass = isMobileDevice ? 'profile-image--busy-m-mobile' : 'profile-image--busy-m';
          break;
      }
    }

    return busyClass;
  }

  getDimension(): number {
    const isMobileDevice = this.deviceDetectorService.isMobile();

    switch (this.size) {
      case ProfileImageSize.Large:
        return isMobileDevice ? 150 : 200;
      case ProfileImageSize.Medium:
        return isMobileDevice ? 80 : 120;
      case ProfileImageSize.Small:
        return 50;
      default:
        return isMobileDevice ? 80 : 120;
    }
  }
}
