import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Broadcast, CommunityProvider, GenreStorageService } from '@teleboy/web.epg';
import { MediaImageStack } from '@teleboy/web.core';
import { map, Observable, zip } from 'rxjs';
import { LanguageService } from '@teleboy/web.user';
import { NgClass, NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MediaImageComponent } from '@teleboy/web.ui';
import { BroadcastPlayDirective } from '../../../directives/broadcast/broadcast-play.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cpvr-provider',
  templateUrl: './cpvr-provider.component.html',
  styleUrls: ['./cpvr-provider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MediaImageComponent, NgIf, NgFor, BroadcastPlayDirective, AsyncPipe, TranslateModule]
})
export class CpvrProviderComponent {
  @Input() expandable = false;
  @Input() broadcast!: Broadcast;
  @Input() provider!: CommunityProvider;

  MediaImageStack = MediaImageStack;

  constructor(
    private genreStorageService: GenreStorageService,
    private languageService: LanguageService
  ) {}

  genres$(): Observable<string[]> {
    return zip(
      this.provider.genres.map((genreId: number) =>
        this.genreStorageService
          .getById(genreId)
          .pipe(map((genre) => genre.getLocalizedName(this.languageService.getLanguage())))
      )
    );
  }
}
