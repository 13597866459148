import { Component, Input, OnInit } from '@angular/core';
import { LandingpageLane, LandingpageSourceEndpoint } from '@teleboy/web.epg';
import { AuthenticationService } from '@teleboy/web.user';
import { SharedModule } from '../../../shared/shared.module';
import { NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { BroadcastSwiperComponent } from '../swiper/broadcast-swiper/broadcast-swiper.component';
import { RecordingSwiperComponent } from '../swiper/recording-swiper/recording-swiper.component';
import { CommunitySwiperComponent } from '../swiper/community-swiper/community-swiper.component';
import { SerieSwiperComponent } from '../swiper/serie-swiper/serie-swiper.component';
import { TeaserSwiperComponent } from '../swiper/teaser-swiper/teaser-swiper.component';
import { SelectionsComponent } from '../selections/selections.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss'],
  imports: [
    SharedModule,
    NgFor,
    NgIf,
    BroadcastSwiperComponent,
    RecordingSwiperComponent,
    CommunitySwiperComponent,
    SerieSwiperComponent,
    TeaserSwiperComponent,
    SelectionsComponent,
    UpperCasePipe,
    TranslateModule
  ]
})
export class LandingpageComponent implements OnInit {
  @Input() title!: string;
  @Input() lanes!: LandingpageLane[];

  protected isAuthenticated!: boolean;

  protected readonly LandingpageSourceEndpoint = LandingpageSourceEndpoint;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    this.isAuthenticated = this.authenticationService.isAuthenticated;
  }

  removeLane(index: number): void {
    this.lanes.splice(index, 1);
  }
}
