import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Broadcast, RecordingApiService } from '@teleboy/web.epg';
import { BehaviorSubject, catchError, EMPTY, finalize, Observable, tap, throwError } from 'rxjs';
import { SnackbarService, SnackbarType } from '../../../../core/services/snackbar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorCode, TeleboyError } from '@teleboy/web.core';
import { UpsellService } from '../../../../core/services/upsell.service';
import { SharedModule } from '../../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-recording-serial',
  templateUrl: './recording-serial.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule, TranslateModule, AsyncPipe]
})
export class RecordingSerialComponent {
  private readonly _busy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly busy$: Observable<boolean> = this._busy$.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) public broadcast: Broadcast,
    private dialogRef: MatDialogRef<RecordingSerialComponent>,
    private recordingApiService: RecordingApiService,
    private snackbarService: SnackbarService,
    private upsellService: UpsellService
  ) {}

  subscribeToSerialgroup(): void {
    if (!this.broadcast.serieGroupId) return;

    this._busy$.next(true);
    this.recordingApiService
      .subscribeToSerialgroup(this.broadcast.serieGroupId)
      .pipe(
        tap(() => this.snackbarService.openSnackbar('pvr.serial.success', SnackbarType.SUCCESS)),
        catchError((error: TeleboyError) => this.handleSerialRecodingError$(error)),
        finalize(() => {
          this._busy$.next(false);
          this.close();
        })
      )
      .subscribe();
  }

  close(): void {
    this.dialogRef.close();
  }

  private handleSerialRecodingError$(error: TeleboyError): Observable<never> {
    if (error.code === ErrorCode.USER_PLUS_SUBSCRIPTION_REQUIRED) {
      this.upsellService.openAboUpsell();
      return EMPTY;
    }

    return throwError(() => error);
  }
}
