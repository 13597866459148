import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Broadcast, Station, StationLogoComponent } from '@teleboy/web.epg';
import moment from 'moment';
import { ScreenService } from '../../../../core/services/screen.service';
import { IntersectionStatus } from '../../../../shared/directives/from-intersection-observer';
import { RouterLink } from '@angular/router';
import { NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, NgIf } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { EpgBroadcastComponent } from '../epg-broadcast/epg-broadcast.component';

@Component({
  selector: 'app-epg-station',
  templateUrl: './epg-station.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    StationLogoComponent,
    NgFor,
    SharedModule,
    NgSwitch,
    NgSwitchCase,
    EpgBroadcastComponent,
    NgSwitchDefault,
    NgIf
  ]
})
export class EpgStationComponent {
  @Input() broadcasts!: Broadcast[];
  @Input() index!: number;
  @Input() station!: Station;

  readonly intersectionStatus = IntersectionStatus;
  readonly pixelPerMinute = 10;
  readonly pixelPerMinuteMobile = 4;
  readonly visibilityStatus: Record<number, IntersectionStatus> = {};

  constructor(private screenService: ScreenService) {}

  @HostBinding('style.margin-left')
  get left(): string {
    if (!this.broadcasts.length) {
      return '0px';
    }

    const first = this.broadcasts[0].begin.clone();
    let pixelToStart = `${(first.diff(moment(first).endOf('day'), 'minutes') - 1) * this.getPixelsPerMinute()}px`;

    if (this.broadcasts[1]) {
      const second = this.broadcasts[1].begin.clone();
      if (first.isSame(second, 'day')) {
        pixelToStart = `${first.diff(moment(first).startOf('day'), 'minutes') * this.getPixelsPerMinute()}px`;
      }
    }

    return pixelToStart;
  }

  getPixelsPerMinute(): number {
    let pixels = this.pixelPerMinute;

    if (this.screenService.isMobileSizeScreen()) {
      pixels = this.pixelPerMinuteMobile;
    }

    return pixels;
  }

  trackByFn(index: number, item: Broadcast): number {
    return item.id;
  }

  onVisibilityChanged(index: number, status: IntersectionStatus) {
    this.visibilityStatus[index] = status;
  }
}
