import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  AdSchedule,
  BitmovinService,
  Brandingday,
  BrandingdayService,
  Gt12PauseAdService,
  SkipAdService
} from '@teleboy/web.player';
import { Player, TrackMetadata, ViewMode } from 'bitmovin-player';
import { AuthenticationService, NoAdblockPolicy } from '@teleboy/web.user';
import AdvertisingBitmovingModule from 'bitmovin-player/modules/bitmovinplayer-advertising-bitmovin';
import AdvertisingOMSKDModule from 'bitmovin-player/modules/bitmovinplayer-advertising-omsdk';
import { EventTrackingService } from '@teleboy/web.core';
import { PlayerService } from '../../services/player.service';
import {
  catchError,
  debounceTime,
  filter,
  fromEvent,
  iif,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
  timer,
  withLatestFrom
} from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { StationLogoSize } from '@teleboy/web.epg';
import { UpsellService } from '../../../core/services/upsell.service';
import { PlayerKeysService } from '../../services/player-keys.service';
import { ScreenService } from '../../../core/services/screen.service';
import { PlayerUiService } from '../../services/player-ui.service';
import { PlayerSize, PlayerSizeService } from '../../services/player-size.service';
import { PopupService } from '../../../core/services/popup.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { UIFactory, UIManager } from 'bitmovin-player-ui';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss', './player-mini.component.scss', './player-fullscreen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PlayerComponent implements OnInit, OnDestroy {
  @ViewChild('player', { static: true }) player!: ElementRef;
  @Input() jumpseekEnabled = true;
  @Input() listingClosed = true;
  @Input() muted = false;

  brandingday$!: Observable<Brandingday | null>;
  connectedToPlayerUI!: boolean;
  enlargePlayer$!: Observable<void>;
  isMobile!: boolean;
  playerSize$!: Observable<PlayerSize>;
  StationLogoSize = StationLogoSize;
  creative$!: Observable<string>;
  loaded$!: Observable<boolean>;
  playerUiShown$!: Observable<boolean>;
  skipButton$!: Observable<AdSchedule | null>;
  readonly PlayerSize = PlayerSize;
  readonly isAuthenticated = this.authenticationService.isAuthenticated;
  readonly isPlusUser = this.authenticationService.user?.isPlusUser;

  private uiManager!: UIManager;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authenticationService: AuthenticationService,
    private bitmovinService: BitmovinService,
    private brandingdayService: BrandingdayService,
    private playerKeysService: PlayerKeysService,
    private gt12PauseAdService: Gt12PauseAdService,
    private playerService: PlayerService,
    private playerSizeService: PlayerSizeService,
    private playerUiService: PlayerUiService,
    private popupService: PopupService,
    private renderer2: Renderer2,
    private screenService: ScreenService,
    private skipAdService: SkipAdService,
    private translateService: TranslateService,
    private upsellService: UpsellService,
    private eventTrackingService: EventTrackingService,
    private noAdblockPolicy: NoAdblockPolicy
  ) {}

  ngOnInit(): void {
    Player.addModule(AdvertisingBitmovingModule);
    Player.addModule(AdvertisingOMSKDModule);
    const getTrackLabels = (data: TrackMetadata): string | undefined => {
      if (data?.mimeType && data.mimeType.indexOf('audio') >= 0) {
        return this.translateService.instant(`playerSettings.audio.lang.${data.lang}`);
      }
      return data.lang;
    };

    this.bitmovinService.init(
      this.player.nativeElement,
      !!this.authenticationService.user?.isPlusUser,
      this.muted,
      getTrackLabels
    );

    if (!this.authenticationService.user?.isPlusUser) {
      this.brandingday$ = this._brandingday$();
    }

    this.screenService
      .orientationChange$()
      .pipe(
        filter(() => !this.screenService.isDesktopSizeScreen()),
        tap(() => (this.screenService.isLandscape() ? this.playerUiService.showUi() : this.playerUiService.hideUi())),
        takeUntil(this.destroy$)
      )
      .subscribe();

    const myLocalizationConfig = this.playerService.getPlayerLocalizationConfig();
    UIManager.setLocalizationConfig(myLocalizationConfig);

    this.bitmovinService.loaded$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.uiManager = UIFactory.buildDefaultUI(this.bitmovinService.player);
      this.observeBitmovinUi();
      this.largePlayer();
    });

    this.bitmovinService.unload$
      .pipe(
        tap(() => this.releaseUiManager()),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.bitmovinService.adError$
      .pipe(
        switchMap(() => {
          return this.noAdblockPolicy.isGranted().pipe(
            catchError(() => {
              return fromPromise(this.bitmovinService.player.unload()).pipe(
                tap(() => {
                  this.upsellService.openAdBlockerUpsell();
                  this.eventTrackingService.trackEvent('adblocker.appeared');
                  this.closePlayer();
                }),
                map(() => false)
              );
            })
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.bitmovinService.viewModeChanged$
      .pipe(
        filter((viewode) => viewode === ViewMode.Fullscreen),
        tap(() => {
          this.onFullScreenActivated();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.skipButton$ = this._skipButton$();
    this.enlargePlayer$ = this.playerSizeService.enlarge$.pipe(startWith(void 0));
    this.playerSize$ = this.playerSizeService.size$;
    this.creative$ = this.gt12PauseAdService.creative$;
    this.connectedToPlayerUI = this.isConnectedToPlayerUI();
    this.playerUiShown$ = this.playerUiService.ui$;
    this.isMobile = this.screenService.isMobileSizeScreen();
    this.loaded$ = this.bitmovinService.loaded$.pipe(map(() => true));

    this.popupService.modalOpened$
      .pipe(
        filter(() => this.bitmovinService.isFullscreen()),
        tap(() => {
          this.fullscreenToggle();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    if (this.connectedToPlayerUI) {
      this.trackUiWhileAdBreak();

      this.bitmovinService.adBreakFinished$
        .pipe(
          tap(() => {
            if (this.bitmovinService.isFullscreen()) {
              this.bitmovinService?.player.setViewMode(ViewMode.Fullscreen);
            } else {
              this.bitmovinService?.player.setViewMode(ViewMode.Inline);
            }
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }

    this.screenService.isTouchScreenDevice$
      .pipe(
        filter((val) => val),
        tap(() => (this.connectedToPlayerUI = this.isConnectedToPlayerUI())),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  fullscreenToggle(): void {
    this.onFullScreenActivated();
    this.bitmovinService.fullscreenToggle();
  }

  play(): void {
    void this.bitmovinService.player.play('ui');
  }

  miniPlayer(): void {
    this.playerSizeService.miniPlayer();
  }

  largePlayer(): void {
    this.playerSizeService.largePlayer();
  }

  closePlayer(): void {
    if (!this.isPlusUser) {
      this.upsellService.openAboUpsell();
      return;
    }
    this.releaseUiManager();
    this.playerService.closePlayer().subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.releaseUiManager();
    this.bitmovinService.destroy();
    this.renderer2.removeClass(this.document.body, 'player-brandingday');

    this.playerSizeService.reset();
  }

  skipAd(adSchedule: AdSchedule): void {
    this.skipAdService.skipAd(adSchedule);
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler($event: KeyboardEvent): void {
    if (
      (this.playerSizeService.isMiniPlayer() && !this.bitmovinService.isFullscreen()) ||
      this.popupService.isPopupOpen()
    ) {
      return;
    }

    this.playerKeysService.handleKeydown($event);
  }

  /**
   * UI manager may be not available in some situations,
   *
   * @see https://teleboy.atlassian.net/browse/WEB-36243
   */
  releaseUiManager(): void {
    if (!this.uiManager) {
      return;
    }

    this.uiManager.release();
  }

  /**
   * @see https://community.bitmovin.com/t/cant-find-any-event-related-to-user-hovering-over-the-ui-in-web-player/1058/4
   * @see https://github.com/bitmovin/bitmovin-player-ui#externally-managed
   */
  private observeBitmovinUi(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.uiManager.currentUi.onControlsShow.subscribe(() => this.playerUiService.showUi());

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.uiManager.currentUi.onControlsHide.subscribe(() => {
      const shouldHideUI =
        !this.connectedToPlayerUI || (this.connectedToPlayerUI && !this.playerUiService.hasCustomControlFocused());

      if (shouldHideUI && this.playerUiService.shown) {
        this.playerUiService.hideUi();
      }
    });
  }

  private onFullScreenActivated() {
    this.playerSizeService.largePlayer();
  }

  private _brandingday$(): Observable<Brandingday | null> {
    return this.brandingdayService.getBrandingday$().pipe(
      map((brandingdayStorage) => {
        this.renderer2.addClass(this.document.body, 'player-brandingday');
        return brandingdayStorage.data;
      }),
      catchError(() => of(null))
    );
  }

  private _skipButton$(): Observable<AdSchedule | null> {
    const skipButton$ = this.skipAdService.skipButton$().pipe(shareReplay({ bufferSize: 1, refCount: true }));

    const uiToggler$ = this.playerUiService.ui$.pipe(
      withLatestFrom(skipButton$),
      map(([uiVisible, adSchedule]) => (!!adSchedule && uiVisible ? adSchedule : null))
    );

    const adScheduleToggler$ = skipButton$.pipe(
      withLatestFrom(this.playerUiService.ui$),
      switchMap(([adSchedule, uiVisible]) =>
        iif(
          () => !uiVisible,
          timer(3000).pipe(
            map(() => null),
            startWith(adSchedule),
            takeUntil(this.playerUiService.ui$)
          ),
          of(adSchedule)
        )
      )
    );

    return merge(uiToggler$, adScheduleToggler$);
  }

  private trackUiWhileAdBreak() {
    // Mouse move
    fromEvent(this.player.nativeElement, 'mousemove')
      .pipe(
        filter(() => this.bitmovinService.isAdBreakActive()),
        withLatestFrom(this.playerUiService.ui$),
        map(([_, ui]) => {
          return ui;
        }),
        startWith(false),
        tap((ui) => {
          if (!ui) {
            this.playerUiService.showUi();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // Mouse stop
    fromEvent(this.player.nativeElement, 'mousemove')
      .pipe(
        filter(() => this.bitmovinService.isAdBreakActive()),
        debounceTime(2000),
        withLatestFrom(this.playerUiService.ui$),
        map(([_, second]) => {
          return second;
        }),
        startWith(true),
        tap((ui) => {
          if (ui && this.bitmovinService.isAdBreakActive() && !this.playerUiService.hasCustomControlFocused()) {
            this.playerUiService.hideUi();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private isConnectedToPlayerUI(): boolean {
    return this.screenService.isDesktopSizeScreen() && !this.screenService.isTouchDevice();
  }
}
