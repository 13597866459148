import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Teaser, TeaserApiService, TeaserParams } from '@teleboy/web.epg';
import { ApiListResponse } from '@teleboy/web.core';
import { DataSwiperDirective } from '../../../directives/swiper/data-swiper.directive';
import { SlideConfig } from '../../../providers/swiper.provider';
import { ItemsListRouteData, EntityServiceType, EntityType } from '../../../interfaces/items-list-route-data';
import { PopupService } from '../../../../core/services/popup.service';
import { ViewToggleType } from '../../../services/view-toggler.service';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { TeaserItemComponent } from '../../teaser-item/teaser-item.component';

@Component({
  selector: 'app-teaser-swiper',
  templateUrl: './teaser-swiper.component.html',
  styleUrls: ['../swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, SharedModule, SwiperModule, NgFor, TeaserItemComponent, AsyncPipe]
})
export class TeaserSwiperComponent extends DataSwiperDirective<Teaser> implements OnInit {
  @Input() params!: TeaserParams;
  @Input() topTeaser = false;

  constructor(
    private popupService: PopupService,
    private teaserApiService: TeaserApiService
  ) {
    super();
  }

  override ngOnInit() {
    this.swiperOptions = SlideConfig[this.topTeaser ? 'topTeaser' : 'teaser'];
    super.ngOnInit();
  }

  itemLoader$(): Observable<ApiListResponse['data']> {
    return this.teaserApiService.getTeasers(this.params).pipe(
      map((response) => {
        return {
          total: response.total,
          items: response.items.filter((teaser) => !!teaser.getImage(this.topTeaser))
        };
      })
    );
  }

  openInListTilesView() {
    const routeData: ItemsListRouteData = {
      title: this.headline,
      params: this.params,
      serviceName: EntityServiceType.TeaserApiService,
      broadcastType: EntityType.Teaser,
      enableListTypeToggle: false,
      predefinedListingType: ViewToggleType.TILES
    };

    this.popupService.open('items/list', { outlet: 'details', data: routeData, skipLocationChange: true });
  }
}
