import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { StarAlert, StarAlertType } from '@teleboy/web.epg';
import { PopupService } from '../../../core/services/popup.service';
import { SearchingService } from '../../services/searching.service';
import { StarAlertService } from '../../services/star-alert.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-staralert-item',
  templateUrl: './staralert-item.component.html',
  styleUrls: ['./staralert-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, AsyncPipe, IconComponent]
})
export class StaralertItemComponent {
  @Input() starAlert!: StarAlert;
  @Input() showDelete!: boolean;
  @Input() card = false;
  @Output() itemClicked: EventEmitter<StarAlert> = new EventEmitter<StarAlert>();
  @Output() deleted: EventEmitter<StarAlert> = new EventEmitter<StarAlert>();

  private readonly _busy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly busy$: Observable<boolean> = this._busy$.asObservable();

  constructor(
    private popupService: PopupService,
    private searchingService: SearchingService,
    private starAlertService: StarAlertService
  ) {}

  itemClick(staralert: StarAlert): void {
    switch (staralert.type) {
      case StarAlertType.EXACT:
        void this.closeOutlets().then(() => {
          this.searchingService.executeSearch(staralert.words);
        });
        return;
      case StarAlertType.NAME:
        this.openActorLP(staralert);
        return;
      case StarAlertType.TAGS:
        this.openTagsLP(staralert);
        return;
      default:
        return;
    }
  }

  openActorLP(staralert: StarAlert): void {
    void this.closeOutlets().then(() => {
      this.popupService.open(`cast/${staralert.words}`, { outlet: 'person', skipLocationChange: false });
    });
  }

  openTagsLP(staralert: StarAlert): void {
    void this.closeOutlets().then(() => {
      this.popupService.open(`tag/${staralert.words}`, { outlet: 'landingpage', skipLocationChange: false });
    });
  }

  deleteStarAlert(starAlert: StarAlert, event: Event): void {
    this._busy$.next(true);
    event.preventDefault();
    event.stopPropagation();
    this.starAlertService.deleteStarAlert$(starAlert).subscribe({
      next: () => {
        this.deleted.emit(starAlert);
      },
      complete: () => {
        this._busy$.next(false);
      }
    });
  }

  private closeOutlets(): Promise<void> {
    return new Promise((resolve) => {
      void this.popupService.close('broadcast').then(() => {
        void this.popupService.close('details').then(() => {
          resolve();
        });
      });
    });
  }
}
