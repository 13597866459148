import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  CommunityRecordParams,
  EpgParams,
  EpgSort,
  Selection,
  SelectionApiService,
  SelectionParams,
  SelectionSource,
  SerieParams,
  SerieSort,
  StarAlertListParams,
  StarAlertType,
  TeaserDevice,
  TeaserParams
} from '@teleboy/web.epg';
import { AboLevel, AuthenticationService, LanguageService } from '@teleboy/web.user';
import { map, Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MetaService } from '../../../core/services/meta.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { StarAlertService } from '../../services/star-alert.service';
import { TeaserButtonsComponent } from './teaser-buttons/teaser-buttons.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { SearchComponent } from '../search/search.component';
import { TeaserSwiperComponent } from '../swiper/teaser-swiper/teaser-swiper.component';
import { InlineAlertComponent } from '../../../shared/components/inline-alert/inline-alert.component';
import { ReplayInfoComponent } from './replay-info/replay-info.component';
import { BroadcastSwiperComponent } from '../swiper/broadcast-swiper/broadcast-swiper.component';
import { StaralertSwiperComponent } from '../swiper/staralert-swiper/staralert-swiper.component';
import { HeartbeatSwiperComponent } from '../swiper/heartbeat-swiper/heartbeat-swiper.component';
import { RecommendationSwiperComponent } from '../swiper/recommendation-swiper/recommendation-swiper.component';
import { SerieSwiperComponent } from '../swiper/serie-swiper/serie-swiper.component';
import { CommunitySwiperComponent } from '../swiper/community-swiper/community-swiper.component';
import { StationSwiperComponent } from '../swiper/station-swiper/station-swiper.component';
import { WebUiHrefPipe } from '../../../shared/pipes/web-ui-href.pipe';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TeaserButtonsComponent,
    NgIf,
    SearchComponent,
    TeaserSwiperComponent,
    InlineAlertComponent,
    ReplayInfoComponent,
    BroadcastSwiperComponent,
    StaralertSwiperComponent,
    HeartbeatSwiperComponent,
    RecommendationSwiperComponent,
    SerieSwiperComponent,
    NgFor,
    CommunitySwiperComponent,
    StationSwiperComponent,
    AsyncPipe,
    TranslateModule,
    WebUiHrefPipe
  ]
})
export class HomeComponent implements OnInit {
  topTeasersParams!: TeaserParams;
  communityParams!: CommunityRecordParams;
  tippsParams!: EpgParams;
  topicTeasersParams!: TeaserParams;
  topicSecondTeasersParams!: TeaserParams;
  topButtonsTeaserParams!: TeaserParams;
  seriesParams!: SerieParams;
  broadcastSelections$!: Observable<Selection<EpgParams>[]>;
  communitySelections$!: Observable<Selection<CommunityRecordParams>[]>;
  genreTeasersParams!: TeaserParams;
  staralertListParamsTags!: StarAlertListParams;
  staralertListParamsName!: StarAlertListParams;
  starAlertsTagsReloaded$!: Observable<boolean>;
  starAlertsNameReloaded$!: Observable<boolean>;

  protected readonly StarAlertType = StarAlertType;

  readonly isAuthenticated = this.authenticationService.isAuthenticated;
  readonly isPlusUser = this.isAuthenticated && this.authenticationService.user.isPlusUser;

  constructor(
    private authenticationService: AuthenticationService,
    private deviceDetector: DeviceDetectorService,
    private languageService: LanguageService,
    private metaService: MetaService,
    private selectionApiService: SelectionApiService,
    private starAlertService: StarAlertService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    // TOP TEASER
    let topTeaserTag = 'tv_topteaser';
    let topicTeaserTag = 'tv_teaser_topics';
    let genreTeaserTag = 'tv_teaser_genres';
    let channelsTeaserTag = 'tv_teaser_channels';
    const buttonsTeaserTag = 'webtv_shop_cta';
    if (this.deviceDetector.deviceType.toLowerCase() === 'mobile') {
      topTeaserTag = 'mobile_topteaser';
      topicTeaserTag = 'mobile_teaser_topics';
      genreTeaserTag = 'mobile_teaser_genre';
      channelsTeaserTag = 'mobile_teaser_channels';
    }
    this.topTeasersParams = new TeaserParams(TeaserDevice.WEB, this.languageService.getLanguage())
      .setTags([topTeaserTag])
      .setAboLevel(this.authenticationService.user?.aboLevel || AboLevel.GUEST);

    // COMMUNITY
    this.communityParams = new CommunityRecordParams().setGenre(1);

    // TIPPS
    this.tippsParams = new EpgParams()
      .setReplayRange()
      .tipsOnly()
      .setSort(EpgSort.TIME_DESC)
      .setLocale(this.languageService.getLanguage());

    // TOPIC TEASERS
    this.topicTeasersParams = new TeaserParams(TeaserDevice.WEB, this.languageService.getLanguage())
      .setTags([topicTeaserTag])
      .setAboLevel(this.authenticationService.user?.aboLevel || AboLevel.GUEST);

    // SERIES
    this.seriesParams = new SerieParams()
      .setGenre(4)
      .setLanguage(this.languageService.getLanguage())
      .setSort(SerieSort.SCORE);

    // BROADCAST SELECTIONS
    const broadcastSelectionParams = new SelectionParams(SelectionSource.BROADCAST).setTag('home');
    this.broadcastSelections$ = this.selectionApiService
      .getSelections<EpgParams>(broadcastSelectionParams)
      .pipe(map((data) => data.items));

    // COMMUNITY
    const communitySelectionParams = new SelectionParams(SelectionSource.COMMUNITY).setTag('home');
    this.communitySelections$ = this.selectionApiService
      .getSelections<CommunityRecordParams>(communitySelectionParams)
      .pipe(map((data) => data.items));

    // GENRE TEASERS
    this.genreTeasersParams = new TeaserParams(TeaserDevice.WEB, this.languageService.getLanguage())
      .setTags([genreTeaserTag])
      .setAboLevel(this.authenticationService.user?.aboLevel || AboLevel.GUEST);

    // TOPIC SECOND TEASERS
    this.topicSecondTeasersParams = new TeaserParams(TeaserDevice.WEB, this.languageService.getLanguage())
      .setTags([channelsTeaserTag])
      .setAboLevel(this.authenticationService.user?.aboLevel || AboLevel.GUEST);

    // BUTTONS teaser
    this.topButtonsTeaserParams = new TeaserParams(TeaserDevice.WEB, this.languageService.getLanguage())
      .setTags([buttonsTeaserTag])
      .setAboLevel(this.authenticationService.user?.aboLevel || AboLevel.GUEST);

    // STARALERTS Tags
    this.staralertListParamsTags = new StarAlertListParams().setType(StarAlertType.TAGS);

    // STARALERTS Name
    this.staralertListParamsName = new StarAlertListParams().setType(StarAlertType.NAME);

    this.starAlertsTagsReloaded$ = this.starAlertService.starAlertListReloaded$(StarAlertType.TAGS);
    this.starAlertsNameReloaded$ = this.starAlertService.starAlertListReloaded$(StarAlertType.NAME);

    this.translateService.get('home.title').subscribe((title) => this.metaService.setTitle([title]));
    this.translateService
      .get('home.description')
      .subscribe((description) => this.metaService.setDescription(description));
  }
}
