import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  numberAttribute,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';
import { Recording } from '@teleboy/web.epg/lib/models/recording.model';
import {
  GenreLabelPipe,
  RecordingApiService,
  RecordingBatchDeleteService,
  RecordingFilterService,
  RecordingParams,
  RecordingSort,
  RecordingSortDirection,
  RecordingType,
  SearchPrefix
} from '@teleboy/web.epg';
import {
  catchError,
  debounceTime,
  EMPTY,
  filter,
  finalize,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import { ApiListData } from '@teleboy/web.core';
import { ToggleablePageType, ViewTogglerService, ViewToggleType } from '../../../../epg/services/view-toggler.service';
import { PopupService } from '../../../../core/services/popup.service';
import { DropdownOption } from '../../../../shared/components/dropdown/dropdown.component';
import { FormControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SnackbarService, SnackbarType } from '../../../../core/services/snackbar.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SharedModule } from '../../../../shared/shared.module';
import { AsyncPipe, NgClass } from '@angular/common';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BroadcastItemComponent } from '../../../../epg/components/broadcast-item/broadcast-item.component';
import { PvrEmptyListComponent } from '../../mics/pvr-empty-list/pvr-empty-list.component';
import { RecordingBatchActionComponent } from '../../mics/recording-batch-action/recording-batch-action.component';
import { RecordingBatchTogglerComponent } from '../../mics/recording-batch-toggler/recording-batch-toggler.component';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-pvr-recording-list',
  templateUrl: './pvr-recording-list.component.html',
  styleUrls: ['pvr-recording-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    SharedModule,
    GenreLabelPipe,
    ReactiveFormsModule,
    AsyncPipe,
    NgClass,
    InfiniteScrollDirective,
    BroadcastItemComponent,
    PvrEmptyListComponent,
    RecordingBatchActionComponent,
    RecordingBatchTogglerComponent,
    IconComponent
  ],
  standalone: true
})
export class PvrRecordingListComponent implements OnInit, OnDestroy {
  pagetype: 'ready' | 'planned' | 'search' = 'ready';
  readonly form = this.formBuilder.group({});
  readonly listingType = signal<ViewToggleType>(ViewToggleType.TILES);

  /**
   * Recordings listed on the page
   */
  readonly recordings: WritableSignal<Recording[]> = signal([]);

  /**
   * Total number of recordings. Written after each pagination and updated after deleting one or more recordings
   */
  readonly totalRecordings: WritableSignal<number> = signal(0);

  /**
   * The current sort direction, date asc or desc
   */
  readonly sortDirection: WritableSignal<RecordingSortDirection>;

  /**
   * Listing states
   */
  readonly isLoading = signal(true);
  readonly isPaginating = signal(false);
  readonly isDeleting = signal(false);
  readonly isBusy = computed(() => this.isLoading() || this.isPaginating() || this.isDeleting());

  /**
   * Optional genre id given from query parameters
   */
  readonly genreId = input<number, string>(0, { transform: numberAttribute });

  /**
   * Optional pageTitle given from query parameters
   */
  readonly pageTitle = input<string>('');

  /**
   * Optional search title given from query parameters
   */
  readonly title = input<string>();

  /**
   * Optional recordingType given from query parameters
   */
  readonly recordingType = input<RecordingType>(RecordingType.READY);

  readonly showSearchPrefixDropdown = computed(() => !this.title());

  /**
   * Optional force sort direction to asc, given from query parameters
   */
  readonly asc = input<boolean>();

  /**
   * Empty array which defines the number of ghost previews shown while busy
   */
  readonly ghostPreviews: unknown[] = new Array(15);

  readonly searchOptions: DropdownOption[] = [
    {
      label: this.translateService.instant('pvr.list.filter.text.query'),
      value: SearchPrefix.QUERY
    },
    {
      label: this.translateService.instant('pvr.list.filter.text.title'),
      value: SearchPrefix.TITLE
    }
  ];

  readonly sortDateOptions: DropdownOption[] = [
    {
      label: this.translateService.instant('pvr.list.filter.sort.desc'),
      value: RecordingSortDirection.DESC
    },
    {
      label: this.translateService.instant('pvr.list.filter.sort.asc'),
      value: RecordingSortDirection.ASC
    }
  ];

  /**
   * The number of deleted recordings.
   * Used to paginate the list after the user deleted 8 recordings
   */
  private readonly deleteCounter: WritableSignal<number> = signal(0);

  private readonly destroy$: Subject<void> = new Subject<void>();

  protected readonly ListingType = ViewToggleType;

  /**
   * The number of recordings to load and display per page
   */
  private readonly PAGINATION_LIMIT = 20;

  private readonly params = new RecordingParams();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public popupService: PopupService,
    public recordingBatchDeleteService: RecordingBatchDeleteService,
    private recordingApiService: RecordingApiService,
    private recordingFilterService: RecordingFilterService,
    private router: Router,
    private snackbarService: SnackbarService,
    private translateService: TranslateService,
    private viewTogglerService: ViewTogglerService
  ) {
    this.sortDirection = signal(this.recordingFilterService.getRecordingSortDirection());

    effect(() => this.recordingFilterService.storeRecordingSortDirection(this.sortDirection()));

    effect(() => {
      if (this.deleteCounter() > 0 && this.deleteCounter() >= 8) {
        this.paginate();
        this.deleteCounter.set(0);
      }
    });

    effect(() => {
      if (this.recordingBatchDeleteService.enabled()) {
        this.form.disable({ onlySelf: true, emitEvent: false });
      } else {
        this.form.enable({ onlySelf: true, emitEvent: false });
      }
    });
  }

  ngOnInit(): void {
    this.listingType.set(this.viewTogglerService.setListingType(ToggleablePageType.RecordingsPage));

    this.setupFilterValues();
    this.subscribeToFilterChange();

    if (this.title()) {
      this.pagetype = 'search';
    } else if (this.recordingType() === RecordingType.PLANNED) {
      this.pagetype = 'planned';
    }

    if (this.asc()) {
      this.sortDirection.set(RecordingSortDirection.ASC);
    }

    this.setParams();

    this.getRecordings$().subscribe();
  }

  ngOnDestroy(): void {
    this.recordingBatchDeleteService.disableBatchDelete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  paginate(): void {
    if (this.params.canPaginate(this.totalRecordings())) {
      this.isPaginating.set(true);
      this.params.paginate();
    }
  }

  openGenregroupList(): void {
    void this.popupService.close('details').then(() => {
      void this.router.navigate([{ outlets: { ['landingpage']: 'pvr/genregroups' } }], {
        skipLocationChange: true
      });
    });
  }

  batchDeleteRecordings(): void {
    if (confirm(this.translateService.instant('pvr.list.filter.batchDelete.confirm'))) {
      this.isDeleting.set(true);

      this.recordingBatchDeleteService
        .delete$()
        .pipe(finalize(() => this.isDeleting.set(false)))
        .subscribe((broadcastIds: number[]) => {
          this.afterRecordingsDelete(broadcastIds);

          this.recordingBatchDeleteService.disableBatchDelete();
        });
    }
  }

  onRecordingDeleted(broadcastId: number): void {
    this.afterRecordingsDelete([broadcastId]);
  }

  toggleListingType(): void {
    this.listingType.set(this.viewTogglerService.toggleListingType(ToggleablePageType.RecordingsPage));
  }

  private afterRecordingsDelete(broadcastIds: number[]): void {
    this.recordings.update((recordings: Recording[]) => {
      return recordings.filter((recording: Recording) => !broadcastIds.includes(recording.id));
    });

    this.params.reduceSkip(broadcastIds.length);

    this.deleteCounter.update((num: number) => num + broadcastIds.length);
    this.totalRecordings.update((total: number) => total - broadcastIds.length);
  }

  private setParams(): void {
    this.params.setLimit(this.PAGINATION_LIMIT).setSort(RecordingSort.DATE).sortDirection(this.sortDirection());

    if (this.genreId()) {
      this.params.setGenre(this.genreId());
    }

    const title = this.title();
    if (title) {
      this.params.setTitle(title);
    }

    this.params.setType(this.recordingType() ?? RecordingType.READY);
  }

  private getRecordings$(): Observable<Recording[]> {
    return this.params.skip$.pipe(
      switchMap(() => {
        return this.recordingApiService
          .query(this.params)
          .pipe(catchError(() => this.handleRecordingsResponseError$()));
      }),
      tap((data: ApiListData<Recording>) => this.totalRecordings.set(data.total)),
      map((data: ApiListData<Recording>) => data.items),
      tap((nextRecordings: Recording[]) => {
        this.recordings.update((recordings) =>
          this.params.skip === 0 ? nextRecordings : [...recordings, ...nextRecordings]
        );
      }),
      tap(() => {
        this.isLoading.set(false);
        this.isPaginating.set(false);
      }),
      takeUntil(this.destroy$)
    );
  }

  private handleRecordingsResponseError$(): Observable<never> {
    this.isLoading.set(false);
    this.isPaginating.set(false);
    this.snackbarService.openSnackbar('pvr.list.error.loading', SnackbarType.ERROR);

    return EMPTY;
  }

  private setupFilterValues() {
    const preselectedSortOption = this.sortDateOptions.find(
      (option) => option.value === this.recordingFilterService.getRecordingSortDirection()
    );

    this.form.addControl('dateSort', new UntypedFormControl(preselectedSortOption));

    if (!this.title()) {
      this.form.addControl('searchPrefix', new UntypedFormControl(SearchPrefix.QUERY));
    }

    this.form.addControl('searchText', new FormControl(''));
  }

  private subscribeToFilterChange() {
    this.form
      .get('dateSort')
      ?.valueChanges.pipe(
        filter(() => !!this.recordings().length),
        takeUntil(this.destroy$)
      )
      .subscribe((sortDirection: RecordingSortDirection) => {
        this.isLoading.set(true);
        this.sortDirection.set(sortDirection);
        this.params.sortDirection(sortDirection).resetSkip();
      });

    if (!this.title()) {
      this.form
        .get('searchPrefix')
        ?.valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          const searchText = this.form.get('searchText')?.value;

          if (!searchText?.length || searchText?.length < 2) {
            return;
          }
          this.isLoading.set(true);
          this.form.get('searchText')?.patchValue(searchText, { emitEvent: true });
        });
    }
    this.form
      .get('searchText')
      ?.valueChanges.pipe(
        debounceTime(700),
        filter((text) => text.length >= 2 || text.length === 0),
        takeUntil(this.destroy$)
      )
      .subscribe((text: string) => {
        this.isLoading.set(true);
        if (!this.showSearchPrefixDropdown()) {
          this.params.setQuery(text).resetSkip();
          return;
        }

        if (this.form.get('searchPrefix')?.value === SearchPrefix.TITLE) {
          this.params.setQuery('').setTitle(text).resetSkip();
        } else {
          this.params.setTitle('').setQuery(text).resetSkip();
        }
      });
  }
}
