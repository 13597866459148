import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { filter, Observable, tap } from 'rxjs';
import { Alert, AlertService } from '@teleboy/web.user';
import { AlertItemComponent } from '@teleboy/web.ui';
import { AsyncPipe } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-inline-alert',
  imports: [AlertItemComponent, AsyncPipe, TranslateModule],
  templateUrl: './inline-alert.component.html',
  styleUrl: './inline-alert.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineAlertComponent implements OnInit {
  alert$!: Observable<Alert | null>;
  detailHref!: string;

  constructor(private alertService: AlertService) {}

  ngOnInit(): void {
    this.alert$ = this.alertService.getInlineAlert$().pipe(
      filter((alert: Alert | null): alert is Alert => !!alert),
      tap((alert: Alert) => {
        const url = new URL(`${environment.cockpit.url}/notifications/alerts/${alert.id}`);
        this.detailHref = url.href;
      })
    );
  }
}
