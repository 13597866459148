import { Pipe, PipeTransform } from '@angular/core';
import { HrefService, HrefTarget } from '../services/href.service';

@Pipe({
  name: 'cockpitHref',
  standalone: false
})
export class CockpitHrefPipe implements PipeTransform {
  constructor(private hrefService: HrefService) {}

  transform(path: string): string {
    return this.hrefService.getHref(path, { appendLocale: false }, HrefTarget.COCKPIT);
  }
}
