import { ChangeDetectionStrategy, Component, Inject, Input, NgZone } from '@angular/core';
import { ListSwiperDirective } from '../../../directives/swiper/list-swiper.directive';
import { Broadcast, RecordingApiService, RecordingParams } from '@teleboy/web.epg';
import { ApiListResponse } from '@teleboy/web.core';
import { Observable } from 'rxjs';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { BroadcastItemComponent } from '../../broadcast-item/broadcast-item.component';

@Component({
  selector: 'app-recording-swiper',
  templateUrl: './recording-swiper.component.html',
  styleUrls: ['../swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, SwiperModule, NgFor, BroadcastItemComponent, AsyncPipe]
})
export class RecordingSwiperComponent extends ListSwiperDirective<Broadcast> {
  @Input() override params!: RecordingParams;

  constructor(
    @Inject(NgZone) ngZone: NgZone,
    private recordingApiService: RecordingApiService
  ) {
    super(ngZone);
  }

  itemLoader$(): Observable<ApiListResponse['data']> {
    return this.recordingApiService.queryReady(this.params);
  }
}
