import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { StreamIdlingComponent } from '../components/stream-idling/stream-idling.component';
import { IdlingConfirmType } from '@teleboy/web.user';

@Injectable({
  providedIn: 'root'
})
export class StreamIdlingService {
  constructor(private matDialog: MatDialog) {}

  openIdlingDialog$(): Observable<IdlingConfirmType> {
    return this.matDialog
      .open(StreamIdlingComponent)
      .afterClosed()
      .pipe(
        switchMap((idlingConfirmType: IdlingConfirmType) => {
          if (idlingConfirmType === IdlingConfirmType.ABORT) {
            return EMPTY;
          }

          return of(idlingConfirmType);
        })
      );
  }
}
