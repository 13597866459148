import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  private readonly SEPARATOR = '•';
  private readonly SUFFIX = 'Teleboy';

  constructor(
    private meta: Meta,
    private title: Title
  ) {}

  setTitle(newTitle: string[]): void {
    newTitle.push(this.SUFFIX);
    this.title.setTitle(newTitle.join(` ${this.SEPARATOR} `));
  }

  setDescription(metaDescription: string): void {
    this.meta.updateTag({ name: 'description', content: metaDescription });
  }
}
