import { inject } from '@angular/core';
import { PlayStationPolicy } from '@teleboy/web.epg';
import { catchError, Observable, of, throwError } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { BitmovinService } from '@teleboy/web.player';
import { ErrorCode, TeleboyError } from '@teleboy/web.core';
import { UpsellService } from '../../core/services/upsell.service';

export const liveGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const bitmovinService = inject(BitmovinService);
  const offset = Math.abs(parseInt(route.queryParams['position'], 10)) || 0;
  const playStationPolicy = inject(PlayStationPolicy);
  const upsellService = inject(UpsellService);

  return playStationPolicy.isGranted(offset).pipe(catchError((error: TeleboyError) => handleError$(error)));

  function handleError$(error: TeleboyError): Observable<boolean> {
    if (bitmovinService.player && bitmovinService.isFullscreen()) {
      bitmovinService.fullscreenToggle();
    }

    switch (error.code) {
      case ErrorCode.USER_AUTHENTICATION_REQUIRED:
      case ErrorCode.USER_PLUS_SUBSCRIPTION_REQUIRED:
        upsellService.openAboUpsell();
        break;
      case ErrorCode.REPLAY_NOT_ACTIVATED:
        upsellService.openReplayActivationUpsell();
        break;
      case ErrorCode.REPLAY_TOO_RECENT:
        upsellService.openReplayTooRecentUpsell();
        break;
      default:
        return throwError(() => error);
    }

    return of(false);
  }
};
