import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Language } from '@teleboy/web.core';
import { LanguageService } from '@teleboy/web.user';
import { DOCUMENT, PlatformLocation } from '@angular/common';

export const HrefTarget = {
  WEBUI: environment.webui.url,
  COCKPIT: environment.cockpit.url
};

export interface WebUiHrefOptions {
  appendLocale?: boolean;
  appendTarget?: boolean;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  queryParams?: any;
  useBlankTarget?: boolean;
}

const translatedRoutes = new Map().set('/sender', {
  de: '/sender',
  fr: '/chaines',
  it: '/sender',
  en: '/sender'
});

@Injectable({
  providedIn: 'root'
})
export class HrefService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private languageService: LanguageService,
    private platformLocation: PlatformLocation
  ) {}

  getHref(path: string, options: WebUiHrefOptions = {}, target = HrefTarget.WEBUI): string {
    const route = [target];

    options = { ...{ appendTarget: false, appendLocale: true, queryParams: {}, useBlankTarget: false }, ...options };

    if (options.appendLocale && this.languageService.getLanguage() !== Language.DE) {
      route.push(this.languageService.getLanguage());
    }

    if (translatedRoutes.has(path)) {
      path = translatedRoutes.get(path)[this.languageService.getLanguage()];
    }

    route.push(path.substring(1));

    const url = route.join('/');
    const queryParams = Object.keys(options.queryParams).map((key) => `${key}=${options.queryParams[key]}`);

    if (options.appendTarget) {
      const target = encodeURIComponent(this.platformLocation.href);
      queryParams.push(`target=${target}`);
    }

    const queryString = queryParams.join('&');

    if (queryString) {
      return [url, queryString].join('?');
    }

    return url;
  }

  goToUrl(path: string, options: WebUiHrefOptions = {}): void {
    const href = this.getHref(path, options);
    if (options.useBlankTarget) {
      this.document.defaultView?.open(href);
    } else {
      this.document.location.href = href;
    }
  }
}
