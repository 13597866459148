import { Injectable } from '@angular/core';
import { map, merge, Observable, shareReplay, takeWhile } from 'rxjs';
import { LiveEpgService } from '@teleboy/web.epg';
import { BitmovinService, BroadcastStreamInfoService, StreamEpg } from '@teleboy/web.player';
import { AuthenticationService } from '@teleboy/web.user';

@Injectable({
  providedIn: 'root'
})
export class EpgService {
  playingBroadcastId$: Observable<number>;

  constructor(
    private authenticationService: AuthenticationService,
    private bitmovinService: BitmovinService,
    private broadcastStreamInfoService: BroadcastStreamInfoService,
    private liveEpgService: LiveEpgService
  ) {
    this.playingBroadcastId$ = this._playingBroadcastId$();
    this.subscribeToUserProfileChange();
  }

  private _playingBroadcastId$(): Observable<number> {
    return merge(
      this.bitmovinService.destroy$.pipe(map(() => 0)),
      this.broadcastStreamInfoService.epg$.pipe(
        takeWhile((epg: StreamEpg) => !!epg),
        map((epg) => epg.current.id)
      )
    ).pipe(shareReplay(1));
  }

  private subscribeToUserProfileChange() {
    this.authenticationService.user$.subscribe(() => {
      this.liveEpgService.listingTtl = 0;
    });
  }
}
