<div class="tabbar">
  <div class="tabbar__left">
    <h1 class="collapse tabbar__title">{{ 'home.title' | translate }}</h1>
  </div>

  <div class="tabbar__right">

    <app-teaser-buttons [params]="topButtonsTeaserParams"></app-teaser-buttons>

    <a *ngIf="!isAuthenticated" [href]="'/login' | webUiHref: {appendTarget: true}" class="btn no-shrink">
      {{ 'home.nav.login' | translate }}
    </a>

    <app-search></app-search>
  </div>
</div>

<div class="grid grid__sliders">
  <!-- TOP TEASERS -->
  <app-teaser-swiper [params]="topTeasersParams" [topTeaser]="true"></app-teaser-swiper>

  <!-- INLINE USER ALERT -->
  @if (isAuthenticated) {
    <app-inline-alert></app-inline-alert>
  }

  <!-- REPLAY INFO -->
  <app-replay-info></app-replay-info>

  <!-- TIPPS -->
  <app-broadcast-swiper [params]="tippsParams"
                        [headline]="'home.slider.tipps.title' | translate"></app-broadcast-swiper>

  <ng-container *ngIf="isAuthenticated">
    <app-staralert-swiper *ngIf="!(starAlertsTagsReloaded$ | async)"
                          [params]="staralertListParamsTags"
                          [headline]="'home.slider.tags.title' | translate">
    </app-staralert-swiper>
  </ng-container>

  <!-- HEARTBEAT -->
  <app-heartbeat-swiper *ngIf="isAuthenticated"
                      [headline]="'home.slider.heartbeats.title' | translate"></app-heartbeat-swiper>

  <!-- TOPICS -->
  <app-teaser-swiper [params]="topicTeasersParams"
                   [headline]="'home.slider.topicTeasers.title' | translate"></app-teaser-swiper>

  <!-- RECOMMENDATIONS -->
  <app-recommendation-swiper *ngIf="isPlusUser"
                           [headline]="'home.slider.recommendations.title' | translate"></app-recommendation-swiper>

  <!-- SERIES -->
  <app-serie-swiper [params]="seriesParams" [headline]="'home.slider.series.title' | translate"></app-serie-swiper>

  <!-- COMMUNITY SELECTIONS -->
  <ng-container *ngFor="let communitySelection of communitySelections$ | async">
    <app-community-swiper [headline]="communitySelection.title"
                          [params]="communitySelection.params"></app-community-swiper>
  </ng-container>

  <!-- STATIONS -->
  <app-station-swiper [headline]="'home.slider.stations.title' | translate"></app-station-swiper>

  <!-- GENRES -->
  <app-teaser-swiper [params]="genreTeasersParams"
                     [headline]="'home.slider.genreTeasers.title' | translate"></app-teaser-swiper>

  <ng-container *ngIf="isAuthenticated">
    <app-staralert-swiper *ngIf="!(starAlertsNameReloaded$ | async)"
                          [params]="staralertListParamsName"
                          [headline]="'home.slider.favourites.title' | translate">
    </app-staralert-swiper>
  </ng-container>

  <!-- BROADCAST SELECTIONS -->
  <ng-container *ngFor="let broadcastSelection of broadcastSelections$ | async">
    <app-broadcast-swiper [headline]="broadcastSelection.title"
                          [params]="broadcastSelection.params"></app-broadcast-swiper>
  </ng-container>

  <!-- TOPICS SECOND -->
  <app-teaser-swiper [params]="topicSecondTeasersParams"
                     [headline]="'home.slider.topicSecondTeasers.title' | translate"></app-teaser-swiper>

</div>
