<app-popup outlet="person" [full]="true">

  @if (isAuthenticated) {
    <ng-container buttons>
      @if (personSearchQuery$ | async; as words) {
        <app-star-alert-button
          [words]="words" [type]="StarAlertType.NAME">
        </app-star-alert-button>
      }
    </ng-container>
  }

  <div class="search__title">
    <h1>{{personSearchQuery$ | async}}</h1>
  </div>


  @if (results$ | async; as results) {
    @if (!(isLoading$ | async)) {
      <div class="search__results">
        @if (results) {
          @if (results['replay'] && results['replay'].items.length) {
            <div>
              <app-broadcasts-swiper
                [broadcasts]="results['replay'].items"
                [headline]="'search.results.replay.title' | translate"
                [searchValue]="this.personSearchQuery$.getValue()"
                [searchSource]="SearchSource.REPLAY"
                [searchQueryMode]="SearchPrefix.NAME"
              ></app-broadcasts-swiper>
            </div>
          }
          @if (results['recordings'] && results['recordings'].items.length) {
            <div>
              <app-broadcasts-swiper
                [broadcasts]="results['recordings'].items"
                [headline]="'search.results.recordings.title' | translate"
                [searchValue]="this.personSearchQuery$.getValue()"
                [searchSource]="SearchSource.PVR"
                [searchQueryMode]="SearchPrefix.NAME"
              ></app-broadcasts-swiper>
            </div>
          }
          @if (results['community_record'] && results['community_record'].items.length) {
            <div>
              <app-broadcasts-swiper
                [broadcasts]="results['community_record'].items"
                [headline]="'search.results.community_record.title' | translate"
                [searchValue]="this.personSearchQuery$.getValue()"
                [searchSource]="SearchSource.COMMUNITY"
                [searchQueryMode]="SearchPrefix.NAME"
              ></app-broadcasts-swiper>
            </div>
          }
          @if (results['epg'] && results['epg'].items.length) {
            <div>
              <app-broadcasts-swiper
                [broadcasts]="results['epg'].items"
                [headline]="'search.results.epg.title' | translate"
                [searchValue]="this.personSearchQuery$.getValue()"
                [searchSource]="SearchSource.EPG"
                [searchQueryMode]="SearchPrefix.NAME"
              ></app-broadcasts-swiper>
            </div>
          }
        } @else {
          <ng-container *ngTemplateOutlet="loading"></ng-container>
        }
      </div>
    } @else {
      <ng-container *ngTemplateOutlet="loading"></ng-container>
    }
  } @else {
    <ng-container *ngTemplateOutlet="loading"></ng-container>
  }

</app-popup>

<ng-template #loading>
  @for (source of [SearchSource.REPLAY, SearchSource.PVR, SearchSource.COMMUNITY, SearchSource.EPG]; track source) {
    <div>
      <h2>{{ ('search.results.' + source + '.title') | translate }}</h2>
      <swiper [slidesPerView]="swiperOptions.slidesPerView" [breakpoints]="swiperOptions.breakpoints"
              class="ghost-listing ghost-listing--horizontal">
        @for (ghost of ghosts; track $index) {
          <ng-template swiperSlide>
            <app-broadcast-item [card]="true"></app-broadcast-item>
          </ng-template>
        }
      </swiper>
      <span>&nbsp;</span>
    </div>
  }
</ng-template>



