import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService, LanguageService } from '@teleboy/web.user';
import { Helper } from '@teleboy/web.core';
import { SnackbarService, SnackbarType } from '../../../core/services/snackbar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DropdownOption } from '../dropdown/dropdown.component';

@Component({
  selector: 'app-login',
  templateUrl: './anonymous-auth.component.html',
  styleUrls: ['./anonymous-auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AnonymousAuthComponent {
  readonly ageOptions: DropdownOption[] = [
    { label: this.translateService.instant('anonymousAuth.form.age.label'), value: '' },
    { label: '0-16', value: Helper.getRandomNumberInRange(0, 16) },
    { label: '17-20', value: Helper.getRandomNumberInRange(17, 20) },
    { label: '21-40', value: Helper.getRandomNumberInRange(21, 40) },
    { label: '41-65', value: Helper.getRandomNumberInRange(41, 65) },
    {
      label: this.translateService.instant('anonymousAuth.form.age.65+'),
      value: Helper.getRandomNumberInRange(65, 100)
    }
  ];
  readonly form = new UntypedFormGroup({
    gender: new UntypedFormControl(null, Validators.required),
    age: new UntypedFormControl(null, [Validators.required])
  });

  constructor(
    private authenticationService: AuthenticationService,
    private dialogRef: MatDialogRef<AnonymousAuthComponent>,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private snackbarService: SnackbarService
  ) {}

  onSubmit(): void {
    if (this.form.invalid) {
      this.snackbarService.openSnackbar('anonymousAuth.form.failed', SnackbarType.ERROR);
      return;
    }

    const formGender = this.form.get('gender')?.value;
    const formAge = this.form.get('age')?.value;
    this.authenticationService.authenticateAnonymously(this.languageService.getLanguage(), formGender, formAge, true);

    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close();
  }
}
