import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { redirectGuard } from '../core/guards/redirect.guard';
import { NavigationLayoutComponent } from './components/layout/navigation-layout/navigation-layout.component';
import { environment } from '../../environments/environment';

const routes: Routes = [
  {
    path: 'tvshop',
    canActivate: [redirectGuard],
    component: NavigationLayoutComponent,
    data: {
      externalUrl: `${environment.webui.url}/tv`
    }
  },
  {
    path: 'login',
    canActivate: [redirectGuard],
    component: NavigationLayoutComponent,
    data: {
      externalUrl: `${environment.webui.url}/login`
    }
  },
  {
    path: 'register',
    canActivate: [redirectGuard],
    component: NavigationLayoutComponent,
    data: {
      externalUrl: `${environment.webui.url}/registrieren`
    }
  },
  {
    path: 'voucher',
    canActivate: [redirectGuard],
    component: NavigationLayoutComponent,
    data: {
      externalUrl: `${environment.webui.url}/voucher?identifier=${environment.voucher.one}`
    }
  },
  {
    path: 'cockpit/profile',
    canActivate: [redirectGuard],
    component: NavigationLayoutComponent,
    data: {
      externalUrl: `${environment.cockpit.url}/profile`
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule {}
