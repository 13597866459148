import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, switchMap, takeUntil, tap, timer } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { PlayerService } from '../../services/player.service';
import { SharedModule } from '../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { EventTrackingService } from '@teleboy/web.core';
import { IdlingConfirmType, IdlingService } from '@teleboy/web.user';

@Component({
  selector: 'app-stream-idling',
  imports: [SharedModule, TranslateModule],
  templateUrl: './stream-idling.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IdlingService]
})
export class StreamIdlingComponent implements OnInit, OnDestroy {
  maxIdlingMinutes!: number;

  private readonly destroy$: Subject<void> = new Subject<void>();
  private readonly ABORT_TIMEOUT_DUE = 2 * 60 * 1000;

  constructor(
    private eventTrackingService: EventTrackingService,
    private idlingService: IdlingService,
    private matDialogRef: MatDialogRef<StreamIdlingComponent>,
    private playerService: PlayerService
  ) {}

  ngOnInit() {
    this.maxIdlingMinutes = this.idlingService.getMaxIdlingMinutes();
    this.eventTrackingService.trackEvent('player.idling.open');

    this.abortTimer$().subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  confirm(): void {
    this.eventTrackingService.trackEvent('player.idling.confirm');
    this.matDialogRef.close(IdlingConfirmType.CONFIRM);
  }

  pause(): void {
    this.eventTrackingService.trackEvent('player.idling.pause');
    this.matDialogRef.close(IdlingConfirmType.PAUSE);
  }

  abort(): void {
    this.eventTrackingService.trackEvent('player.idling.abort');
    this.matDialogRef.close(IdlingConfirmType.ABORT);
  }

  private abortTimer$(): Observable<unknown> {
    return timer(this.ABORT_TIMEOUT_DUE).pipe(
      tap(() => this.abort()),
      switchMap(() => this.playerService.closePlayer()),
      takeUntil(this.destroy$)
    );
  }
}
