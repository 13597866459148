import { Injectable } from '@angular/core';
import { Upsell, UpsellCta } from '../../interfaces/upsell.interface';
import { PopupService } from '../popup.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProfileUpdateUpsellService implements Upsell {
  readonly name = 'profileUpdate';
  readonly icon = 'navi-user';
  readonly cta: UpsellCta[];

  constructor(
    private popupService: PopupService,
    private router: Router
  ) {
    this.cta = this._cta();
  }

  private _cta(): UpsellCta[] {
    return [
      {
        key: 'close',
        click: () => this.popupService.closeModal()
      },
      {
        key: 'update',
        click: () => this.goToCockpit()
      }
    ];
  }

  private goToCockpit(): void {
    void this.popupService.close().then(() => {
      void this.router.navigate(['/cockpit/profile']);
    });
  }
}
