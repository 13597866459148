import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { StationLogoSize } from '@teleboy/web.epg';
import { BroadcastStreamInfoService, StreamEpg } from '@teleboy/web.player';
import { AuthenticationService } from '@teleboy/web.user';
import { BroadcastActionService } from '../../../epg/services/broadcast-action.service';

@Component({
  selector: 'app-player-broadcast',
  templateUrl: './player-broadcast.component.html',
  styleUrls: ['./player-broadcast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PlayerBroadcastComponent {
  readonly epg$: Observable<StreamEpg> = this.broadcastStreamInfoService.epg$;

  readonly StationLogoSize = StationLogoSize;

  @Input() listingClosed = true;
  @Input() muted = false;
  @Input() jumpseekEnabled = this.authenticationService.user?.isPlusUser;

  constructor(
    private authenticationService: AuthenticationService,
    private broadcastActionService: BroadcastActionService,
    private broadcastStreamInfoService: BroadcastStreamInfoService
  ) {}

  playNextEpisode(epg: StreamEpg): void {
    if (epg.nextEpisode) {
      this.broadcastActionService.handlePlay$(epg.nextEpisode, 0, false);
    }
  }
}
