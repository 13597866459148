import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Upsell } from '../../../core/interfaces/upsell.interface';
import { Observable, of } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upsell-modal',
  templateUrl: './upsell-modal.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class UpsellModalComponent implements OnInit {
  readonly config: Upsell;
  busy$!: Observable<boolean>;

  constructor(
    private dialogRef: MatDialogRef<UpsellModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Upsell
  ) {
    this.config = data;
  }

  ngOnInit(): void {
    this.busy$ = this.config.busy$ ?? of(false);
  }

  close() {
    this.dialogRef.close();
  }
}
