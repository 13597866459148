"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Label = void 0;
var component_1 = require("./component");
var dom_1 = require("../dom");
var eventdispatcher_1 = require("../eventdispatcher");
var i18n_1 = require("../localization/i18n");
/**
 * A simple text label.
 *
 * DOM example:
 * <code>
 *     <span class='ui-label'>...some text...</span>
 * </code>
 *
 * @category Components
 */
var Label = /** @class */function (_super) {
  __extends(Label, _super);
  function Label(config) {
    if (config === void 0) {
      config = {};
    }
    var _this = _super.call(this, config) || this;
    _this.labelEvents = {
      onClick: new eventdispatcher_1.EventDispatcher(),
      onTextChanged: new eventdispatcher_1.EventDispatcher()
    };
    _this.config = _this.mergeConfig(config, {
      cssClass: 'ui-label'
    }, _this.config);
    _this.text = _this.config.text;
    return _this;
  }
  Label.prototype.toDomElement = function () {
    var _this = this;
    var tagName = this.config.for != null ? 'label' : 'span';
    var labelElement = new dom_1.DOM(tagName, {
      'id': this.config.id,
      'for': this.config.for,
      'class': this.getCssClasses()
    }, this).html(i18n_1.i18n.performLocalization(this.text));
    labelElement.on('click', function () {
      _this.onClickEvent();
    });
    return labelElement;
  };
  /**
   * Set the text on this label.
   * @param text
   */
  Label.prototype.setText = function (text) {
    if (text === this.text) {
      return;
    }
    this.text = text;
    var localizedText = i18n_1.i18n.performLocalization(text);
    this.getDomElement().html(localizedText);
    this.onTextChangedEvent(localizedText);
  };
  /**
   * Gets the text on this label.
   * @return {string} The text on the label
   */
  Label.prototype.getText = function () {
    return i18n_1.i18n.performLocalization(this.text);
  };
  /**
   * Clears the text on this label.
   */
  Label.prototype.clearText = function () {
    this.getDomElement().html('');
    this.onTextChangedEvent(null);
  };
  /**
   * Tests if the label is empty and does not contain any text.
   * @return {boolean} True if the label is empty, else false
   */
  Label.prototype.isEmpty = function () {
    return !this.text;
  };
  /**
   * Fires the {@link #onClick} event.
   * Can be used by subclasses to listen to this event without subscribing an event listener by overwriting the method
   * and calling the super method.
   */
  Label.prototype.onClickEvent = function () {
    this.labelEvents.onClick.dispatch(this);
  };
  /**
   * Fires the {@link #onClick} event.
   * Can be used by subclasses to listen to this event without subscribing an event listener by overwriting the method
   * and calling the super method.
   */
  Label.prototype.onTextChangedEvent = function (text) {
    this.labelEvents.onTextChanged.dispatch(this, text);
  };
  Object.defineProperty(Label.prototype, "onClick", {
    /**
     * Gets the event that is fired when the label is clicked.
     * @returns {Event<Label<LabelConfig>, NoArgs>}
     */
    get: function () {
      return this.labelEvents.onClick.getEvent();
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Label.prototype, "onTextChanged", {
    /**
     * Gets the event that is fired when the text on the label is changed.
     * @returns {Event<Label<LabelConfig>, string>}
     */
    get: function () {
      return this.labelEvents.onTextChanged.getEvent();
    },
    enumerable: false,
    configurable: true
  });
  return Label;
}(component_1.Component);
exports.Label = Label;