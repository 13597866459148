import { APP_INITIALIZER } from '@angular/core';
import SwiperCore, { FreeMode, Mousewheel, Navigation, SwiperOptions, Virtual } from 'swiper';

export const SlideConfig: Record<
  string,
  {
    spaceBetween?: SwiperOptions['spaceBetween'];
    slidesPerView: SwiperOptions['slidesPerView'];
    breakpoints: SwiperOptions['breakpoints'];
  }
> = {
  default: {
    slidesPerView: 1.3,
    breakpoints: {
      480: { slidesPerView: 2.3 },
      896: { slidesPerView: 3.3 },
      1100: { slidesPerView: 4.3 },
      1400: {
        slidesPerView: 5.3,
        freeMode: {
          enabled: true,
          sticky: true
        }
      },
      1920: {
        slidesPerView: 8.3,
        freeMode: {
          enabled: true,
          sticky: true
        }
      }
    }
  },
  teaser: {
    slidesPerView: 2.3,
    breakpoints: {
      480: { slidesPerView: 3.3 },
      896: { slidesPerView: 4.3 },
      1100: { slidesPerView: 5.3 },
      1400: {
        slidesPerView: 5.3,
        freeMode: {
          enabled: true,
          sticky: true
        }
      },
      1920: {
        slidesPerView: 9.3,
        freeMode: {
          enabled: true,
          sticky: true
        }
      }
    }
  },
  station: {
    slidesPerView: 3.3,
    breakpoints: {
      480: { slidesPerView: 4.3 },
      896: { slidesPerView: 5.3 },
      1100: { slidesPerView: 6.3 },
      1400: {
        slidesPerView: 7.3,
        freeMode: {
          enabled: true,
          sticky: true
        }
      },
      1920: {
        slidesPerView: 9.3,
        freeMode: {
          enabled: true,
          sticky: true
        }
      }
    }
  },
  topTeaser: {
    slidesPerView: 1.1,
    breakpoints: {
      480: {
        slidesPerView: 1.1,
        slidesPerGroup: 1
      },
      896: {
        slidesPerView: 1.1,
        slidesPerGroup: 1
      },
      1100: {
        slidesPerView: 1.1,
        slidesPerGroup: 1
      },
      1400: {
        slidesPerView: 1.1,
        slidesPerGroup: 1
      },
      1920: {
        slidesPerView: 1.1,
        slidesPerGroup: 1
      }
    }
  },
  popup: {
    slidesPerView: 1.3,
    breakpoints: {
      480: {
        slidesPerView: 2.3,
        slidesPerGroup: 2
      },
      896: {
        slidesPerView: 3.3,
        slidesPerGroup: 3
      },
      1100: {
        slidesPerView: 3.3,
        freeMode: {
          enabled: true,
          sticky: true
        }
      },
      1400: {
        slidesPerView: 3.3,
        freeMode: {
          enabled: true,
          sticky: true
        }
      },
      1920: {
        slidesPerView: 3.3,
        freeMode: {
          enabled: true,
          sticky: true
        }
      }
    }
  },
  episodes: {
    spaceBetween: 5,
    slidesPerView: 4.3,
    breakpoints: {
      480: { slidesPerView: 5.3 },
      768: { slidesPerView: 6.3 },
      1024: { slidesPerView: 7.3 },
      1440: { slidesPerView: 7.3, spaceBetween: 50 },
      1920: { slidesPerView: 7.3 }
    }
  }
};

export function swiperConfigFactory(): () => boolean {
  return () => {
    SwiperCore.use([Mousewheel, Virtual, FreeMode, Navigation]);
    SwiperCore.extendDefaults({
      spaceBetween: 14,
      freeMode: {
        enabled: true,
        sticky: false
      },
      mousewheel: {
        forceToAxis: true
      },
      virtual: {
        addSlidesBefore: 3,
        addSlidesAfter: 3,
        enabled: true,
        cache: true
      },
      slidesPerView: SlideConfig['default'].slidesPerView,
      breakpoints: SlideConfig['default'].breakpoints
    });

    return true;
  };
}

export const SWIPER_PROVIDER = {
  provide: APP_INITIALIZER,
  useFactory: swiperConfigFactory,
  multi: true
};
