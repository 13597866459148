<a class="preview-new card cursor-pointer preview-new__bd pvr-grid-button text-link no-underline"
   [ngClass]="{ 'preview-new--round': rounded()}">

  @if (icon()) {
    <tbui-icon [iconId]="icon()" [size]="size()"></tbui-icon>
  }

  <p class="pvr-grid-button__label h3">{{ label() }}</p>

  @if (subLabel()) {
    <p class="pvr-grid-button__count font-size-medium">{{ subLabel() }}</p>
  }
</a>
