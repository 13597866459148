@if (broadcast && isVisible) {
  <div class="epg-broadcast"
       [ngClass]="{ 'epg-broadcast--active': isPlaying$ | async,
                  'epg-broadcast--playing': broadcast.flags.is_playable
                }" #broadcastItem>
    <a [appBroadcastDetail]="broadcast">
      <h3 class="h3 collapse ellipsis">{{ broadcast.title }}</h3>
      <div class="epg-broadcast__subtitle ellipsis">
        <span
          [matTooltip]="broadcast | broadcastInfo"
          matTooltipPosition="above"
          matTooltipClass="tb-tooltip">{{ broadcast.begin.format('HH:mm') }}
          <span *ngIf="broadcast.subtitle">&bull;&nbsp;{{ broadcast.subtitle }}</span>
        </span>

        &nbsp;<app-broadcast-icons class="epg-broadcast__flags" [broadcast]="broadcast"></app-broadcast-icons>
      </div>
    </a>
    <div class="epg-broadcast__actions">
      @if (broadcast.flags.isRecorded()) {
        <button class="btn btn__secondary btn__icon btn--circle btn--sm"
                [appBroadcastDeleteRecord]="broadcast">
          <tbui-icon iconId="bin"></tbui-icon>
        </button>
      }

      @if (!broadcast.flags.isRecorded() && broadcast.flags.isRecordable()) {
        <button class="btn btn__secondary btn__icon btn--circle btn--sm"
                [appBroadcastToggleRecord]="broadcast">
          <tbui-icon iconId="record"></tbui-icon>
        </button>
      }

      @if (broadcast.flags.is_playable) {
        <button class="btn btn__secondary btn__icon btn--circle btn--sm" [appBroadcastPlay]="broadcast">
          <tbui-icon iconId="play"></tbui-icon>
        </button>
      }
    </div>
  </div>
} @else {
  <div class="epg-broadcast">
    <a>
      <h3 class="collapse">&nbsp;</h3>
      <div class="epg-broadcast__subtitle">&nbsp;</div>
    </a>
  </div>
}
