import { inject } from '@angular/core';
import { PlayRecordingPolicy } from '@teleboy/web.epg';
import { catchError, from, map, Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { ErrorCode, TeleboyError } from '@teleboy/web.core';
import { UpsellService } from '../../core/services/upsell.service';

export const recordingGuard = (): Observable<boolean> => {
  const playRecordingPolicy = inject(PlayRecordingPolicy);
  const router = inject(Router);
  const upsellService = inject(UpsellService);

  return playRecordingPolicy.isGranted().pipe(catchError((err: TeleboyError) => handleError$(err)));

  function handleError$(err: TeleboyError): Observable<boolean> {
    return from(router.navigateByUrl('/home')).pipe(
      tap(() => {
        if (err.code === ErrorCode.USER_AUTHENTICATION_REQUIRED) {
          upsellService.openLoginUpsell();
        }
      }),
      map(() => false)
    );
  }
};
