<div class="stations-dropdown-container">
  <form>

    <button (click)="onDropdownClick($event)" class="selected-station btn btn__select">
      <tbui-station-logo [station]="station" [size]="StationLogoSize.S"></tbui-station-logo>
      <small class="selected-station-text">{{selectedStation.label}}</small>

      <div>
        <tbui-icon *ngIf="dropdownListShown" iconId="arrow-up" class="dropdown-toggle__arrow-openshow"></tbui-icon>
        <tbui-icon *ngIf="!dropdownListShown" iconId="arrow-down" class="dropdown-toggle__arrow-closeshow"></tbui-icon>
      </div>

    </button>

    <mat-form-field class="stations-dropdown dropdown-menu" appearance="fill" [ngClass]="{'dropdownlist-hidden': !dropdownListShown}">
      <input type="text"
             class="station-input"
             [placeholder]="'station.search.label' | translate"
             matInput
             #trigger="matAutocompleteTrigger"
             [formControl]="stationControl"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [panelWidth]="'15rem'" hideSingleSelectionIndicator="true">
        <mat-option *ngFor="let option of filteredStationOptions$ | async; trackBy: trackByFn"
                    (click)="stationClicked(option)">
          <tbui-station-logo class="autocomplete-option-image" [station]="option" [size]="StationLogoSize.S"></tbui-station-logo>
          <small>{{option.label}}</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>





