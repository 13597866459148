import { ErrorHandler, Injectable } from '@angular/core';
import { DatadogLoggingService, ErrorCode, TeleboyError, TrackJsService } from '@teleboy/web.core';
import { environment } from '../../../environments/environment';
import { UpsellService } from '../services/upsell.service';
import { HrefService } from '../../shared/services/href.service';
import { PopupService } from '../services/popup.service';
import { ErrorModalComponent } from '../../shared/components/error-modal/error-modal.component';
import { take } from 'rxjs';
import { PlayerService } from '../../player/services/player.service';

type TeleboyErrorOrError = TeleboyError | Error;

@Injectable()
export class ErrorHandlerProvider implements ErrorHandler {
  constructor(
    private datadogLoggingService: DatadogLoggingService,
    private hrefService: HrefService,
    private playerService: PlayerService,
    private popupService: PopupService,
    private trackJsService: TrackJsService,
    private upsellService: UpsellService
  ) {}

  handleError(error: TeleboyErrorOrError): void {
    // @ts-expect-error Actual error needs to be mapped from the TrackJS object
    error = error['innerError'] ?? error;

    /**
     * Error context sent to the loggers. It may be any data object.
     */
    let errorContext = {};

    if (error instanceof TeleboyError) {
      /**
       * Handle common errors which may be thrown from any API endpoint or policy
       * Using "return" instead of "break" will exit the handleError function and prevent the error from being logged
       */
      switch (error.code) {
        case ErrorCode.USER_AUTHENTICATION_REQUIRED:
          this.upsellService.openLoginUpsell();
          return;

        case ErrorCode.USER_PLUS_SUBSCRIPTION_REQUIRED:
          this.upsellService.openAboUpsell();
          return;

        case ErrorCode.USER_BLOCKED:
          this.hrefService.goToUrl('/blocked');
          return;

        case ErrorCode.REPLAY_NOT_ACTIVATED:
          this.upsellService.openReplayActivationUpsell();
          return;

        case ErrorCode.REPLAY_TOO_RECENT:
          this.upsellService.openReplayTooRecentUpsell();
          return;

        case ErrorCode.ADBLOCK_ENABLED:
          this.upsellService.openAdBlockerUpsell();
          return;

        case ErrorCode.STREAM_USERDATA_REQUIRED:
          this.upsellService.openProfileUpdateUpsell();
          return;

        case ErrorCode.USER_NOT_IN_SWITZERLAND:
          this.upsellService.openNotInSwitzerlandUpsell();
          return;

        case ErrorCode.STREAM_BROADCAST_NOT_STREAMABLE: {
          this.popupService
            .openAsModal<ErrorModalComponent>(ErrorModalComponent, error.code.toString())
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => this.playerService.closePlayer());
          return;
        }

        case ErrorCode.GENERAL_ERROR:
        case ErrorCode.GENERAL_API_ERROR:
        case ErrorCode.USER_TOO_MANY_REQUESTS:
        case ErrorCode.USER_BLACKLISTED:
        case ErrorCode.STREAM_RECORDING_NOT_ASSIGNED:
        case ErrorCode.STREAM_AUTHENTICATION_REQUIRED:
        case ErrorCode.REPLAY_TOO_LATE:
          this.popupService.openAsModal<ErrorModalComponent>(ErrorModalComponent, error.code.toString());
          return;
      }

      if (error.data) {
        errorContext = error.data;
      }
    }

    /**
     * Log the error to the console as a warning, to prevent the loggers from logging it twice
     */
    if (!environment.production) {
      console.warn(error);
    }

    /**
     * Log errors to TrackJS and Datadog
     */
    this.logToDatadog(error, errorContext);
    this.logToTrackJs(error, errorContext);
  }

  private logToDatadog(error: TeleboyErrorOrError, errorContext = {}): void {
    this.datadogLoggingService.logError(error.message, errorContext, error);
  }

  private logToTrackJs(error: TeleboyErrorOrError, errorContext: object): void {
    this.trackJsService.track(error, Object.keys(errorContext).length ? errorContext : null);
  }
}

export const ERROR_HANDLER = {
  provide: ErrorHandler,
  useClass: ErrorHandlerProvider
};
