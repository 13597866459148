<div class="recording-batch__background">
  <div class="btn__list btn__list-h btn__list-h--apart">
    <button (click)="deleteBatch()" [attr.aria-disabled]="!recordingBatchDeleteService.count()" class="btn btn__primary btn--sm">
      {{ 'pvr.batch.delete' | translate: {numRecordings: recordingBatchDeleteService.count()} }}
    </button>
    <button (click)="checkAll()" class="btn btn__primary btn--sm">
      {{ 'pvr.batch.checkAll' | translate }}
    </button>
    <button (click)="disableBatchDelete()" class="btn btn__primary btn--sm">
      {{ 'pvr.batch.cancel' | translate }}
    </button>
  </div>
</div>

