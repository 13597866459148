<div class="swiper__container" *ngIf="!(isEmptyResult$() | async)">
  <app-expand-link [linkText]="headline" (clicked)="openInListTilesView()"></app-expand-link>

  <swiper *ngIf="data$ | async as stations; else loading" #swiper [slidesPerView]="swiperOptions.slidesPerView"
          [breakpoints]="swiperOptions.breakpoints">
    <ng-template *ngFor="let station of stations" swiperSlide>
      <app-station-item [station]="station"></app-station-item>
    </ng-template>
  </swiper>
</div>

<ng-template #loading>
  <swiper class="ghost-listing ghost-listing--horizontal" [slidesPerView]="swiperOptions.slidesPerView"
          [breakpoints]="swiperOptions.breakpoints">
    <ng-template *ngFor="let ghost of ghosts" swiperSlide>
      <app-station-item></app-station-item>
    </ng-template>
  </swiper>
</ng-template>
