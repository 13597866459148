<div ngbDropdown class="dropdown" (click)="onDropdownClick($event)">
  <button type="button" class="dropdown-toggle btn btn__icon btn__select" [attr.aria-disabled]="isDisabled$ | async" [ngClass]="{ 'btn--bordered': bordered(), 'rounded-left': roundedLeft() }" id="dropdownBasic1" ngbDropdownToggle [disabled]="isDisabled$ | async">
    {{ (label || options()[0].label)}}
    <tbui-icon iconId="arrow-down" class="dropdown-toggle__arrow-open"></tbui-icon>
    <tbui-icon iconId="arrow-up" class="dropdown-toggle__arrow-close"></tbui-icon>
  </button>
  <div aria-labelledby="dropdownBasic1" ngbDropdownMenu class="dropdown-menu">
    @for (option of options(); track $index) {
      <button ngbDropdownItem class="dropdown-item" (click)="select(option)" [ngClass]="{ 'active': highlightSelectedOption() && selectedOption?.label == option.label }" >
        {{ option.label }}
      </button>
    }
  </div>
</div>
