<div [ngClass]="{ 'swiper__container swiper__container--broadcast': listingType !== ListingType.LIST }" *ngIf="!(isEmptyResult$() | async)">
  <app-expand-link [linkText]="headline" (clicked)="openInListTilesView()" [clickable]="isLinkClickable"></app-expand-link>

  <ng-container *ngIf="data$ | async as broadcasts; else loading">
    <swiper *ngIf="!listingType || listingType === ListingType.TILES" #swiper [slidesPerView]="swiperOptions.slidesPerView"
            [breakpoints]="swiperOptions.breakpoints">
      <ng-template *ngFor="let broadcast of broadcasts" swiperSlide>
        <app-broadcast-item [broadcast]="broadcast" [card]="true"></app-broadcast-item>
      </ng-template>
      <ng-template swiperSlide>
        <app-broadcast-item *ngIf="isPaginating$ | async" [card]="true" class="ghost-listing"></app-broadcast-item>
      </ng-template>
    </swiper>
  </ng-container>
</div>

<ng-container *ngIf="listingType === ListingType.LIST">
  <div *ngIf="data$ | async as broadcasts; else loading" class="grid">
    <app-broadcast-item *ngFor="let broadcast of broadcasts" [broadcast]="broadcast"
                        [card]="false"></app-broadcast-item>
  </div>
</ng-container>

<ng-template #loading>
  <swiper *ngIf="listingType === ListingType.TILES"  [slidesPerView]="swiperOptions.slidesPerView"
          [breakpoints]="swiperOptions.breakpoints" class="ghost-listing ghost-listing--horizontal">
    <ng-template *ngFor="let ghost of ghosts" swiperSlide>
      <app-broadcast-item [card]="true"></app-broadcast-item>
    </ng-template>
  </swiper>

  <div *ngIf="listingType === ListingType.LIST" class="grid ghost-listing">
    <app-broadcast-item *ngFor="let ghost of ghosts"
                        [card]="false"></app-broadcast-item>
  </div>
</ng-template>
