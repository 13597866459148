  <div (click)="jumpBackward()" class="jumpseek-button jumpseek-button__back">
    <span class="btn btn--opaque btn__icon btn--circle btn--lg">
      <tbui-icon iconId="jumpseek-back"></tbui-icon>
    </span>
    <div class="jumpseek-button__label">
      <span [hidden]="secondsToJump >= 0">
          {{ mathAbs(secondsToJump) }} {{ 'player.epg.seconds' | translate }}
      </span>
    </div>
  </div>

  <div (click)="jumpForward()" class="jumpseek-button jumpseek-button__forward">
    <span class="btn btn--opaque btn__icon btn--circle btn--lg">
      <tbui-icon iconId="jumpseek-forward"></tbui-icon>
    </span>
    <div *ngIf="secondsToJump !== 0" class="jumpseek-button__label">
      <span
        [hidden]="isLive || secondsToJump <= 0">{{ mathAbs(secondsToJump) }} {{ 'player.epg.seconds' | translate }}</span>
      <span [hidden]="!isLive">Live</span>
    </div>
  </div>
