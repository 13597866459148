import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from '@teleboy/web.user';
import { Broadcast, CommunityApiService } from '@teleboy/web.epg';
import { CpvrService } from '../../../services/cpvr.service';
import { BehaviorSubject, finalize, Observable } from 'rxjs';
import { ErrorCode, TeleboyError } from '@teleboy/web.core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-cpvr-join',
  templateUrl: './cpvr-join.component.html',
  styleUrls: ['./cpvr-join.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
    AsyncPipe,
    TranslateModule,
    IconComponent
  ]
})
export class CpvrJoinComponent {
  readonly form = new UntypedFormGroup(
    {
      share_records: new UntypedFormControl(true, Validators.requiredTrue),
      friendship_auto_accept: new UntypedFormControl(true),
      nickname: new UntypedFormControl(this.authenticationService.user.firstname, Validators.required)
    },
    { updateOn: 'submit' }
  );

  private readonly _busy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly busy$: Observable<boolean> = this._busy$.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) public broadcast: Broadcast,
    private authenticationService: AuthenticationService,
    private changeDetectorRef: ChangeDetectorRef,
    private communityApiService: CommunityApiService,
    private cpvrService: CpvrService,
    private dialogRef: MatDialogRef<CpvrJoinComponent>
  ) {}

  get isCommunityMember(): boolean {
    return this.authenticationService.user.isCommunityMember;
  }

  join(): void {
    if (!this.form.valid) {
      return;
    }

    this.form.disable();
    this._busy$.next(true);

    this.communityApiService
      .joinCommunity(this.form.getRawValue())
      .pipe(finalize(() => this._busy$.next(false)))
      .subscribe({
        next: () => this.changeDetectorRef.detectChanges(),
        error: (err: TeleboyError) => this.handleJoinFailed(err.code)
      });
  }

  befriend(): void {
    this.dialogRef.close();

    this.cpvrService.requestFriendship(this.broadcast);
  }

  close(): void {
    this.dialogRef.close();
  }

  private handleJoinFailed(errorCode: ErrorCode): void {
    this.form.enable();

    switch (errorCode) {
      case ErrorCode.COMMUNITY_NICKNAME_REQUIRED:
        this.form.get('nickname')?.setErrors({ required: true });
        break;
      case ErrorCode.COMMUNITY_NICKNAME_TAKEN:
        this.form.get('nickname')?.setErrors({ taken: true });
        break;
      case ErrorCode.COMMUNITY_NICKNAME_NOT_ALLOWED:
        this.form.get('nickname')?.setErrors({ invalid: true });
        break;
      case ErrorCode.COMMUNITY_SHARING_REQUIRED:
        this.form.get('share_records')?.setErrors({ required: true });
        break;
    }

    this.changeDetectorRef.detectChanges();
  }
}
