<ng-container *ngIf="adInfo$ | async as adInfo">
  <div class="ad-ui__container fullsize-centered" (mousemove)="mouseMove(adInfo)">
    <button *ngIf="!allowPlayerUi(adInfo)" (click)="closePlayer()"
            class="btn btn__icon btn--circle btn--opaque ad-ui__close">
      <tbui-icon iconId="close"></tbui-icon>
    </button>

    <div class="ratio ratio__16x9 full-width" [ngClass]="{ 'ad-ui__blackened': darkBg$ | async}">
      <tbui-ad-info [adCounterMessage]="'player.ads.counter' | translate"
                    [durationTimerMessage]="'player.ads.timer' | translate"
                    [untilSkippableMessage]="'player.ads.skippableTimer' | translate"
                    [skipLabel]="'player.ads.skip' | translate"
                    [skipClasses]="['btn', 'btn__link-like']">

        <ng-container customContent *ngIf="allowPlayerUi(adInfo)">
          <div>
            <span>{{'player.ads.noAds' | translate}}</span>
            <a (click)="gotoSubscriptionUpdate()"
                    class="action__button">{{ 'player.ads.clickHere' | translate }}
            </a>
          </div>
        </ng-container>

      </tbui-ad-info>
    </div>
  </div>
</ng-container>
