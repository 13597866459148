import { Injectable } from '@angular/core';
import { Upsell, UpsellCta } from '../../interfaces/upsell.interface';
import { PopupService } from '../popup.service';
import { HrefService } from '../../../shared/services/href.service';

@Injectable({
  providedIn: 'root'
})
export class NotInSwitzerlandUpsellService implements Upsell {
  readonly name = 'notInSwitzerland';
  readonly icon = 'location';
  readonly placeholders = {};
  readonly cta: UpsellCta[];

  constructor(
    private hrefService: HrefService,
    private popupService: PopupService
  ) {
    this.cta = this._cta();
  }

  private _cta(): UpsellCta[] {
    return [
      {
        key: 'close',
        click: () => this.popupService.closeModal()
      },
      {
        key: 'status',
        click: () => {
          this.hrefService.goToUrl('/status', {
            useBlankTarget: true
          });
        }
      }
    ];
  }
}
