import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  delay,
  filter,
  finalize,
  map,
  Observable,
  scan,
  shareReplay,
  Subject,
  takeUntil,
  tap
} from 'rxjs';
import {
  Broadcast,
  CommunityApiService,
  CommunityRecordParams,
  ContentApiService,
  EpgApiService,
  EpgParams,
  HeartbeatApiService,
  HeartbeatParams,
  RecommendationsApiService,
  RecommendationsParams,
  SearchApiService,
  SearchParams,
  SearchPrefix,
  SearchSort,
  SearchSource,
  Serie,
  SerieParams,
  StarAlertListParams,
  StationOrderStorageService,
  TeaserApiService,
  TeaserDevice,
  TeaserParams,
  WatchlistApiService,
  WatchlistParams
} from '@teleboy/web.epg';
import { RouteDataService } from '../../../core/services/route-data.service';
import { EntityServiceType, EntityType, ItemsListRouteData } from '../../interfaces/items-list-route-data';
import { PopupService } from '../../../core/services/popup.service';
import { ToggleablePageType, ViewTogglerService, ViewToggleType } from '../../services/view-toggler.service';
import { ListParams } from '@teleboy/web.core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { LanguageService } from '@teleboy/web.user';
import { HeartbeatswiperService } from '../swiper/heartbeat-swiper/heartbeat-swiper.service';
import { StarAlertService } from '../../services/star-alert.service';
import { SearchingService } from '../../services/searching.service';
import { SharedModule } from '../../../shared/shared.module';
import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgFor, NgSwitchDefault, AsyncPipe } from '@angular/common';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { SerieItemComponent } from '../serie-item/serie-item.component';
import { StationItemComponent } from '../station-item/station-item.component';
import { BroadcastItemComponent } from '../broadcast-item/broadcast-item.component';
import { TeaserItemComponent } from '../teaser-item/teaser-item.component';
import { StaralertItemComponent } from '../staralert-item/staralert-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-items-list-page',
  templateUrl: './items-list-page.component.html',
  styleUrls: ['./items-list-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedModule,
    NgClass,
    NgIf,
    InfiniteScrollDirective,
    NgSwitch,
    NgSwitchCase,
    NgFor,
    SerieItemComponent,
    StationItemComponent,
    BroadcastItemComponent,
    TeaserItemComponent,
    StaralertItemComponent,
    NgSwitchDefault,
    RouterLink,
    AsyncPipe,
    TranslateModule,
    IconComponent
  ]
})
export class ItemsListPageComponent implements OnInit, OnDestroy {
  listingType!: ViewToggleType | undefined;

  itemsListType!: EntityType;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  itemsList$!: Observable<any[]>;
  pageConfiguration!: ItemsListRouteData;
  itemsPerPage!: number;
  title!: string;
  totalItems = 0;
  toggleButtonVisible = true;

  readonly busy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly ghosts = new Array(15);
  readonly ListingType = ViewToggleType;
  readonly ItemsListType = EntityType;

  private heartbeatDeleted!: boolean;

  private serviceName!: EntityServiceType;
  private searchSourceValue!: SearchSource;
  private searchValue = '';
  private searchQueryMode!: SearchPrefix;
  private params: ItemsListRouteData['params'] | undefined;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private heartbeatswiperService: HeartbeatswiperService,
    private injector: Injector,
    private languageService: LanguageService,
    private popupService: PopupService,
    private routeDataService: RouteDataService,
    private router: Router,
    private searchingService: SearchingService,
    private viewTogglerService: ViewTogglerService
  ) {}

  ngOnInit(): void {
    if (!this.routeDataService.getDataForRoute<ItemsListRouteData>()) {
      void this.router.navigate([''], { skipLocationChange: false });
      return;
    }

    this.pageConfiguration = this.routeDataService.getDataForRoute<ItemsListRouteData>();

    this.title = this.pageConfiguration.title;
    const paramsFromRoute = this.pageConfiguration.params;
    this.itemsListType = this.pageConfiguration.broadcastType;
    this.serviceName = this.pageConfiguration.serviceName;
    if (this.pageConfiguration.searchSourceValue) {
      this.searchSourceValue = this.pageConfiguration.searchSourceValue;
    }
    this.searchValue = this.pageConfiguration.searchValue ?? '';
    this.searchQueryMode = this.pageConfiguration.searchQueryMode ?? SearchPrefix.TITLE;
    this.itemsPerPage = this.pageConfiguration.itemsPerPage ?? 30;
    this.itemsListType = this.pageConfiguration.broadcastType;

    this.params = paramsFromRoute ? this.getParamsCopy(paramsFromRoute, this.itemsListType) : undefined;

    this.initViewConfiguration();
    this.resetParametersLimit();
    this.itemsList$ = this.initDataSubscription$();
    this.trackAppNavigation();
  }

  loadMore(): void {
    if (this.params instanceof ListParams && this.params.canPaginate(this.totalItems)) {
      this.params.paginate();
    }
  }

  onSuccessDeleteHeartbeat() {
    this.heartbeatDeleted = true;
    this.itemsList$ = this.initDataSubscription$();
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  onSuccessDeleteItem(listItem: any) {
    listItem.deleted = true;
    if (this.params instanceof ListParams) {
      this.params.reduceSkip(1);
    }
  }

  toggleListingType(): void {
    this.listingType = this.viewTogglerService.toggleListingType(ToggleablePageType.BroadcastsPage);
  }

  trackByFn(index: number, item: Broadcast | Serie): number | null {
    return item ? item.id : null;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeListTilesView() {
    void this.popupService.close('details');

    if (this.heartbeatDeleted) {
      this.heartbeatswiperService.fetchItems();
    }
  }

  navigate(path: string) {
    this.searchingService.setShowSearch(false);
    void this.router.navigate([{ outlets: { primary: path, landingpage: null } }]).then(() => {
      void this.router.navigate([{ outlets: { primary: path, details: null } }]);
    });
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private getData(serviceName: EntityServiceType, params?: ItemsListRouteData['params']): Observable<any> {
    this.busy$.next(true);

    switch (serviceName) {
      case EntityServiceType.EpgApiService:
        return this.injector.get(EpgApiService).getBroadcasts((params as EpgParams).httpParams);
      case EntityServiceType.HeartbeatApiService:
        return this.injector.get(HeartbeatApiService).getLatestHeartbeats(params as HeartbeatParams);
      case EntityServiceType.CommunityApiService:
        return this.injector.get(CommunityApiService).queryRecords(params as CommunityRecordParams);
      case EntityServiceType.RecommendationsApiService:
        return this.injector.get(RecommendationsApiService).getBroadcasts(params as RecommendationsParams);
      case EntityServiceType.WatchlistApiService:
        return this.injector.get(WatchlistApiService).broadcasts(params as WatchlistParams);
      case EntityServiceType.ContentApiService:
        return this.injector.get(ContentApiService).getSeries(params as SerieParams);
      case EntityServiceType.SearchApiService:
        return this.injector.get(SearchApiService).search(params as SearchParams);
      case EntityServiceType.StationOrderStorageService:
        return this.injector
          .get(StationOrderStorageService)
          .getUserStations()
          .pipe(
            map((stations) => {
              return {
                total: stations.length,
                items: stations
              };
            })
          );
      case EntityServiceType.TeaserApiService:
        return this.injector.get(TeaserApiService).getTeasers(params as TeaserParams);
      case EntityServiceType.StarAlertService:
        return this.injector.get(StarAlertService).getStarAlertsList(params as StarAlertListParams);
      default:
        throw new Error('Unsupported serviceName parameter');
    }
  }

  private getParamsCopy(
    routeParams: ItemsListRouteData['params'],
    broadcastType: EntityType
  ): ItemsListRouteData['params'] {
    switch (broadcastType) {
      case EntityType.Epg:
        return routeParams && new EpgParams().setFromObject(routeParams.asObject);
      case EntityType.Recommendation:
        return routeParams && new RecommendationsParams().setFromObject(routeParams.asObject);
      case EntityType.Watchlist:
        return routeParams && new WatchlistParams().setFromObject(routeParams.asObject);
      case EntityType.Heartbeat:
        return routeParams && new HeartbeatParams().setFromObject(routeParams.asObject);
      case EntityType.CommunityRecord:
        return routeParams && new CommunityRecordParams().setFromObject(routeParams.asObject);
      case EntityType.Serie:
        return routeParams && new SerieParams().setFromObject(routeParams.asObject);
      case EntityType.Search:
        return (
          routeParams &&
          new SearchParams(this.searchValue, [this.searchSourceValue, SearchSource.LIVE], this.searchQueryMode)
            .setFromObject(routeParams.asObject)
            .setSort(SearchSort.DATE)
            .allStations()
            .setLimit(this.itemsPerPage)
        );
      case EntityType.Teaser:
        return (
          routeParams &&
          new TeaserParams(TeaserDevice.WEB, this.languageService.getLanguage()).setFromObject(routeParams.asObject)
        );
      case EntityType.StarAlert:
        return routeParams && new StarAlertListParams().setFromObject(routeParams.asObject);
      default:
        throw new Error('Unsupported routeParamName parameter');
    }
  }

  private initDataSubscription$(): Observable<unknown[]> {
    if (!(this.params instanceof ListParams)) {
      return this.initNonPageableData$();
    } else {
      return this.initPageableData$(this.params);
    }
  }

  private initPageableData$(params: ListParams): Observable<unknown[]> {
    return params.skip$
      .pipe(
        concatMap(() =>
          this.getData(this.serviceName, params).pipe(
            tap((data) => (this.totalItems = this.searchSourceValue ? data[this.searchSourceValue].total : data.total)),
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            map((data: any) => (this.searchSourceValue ? data[this.searchSourceValue].items : data.items)),
            finalize(() => this.busy$.next(false)),
            catchError(() => [])
          )
        ),
        scan((accItems, items) => accItems.concat(items))
      )
      .pipe(shareReplay());
  }

  private initNonPageableData$(): Observable<unknown[]> {
    return this.getData(this.serviceName, this.params)
      .pipe(
        tap((data) => (this.totalItems = this.searchSourceValue ? data[this.searchSourceValue].total : data.total)),
        map((data) => (this.searchSourceValue ? data[this.searchSourceValue].items : data.items)),
        finalize(() => this.busy$.next(false)),
        catchError(() => [])
      )
      .pipe(shareReplay());
  }

  private initViewConfiguration(): void {
    if (this.routeDataService.getDataForRoute<ItemsListRouteData>()?.enableListTypeToggle !== undefined) {
      this.toggleButtonVisible =
        this.routeDataService.getDataForRoute<ItemsListRouteData>()?.enableListTypeToggle ?? false;
    }

    if (this.toggleButtonVisible) {
      this.listingType = this.viewTogglerService.setListingType(ToggleablePageType.BroadcastsPage);
    } else {
      this.listingType = this.routeDataService.getDataForRoute<ItemsListRouteData>()?.predefinedListingType;
    }
  }

  private resetParametersLimit(): void {
    if (!(this.params instanceof ListParams) || this.itemsListType === this.ItemsListType.Search) {
      return;
    }

    this.params.setLimit(this.itemsPerPage);
    this.params.resetSkip();
  }

  private trackAppNavigation(): void {
    // Delay closing the page when clicked on Station or Teaser item
    // so the Home page is not shown to the user for a short time.
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        delay(300),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.itemsListType === EntityType.Teaser || this.itemsListType === EntityType.Station) {
          this.closeListTilesView();
        }
      });
  }
}
