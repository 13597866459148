import { Injectable, Injector } from '@angular/core';
import { PopupService } from './popup.service';
import { AboUpsellService } from './upsell/abo-upsell.service';
import { Upsell } from '../interfaces/upsell.interface';
import { LoginUpsellService } from './upsell/login-upsell.service';
import { ReplayTooRecentUpsellService } from './upsell/replay-too-recent-upsell.service';
import { RecordingTimeUpsellService } from './upsell/recording-time-upsell.service';
import { ReplayActivationUpsellService } from './upsell/replay-activation-upsell.service';
import { AdblockerUpsellService } from './upsell/adblocker-upsell.service';
import { ProfileUpdateUpsellService } from './upsell/profile-update-upsell.service';
import { UpsellModalComponent } from '../../shared/components/upsell-modal/upsell-modal.component';
import { NotInSwitzerlandUpsellService } from './upsell/not-in-switzerland-upsell.service';

@Injectable({
  providedIn: 'root'
})
export class UpsellService {
  constructor(
    private injector: Injector,
    private popupService: PopupService
  ) {}

  openLoginUpsell(): void {
    const loginUpsellService = this.injector.get(LoginUpsellService);
    this.openUpsell(loginUpsellService);
  }

  openAboUpsell(): void {
    const aboUpsellService = this.injector.get(AboUpsellService);
    this.openUpsell(aboUpsellService);
  }

  openReplayActivationUpsell(): void {
    const replayActivationUpsellService = this.injector.get(ReplayActivationUpsellService);
    this.openUpsell(replayActivationUpsellService);
  }

  openReplayTooRecentUpsell(): void {
    const replayTooRecentUpsellService = this.injector.get(ReplayTooRecentUpsellService);
    this.openUpsell(replayTooRecentUpsellService);
  }

  openRecordingTimeUpsell(): void {
    const recordingTimeUpsellService = this.injector.get(RecordingTimeUpsellService);
    this.openUpsell(recordingTimeUpsellService);
  }

  openAdBlockerUpsell(): void {
    const adblockerUpsellService = this.injector.get(AdblockerUpsellService);
    this.openUpsell(adblockerUpsellService);
  }

  openProfileUpdateUpsell(): void {
    const profileUpdateUpsellService = this.injector.get(ProfileUpdateUpsellService);
    this.openUpsell(profileUpdateUpsellService);
  }

  openNotInSwitzerlandUpsell(): void {
    const notInSwitzerlandUpsellService = this.injector.get(NotInSwitzerlandUpsellService);
    this.openUpsell(notInSwitzerlandUpsellService);
  }

  private openUpsell(upsellData: Upsell): void {
    this.popupService.openAsModal<UpsellModalComponent>(UpsellModalComponent, upsellData);
  }
}
