import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RecordingBatchDeleteService } from '@teleboy/web.epg';

@Component({
  selector: 'app-recording-batch-action',
  templateUrl: './recording-batch-action.component.html',
  styleUrls: ['./recording-batch-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule]
})
export class RecordingBatchActionComponent {
  @Output() batchDeleted: EventEmitter<number> = new EventEmitter<number>();
  @Output() batchSelectAll: EventEmitter<void> = new EventEmitter<void>();

  constructor(public recordingBatchDeleteService: RecordingBatchDeleteService) {}

  disableBatchDelete(): void {
    this.recordingBatchDeleteService.disableBatchDelete();
  }

  checkAll(): void {
    this.batchSelectAll.emit();
  }

  deleteBatch(): void {
    this.batchDeleted.emit();
  }
}
