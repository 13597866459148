import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  HEADERS_INTERCEPTOR_PROVIDER,
  HTTP_ERROR_LOGGING_PROVIDER,
  HTTP_ERROR_MAPPING_PROVIDER,
  HTTP_ERROR_RETRY_PROVIDER,
  Language
} from '@teleboy/web.core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_CONFIG_PROVIDER } from './core/providers/app-config.provider';
import { APP_INIT_PROVIDER } from './core/providers/init.provider';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig } from 'ngx-webstorage';
import { AUTH_HEADERS_INTERCEPTOR_PROVIDER, AUTH_PARAMS_INTERCEPTOR_PROVIDER } from '@teleboy/web.user';
import { EpgModule } from './epg/epg.module';
import { PlayerModule } from './player/player.module';
import { BITMOVIN_CONFIG_PROVIDER } from './core/configs/bitmovin.config';
import { GTM_PROVIDER } from './core/providers/gtm.provider';
import { VIDEO_ADS_PROVIDER } from './core/providers/video-ads.provider';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import packageInfo from '../../package.json';
import { A11yModule } from '@angular/cdk/a11y';
import { StartModule } from './start/start.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FallbackRoutingModule } from './fallback-routing.module';
import { environment } from '../environments/environment';
import { ERROR_HANDLER } from './core/providers/error-handler.provider';
import { SESSION_SOURCE_PROVIDER } from './core/providers/session-source.provider';
import { ProfilesModule } from './profiles/profiles.module';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    A11yModule,
    BrowserModule,
    EpgModule,
    PlayerModule,
    SharedModule,
    StartModule,
    ProfilesModule,
    TranslateModule.forRoot({
      defaultLanguage: Language.DE,
      loader: {
        provide: TranslateLoader,
        useFactory: (httpClient: HttpClient) => {
          return new TranslateHttpLoader(httpClient, 'assets/i18n/', `.json?${packageInfo.version}`);
        },
        deps: [HttpClient]
      }
    }),
    NoopAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
    FallbackRoutingModule
  ],
  providers: [
    APP_CONFIG_PROVIDER,
    APP_INIT_PROVIDER,
    SESSION_SOURCE_PROVIDER,
    GTM_PROVIDER,
    BITMOVIN_CONFIG_PROVIDER,
    VIDEO_ADS_PROVIDER,
    ERROR_HANDLER,
    provideHttpClient(withInterceptorsFromDi()),
    AUTH_PARAMS_INTERCEPTOR_PROVIDER,
    AUTH_HEADERS_INTERCEPTOR_PROVIDER,

    // REQUEST INTERCEPTORS
    HEADERS_INTERCEPTOR_PROVIDER,

    // RESPONSE INTERCEPTORS
    HTTP_ERROR_LOGGING_PROVIDER,
    HTTP_ERROR_MAPPING_PROVIDER,
    HTTP_ERROR_RETRY_PROVIDER,
    provideNgxWebstorage(withNgxWebstorageConfig({ separator: '|', caseSensitive: true }), withLocalStorage())
  ]
})
export class AppModule {}
