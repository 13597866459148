import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { fromIntersectionObserver, IntersectionStatus } from './from-intersection-observer';

@Directive({
  selector: '[appIntersectionObserver]',
  standalone: false
})

// https://stackblitz.com/edit/intersection-observer-api?file=src%2Fapp%2Fintersection-observer.directive.ts
export class IntersectionObserverDirective implements OnInit, OnDestroy {
  // Delays the emitting of the visibility status change
  @Input() intersectionDebounce = 0;
  @Input() intersectionRootMargin = '0px';
  @Input() intersectionRoot!: HTMLElement;
  @Input() intersectionThreshold!: number | number[];

  @Output() visibilityChange = new EventEmitter<IntersectionStatus>();

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(private element: ElementRef) {}

  ngOnInit() {
    const element = this.element.nativeElement;

    const config = {
      root: this.intersectionRoot,
      rootMargin: this.intersectionRootMargin,
      threshold: this.intersectionThreshold
    };

    fromIntersectionObserver(element, config, this.intersectionDebounce)
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: IntersectionStatus) => {
        if (status == IntersectionStatus.Visible || status == IntersectionStatus.NotVisible) {
          this.visibilityChange.emit(status);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
