<div class="tabbar">
  <div class="tabbar__left">
    <h1 class="collapse">Styleguide</h1>
  </div>

  <div class="tabbar__right">
    <a href="/styleguide#icons" class="btn">Icons</a>
    <button routerLink="serialgroups" class="btn">{{ 'pvr.nav.series' | translate }}</button>

    <button class="btn btn__icon btn--circle">
      <tbui-icon iconId="view-list"></tbui-icon>
    </button>

    <a href="#" class="btn btn--lg btn__icon btn--circle">
      <tbui-icon iconId="navi-search"></tbui-icon>
    </a>

    </div>
</div>

<p>
  <label><input type="radio" name="bg" (change)="setBgColor('')" checked> BG None</label>
  <label><input type="radio" name="bg" (change)="setBgColor('white')"> BG White</label>
  <label><input type="radio" name="bg" (change)="setBgColor('translucent')"> BG Trans</label>
</p>

<hr class="divider">

<div [class]="bgColor$ | async">
  <ng-container *ngTemplateOutlet="typography"></ng-container>
  <ng-container *ngTemplateOutlet="forms"></ng-container>
  <ng-container *ngTemplateOutlet="badges"></ng-container>
  <ng-container *ngTemplateOutlet="buttons"></ng-container>
  <ng-container *ngTemplateOutlet="broadcastList"></ng-container>
  <ng-container *ngTemplateOutlet="swipers"></ng-container>
  <ng-container *ngTemplateOutlet="iconSet"></ng-container>
</div>

<ng-template #typography>
  <h2>Typography</h2>

  <h1>h1: Lorem ipsum dolor sit amet</h1>
  <h2>h2: Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</h2>
  <h3>h3: Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
    dolore
    magna aliquyam erat, sed diam voluptua.</h3>
  <p>
    p: Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
    dolore
    magna
    aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
    gubergren,
    no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
    diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
    accusam
    et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
    magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
    gubergren,
    no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
    diam
    nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
    justo duo
  </p>
  <p>
    p: Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
    dolore
    magna
    aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
    gubergren,
    no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
    sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
    accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
    sit
    amet
  </p>

  <hr class="divider">
</ng-template>

<ng-template #forms>
  <h2>Forms</h2>

  <p>
    <label><input type="checkbox" (change)="toggleFormStyle('invalid')"> Invalid</label>
  </p>

  <form>
    <div class="form-item">
      <label for="text-1">Some Text-Input</label>
      <input id="text-1" type="text" [ngClass]="{'ng-invalid': formStates.invalid}">
      <span class="invalid-feedback">Something went wrong</span>
    </div>

    <fieldset>
      <legend>Radio fieldset</legend>
      <div class="form-item-check">
        <input type="radio" name="radio" id="radio-1">
        <label for="radio-1">My first option</label>
      </div>
      <div class="form-item-check">
        <input type="radio" name="radio" id="radio-2">
        <label for="radio-2">Another option to choose</label>
      </div>
      <div class="form-item-check">
        <input type="radio" name="radio" id="radio-3">
        <label for="radio-3">Can't decide</label>
      </div>
      <div class="invalid-feedback">Something went wrong</div>
    </fieldset>

    <fieldset>
      <legend>Checkbox Fieldset</legend>
      <div class="form-item-check">
        <input type="checkbox" name="checkbox[]" id="checkbox-1">
        <label for="checkbox-1">My first option</label>
      </div>
      <div class="form-item-check">
        <input type="checkbox" name="checkbox[]" id="checkbox-2">
        <label for="checkbox-2">Another option to choose</label>
      </div>
      <div class="form-item-check">
        <input type="checkbox" name="checkbox[]" id="checkbox-3">
        <label for="checkbox-3">Can't decide</label>
      </div>
      <div class="form-item-check">
        <input type="checkbox" name="checkbox[]" id="checkbox-4" [ngClass]="{'ng-invalid': formStates.invalid}">
        <label for="checkbox-4">Another option to choose</label>
        <span class="invalid-feedback">Something went wrong</span>
      </div>
      <div class="form-item-check">
        <input type="checkbox" name="checkbox[]" id="checkbox-5">
        <label for="checkbox-5">Can't decide</label>
      </div>
    </fieldset>

    <label for="inputSwitch">Checkbox toggle switch</label>
    <input class="switch" type="checkbox" id="inputSwitch"/>
    <label for="inputSwitch"></label>

  </form>

  <hr class="divider">
</ng-template>

<ng-template #badges>
  <span class="badge">Badge Default</span>
  <span class="badge badge__secondary">Badge Secondary</span>
  <span class="badge badge__primary">Badge Primary</span>
  <hr class="divider">
</ng-template>

<ng-template #buttons>
  <h2>Buttons</h2>

  <form>
    <fieldset class="form-item--inline">
      <legend>Switch states</legend>
      <div class="form-item-check">
        <input id="button-state-toggle-active" type="checkbox" (change)="toggleButtonStyle('active')">
        <label for="button-state-toggle-active">Active</label>
      </div>
      <div class="form-item-check">
        <input id="button-state-toggle-bordered" type="checkbox" (change)="toggleButtonStyle('bordered')">
        <label for="button-state-toggle-bordered">Bordered</label>
      </div>
      <div class="form-item-check">
        <input id="button-state-toggle-opaque" type="checkbox" (change)="toggleButtonStyle('opaque')">
        <label for="button-state-toggle-opaque">Opaque</label>
      </div>
      <div class="form-item-check">
        <input id="button-state-toggle-busy" type="checkbox" (change)="toggleButtonStyle('busy')">
        <label for="button-state-toggle-busy">Busy</label>
      </div>
    </fieldset>
  </form>

  <h3>Styles</h3>

  <div class="btn__list-h" style="margin-bottom: 1.5rem;">
    <button class="btn" [ngClass]="buttonClassList">Button Default</button>
    <button class="btn btn__primary" [ngClass]="buttonClassList">Button Primary</button>
    <button class="btn btn__secondary" [ngClass]="buttonClassList">Button Secondary</button>
  </div>

  <h3>Horizontale Liste</h3>

  <div class="btn__list-h" style="margin-bottom: 1rem;">
    <button class="btn btn--sm" [ngClass]="buttonClassList">Button SM Default</button>
    <button class="btn btn__icon btn--sm" [ngClass]="buttonClassList">
      <tbui-icon iconId="trailer"></tbui-icon>
      Button SM mit Icon
    </button>
    <button class="btn btn__icon btn--circle btn--sm" [ngClass]="buttonClassList">
      <tbui-icon iconId="trailer"></tbui-icon>
    </button>
  </div>

  <div class="btn__list-h" style="margin-bottom: 1rem;">
    <button class="btn" [ngClass]="buttonClassList">Button Default</button>
    <button class="btn btn__icon" [ngClass]="buttonClassList">
      <tbui-icon iconId="trailer"></tbui-icon>
      Button Default mit Icon
    </button>
    <button class="btn btn__icon btn--circle" [ngClass]="buttonClassList">
      <tbui-icon iconId="trailer"></tbui-icon>
    </button>
  </div>

  <div class="btn__list-h" style="margin-bottom: 1rem;">
    <button class="btn btn--lg" [ngClass]="buttonClassList">Button LG Default</button>
    <button class="btn btn__icon btn--lg" [ngClass]="buttonClassList">
      <tbui-icon iconId="trailer"></tbui-icon>
      Button LG mit Icon
    </button>
    <button class="btn btn__icon btn--circle btn--lg" [ngClass]="buttonClassList">
      <tbui-icon iconId="trailer"></tbui-icon>
    </button>
  </div>

  <h3>Vertikale Liste</h3>

  <div class="btn__list-v btn__list-v--stretch" style="margin-bottom: 1rem; width: 30%;">
    <button class="btn" [ngClass]="buttonClassList">Button SM Default</button>
    <button class="btn btn__icon" [ngClass]="buttonClassList">
      <tbui-icon iconId="trailer"></tbui-icon>
      Button Default mit Icon
    </button>
  </div>

  <hr class="divider">
</ng-template>

<ng-template #swipers>
  <h2>Swipers</h2>

  <h3>Top Teasers</h3>
  <app-teaser-swiper [params]="topTeasersParams" [topTeaser]="true"></app-teaser-swiper>

  <h3>Broadcasts</h3>
  <app-broadcast-swiper [params]="epgParams"></app-broadcast-swiper>

  <h3>Teasers</h3>
  <app-teaser-swiper [params]="topicTeasersParams"></app-teaser-swiper>

  <h3>Serien</h3>
  <app-serie-swiper [params]="seriesParams"></app-serie-swiper>

  <hr class="divider">
</ng-template>


<ng-template #iconSet>
  <h1 id="icons">Icons</h1>
  <h2>Icons Navi</h2>
  <div style="font-size: 40px; color: gray; margin-bottom: 10rem; display: flex; flex-wrap: wrap;">
    <div *ngFor="let icon of iconsNavi"
         style="background: #fff; margin: 5px; padding: 10px; text-align: center; width: 200px">
      <tbui-icon iconId="{{ icon }}"></tbui-icon>
      <p style="font-size: 14px">{{ icon }}</p>
    </div>
  </div>

  <h2>Icons Action</h2>
  <div style="font-size: 40px; color: gray; margin-bottom: 10rem; display: flex; flex-wrap: wrap;">
    <div *ngFor="let icon of iconsActions"
         style="margin: 5px; padding: 10px; text-align: center; width: 200px">
      <a class="btn btn__icon">
        <tbui-icon iconId="{{ icon }}"></tbui-icon>
      </a>
      <p style="font-size: 14px">{{ icon }}</p>
    </div>
  </div>

  <h2>Icons Info</h2>
  <div style="font-size: 40px; color: gray; margin-bottom: 10rem; display: flex; flex-wrap: wrap;">
    <div *ngFor="let icon of iconsInfo"
         style="background: #fff; margin: 5px; padding: 10px; text-align: center; width: 200px">
      <tbui-icon iconId="{{ icon }}"></tbui-icon>
      <p style="font-size: 14px">{{ icon }}</p>
    </div>
  </div>

  <hr class="divider">
</ng-template>


<ng-template #broadcastList>
  <h2>Broadcast listing</h2>

  <p>
    <label><input type="checkbox" (change)="toggleBroadcastListingItems('card')"> Als Cards</label>
    <label><input type="checkbox" (change)="toggleBroadcastListingItems('tipp')"> Tipps deaktivieren</label>
    <label><input type="checkbox" (change)="toggleBroadcastListingItems('active')"> Active</label>
  </p>

  <div class="grid" [ngClass]="{'grid__5': broastcastListingItemStates.card }">
    <app-broadcast-item *ngFor="let broadcast of epg$ | async" [broadcast]="broadcast"
                        [card]="broastcastListingItemStates.card"
                        [hightlightTipp]="broastcastListingItemStates.tipp"
                        [active]="broastcastListingItemStates.active "></app-broadcast-item>
  </div>

  <hr class="divider">
</ng-template>
