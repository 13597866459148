import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Broadcast, BroadcastApiService } from '@teleboy/web.epg';
import { BehaviorSubject, iif, Observable, of, switchMap } from 'rxjs';
import { BroadcastActionService } from '../../../services/broadcast-action.service';
import { MediaImageStack } from '@teleboy/web.core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgIf, AsyncPipe } from '@angular/common';
import { BroadcastItemComponent } from '../../broadcast-item/broadcast-item.component';
import { SharedModule } from '../../../../shared/shared.module';
import { CpvrProfileComponent } from '../cpvr-profile/cpvr-profile.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cpvr-borrow',
  templateUrl: './cpvr-borrow.component.html',
  styleUrls: ['./cpvr-borrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, BroadcastItemComponent, SharedModule, CpvrProfileComponent, AsyncPipe, TranslateModule]
})
export class CpvrBorrowComponent implements OnInit {
  protected broadcast$!: Observable<Broadcast>;
  protected readonly MediaImageStack = MediaImageStack;
  private readonly _slotConfirmed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly slotConfirmed$: Observable<boolean> = this._slotConfirmed$.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) private broadcast: Broadcast,
    private broadcastApiService: BroadcastApiService,
    private broadcastActionService: BroadcastActionService,
    private dialogRef: MatDialogRef<CpvrBorrowComponent>
  ) {}

  ngOnInit(): void {
    this.broadcast$ = of(this.broadcast).pipe(
      switchMap((broadcast) =>
        iif(() => !broadcast.thumbnail, this.broadcastApiService.get(broadcast.id), of(broadcast))
      )
    );
  }

  confirmSlot(): void {
    this._slotConfirmed$.next(true);
  }

  close(): void {
    this.dialogRef.close();
  }

  play(): void {
    this.broadcastActionService.handlePlay$(this.broadcast).subscribe(() => this.close());
  }
}
