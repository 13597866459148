import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  AppComebackTimeService,
  AuthenticationService,
  DatadogRumService,
  User,
  UserProfile,
  UserProfileService,
  UserProfileSwitchParams
} from '@teleboy/web.user';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, finalize, Observable, of, switchMap, tap } from 'rxjs';
import { ClientDataService } from '../../../core/services/client-data.service';
import { SnackbarService, SnackbarType } from '../../../core/services/snackbar.service';
import {
  ProfileImageComponent,
  ProfileImageSize
} from '../../../shared/components/profile-image/profile-image.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AsyncPipe, NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DatadogLoggingService } from '@teleboy/web.core';
import { IconComponent, TruncatePipe } from '@teleboy/web.ui';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, AsyncPipe, ProfileImageComponent, TranslateModule, TruncatePipe, IconComponent]
})
export class ProfileListComponent implements OnInit {
  @Output() selected: EventEmitter<UserProfile> = new EventEmitter<UserProfile>();

  activeUserId!: number | null;
  isEditMode = false;
  isMobileScreen!: boolean;
  imageSize!: ProfileImageSize;
  imageAddSize!: 'l' | 'xxl';
  userProfiles$!: Observable<UserProfile[]>;

  readonly busy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly selectedProfile$: BehaviorSubject<UserProfile | null> = new BehaviorSubject<UserProfile | null>(null);
  readonly user = this.authenticationService.user;

  constructor(
    private appComebackTimeService: AppComebackTimeService,
    private authenticationService: AuthenticationService,
    private clientDataService: ClientDataService,
    private deviceDetectorService: DeviceDetectorService,
    private datadogLoggingService: DatadogLoggingService,
    private datadogRumService: DatadogRumService,
    private router: Router,
    private snackbarService: SnackbarService,
    private userProfileService: UserProfileService
  ) {}

  ngOnInit(): void {
    this.userProfiles$ = this.getProfiles();
    this.isMobileScreen = this.deviceDetectorService.isMobile();
    this.imageSize = this.deviceDetectorService.isMobile() ? ProfileImageSize.Small : ProfileImageSize.Medium;
    this.imageAddSize = this.deviceDetectorService.isMobile() ? 'l' : 'xxl';
  }

  addProfile(): void {
    void this.router.navigateByUrl('/user-profile-create');
  }

  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
  }

  onUserProfileClick(userProfile: UserProfile) {
    if (this.busy$.value) {
      return;
    }

    if (this.isEditMode) {
      void this.router.navigate(['/user-profile-create'], {
        queryParams: { id: userProfile.userId }
      });

      return;
    }

    if (userProfile.isCurrent) {
      this.selected.emit(userProfile);
      this.selectedProfile$.next(userProfile);
      this.appComebackTimeService.refreshProductComebackTime();
      return;
    }

    this.activeUserId = userProfile.userId;
    this.busy$.next(true);
    const userProfileSwitchParams = new UserProfileSwitchParams();

    this.userProfileService
      .switchProfile$(userProfile.userId, userProfileSwitchParams, !userProfile.isCurrent)
      .pipe(
        tap((user: User) => {
          this.datadogRumService.setUser(user);
          this.datadogLoggingService.setUser(user.id);
        }),
        tap(() => this.selectedProfile$.next(userProfile)),
        switchMap(() => this.clientDataService.refreshUserData()),
        finalize(() => this.selected.next(userProfile))
      )
      .subscribe({
        error: () => this.snackbarService.openSnackbar('userProfiles.restricted.switchError', SnackbarType.ERROR)
      });
  }

  isBusy(userId: number): boolean {
    return this.busy$.value && this.activeUserId === userId;
  }

  private getProfiles(): Observable<UserProfile[]> {
    return this.userProfileService.profiles$.pipe(catchError(() => of([])));
  }
}
