import { Vocabularies } from 'bitmovin-player-ui/dist/js/framework/localization/i18n';

export const BitmovinLocalizationConfig: Vocabularies = {
  de: {
    'colors.cyan': 'türkis',
    'colors.magenta': 'magenta',
    'settings.subtitles.characterEdge.raised': 'erhoben',
    'settings.subtitles.characterEdge.depressed': 'depressed',
    'settings.subtitles.characterEdge.uniform': 'uniform',
    'settings.subtitles.characterEdge.dropshadowed': 'Schlagschatten',
    'settings.subtitles.font.family.monospacedserif': 'einzeilig serif',
    'settings.subtitles.font.family.proportionalserif': 'proportional serif',
    'settings.subtitles.font.family.monospacedsansserif': 'einzeilig sans serif',
    'settings.subtitles.font.family.proportionalsansserif': 'proportional sans serif',
    'settings.subtitles.font.family.casual': 'casual',
    'settings.subtitles.font.family.cursive': 'kursiv',
    'settings.subtitles.font.family.smallcapital': 'small capital',
    normal: 'normal'
  },
  it: {
    'settings.video.quality': 'Qualità video',
    'settings.subtitles.window.color': 'Colore della finestra',
    'settings.subtitles.window.opacity': 'Opacità della finestra',
    'subtitle.example': 'esempio di sottotitolo',
    'settings.subtitles': 'Sottotitoli',
    'settings.subtitles.font.color': 'Colore del carattere',
    'settings.subtitles.font.opacity': 'Opacità del carattere',
    'settings.subtitles.background.color': 'Colore di sfondo',
    'settings.subtitles.background.opacity': 'Opacità dello sfondo',
    'colors.white': 'bianco',
    'colors.black': 'nero',
    'colors.red': 'rosso',
    'colors.green': 'verde',
    'colors.blue': 'blu',
    'colors.cyan': 'ciano',
    'colors.yellow': 'giallo',
    'colors.magenta': 'magenta',
    'settings.subtitles.font.size': 'Dimensione del carattere',
    'settings.subtitles.characterEdge': 'Bordo del carattere',
    'settings.subtitles.characterEdge.raised': 'sollevato',
    'settings.subtitles.characterEdge.depressed': 'depressed',
    'settings.subtitles.characterEdge.uniform': 'uniforme',
    'settings.subtitles.characterEdge.dropshadowed': 'ombra a goccia',
    'settings.subtitles.font.family': 'Famiglia di caratteri',
    'settings.subtitles.font.family.monospacedserif': 'serif monospaziato',
    'settings.subtitles.font.family.proportionalserif': 'proporzionale serif',
    'settings.subtitles.font.family.monospacedsansserif': 'monospaziato sans serif',
    'settings.subtitles.font.family.proportionalsansserif': 'proporzionale sans serif',
    'settings.subtitles.font.family.casual': 'casual',
    'settings.subtitles.font.family.cursive': 'corsivo',
    'settings.subtitles.font.family.smallcapital': 'small capital',
    speed: 'Velocità',
    back: 'Indietro',
    reset: 'Azzeramento',
    normal: 'normale',
    default: 'predefinito'
  },
  fr: {
    'settings.video.quality': 'Qualité de la vidéo',
    'settings.subtitles.window.color': 'Couleur de la fenêtre',
    'settings.subtitles.window.opacity': 'Opacité de la fenêtre',
    'subtitle.example': 'exemple de sous-titre',
    'settings.subtitles': 'Sous-titres',
    'settings.subtitles.font.color': 'Couleur de la police',
    'settings.subtitles.font.opacity': 'Opacité de la police',
    'settings.subtitles.background.color': 'Couleur de fond',
    'settings.subtitles.background.opacity': "Opacité de l'arrière-plan",
    'colors.white': 'blanc',
    'colors.black': 'noir',
    'colors.red': 'rouge',
    'colors.green': 'vert',
    'colors.blue': 'bleu',
    'colors.cyan': 'cyan',
    'colors.yellow': 'jaune',
    'colors.magenta': 'magenta',
    'settings.subtitles.font.size': 'Taille de la police',
    'settings.subtitles.characterEdge': 'Bordure de caractère',
    'settings.subtitles.characterEdge.raised': 'soulevés',
    'settings.subtitles.characterEdge.depressed': 'depressed',
    'settings.subtitles.characterEdge.uniform': 'uniforme',
    'settings.subtitles.characterEdge.dropshadowed': 'ombres portées',
    'settings.subtitles.font.family': 'Famille de polices',
    'settings.subtitles.font.family.monospacedserif': 'monospaced serif',
    'settings.subtitles.font.family.proportionalserif': 'proportional serif',
    'settings.subtitles.font.family.monospacedsansserif': 'monospaced sans serif',
    'settings.subtitles.font.family.proportionalsansserif': 'sans empattement proportionnel',
    'settings.subtitles.font.family.casual': 'casual',
    'settings.subtitles.font.family.cursive': 'cursive',
    'settings.subtitles.font.family.smallcapital': 'small capital',
    speed: 'La vitesse',
    back: 'Retour',
    reset: 'Remise à zéro',
    normal: 'normal',
    default: 'par défaut'
  }
};
