<app-close-button (closed)="close(false)"></app-close-button>

<div class="profiles-upsell">
  <div class="profiles-upsell__image">
      <img [src]="'/shared/profiles/profile-upsell.svg' | staticAsset" alt="Teleboy Profiles"/>
  </div>

  <h1 class="h2 profiles-upsell__title">{{ 'upsell.profiles.title' | translate }}</h1>

  <div class="profiles-upsell__info">
    <div class="profiles-upsell__info__left">
      <p> {{ 'upsell.profiles.infoText' | translate }} </p>
    </div>

    <div class="profiles-upsell__info__right">
      <strong>{{ 'upsell.profiles.infoTextList' | translate }}</strong>
      <ul class="profiles-upsell__info__checklist">
        <li><tbui-icon iconId="watchlist" class="profiles-upsell__info__check"></tbui-icon> {{ 'upsell.profiles.channelsList' | translate }}</li>
        <li><tbui-icon iconId="watchlist" class="profiles-upsell__info__check"></tbui-icon> {{ 'upsell.profiles.watchlist' | translate }}</li>
        <li><tbui-icon iconId="watchlist" class="profiles-upsell__info__check"></tbui-icon> {{ 'upsell.profiles.alerts' | translate }}</li>
        <li><tbui-icon iconId="watchlist" class="profiles-upsell__info__check"></tbui-icon> {{ 'upsell.profiles.recommendations' | translate }}</li>
      </ul>
    </div>
  </div>

  @switch (subscriptionInfo) {
    @case (Subscription.Onboarding) {
      <ng-container *ngTemplateOutlet="onboardingInfo"></ng-container>
    }
    @case (Subscription.UpdateSubscription) {
      <ng-container *ngTemplateOutlet="updateSubscription"></ng-container>
    }
    @case (Subscription.BuySubscription) {
      <ng-container *ngTemplateOutlet="buySubscription"></ng-container>
    }
  }
</div>

<ng-template #buySubscription>
  <div class="profiles-upsell__footer">
    <strong>{{ 'upsell.profiles.profilesNoSubscription.needAbo' | translate }}</strong>

    <button (click)="goToShop()" [ngClass]="{'btn--busy': busy$ | async }" class="btn btn__primary profiles-upsell__footer__button" appAutofocus
            appDataLayer dataLayerEventName="upsell_buy_subscription">
      {{ 'upsell.profiles.profilesNoSubscription.cta.buy' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #updateSubscription>
    <div class="profiles-upsell__footer">
      <p><strong>{{ 'upsell.profiles.freeSubscription.testForFree' | translate }}</strong></p>
      <p>{{ 'upsell.profiles.freeSubscription.fromSeptember' | translate }}</p>

      <button (click)="goToShop()" [ngClass]="{'btn--busy': busy$ | async }" class="btn btn__primary profiles-upsell__footer__button" appAutofocus
              appDataLayer dataLayerEventName="upsell_update_subscription">
        {{ 'upsell.profiles.freeSubscription.cta.use' | translate }}
      </button>
    </div>
</ng-template>

<ng-template #onboardingInfo>
  <div class="profiles-upsell__footer">
    <p><strong>{{ 'upsell.profiles.fullSubscription.teleboyProfile' | translate }}</strong></p>
    <p>{{ 'upsell.profiles.fullSubscription.free' | translate }}</p>

    <button [ngClass]="{'btn--busy': busy$ | async }" (click)="initializeProfiles()" class="btn btn__primary profiles-upsell__footer__button" appAutofocus
            appDataLayer dataLayerEventName="upsell_onboarding">
      {{ 'upsell.profiles.fullSubscription.cta.use' | translate }}
    </button>
  </div>
</ng-template>





