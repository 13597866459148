import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '@teleboy/web.user';
import { map } from 'rxjs/operators';
import {
  GenreStorageService,
  LandingpageLane,
  TeaserDevice,
  TeaserUrlCreator,
  TeaserUrlParser
} from '@teleboy/web.epg';
import { SnackbarService, SnackbarType } from '../../../../core/services/snackbar.service';
import { NgIf, AsyncPipe } from '@angular/common';
import { LandingpageComponent } from '../landingpage.component';

@Component({
  templateUrl: './genre-landingpage.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, LandingpageComponent, AsyncPipe]
})
export class GenreLandingpageComponent implements OnInit {
  protected content$!: Observable<{ title: string; lanes: LandingpageLane[] }>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private genreStorageService: GenreStorageService,
    private languageService: LanguageService,
    private router: Router,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.content$ = this.getContent$();
  }

  private getContent$(): Observable<{ title: string; lanes: LandingpageLane[] }> {
    return this.activatedRoute.params.pipe(
      switchMap((params) => {
        const genre = this.genreStorageService.getById(parseInt(params['genre'], 10));
        return genre || throwError(() => 'not found');
      }),
      map((genre) => {
        const teaserUrl = new TeaserUrlCreator()
          .setBroadcastGenres([genre.id])
          .setSources(['tip', 'replay', 'content', 'community', 'programmtip', 'programm'])
          .allStations()
          .asTeaserUrl();

        return {
          title: genre.getLocalizedName(this.languageService.getLanguage()),
          lanes: new TeaserUrlParser(teaserUrl).toParams(TeaserDevice.WEB, this.languageService.getLanguage())
        };
      }),
      catchError(() => {
        this.snackbarService.openSnackbar('landingpage.error.404', SnackbarType.ERROR);
        return throwError(() => this.router.navigate(['', { outlets: { ['landingpage']: null } }]));
      })
    );
  }
}
