import { inject } from '@angular/core';
import { AuthenticationService, SettingsService } from '@teleboy/web.user';
import { StartService } from '../services/start.service';

export const loadingAdGuard = (): boolean => {
  const authenticationService = inject(AuthenticationService);
  const settingsService = inject(SettingsService);
  const startService = inject(StartService);

  const hasLoadingAd = settingsService.getSetting('sales.loadingad.web.active', false, false, true);
  const isFreeUser = !authenticationService.user?.isPlusUser;

  if (isFreeUser && hasLoadingAd) {
    return true;
  }

  startService.goToDestination();
  return false;
};
