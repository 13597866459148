import { LogLevel } from 'bitmovin-player';
import environmentConfig from './environment.prod.json';

export const environment = {
  production: true,
  authCookie: environmentConfig.authCookie,
  api: {
    url: environmentConfig.api.url,
    key: 'rZahdea7Ce8s1MEmGaS0VeeE1Ar0abyN6hiESdKe0fi2FlgjeGR8vQRsbstaouuY',
    version: '2.0'
  },
  trackJs: {
    application: 'teleboy-tv',
    token: 'e773b7473d224431b776814bcec43571'
  },
  datadog: {
    rum: {
      env: 'prod',
      sessionSampleRate: 10,
      sessionReplaySampleRate: 10,
      traceSampleRate: 100
    },
    logger: {
      env: 'prod',
      sessionSampleRate: 100,
      telemetrySampleRate: 100
    }
  },
  webui: {
    url: environmentConfig.webui.url
  },
  cockpit: {
    url: environmentConfig.cockpit.url
  },
  static: {
    url: environmentConfig.static.url
  },
  bitmovin: {
    playerKey: 'd94cfbfe-829f-4f35-8f98-8b0b1a784647',
    analyticsKey: '4C16D30A-AA04-40AB-AFDF-0D5DF8DC9E3E',
    logLevel: LogLevel.OFF
  },
  googleTagManager: {
    id: 'GTM-5PP2685'
  },
  secrets: {
    anonymousStreamSecret: '291482ee7332550367c172fe29f80e547c2f0e0952a2b21c6f9c29f80e547c2f0e09eae699adcd8bece1713saf'
  },
  voucher: {
    max: 'V0VCMzBNQVg%3D',
    one: 'V0VMQ09NRU9ORQ==',
    onepro: 'V0VMQ09NRU9ORVBSTw=='
  },
  features: {
    profiles: true
  }
};
