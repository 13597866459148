import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MediaImageStack } from '@teleboy/web.core';
import { LandingpageLane, Teaser, TeaserApiService, TeaserDevice, TeaserUrlParser } from '@teleboy/web.epg';
import { map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { LanguageService } from '@teleboy/web.user';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteDataService } from '../../../../core/services/route-data.service';
import { NgIf, NgStyle, AsyncPipe } from '@angular/common';
import { LandingpageComponent } from '../landingpage.component';

@Component({
  selector: 'app-teaser-landingpage',
  templateUrl: './teaser-landingpage.component.html',
  styleUrls: ['./teaser-landingpage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, LandingpageComponent, NgStyle, AsyncPipe]
})
export class TeaserLandingpageComponent implements OnInit {
  lanes!: LandingpageLane[];
  teaser$!: Observable<Teaser>;
  backgroundImage$!: Observable<string>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private router: Router,
    private routeDataService: RouteDataService,
    private teaserApiService: TeaserApiService
  ) {}

  ngOnInit(): void {
    this.teaser$ = this.loadTeaser().pipe(
      tap((teaser) => {
        this.lanes = new TeaserUrlParser(teaser.getLinkUrlParams()).toParams(
          TeaserDevice.WEB,
          this.languageService.getLanguage()
        );
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.backgroundImage$ = this.teaser$.pipe(
      map((teaser: Teaser) => teaser.image!.getSrc(MediaImageStack.RAW)),
      map((imageUrl: string) => `url(${imageUrl})`)
    );
  }

  private loadTeaser(): Observable<Teaser> {
    return this.activatedRoute.params.pipe(
      switchMap((params) => {
        const routeTeaser = this.routeDataService.getDataForRoute<Teaser>(this.router.url);
        if (routeTeaser) {
          return of(routeTeaser);
        }

        return this.teaserApiService.getTeaser(params['id']);
      })
    );
  }
}
