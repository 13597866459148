<div *ngIf="buttons$ | async as buttons" class="buttons-container">
    <ng-container *ngFor="let button of buttons; index as index">
      <a [href]="button.link" target="_blank" class="btn btn__teaser"
         [attr.id]="'teaser-button-' + index"
         [ngClass]="{ 'btn__primary': button.isTopPriority() }">
        {{button.ctaText}}
      </a>
    </ng-container>
</div>

