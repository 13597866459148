import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DatadogRumService, User, UserProfile, UserProfileService, UserProfileSwitchParams } from '@teleboy/web.user';
import { BehaviorSubject, finalize, switchMap, tap } from 'rxjs';
import { ClientDataService } from '../../../core/services/client-data.service';
import { SnackbarService, SnackbarType } from '../../../core/services/snackbar.service';
import { DatadogLoggingService } from '@teleboy/web.core';

@Component({
  selector: 'app-profile-restriction',
  templateUrl: './profile-restriction.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ProfileRestrictionComponent {
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private clientDataService: ClientDataService,
    private datadogLoggingService: DatadogLoggingService,
    private datadogRumService: DatadogRumService,
    private dialogRef: MatDialogRef<ProfileRestrictionComponent>,
    private snackbarService: SnackbarService,
    private userProfileService: UserProfileService
  ) {}

  switchToMainProfile() {
    this.isLoading$.next(true);

    this.userProfileService
      .getParentProfile$()
      .pipe(
        switchMap((parent: UserProfile) => {
          const userProfileSwitchParams = new UserProfileSwitchParams();
          return this.userProfileService.switchProfile$(parent.userId, userProfileSwitchParams);
        }),
        tap((user: User) => {
          this.datadogRumService.setUser(user);
          this.datadogLoggingService.setUser(user.id);
        }),
        switchMap(() => this.clientDataService.refreshUserData()),
        finalize(() => this.isLoading$.next(false))
      )
      .subscribe({
        next: () => this.close(true),
        error: () => {
          this.snackbarService.openSnackbar('userProfiles.restricted.switchError', SnackbarType.ERROR);
          this.close(false);
        }
      });
  }

  close(result?: boolean) {
    this.dialogRef.close(result);
  }
}
