import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthenticationService,
  LanguageService,
  UserGender,
  UserProfile,
  UserProfileApiService,
  UserProfileCreateParams,
  UserProfileGender,
  UserProfileService,
  UserProfileUpdateParams
} from '@teleboy/web.user';
import { Teaser, TeaserApiService, TeaserDevice, TeaserParams } from '@teleboy/web.epg';
import { BehaviorSubject, catchError, EMPTY, finalize, map, Observable, take, tap } from 'rxjs';
import { SnackbarService, SnackbarType } from '../../../core/services/snackbar.service';
import { MediaImage } from '@teleboy/web.core';
import { AsyncPipe, NgClass } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProfileImageComponent } from '../../../shared/components/profile-image/profile-image.component';
import { SharedModule } from '../../../shared/shared.module';

export enum EditMode {
  Name = 'name',
  Image = 'image'
}

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
  imports: [TranslateModule, NgClass, AsyncPipe, ProfileImageComponent, ReactiveFormsModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditProfileComponent implements OnInit {
  EditMode = EditMode;
  editMode = EditMode.Name;
  images$!: Observable<Teaser[]>;
  selectedImage!: MediaImage;
  isEditProfile!: boolean;
  userProfile!: UserProfile;

  readonly form = new UntypedFormGroup({ profileName: new UntypedFormControl('', Validators.required) });
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly user = this.authenticationService.user;

  private userEditProfileId!: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private changeDetectorRef: ChangeDetectorRef,
    private languageService: LanguageService,
    private router: Router,
    private snackbarService: SnackbarService,
    private teaserApiService: TeaserApiService,
    private translateService: TranslateService,
    private userProfileApiService: UserProfileApiService,
    private userProfileService: UserProfileService
  ) {}

  ngOnInit(): void {
    this.userEditProfileId = Number(this.activatedRoute.snapshot.queryParamMap.get('id'));
    this.isEditProfile = !!this.userEditProfileId;
    this.populateProfileForm();

    this.images$ = this.loadImages$();
  }

  setSelectedImage(image: MediaImage) {
    this.selectedImage = image;
    this.editMode = EditMode.Name;
  }

  saveProfile() {
    if (!this.selectedImage) {
      this.setEditImageMode();
      return;
    }
    this.createProfile();
  }

  private createProfile(): void {
    if (this.isLoading$.value) {
      return;
    }

    if (!this.form.valid) {
      this.snackbarService.openSnackbar('userProfiles.create.name.error', SnackbarType.ERROR);

      return;
    }

    this.isLoading$.next(true);

    if (this.isEditProfile) {
      this.updateUserProfile();
    } else {
      this.createUserProfile();
    }
  }

  createUserProfile(): void {
    const params: UserProfileCreateParams = new UserProfileCreateParams(
      this.form.get('profileName')?.value,
      this.selectedImage.hash
    )
      .setBirthday(this.user.birthday)
      .setGender(this.user.gender === UserGender.MALE ? UserProfileGender.MALE : UserProfileGender.FEMALE);

    this.userProfileApiService
      .createUserProfile(params)
      .pipe(
        tap(() => {
          this.userProfileService.reloadProfiles();
          this.onBack();
        }),
        finalize(() => {
          this.isLoading$.next(false);
        })
      )
      .subscribe();
  }

  updateUserProfile(): void {
    const params: UserProfileUpdateParams = new UserProfileUpdateParams()
      .setName(this.form.get('profileName')?.value)
      .setAccountImageHash(this.selectedImage.hash);

    this.userProfileApiService
      .updateUserProfile(this.userEditProfileId, params)
      .pipe(
        tap(() => {
          this.userProfileService.reloadProfiles();
          this.onBack();
        }),
        finalize(() => {
          this.isLoading$.next(false);
        })
      )
      .subscribe();
  }

  deleteUserProfile(): void {
    if (confirm(`${this.translateService.instant('userProfiles.delete.confirm.label')}`)) {
      this.userProfileApiService.deleteUserProfile(this.userEditProfileId).subscribe(() => {
        this.userProfileService.reloadProfiles();
        this.onBack();
      });
    }
  }

  setEditImageMode() {
    this.editMode = EditMode.Image;
  }

  setEditNameMode() {
    this.editMode = EditMode.Name;
  }

  onBack(): void {
    void this.router.navigateByUrl('/profile-settings-list');
  }

  private loadImages$(): Observable<Teaser[]> {
    const profileImagesTeaserTag = 'profile_picture';

    const params = new TeaserParams(TeaserDevice.WEB, this.languageService.getLanguage()).setTags([
      profileImagesTeaserTag
    ]);

    return this.teaserApiService.getTeasers(params).pipe(map((response) => response.items));
  }

  private populateProfileForm(): void {
    if (this.isEditProfile) {
      this.userProfileService
        .getProfileById$(this.userEditProfileId)
        .pipe(
          take(1),
          catchError(() => {
            this.onBack();
            return EMPTY;
          })
        )
        .subscribe((userProfile) => {
          this.selectedImage = userProfile?.profileImage;
          this.form.get('profileName')?.setValue(userProfile?.name);
          this.userProfile = userProfile;
          this.changeDetectorRef.markForCheck();
        });
    }
  }
}
