<app-close-button (closed)="close(false)"></app-close-button>

<app-info [icon]="'navi-user'"
          [title]="'userProfiles.restricted.title' | translate"
          [text]="'userProfiles.restricted.text' | translate">
</app-info>

<div class="popup__footer btn__list-h btn__list-h--apart" cta>
  <button class="btn btn__secondary" (click)="close()">{{ 'userProfiles.restricted.cta.cancel' | translate }}</button>
  <button class="btn btn__primary"
          [ngClass]="{'btn--busy': isLoading$ | async}"
          (click)="switchToMainProfile()">{{ 'userProfiles.restricted.cta.switch' | translate }}</button>
</div>
