import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RecordingApiService, StationLogoSize, StationLogoComponent } from '@teleboy/web.epg';
import { Serialgroup } from '@teleboy/web.epg/lib/models/serialgroup.model';
import { SnackbarService, SnackbarType } from '../../../../core/services/snackbar.service';
import { BehaviorSubject, finalize, Observable, tap } from 'rxjs';
import { NgIf, NgClass, NgStyle, AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-serialgroup-item',
  templateUrl: './serialgroup-item.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, StationLogoComponent, NgClass, NgStyle, AsyncPipe, TranslateModule, IconComponent]
})
export class SerialgroupItemComponent {
  StationLogoSize = StationLogoSize;

  @Input() serialgroup!: Serialgroup;
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  private readonly _busy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly busy$: Observable<boolean> = this._busy$.asObservable();

  constructor(
    private recordingApiService: RecordingApiService,
    private snackbarService: SnackbarService
  ) {}

  delete(): void {
    if (confirm('Sicher, dass diese Serienaufnahme gestoppt werden soll?')) {
      this._busy$.next(true);
      this.recordingApiService
        .unsubscribeFromSerialgroup(this.serialgroup.id)
        .pipe(
          tap(() => this.snackbarService.openSnackbar('pvr.serialgroups.delete.success', SnackbarType.SUCCESS)),
          finalize(() => this._busy$.next(false))
        )
        .subscribe({
          next: () => this.deleted.emit(),
          error: () => this.snackbarService.openSnackbar('pvr.serialgroups.delete.failed', SnackbarType.ERROR)
        });
    }
  }
}
