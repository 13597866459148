"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UIFactory = void 0;
var subtitleoverlay_1 = require("./components/subtitleoverlay");
var settingspanelpage_1 = require("./components/settingspanelpage");
var settingspanelitem_1 = require("./components/settingspanelitem");
var videoqualityselectbox_1 = require("./components/videoqualityselectbox");
var playbackspeedselectbox_1 = require("./components/playbackspeedselectbox");
var audiotrackselectbox_1 = require("./components/audiotrackselectbox");
var audioqualityselectbox_1 = require("./components/audioqualityselectbox");
var settingspanel_1 = require("./components/settingspanel");
var subtitlesettingspanelpage_1 = require("./components/subtitlesettings/subtitlesettingspanelpage");
var settingspanelpageopenbutton_1 = require("./components/settingspanelpageopenbutton");
var subtitlesettingslabel_1 = require("./components/subtitlesettings/subtitlesettingslabel");
var subtitleselectbox_1 = require("./components/subtitleselectbox");
var controlbar_1 = require("./components/controlbar");
var container_1 = require("./components/container");
var playbacktimelabel_1 = require("./components/playbacktimelabel");
var seekbar_1 = require("./components/seekbar");
var seekbarlabel_1 = require("./components/seekbarlabel");
var playbacktogglebutton_1 = require("./components/playbacktogglebutton");
var volumetogglebutton_1 = require("./components/volumetogglebutton");
var volumeslider_1 = require("./components/volumeslider");
var spacer_1 = require("./components/spacer");
var pictureinpicturetogglebutton_1 = require("./components/pictureinpicturetogglebutton");
var airplaytogglebutton_1 = require("./components/airplaytogglebutton");
var casttogglebutton_1 = require("./components/casttogglebutton");
var vrtogglebutton_1 = require("./components/vrtogglebutton");
var settingstogglebutton_1 = require("./components/settingstogglebutton");
var fullscreentogglebutton_1 = require("./components/fullscreentogglebutton");
var uicontainer_1 = require("./components/uicontainer");
var bufferingoverlay_1 = require("./components/bufferingoverlay");
var playbacktoggleoverlay_1 = require("./components/playbacktoggleoverlay");
var caststatusoverlay_1 = require("./components/caststatusoverlay");
var titlebar_1 = require("./components/titlebar");
var recommendationoverlay_1 = require("./components/recommendationoverlay");
var watermark_1 = require("./components/watermark");
var errormessageoverlay_1 = require("./components/errormessageoverlay");
var adclickoverlay_1 = require("./components/adclickoverlay");
var admessagelabel_1 = require("./components/admessagelabel");
var adskipbutton_1 = require("./components/adskipbutton");
var closebutton_1 = require("./components/closebutton");
var metadatalabel_1 = require("./components/metadatalabel");
var playerutils_1 = require("./playerutils");
var label_1 = require("./components/label");
var castuicontainer_1 = require("./components/castuicontainer");
var uimanager_1 = require("./uimanager");
var i18n_1 = require("./localization/i18n");
var subtitlelistbox_1 = require("./components/subtitlelistbox");
var audiotracklistbox_1 = require("./components/audiotracklistbox");
var spatialnavigation_1 = require("./spatialnavigation/spatialnavigation");
var rootnavigationgroup_1 = require("./spatialnavigation/rootnavigationgroup");
var ListNavigationGroup_1 = require("./spatialnavigation/ListNavigationGroup");
var ecomodecontainer_1 = require("./components/ecomodecontainer");
var UIFactory;
(function (UIFactory) {
  function buildDefaultUI(player, config) {
    if (config === void 0) {
      config = {};
    }
    return UIFactory.buildModernUI(player, config);
  }
  UIFactory.buildDefaultUI = buildDefaultUI;
  function buildDefaultSmallScreenUI(player, config) {
    if (config === void 0) {
      config = {};
    }
    return UIFactory.buildModernSmallScreenUI(player, config);
  }
  UIFactory.buildDefaultSmallScreenUI = buildDefaultSmallScreenUI;
  function buildDefaultCastReceiverUI(player, config) {
    if (config === void 0) {
      config = {};
    }
    return UIFactory.buildModernCastReceiverUI(player, config);
  }
  UIFactory.buildDefaultCastReceiverUI = buildDefaultCastReceiverUI;
  function buildDefaultTvUI(player, config) {
    if (config === void 0) {
      config = {};
    }
    return UIFactory.buildModernTvUI(player, config);
  }
  UIFactory.buildDefaultTvUI = buildDefaultTvUI;
  function modernUI(config) {
    var subtitleOverlay = new subtitleoverlay_1.SubtitleOverlay();
    var mainSettingsPanelPage;
    var components = [new settingspanelitem_1.SettingsPanelItem(i18n_1.i18n.getLocalizer('settings.video.quality'), new videoqualityselectbox_1.VideoQualitySelectBox()), new settingspanelitem_1.SettingsPanelItem(i18n_1.i18n.getLocalizer('speed'), new playbackspeedselectbox_1.PlaybackSpeedSelectBox()), new settingspanelitem_1.SettingsPanelItem(i18n_1.i18n.getLocalizer('settings.audio.track'), new audiotrackselectbox_1.AudioTrackSelectBox()), new settingspanelitem_1.SettingsPanelItem(i18n_1.i18n.getLocalizer('settings.audio.quality'), new audioqualityselectbox_1.AudioQualitySelectBox())];
    if (config.ecoMode) {
      var ecoModeContainer = new ecomodecontainer_1.EcoModeContainer();
      ecoModeContainer.setOnToggleCallback(function () {
        // forces the browser to re-calculate the height of the settings panel when adding/removing elements
        settingsPanel.getDomElement().css({
          width: '',
          height: ''
        });
      });
      components.unshift(ecoModeContainer);
    }
    mainSettingsPanelPage = new settingspanelpage_1.SettingsPanelPage({
      components: components
    });
    var settingsPanel = new settingspanel_1.SettingsPanel({
      components: [mainSettingsPanelPage],
      hidden: true
    });
    var subtitleSettingsPanelPage = new subtitlesettingspanelpage_1.SubtitleSettingsPanelPage({
      settingsPanel: settingsPanel,
      overlay: subtitleOverlay
    });
    var subtitleSelectBox = new subtitleselectbox_1.SubtitleSelectBox();
    var subtitleSettingsOpenButton = new settingspanelpageopenbutton_1.SettingsPanelPageOpenButton({
      targetPage: subtitleSettingsPanelPage,
      container: settingsPanel,
      ariaLabel: i18n_1.i18n.getLocalizer('settings.subtitles'),
      text: i18n_1.i18n.getLocalizer('open')
    });
    mainSettingsPanelPage.addComponent(new settingspanelitem_1.SettingsPanelItem(new subtitlesettingslabel_1.SubtitleSettingsLabel({
      text: i18n_1.i18n.getLocalizer('settings.subtitles'),
      opener: subtitleSettingsOpenButton
    }), subtitleSelectBox, {
      role: 'menubar'
    }));
    settingsPanel.addComponent(subtitleSettingsPanelPage);
    var controlBar = new controlbar_1.ControlBar({
      components: [settingsPanel, new container_1.Container({
        components: [new playbacktimelabel_1.PlaybackTimeLabel({
          timeLabelMode: playbacktimelabel_1.PlaybackTimeLabelMode.CurrentTime,
          hideInLivePlayback: true
        }), new seekbar_1.SeekBar({
          label: new seekbarlabel_1.SeekBarLabel()
        }), new playbacktimelabel_1.PlaybackTimeLabel({
          timeLabelMode: playbacktimelabel_1.PlaybackTimeLabelMode.TotalTime,
          cssClasses: ['text-right']
        })],
        cssClasses: ['controlbar-top']
      }), new container_1.Container({
        components: [new playbacktogglebutton_1.PlaybackToggleButton(), new volumetogglebutton_1.VolumeToggleButton(), new volumeslider_1.VolumeSlider(), new spacer_1.Spacer(), new pictureinpicturetogglebutton_1.PictureInPictureToggleButton(), new airplaytogglebutton_1.AirPlayToggleButton(), new casttogglebutton_1.CastToggleButton(), new vrtogglebutton_1.VRToggleButton(), new settingstogglebutton_1.SettingsToggleButton({
          settingsPanel: settingsPanel
        }), new fullscreentogglebutton_1.FullscreenToggleButton()],
        cssClasses: ['controlbar-bottom']
      })]
    });
    return new uicontainer_1.UIContainer({
      components: [subtitleOverlay, new bufferingoverlay_1.BufferingOverlay(), new playbacktoggleoverlay_1.PlaybackToggleOverlay(), new caststatusoverlay_1.CastStatusOverlay(), controlBar, new titlebar_1.TitleBar(), new recommendationoverlay_1.RecommendationOverlay(), new watermark_1.Watermark(), new errormessageoverlay_1.ErrorMessageOverlay()],
      hideDelay: 2000,
      hidePlayerStateExceptions: [playerutils_1.PlayerUtils.PlayerState.Prepared, playerutils_1.PlayerUtils.PlayerState.Paused, playerutils_1.PlayerUtils.PlayerState.Finished]
    });
  }
  UIFactory.modernUI = modernUI;
  function modernAdsUI() {
    return new uicontainer_1.UIContainer({
      components: [new bufferingoverlay_1.BufferingOverlay(), new adclickoverlay_1.AdClickOverlay(), new playbacktoggleoverlay_1.PlaybackToggleOverlay(), new container_1.Container({
        components: [new admessagelabel_1.AdMessageLabel({
          text: i18n_1.i18n.getLocalizer('ads.remainingTime')
        }), new adskipbutton_1.AdSkipButton()],
        cssClass: 'ui-ads-status'
      }), new controlbar_1.ControlBar({
        components: [new container_1.Container({
          components: [new playbacktogglebutton_1.PlaybackToggleButton(), new volumetogglebutton_1.VolumeToggleButton(), new volumeslider_1.VolumeSlider(), new spacer_1.Spacer(), new fullscreentogglebutton_1.FullscreenToggleButton()],
          cssClasses: ['controlbar-bottom']
        })]
      })],
      cssClasses: ['ui-skin-ads'],
      hideDelay: 2000,
      hidePlayerStateExceptions: [playerutils_1.PlayerUtils.PlayerState.Prepared, playerutils_1.PlayerUtils.PlayerState.Paused, playerutils_1.PlayerUtils.PlayerState.Finished]
    });
  }
  UIFactory.modernAdsUI = modernAdsUI;
  function modernSmallScreenUI() {
    var subtitleOverlay = new subtitleoverlay_1.SubtitleOverlay();
    var mainSettingsPanelPage = new settingspanelpage_1.SettingsPanelPage({
      components: [new settingspanelitem_1.SettingsPanelItem(i18n_1.i18n.getLocalizer('settings.video.quality'), new videoqualityselectbox_1.VideoQualitySelectBox()), new settingspanelitem_1.SettingsPanelItem(i18n_1.i18n.getLocalizer('speed'), new playbackspeedselectbox_1.PlaybackSpeedSelectBox()), new settingspanelitem_1.SettingsPanelItem(i18n_1.i18n.getLocalizer('settings.audio.track'), new audiotrackselectbox_1.AudioTrackSelectBox()), new settingspanelitem_1.SettingsPanelItem(i18n_1.i18n.getLocalizer('settings.audio.quality'), new audioqualityselectbox_1.AudioQualitySelectBox())]
    });
    var settingsPanel = new settingspanel_1.SettingsPanel({
      components: [mainSettingsPanelPage],
      hidden: true,
      pageTransitionAnimation: false,
      hideDelay: -1
    });
    var subtitleSettingsPanelPage = new subtitlesettingspanelpage_1.SubtitleSettingsPanelPage({
      settingsPanel: settingsPanel,
      overlay: subtitleOverlay
    });
    var subtitleSettingsOpenButton = new settingspanelpageopenbutton_1.SettingsPanelPageOpenButton({
      targetPage: subtitleSettingsPanelPage,
      container: settingsPanel,
      ariaLabel: i18n_1.i18n.getLocalizer('settings.subtitles'),
      text: i18n_1.i18n.getLocalizer('open')
    });
    var subtitleSelectBox = new subtitleselectbox_1.SubtitleSelectBox();
    mainSettingsPanelPage.addComponent(new settingspanelitem_1.SettingsPanelItem(new subtitlesettingslabel_1.SubtitleSettingsLabel({
      text: i18n_1.i18n.getLocalizer('settings.subtitles'),
      opener: subtitleSettingsOpenButton
    }), subtitleSelectBox, {
      role: 'menubar'
    }));
    settingsPanel.addComponent(subtitleSettingsPanelPage);
    settingsPanel.addComponent(new closebutton_1.CloseButton({
      target: settingsPanel
    }));
    subtitleSettingsPanelPage.addComponent(new closebutton_1.CloseButton({
      target: settingsPanel
    }));
    var controlBar = new controlbar_1.ControlBar({
      components: [new container_1.Container({
        components: [new playbacktimelabel_1.PlaybackTimeLabel({
          timeLabelMode: playbacktimelabel_1.PlaybackTimeLabelMode.CurrentTime,
          hideInLivePlayback: true
        }), new seekbar_1.SeekBar({
          label: new seekbarlabel_1.SeekBarLabel()
        }), new playbacktimelabel_1.PlaybackTimeLabel({
          timeLabelMode: playbacktimelabel_1.PlaybackTimeLabelMode.TotalTime,
          cssClasses: ['text-right']
        })],
        cssClasses: ['controlbar-top']
      })]
    });
    return new uicontainer_1.UIContainer({
      components: [subtitleOverlay, new bufferingoverlay_1.BufferingOverlay(), new caststatusoverlay_1.CastStatusOverlay(), new playbacktoggleoverlay_1.PlaybackToggleOverlay(), new recommendationoverlay_1.RecommendationOverlay(), controlBar, new titlebar_1.TitleBar({
        components: [new metadatalabel_1.MetadataLabel({
          content: metadatalabel_1.MetadataLabelContent.Title
        }), new casttogglebutton_1.CastToggleButton(), new vrtogglebutton_1.VRToggleButton(), new pictureinpicturetogglebutton_1.PictureInPictureToggleButton(), new airplaytogglebutton_1.AirPlayToggleButton(), new volumetogglebutton_1.VolumeToggleButton(), new settingstogglebutton_1.SettingsToggleButton({
          settingsPanel: settingsPanel
        }), new fullscreentogglebutton_1.FullscreenToggleButton()]
      }), settingsPanel, new watermark_1.Watermark(), new errormessageoverlay_1.ErrorMessageOverlay()],
      cssClasses: ['ui-skin-smallscreen'],
      hideDelay: 2000,
      hidePlayerStateExceptions: [playerutils_1.PlayerUtils.PlayerState.Prepared, playerutils_1.PlayerUtils.PlayerState.Paused, playerutils_1.PlayerUtils.PlayerState.Finished]
    });
  }
  UIFactory.modernSmallScreenUI = modernSmallScreenUI;
  function modernSmallScreenAdsUI() {
    return new uicontainer_1.UIContainer({
      components: [new bufferingoverlay_1.BufferingOverlay(), new adclickoverlay_1.AdClickOverlay(), new playbacktoggleoverlay_1.PlaybackToggleOverlay(), new titlebar_1.TitleBar({
        components: [
        // dummy label with no content to move buttons to the right
        new label_1.Label({
          cssClass: 'label-metadata-title'
        }), new fullscreentogglebutton_1.FullscreenToggleButton()]
      }), new container_1.Container({
        components: [new admessagelabel_1.AdMessageLabel({
          text: 'Ad: {remainingTime} secs'
        }), new adskipbutton_1.AdSkipButton()],
        cssClass: 'ui-ads-status'
      })],
      cssClasses: ['ui-skin-ads', 'ui-skin-smallscreen'],
      hideDelay: 2000,
      hidePlayerStateExceptions: [playerutils_1.PlayerUtils.PlayerState.Prepared, playerutils_1.PlayerUtils.PlayerState.Paused, playerutils_1.PlayerUtils.PlayerState.Finished]
    });
  }
  UIFactory.modernSmallScreenAdsUI = modernSmallScreenAdsUI;
  function modernCastReceiverUI() {
    var controlBar = new controlbar_1.ControlBar({
      components: [new container_1.Container({
        components: [new playbacktimelabel_1.PlaybackTimeLabel({
          timeLabelMode: playbacktimelabel_1.PlaybackTimeLabelMode.CurrentTime,
          hideInLivePlayback: true
        }), new seekbar_1.SeekBar({
          smoothPlaybackPositionUpdateIntervalMs: -1
        }), new playbacktimelabel_1.PlaybackTimeLabel({
          timeLabelMode: playbacktimelabel_1.PlaybackTimeLabelMode.TotalTime,
          cssClasses: ['text-right']
        })],
        cssClasses: ['controlbar-top']
      })]
    });
    return new castuicontainer_1.CastUIContainer({
      components: [new subtitleoverlay_1.SubtitleOverlay(), new bufferingoverlay_1.BufferingOverlay(), new playbacktoggleoverlay_1.PlaybackToggleOverlay(), new watermark_1.Watermark(), controlBar, new titlebar_1.TitleBar({
        keepHiddenWithoutMetadata: true
      }), new errormessageoverlay_1.ErrorMessageOverlay()],
      cssClasses: ['ui-skin-cast-receiver'],
      hideDelay: 2000,
      hidePlayerStateExceptions: [playerutils_1.PlayerUtils.PlayerState.Prepared, playerutils_1.PlayerUtils.PlayerState.Paused, playerutils_1.PlayerUtils.PlayerState.Finished]
    });
  }
  UIFactory.modernCastReceiverUI = modernCastReceiverUI;
  function buildModernUI(player, config) {
    if (config === void 0) {
      config = {};
    }
    // show smallScreen UI only on mobile/handheld devices
    var smallScreenSwitchWidth = 600;
    return new uimanager_1.UIManager(player, [{
      ui: modernSmallScreenAdsUI(),
      condition: function (context) {
        return context.isMobile && context.documentWidth < smallScreenSwitchWidth && context.isAd && context.adRequiresUi;
      }
    }, {
      ui: modernAdsUI(),
      condition: function (context) {
        return context.isAd && context.adRequiresUi;
      }
    }, {
      ui: modernSmallScreenUI(),
      condition: function (context) {
        return !context.isAd && !context.adRequiresUi && context.isMobile && context.documentWidth < smallScreenSwitchWidth;
      }
    }, {
      ui: modernUI(config),
      condition: function (context) {
        return !context.isAd && !context.adRequiresUi;
      }
    }], config);
  }
  UIFactory.buildModernUI = buildModernUI;
  function buildModernSmallScreenUI(player, config) {
    if (config === void 0) {
      config = {};
    }
    return new uimanager_1.UIManager(player, [{
      ui: modernSmallScreenAdsUI(),
      condition: function (context) {
        return context.isAd && context.adRequiresUi;
      }
    }, {
      ui: modernSmallScreenUI(),
      condition: function (context) {
        return !context.isAd && !context.adRequiresUi;
      }
    }], config);
  }
  UIFactory.buildModernSmallScreenUI = buildModernSmallScreenUI;
  function buildModernCastReceiverUI(player, config) {
    if (config === void 0) {
      config = {};
    }
    return new uimanager_1.UIManager(player, modernCastReceiverUI(), config);
  }
  UIFactory.buildModernCastReceiverUI = buildModernCastReceiverUI;
  function buildModernTvUI(player, config) {
    if (config === void 0) {
      config = {};
    }
    return new uimanager_1.UIManager(player, [__assign({}, modernTvUI())], config);
  }
  UIFactory.buildModernTvUI = buildModernTvUI;
  function modernTvUI() {
    var subtitleListBox = new subtitlelistbox_1.SubtitleListBox();
    var subtitleListPanel = new settingspanel_1.SettingsPanel({
      components: [new settingspanelpage_1.SettingsPanelPage({
        components: [new settingspanelitem_1.SettingsPanelItem(null, subtitleListBox)]
      })],
      hidden: true
    });
    var audioTrackListBox = new audiotracklistbox_1.AudioTrackListBox();
    var audioTrackListPanel = new settingspanel_1.SettingsPanel({
      components: [new settingspanelpage_1.SettingsPanelPage({
        components: [new settingspanelitem_1.SettingsPanelItem(null, audioTrackListBox)]
      })],
      hidden: true
    });
    var seekBar = new seekbar_1.SeekBar({
      label: new seekbarlabel_1.SeekBarLabel()
    });
    var playbackToggleOverlay = new playbacktoggleoverlay_1.PlaybackToggleOverlay();
    var subtitleToggleButton = new settingstogglebutton_1.SettingsToggleButton({
      settingsPanel: subtitleListPanel,
      autoHideWhenNoActiveSettings: true,
      cssClass: 'ui-subtitlesettingstogglebutton',
      text: i18n_1.i18n.getLocalizer('settings.subtitles')
    });
    var audioToggleButton = new settingstogglebutton_1.SettingsToggleButton({
      settingsPanel: audioTrackListPanel,
      autoHideWhenNoActiveSettings: true,
      cssClass: 'ui-audiotracksettingstogglebutton',
      ariaLabel: i18n_1.i18n.getLocalizer('settings.audio.track'),
      text: i18n_1.i18n.getLocalizer('settings.audio.track')
    });
    var uiContainer = new uicontainer_1.UIContainer({
      components: [new subtitleoverlay_1.SubtitleOverlay(), new bufferingoverlay_1.BufferingOverlay(), playbackToggleOverlay, new controlbar_1.ControlBar({
        components: [new container_1.Container({
          components: [new playbacktimelabel_1.PlaybackTimeLabel({
            timeLabelMode: playbacktimelabel_1.PlaybackTimeLabelMode.CurrentTime,
            hideInLivePlayback: true
          }), seekBar, new playbacktimelabel_1.PlaybackTimeLabel({
            timeLabelMode: playbacktimelabel_1.PlaybackTimeLabelMode.RemainingTime,
            cssClasses: ['text-right']
          })],
          cssClasses: ['controlbar-top']
        })]
      }), new titlebar_1.TitleBar({
        components: [new container_1.Container({
          components: [new metadatalabel_1.MetadataLabel({
            content: metadatalabel_1.MetadataLabelContent.Title
          }), subtitleToggleButton, audioToggleButton],
          cssClasses: ['ui-titlebar-top']
        }), new container_1.Container({
          components: [new metadatalabel_1.MetadataLabel({
            content: metadatalabel_1.MetadataLabelContent.Description
          }), subtitleListPanel, audioTrackListPanel],
          cssClasses: ['ui-titlebar-bottom']
        })]
      }), new recommendationoverlay_1.RecommendationOverlay(), new errormessageoverlay_1.ErrorMessageOverlay()],
      cssClasses: ['ui-skin-tv'],
      hideDelay: 2000,
      hidePlayerStateExceptions: [playerutils_1.PlayerUtils.PlayerState.Prepared, playerutils_1.PlayerUtils.PlayerState.Paused, playerutils_1.PlayerUtils.PlayerState.Finished]
    });
    var spatialNavigation = new spatialnavigation_1.SpatialNavigation(new rootnavigationgroup_1.RootNavigationGroup(uiContainer, playbackToggleOverlay, seekBar, audioToggleButton, subtitleToggleButton), new ListNavigationGroup_1.ListNavigationGroup(ListNavigationGroup_1.ListOrientation.Vertical, subtitleListPanel, subtitleListBox), new ListNavigationGroup_1.ListNavigationGroup(ListNavigationGroup_1.ListOrientation.Vertical, audioTrackListPanel, audioTrackListBox));
    return {
      ui: uiContainer,
      spatialNavigation: spatialNavigation
    };
  }
  UIFactory.modernTvUI = modernTvUI;
})(UIFactory = exports.UIFactory || (exports.UIFactory = {}));