<app-popup outlet="landingpage" [full]="true">

  <h1>{{ 'pvr.genreGroups.title' | translate }}</h1>

  @if (genreGroups().length) {
    <div class="grid grid__tiles genregroup-list"
         (scrolled)="paginate()" infiniteScroll
         [infiniteScrollContainer]="'.popup__backdrop--landingpage'"
         [fromRoot]="true"
         [immediateCheck]="true">

      <app-pvr-grid-button [label]="'pvr.groups.genres.showAll' | translate" [rounded]="true" (click)="openRecordingList()">
      </app-pvr-grid-button>

      @for (genreGroup of genreGroups(); track $index) {
        <app-pvr-genregroup-preview [genreGroup]="genreGroup"></app-pvr-genregroup-preview>
      } @empty {
        <app-pvr-empty-list></app-pvr-empty-list>
      }
    </div>
  }

  @if (busy()) {
    <div class="grid ghost-listing grid__tiles">
      @for (ghost of ghostPreviews; track $index) {
        <app-broadcast-item [card]="true"></app-broadcast-item>
      }
    </div>
  }
</app-popup>

