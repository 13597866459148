import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VideoAdDevice, VideoAds } from '@teleboy/web.player';

@Injectable()
export class VideoAdsProvider implements VideoAds {
  readonly enabled = true;
  readonly device: VideoAdDevice;

  constructor(private deviceDetectorService: DeviceDetectorService) {
    this.device = this.deviceDetectorService.isMobile() ? VideoAdDevice.MOBILE : VideoAdDevice.DESKTOP;
  }
}

export const VIDEO_ADS_PROVIDER = {
  provide: 'VIDEO_ADS',
  useClass: VideoAdsProvider
};
