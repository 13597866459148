<div *ngIf="serie; else ghost" class="serie-item card">
  <tbui-media-image *ngIf="serie.images && serie.images.length" [image]="serie.images[0]"
                    [alt]="serie.title"
                    [size]="MediaImageStack.HUGE"
                    class="ratio ratio__16x9"></tbui-media-image>
  <div class="card__body">
    <h3 class="ellipsis collapse">
      <a [routerLink]="getDetailRouterLinkCommand()"
         [title]="'serie.action.details.label' | translate">{{serie.title}}</a>
    </h3>
    <p class="collapse">{{('serie.info.numSeasons.' + (serie.seasonsCount > 1 ? 'plural' : 'singular')) | translate: {
      seasons: serie.seasonsCount
    } }}</p>
  </div>
</div>

<ng-template #ghost>
  <div class="serie-item card">
    <div class="ratio ratio__16x9"></div>
    <div class="card__body">
      <h3 class="ellipsis collapse">&nbsp;</h3>
      <p class="collapse">&nbsp;</p>
    </div>
  </div>
</ng-template>
