import { ChangeDetectorRef, Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { BroadcastActionDirective } from './broadcast-action.directive';
import { BroadcastActionService } from '../../services/broadcast-action.service';
import { Broadcast } from '@teleboy/web.epg';
import { tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: 'button[appBroadcastDeleteRecord]'
})
export class BroadcastDeleteRecordDirective extends BroadcastActionDirective implements OnInit {
  @Input('appBroadcastDeleteRecord') broadcast!: Broadcast;
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected override changeDetectionRef: ChangeDetectorRef,
    private broadcastActionService: BroadcastActionService,
    private translateService: TranslateService
  ) {
    super(changeDetectionRef);
  }

  ngOnInit(): void {
    this.translateService.get('broadcast.action.recordDelete.label').subscribe((text) => (this._title = text));
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    if (confirm(`${this.translateService.instant('broadcast.action.recordDelete.label')}?`)) {
      this.performAction(() =>
        this.broadcastActionService.handleRecord$(this.broadcast).pipe(tap(() => this.deleted.emit()))
      );
    }
  }
}
