import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Broadcast, RecordingResult } from '@teleboy/web.epg';
import { BroadcastActionService } from '../../services/broadcast-action.service';
import { BroadcastActionDirective } from './broadcast-action.directive';
import { Observable, switchMap, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { EventTrackingService } from '@teleboy/web.core';

@Directive({
  selector: 'button[appBroadcastToggleRecord]'
})
export class BroadcastToggleRecordDirective extends BroadcastActionDirective implements OnInit {
  @Input('appBroadcastToggleRecord') broadcast!: Broadcast;
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected override changeDetectionRef: ChangeDetectorRef,
    private broadcastActionService: BroadcastActionService,
    private eventTrackingService: EventTrackingService,
    private translateService: TranslateService
  ) {
    super(changeDetectionRef);
  }

  ngOnInit(): void {
    const key = `broadcast.action.${this.broadcast.flags.isRecorded() ? 'recordDelete' : 'record'}.label`;
    this.translateService.get(key).subscribe((text) => (this._title = text));
  }

  @HostBinding('class.btn--active')
  get active(): boolean {
    return this.broadcast.flags.isRecorded();
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    this.performAction(() => this.broadcastAction());
  }

  private broadcastAction(): Observable<string> {
    return this.broadcastActionService.handleRecord$(this.broadcast).pipe(
      switchMap((recordingResult: RecordingResult) => {
        if (recordingResult === RecordingResult.RECORDING_DELETED) {
          this.deleted.emit();
          this.eventTrackingService.trackEvent(`record_${this.broadcast.id}`);
          return this.translateService.get('broadcast.action.recordDelete.label');
        } else {
          this.eventTrackingService.trackEvent(`bin_${this.broadcast.id}`);
          return this.translateService.get('broadcast.action.record.label');
        }
      }),
      tap((title) => (this._title = title))
    );
  }
}
