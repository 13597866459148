<app-close-button (closed)="close()"></app-close-button>
<app-info [icon]="config.icon" [title]="('upsell.' + config.name + '.title') | translate"
          [text]="'upsell.' + config.name + '.text' | translate: config.placeholders" [collapse]="true">
  <div class="popup__footer btn__list-h" cta
       [ngClass]="{ 'btn__list-h--apart': config.cta.length > 1, 'btn__list-h--end': config.cta.length === 1 }">
    <button *ngFor="let cta of config.cta; let last = last"
            (click)="cta.click()"
            [ngClass]="{'btn__primary': last, 'btn__secondary': !last, 'busy': busy$ | async }"
            class="btn" rel="nofollow" appDataLayer [dataLayerEventName]="'upsell_' + config.name">
      {{ ('upsell.' + config.name + '.cta.' + cta.key) | translate }}
    </button>
  </div>
</app-info>
