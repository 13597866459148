import { ChangeDetectionStrategy, Component, OnInit, signal, WritableSignal } from '@angular/core';
import { BroadcastItemComponent } from '../../../../epg/components/broadcast-item/broadcast-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { SharedModule } from '../../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, EMPTY, map, Observable, scan, switchMap, tap } from 'rxjs';
import { Broadcast, WatchlistApiService, WatchlistParams } from '@teleboy/web.epg';
import { SnackbarService, SnackbarType } from '../../../../core/services/snackbar.service';
import { ApiListData } from '@teleboy/web.core';
import { AsyncPipe, NgClass } from '@angular/common';
import { ToggleablePageType, ViewTogglerService, ViewToggleType } from '../../../../epg/services/view-toggler.service';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-pvr-watchlist-list',
  imports: [
    BroadcastItemComponent,
    FormsModule,
    InfiniteScrollDirective,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    AsyncPipe,
    NgClass,
    IconComponent
  ],
  templateUrl: './pvr-watchlist-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PvrWatchlistListComponent implements OnInit {
  broadcasts$!: Observable<Broadcast[]>;

  readonly busy: WritableSignal<boolean> = signal(true);
  readonly ghostPreviews: unknown[] = new Array(15);
  readonly listingType = signal<ViewToggleType>(ViewToggleType.TILES);

  private totalBroadcasts = 0;

  private readonly PAGINATION_LIMIT = 20;
  private readonly params = new WatchlistParams();

  protected readonly ListingType = ViewToggleType;

  constructor(
    private snackbarService: SnackbarService,
    private watchlistApiService: WatchlistApiService,
    private viewTogglerService: ViewTogglerService
  ) {}

  ngOnInit(): void {
    this.setParams();

    this.broadcasts$ = this.getBroadcasts$();
    this.listingType.set(this.viewTogglerService.setListingType(ToggleablePageType.RecordingsPage));
  }

  paginate(): void {
    if (!this.params.canPaginate(this.totalBroadcasts)) {
      return;
    }

    this.params.paginate();
  }

  toggleListingType(): void {
    this.listingType.set(this.viewTogglerService.toggleListingType(ToggleablePageType.RecordingsPage));
  }

  private setParams(): void {
    this.params.setLimit(this.PAGINATION_LIMIT);
  }

  private getBroadcasts$(): Observable<Broadcast[]> {
    return this.params.skip$.pipe(
      tap(() => this.busy.set(true)),
      switchMap(() => {
        return this.watchlistApiService
          .broadcasts(this.params)
          .pipe(catchError(() => this.handleWatchlistResponseError$()));
      }),
      tap((data: ApiListData<Broadcast>) => (this.totalBroadcasts = data.total)),
      map((response: ApiListData<Broadcast>) => response.items),
      scan((acc, delta) => (this.params.skip === 0 ? delta : acc.concat(delta))),
      tap(() => this.busy.set(false))
    );
  }

  private handleWatchlistResponseError$(): Observable<never> {
    this.busy.set(false);
    this.snackbarService.openSnackbar('pvr.list.error.loading', SnackbarType.ERROR);

    return EMPTY;
  }
}
