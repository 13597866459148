import { Injectable } from '@angular/core';
import { StationOrderStorageService } from '@teleboy/web.epg';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from '@teleboy/web.user';

@Injectable({
  providedIn: 'root'
})
export class StartService {
  constructor(
    private authenticationService: AuthenticationService,
    private location: Location,
    private router: Router,
    private stationOrderStorageService: StationOrderStorageService
  ) {}

  goToDestination(): void {
    const targetUrl = this.location.path();

    if (
      (this.authenticationService.isAuthenticated && !this.authenticationService.user.isFreeUser) ||
      this.isPlayerTarget(targetUrl)
    ) {
      void this.router.navigateByUrl(this.router.parseUrl(targetUrl));
      return;
    }

    this.stationOrderStorageService.getDefaultStation().subscribe((defaultStation) => {
      if (targetUrl) {
        void this.router.navigateByUrl(targetUrl).then(() => {
          void this.router.navigate([{ outlets: { player: `live/${defaultStation.id}` } }]);
        });
      } else {
        void this.router.navigate([{ outlets: { primary: 'live', player: `live/${defaultStation.id}` } }]);
      }
    });
  }

  private isPlayerTarget(targetUrl: string): boolean {
    return !!this.router.parseUrl(targetUrl).root.children['player'];
  }
}
