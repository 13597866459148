import { AfterViewInit, Directive, Input, NgZone, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, scan, switchMap, tap } from 'rxjs';
import { ListParams } from '@teleboy/web.core';
import { DataSwiperDirective } from './data-swiper.directive';

@Directive({
  standalone: false
})
export abstract class ListSwiperDirective<T> extends DataSwiperDirective<T> implements OnInit, AfterViewInit {
  @Input() fetchAllItems = false;

  isPaginating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  protected params!: ListParams;

  private readonly LIMIT = 10;
  private readonly PAGINATE_PROGRESS = 0.8;

  protected constructor(private ngZone: NgZone) {
    super();
  }

  override ngOnInit(): void {
    if (!this.fetchAllItems) {
      this.params.setLimit(this.LIMIT);
    }
    super.ngOnInit();
  }

  override ngAfterViewInit(): void {
    if (!this._swiper) return;
    this._swiper.navigation = true;
    this._swiper.swiperRef.on('slideChange', () => this.paginate());
  }

  protected override getData$(): Observable<T[]> {
    return this.params.skip$.pipe(
      switchMap(() => super.getData$()),
      scan((accItems, items) => accItems.concat(items)),
      tap(() => this.isPaginating$.next(false))
    );
  }

  private paginate(): void {
    if (
      !this.isPaginating$.value &&
      this._swiper.swiperRef.progress > this.PAGINATE_PROGRESS &&
      this.params.canPaginate(this.total$.value ?? 0)
    ) {
      this.isPaginating$.next(true);
      this.ngZone.run(() => this.params.paginate());
    }
  }
}
