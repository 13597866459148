import { inject } from '@angular/core';

import { ProfileCreatePolicy } from '@teleboy/web.user';
import { catchError, map, Observable, of } from 'rxjs';
import { ErrorCode, TeleboyError } from '@teleboy/web.core';
import { ProfileRestrictionComponent } from '../components/profile-restriction/profile-restriction.component';
import { PopupService } from '../../core/services/popup.service';

export const profileCreateGuard = (): Observable<boolean> => {
  const popupService = inject(PopupService);
  const profileCreatePolicy = inject(ProfileCreatePolicy);

  return profileCreatePolicy.isGranted().pipe(
    catchError((err: TeleboyError) => {
      if (err.code === ErrorCode.USER_PROFILE_CREATE_PROFILE_PERMISSIONS_REQUIRED) {
        return popupService
          .openAsModal<ProfileRestrictionComponent>(ProfileRestrictionComponent)
          .beforeClosed()
          .pipe(map((result) => !!result));
      }

      return of(false);
    })
  );
};
