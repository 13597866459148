import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { SnackbarService, SnackbarType } from '../services/snackbar.service';
import { StationOrderStorageService } from '@teleboy/web.epg';
import { map } from 'rxjs';
import { AuthenticationService } from '@teleboy/web.user';

export const notFoundGuard = () => {
  const REDIRECT_ROUTE = 'home';

  const authenticationService = inject(AuthenticationService);
  const snackbarService = inject(SnackbarService);
  const stationOrderStorageService = inject(StationOrderStorageService);
  const router = inject(Router);

  snackbarService.openSnackbar('routing.notFound', SnackbarType.ERROR);

  return stationOrderStorageService.getDefaultStation().pipe(
    map((defaultStation) => {
      let redirectUrl: UrlTree = router.createUrlTree([REDIRECT_ROUTE]);

      if (!authenticationService.isAuthenticated || authenticationService.user.isFreeUser) {
        redirectUrl = router.createUrlTree([
          {
            outlets: {
              primary: REDIRECT_ROUTE,
              player: `live/${defaultStation.id}`
            }
          }
        ]);
      }

      return redirectUrl;
    })
  );
};
