import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  signal,
  WritableSignal,
  OnInit
} from '@angular/core';
import { EMPTY, map, Observable, switchMap } from 'rxjs';
import {
  RecordingApiService,
  RecordingFilterService,
  RecordingGroup,
  RecordingGroupsParams,
  RecordingGroupsSort,
  RecordingGroupType
} from '@teleboy/web.epg';
import { SnackbarService, SnackbarType } from '../../../../core/services/snackbar.service';
import { catchError, tap } from 'rxjs/operators';
import { ApiListData } from '@teleboy/web.core';
import { BroadcastItemComponent } from '../../../../epg/components/broadcast-item/broadcast-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { SharedModule } from '../../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PvrTitlegroupPreviewComponent } from '../../previews/pvr-titlegroup-preview/pvr-titlegroup-preview.component';
import { PvrEmptyListComponent } from '../../mics/pvr-empty-list/pvr-empty-list.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-pvr-titlegroup-list',
  imports: [
    BroadcastItemComponent,
    FormsModule,
    InfiniteScrollDirective,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    PvrTitlegroupPreviewComponent,
    PvrEmptyListComponent,
    NgClass
  ],
  templateUrl: './pvr-titlegroup-list.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PvrTitlegroupListComponent implements OnInit {
  moviesOnly = input(false, { transform: booleanAttribute });

  readonly titleGroups = signal<RecordingGroup[]>([]);

  /**
   * Defines the busy state of the page. Ghost previews are shown while busy
   */
  readonly busy = signal(true);

  /**
   * Optional pageTitle given from query parameters
   */
  readonly pageTitle = input<string>('');

  /**
   * The current sort, begin or count
   */
  readonly sort: WritableSignal<RecordingGroupsSort>;

  /**
   * Empty array which defines the number of ghost previews shown while busy
   */
  readonly ghostPreviews: unknown[] = new Array(12);

  private totalTitleGroups = 0;

  /**
   * The number of title groups to load and display per page
   */
  private readonly PAGINATION_LIMIT = 20;

  private readonly params = new RecordingGroupsParams();

  constructor(
    private recordingApiService: RecordingApiService,
    private recordingFilterService: RecordingFilterService,
    private snackbarService: SnackbarService
  ) {
    this.sort = signal(this.recordingFilterService.getRecordingGroupsSort());
    effect(() => this.recordingFilterService.storeRecordingGroupsSort(this.sort()));
  }

  ngOnInit() {
    this.setParams();
    this.getTitleGroups$().subscribe();
  }

  toggleSort(): void {
    this.sort.update((sort: RecordingGroupsSort) => {
      return sort === RecordingGroupsSort.BEGIN ? RecordingGroupsSort.COUNT : RecordingGroupsSort.BEGIN;
    });

    this.params.setSort(this.sort()).resetSkip();
  }

  paginate(): void {
    if (!this.params.canPaginate(this.totalTitleGroups)) {
      return;
    }

    this.params.paginate();
  }

  private setParams(): void {
    this.params.setSort(this.sort()).setLimit(this.PAGINATION_LIMIT);

    if (this.moviesOnly()) {
      this.params.setGenre(RecordingGroupsParams.MOVIE_GENRES);
    } else {
      this.params.setGenre(RecordingGroupsParams.SERIES_GENRES);
    }
  }

  private getTitleGroups$(): Observable<RecordingGroup[]> {
    return this.params.skip$.pipe(
      tap(() => this.busy.set(true)),
      switchMap(() => {
        return this.recordingApiService
          .group(RecordingGroupType.TITLE, this.params)
          .pipe(catchError(() => this.handleTitleGroupsResponseError$()));
      }),
      tap((data: ApiListData<RecordingGroup>) => (this.totalTitleGroups = data.total)),
      map((response: ApiListData<RecordingGroup>) => response.items),
      tap((nextRecordingGroups: RecordingGroup[]) => {
        this.titleGroups.update((recordingGroups) =>
          this.params.skip === 0 ? nextRecordingGroups : [...recordingGroups, ...nextRecordingGroups]
        );
      }),
      tap(() => this.busy.set(false))
    );
  }

  private handleTitleGroupsResponseError$(): Observable<never> {
    this.busy.set(false);
    this.snackbarService.openSnackbar('pvr.list.error.loading', SnackbarType.ERROR);

    return EMPTY;
  }
}
