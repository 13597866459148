"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HugeReplayButton = void 0;
var button_1 = require("./button");
var dom_1 = require("../dom");
var i18n_1 = require("../localization/i18n");
/**
 * A button to play/replay a video.
 *
 * @category Buttons
 */
var HugeReplayButton = /** @class */function (_super) {
  __extends(HugeReplayButton, _super);
  function HugeReplayButton(config) {
    if (config === void 0) {
      config = {};
    }
    var _this = _super.call(this, config) || this;
    _this.config = _this.mergeConfig(config, {
      cssClass: 'ui-hugereplaybutton',
      text: i18n_1.i18n.getLocalizer('replay')
    }, _this.config);
    return _this;
  }
  HugeReplayButton.prototype.configure = function (player, uimanager) {
    _super.prototype.configure.call(this, player, uimanager);
    this.onClick.subscribe(function () {
      player.play('ui');
    });
  };
  HugeReplayButton.prototype.toDomElement = function () {
    var buttonElement = _super.prototype.toDomElement.call(this);
    // Add child that contains the play button image
    // Setting the image directly on the button does not work together with scaling animations, because the button
    // can cover the whole video player are and scaling would extend it beyond. By adding an inner element, confined
    // to the size if the image, it can scale inside the player without overshooting.
    buttonElement.append(new dom_1.DOM('div', {
      'class': this.prefixCss('image')
    }));
    return buttonElement;
  };
  return HugeReplayButton;
}(button_1.Button);
exports.HugeReplayButton = HugeReplayButton;