import { Injectable } from '@angular/core';
import { RecordingDownloadQuality } from '@teleboy/web.epg';
import { Language } from '@teleboy/web.core';
import { AboLevel, Settings, SettingsParams, User } from '@teleboy/web.user';
import { TranslateService } from '@ngx-translate/core';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown.component';

@Injectable()
export class SettingsPageService {
  readonly AboLevel = AboLevel;

  constructor(private translateService: TranslateService) {}

  getDownloadQualityOptions(): DropdownOption[] {
    return [
      {
        label: this.translateService.instant('settings.content.downloadQuality.high'),
        value: RecordingDownloadQuality.HIGH
      },
      {
        label: this.translateService.instant('settings.content.downloadQuality.medium'),
        value: RecordingDownloadQuality.MEDIUM
      },
      {
        label: this.translateService.instant('settings.content.downloadQuality.low'),
        value: RecordingDownloadQuality.LOW
      }
    ];
  }
  getLanguageOptions(): DropdownOption[] {
    return [
      { label: 'settings.content.languageDropdown.german', value: Language.DE },
      { label: 'settings.content.languageDropdown.french', value: Language.FR },
      { label: 'settings.content.languageDropdown.english', value: Language.EN },
      { label: 'settings.content.languageDropdown.italian', value: Language.IT }
    ];
  }

  getStaralertOptions(): DropdownOption[] {
    return [
      { label: this.translateService.instant('settings.email.staralertsDropdown.none'), value: 0 },
      { label: this.translateService.instant('settings.email.staralertsDropdown.sameDay'), value: 1 },
      { label: this.translateService.instant('settings.email.staralertsDropdown.oneDayAhead'), value: 2 },
      { label: this.translateService.instant('settings.email.staralertsDropdown.oneWeekAhead'), value: 8 }
    ];
  }

  getSubscriptionLevel(user: User): string {
    let subscriptionLevel;

    if (user.isOneUser) {
      subscriptionLevel = this.AboLevel.ONE;
    } else if (user.isMaxUser) {
      subscriptionLevel = this.AboLevel.MAX;
    } else if (user.isComfortUser) {
      subscriptionLevel = this.AboLevel.COMFORT;
    } else if (user.isPlusUser) {
      subscriptionLevel = this.AboLevel.PLUS;
    } else {
      subscriptionLevel = this.AboLevel.FREE;
    }

    return subscriptionLevel;
  }

  getWatchlistOptions(): DropdownOption[] {
    return [
      { label: this.translateService.instant('settings.content.watchlistDropdown.none'), value: 0 },
      { label: this.translateService.instant('settings.content.watchlistDropdown.15min'), value: 15 },
      { label: this.translateService.instant('settings.content.watchlistDropdown.20min'), value: 30 },
      { label: this.translateService.instant('settings.content.watchlistDropdown.1h'), value: 60 },
      { label: this.translateService.instant('settings.content.watchlistDropdown.3h'), value: 180 },
      { label: this.translateService.instant('settings.content.watchlistDropdown.6h'), value: 300 },
      { label: this.translateService.instant('settings.content.watchlistDropdown.12h'), value: 720 },
      { label: this.translateService.instant('settings.content.watchlistDropdown.24h'), value: 1440 }
    ];
  }

  getUserSettingsControlNames(): string[] {
    return [
      'streamingEcoMode',
      'newsletterProductinfo',
      'recordNewNotifyEmail',
      'watchlistNotifyEmail',
      'staralertNotifyEmail',
      'communityFriendshipMail',
      'newsletterTvDaily',
      'newsletterProductinfo',
      'communityFriendshipAutoAccept'
    ];
  }

  getSettingsParams(settingsFormValue: Settings, isCommunityMember: boolean): SettingsParams {
    const settingsParams = new SettingsParams();
    settingsParams.setNewsletterTvDaily(settingsFormValue.newsletterTvDaily);
    settingsParams.setNewsletterProductinfo(settingsFormValue.newsletterProductinfo);
    settingsParams.setRecordNewNotifyEmail(settingsFormValue.recordNewNotifyEmail);
    settingsParams.setWatchlistNotifyEmail(settingsFormValue.watchlistNotifyEmail);
    settingsParams.setStaralertNotifyEmail(settingsFormValue.staralertNotifyEmail);
    settingsParams.setCommunityFriendshipMail(settingsFormValue.communityFriendshipMail);
    settingsParams.setEcoMode(settingsFormValue.streamingEcoMode);

    if (isCommunityMember) {
      settingsParams.setCommunityFriendshipAutoAccept(settingsFormValue.communityFriendshipAutoAccept);
    }

    return settingsParams;
  }
}
