import { ChangeDetectorRef, Directive, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { Broadcast } from '@teleboy/web.epg';
import { BroadcastActionService } from '../../services/broadcast-action.service';
import { BroadcastActionDirective } from './broadcast-action.directive';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY, Observable, switchMap, tap, throwError } from 'rxjs';
import { ErrorCode, TeleboyError } from '@teleboy/web.core';
import { UpsellService } from '../../../core/services/upsell.service';

@Directive({
  selector: 'button[appBroadcastWatchlist]'
})
export class BroadcastWatchlistDirective extends BroadcastActionDirective implements OnInit {
  @Input('appBroadcastWatchlist') broadcast!: Broadcast;

  constructor(
    protected override changeDetectionRef: ChangeDetectorRef,
    private broadcastActionService: BroadcastActionService,
    private translateService: TranslateService,
    private upsellService: UpsellService
  ) {
    super(changeDetectionRef);
  }

  @HostBinding('class.btn--active')
  get active(): boolean {
    return this.broadcast.flags.isWatchlisted();
  }

  ngOnInit(): void {
    const key = `broadcast.action.${this.broadcast.flags.isWatchlisted() ? 'watchlistDelete' : 'watchlist'}.label`;
    this.translateService.get(key).subscribe((text) => (this._title = text));
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    this.performAction(() => this.broadcastAction());
  }

  private broadcastAction(): Observable<string> {
    return this.broadcastActionService.handleWatchlist$(this.broadcast).pipe(
      switchMap(() => {
        if (this.broadcast.flags.isWatchlisted()) {
          return this.translateService.get('broadcast.action.watchlistDelete.label');
        } else {
          return this.translateService.get('broadcast.action.watchlist.label');
        }
      }),
      catchError((error: TeleboyError) => this.handleError$(error)),
      tap((title) => (this._title = title))
    );
  }

  handleError$(error: TeleboyError): Observable<never> {
    if (error.code === ErrorCode.USER_AUTHENTICATION_REQUIRED) {
      this.upsellService.openLoginUpsell();
      return EMPTY;
    }

    return throwError(() => error);
  }
}
