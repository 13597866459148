import { ChangeDetectionStrategy, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ContentApiService, Episode, EpisodeParams, EpisodeSource, Serie } from '@teleboy/web.epg';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, catchError, map, Observable, of, scan, startWith, switchMap, tap } from 'rxjs';
import { MediaImageStack } from '@teleboy/web.core';
import { SlideConfig } from '../../providers/swiper.provider';
import { SnackbarService, SnackbarType } from '../../../core/services/snackbar.service';
import { LanguageService } from '@teleboy/web.user';
import { ScrollableItemDirective } from '../../../shared/directives/scrollable-item.directive';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { MediaImageComponent } from '@teleboy/web.ui';
import { SwiperModule } from 'swiper/angular';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { SerieEpisodeItemComponent } from '../serie-episode-item/serie-episode-item.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-serie',
  templateUrl: './serie.component.html',
  styleUrls: ['./serie.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    SharedModule,
    MediaImageComponent,
    SwiperModule,
    NgFor,
    NgClass,
    InfiniteScrollDirective,
    SerieEpisodeItemComponent,
    AsyncPipe,
    TranslateModule
  ]
})
export class SerieComponent implements OnInit {
  serie$!: Observable<Serie>;
  episodes$!: Observable<Episode[] | null>;

  readonly busy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly ghosts = new Array(2);
  readonly swiperOptions = SlideConfig['episodes'];
  readonly MediaImageStack = MediaImageStack;

  private episodeTotal = 0;

  private readonly EPISODE_LIMIT = 20;
  private readonly _activeSeasonId$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private readonly _selectedSeasonId$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private readonly episodeParams = new EpisodeParams();

  @ViewChildren(ScrollableItemDirective)
  scrollableItems!: QueryList<ScrollableItemDirective>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private contentApiService: ContentApiService,
    private languageService: LanguageService,
    private snackbarService: SnackbarService
  ) {}

  getGenreLabel(serie: Serie): string | null {
    return serie.genre?.getLocalizedName(this.languageService.getLanguage()) ?? null;
  }

  ngOnInit(): void {
    this.serie$ = this._serie$();
    this.episodes$ = this._episodes$();
  }

  onSeasonSelect(seasonId: number): void {
    if (!this.isActiveSeason(seasonId)) {
      this.busy$.next(true);
      this._selectedSeasonId$.next(seasonId);
    }
  }

  isActiveSeason(seasonId: number): boolean {
    return seasonId === this._activeSeasonId$.value;
  }

  paginate(): void {
    if (this.episodeParams.canPaginate(this.episodeTotal) && !this.busy$.value) {
      this.busy$.next(true);
      this.episodeParams.paginate();
    }
  }

  private _serie$(): Observable<Serie> {
    return this.contentApiService.getSerie(this.activatedRoute.snapshot.params['serieId']).pipe(
      tap((serie) => {
        if (serie.seasons?.length) {
          const preselectedSeasonId = parseInt(this.activatedRoute.snapshot.paramMap.get('seasonId') ?? '0', 10);
          this.onSeasonSelect(preselectedSeasonId || serie.seasons[0].id);
        }
      })
    );
  }

  private _episodes$(): Observable<Episode[] | null> {
    return this._selectedSeasonId$.pipe(
      switchMap((seasonId) => {
        this.episodeParams
          .setSource([EpisodeSource.REPLAY, EpisodeSource.EPG, EpisodeSource.CPVR, EpisodeSource.PVR])
          .setLimit(this.EPISODE_LIMIT)
          .resetSkip();

        return this.episodeParams.skip$.pipe(
          switchMap(() => this.contentApiService.getEpisodes(seasonId, this.episodeParams)),
          tap((response) => (this.episodeTotal = response.total)),
          map((response) => response.items),
          scan((accItems, items) => accItems.concat(items)),
          tap(() => {
            if (!this._activeSeasonId$.value) {
              // scroll season button into view upon initial load of the serie
              const buttonToScroll = this.scrollableItems.find((item) => item.key === seasonId);
              buttonToScroll?.scrollIntoView({ block: 'end', inline: 'center' });
            }
          }),
          tap(() => this._activeSeasonId$.next(seasonId)),
          tap(() => this.busy$.next(false)),
          catchError(() => {
            this.busy$.next(false);
            this.snackbarService.openSnackbar('serie.details.episodes.error', SnackbarType.ERROR);
            return of([]);
          }),
          startWith(null)
        );
      })
    );
  }
}
