import { Injectable } from '@angular/core';
import { Upsell, UpsellCta } from '../../interfaces/upsell.interface';
import { PopupService } from '../popup.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '@teleboy/web.user';

@Injectable({
  providedIn: 'root'
})
export class RecordingTimeUpsellService implements Upsell {
  readonly name = 'recordingTime';
  readonly icon = 'navi-player-pvr';
  readonly cta: UpsellCta[];

  constructor(
    private authenticationService: AuthenticationService,
    private popupService: PopupService,
    private router: Router
  ) {
    this.cta = this._cta();
  }

  private _cta(): UpsellCta[] {
    const cta = [
      {
        key: 'admin',
        click: () => this.goToRecordings()
      }
    ];

    if (!this.authenticationService.user?.isOneUser) {
      cta.unshift({
        key: 'upgrade',
        click: () => this.goToShop()
      });
    }

    return cta;
  }

  private goToShop(): void {
    this.popupService.closeModal();
    void this.router.navigate(['/tvshop']);
  }

  private goToRecordings(): void {
    this.popupService.closeModal();
    void this.router.navigate(['/pvr']);
  }
}
