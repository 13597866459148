import { Pipe, PipeTransform } from '@angular/core';
import { HrefService, WebUiHrefOptions } from '../services/href.service';

@Pipe({
  name: 'webUiHref',
  standalone: true
})
export class WebUiHrefPipe implements PipeTransform {
  constructor(private hrefService: HrefService) {}

  transform(path: string, options?: WebUiHrefOptions): string {
    return this.hrefService.getHref(path, options);
  }
}
