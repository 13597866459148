import { ChangeDetectorRef, Directive, HostBinding, HostListener, Inject, Input, OnInit } from '@angular/core';
import { Broadcast } from '@teleboy/web.epg';
import { BroadcastActionService } from '../../services/broadcast-action.service';
import { BroadcastActionDirective } from './broadcast-action.directive';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { EventTrackingService } from '@teleboy/web.core';
import { PlayerListingService } from '../../../player/components/player/player-listing/player-listing.service';

@Directive({
  selector: '[appBroadcastPlay]'
})
export class BroadcastPlayDirective extends BroadcastActionDirective implements OnInit {
  @Input('appBroadcastPlay') broadcast!: Broadcast;
  @Input() restart = false;
  @Input() live!: boolean;
  @Input() origin: 'playList' | '' = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    protected override changeDetectionRef: ChangeDetectorRef,
    private broadcastActionService: BroadcastActionService,
    private translateService: TranslateService,
    private eventTrackingService: EventTrackingService,
    private playerListingService: PlayerListingService
  ) {
    super(changeDetectionRef);
  }

  @HostBinding('class.btn--active')
  readonly active = false;

  ngOnInit(): void {
    this.translateService.get('broadcast.action.play.label').subscribe((text) => (this._title = text));
  }

  @HostListener('click', ['$event'])
  onClick($event: Event): void {
    if (!this.canPerformAction) {
      return;
    }

    this.playerListingService.setListingActiveId(this.broadcast?.id);
    let position: number;

    $event.preventDefault();
    $event.stopPropagation();

    if (this.restart) {
      position = this.broadcast.begin.diff(moment(), 'seconds');
      this.setTracking(this.origin, 'livelisting.toBeginning');
    } else {
      this.setTracking(this.origin, 'livelisting.play');
    }

    this.performAction(() => this.broadcastActionService.handlePlay$(this.broadcast, position, this.live));
  }

  private setTracking(origin: 'playList' | '', event: string) {
    if (origin === 'playList') {
      this.eventTrackingService.trackEvent(event);
    }
  }
}
