import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BitmovinService, BroadcastStreamInfoService, LivePlayerService } from '@teleboy/web.player';
import { ActivatedRoute } from '@angular/router';
import { catchError, filter, map, Observable, retry, Subject, switchMap, takeUntil, tap, throwError } from 'rxjs';
import { AuthenticationService, IdlingConfirmType, IdlingService, Verification } from '@teleboy/web.user';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorCode, TeleboyError } from '@teleboy/web.core';
import { PopupService } from '../../../core/services/popup.service';
import { PlayerService } from '../../services/player.service';
import { AnonymousVerificationComponent } from '../../../shared/components/anonymous-verification/anonymous-verification.component';
import { StreamIdlingService } from '../../services/stream-idling.service';

@Component({
  selector: 'app-player-live',
  templateUrl: './player-live.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IdlingService],
  standalone: false
})
export class PlayerLiveComponent implements OnInit, OnDestroy {
  @ViewChild('player', { static: true }) player!: ElementRef;

  muted = false;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private bitmovinService: BitmovinService,
    private broadcastStreamInfoService: BroadcastStreamInfoService,
    private idlingService: IdlingService,
    private livePlayerService: LivePlayerService,
    private playerService: PlayerService,
    private popupService: PopupService,
    private streamIdlingService: StreamIdlingService
  ) {}

  ngOnInit(): void {
    if (!this.authenticationService.isAuthenticated || !this.authenticationService.user.isPlusUser) {
      this.muted = true;
    }

    this.activatedRoute.params
      .pipe(
        map((params) => params['stationId']),
        switchMap((stationId) => {
          return this.livePlayerService.play$(stationId, 0).pipe(
            catchError((err: HttpErrorResponse | TeleboyError<Verification>) => {
              if (err instanceof TeleboyError && err.code === ErrorCode.VERIFICATION_CAPTCHA_REQUIRED) {
                if (!this.bitmovinService.player.getSource()) {
                  this.playerService.closePlayer();
                }

                this.popupService.openAsModal<AnonymousVerificationComponent>(
                  AnonymousVerificationComponent,
                  err.context
                );
              }

              return throwError(() => err);
            }),
            retry({ delay: () => this.playerService.reloadStream$ })
          );
        }),
        switchMap((stream) => this.playerService.setPageTitle$(stream).pipe(map(() => stream))),
        switchMap(() => {
          return this.activatedRoute.queryParamMap.pipe(
            filter((params) => params.has('position')),
            map((params) => Number(params.get('position'))),
            tap((position) => this.bitmovinService.seek(position))
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.epgChange$().subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private epgChange$(): Observable<unknown> {
    return this.broadcastStreamInfoService.epg$.pipe(
      filter(() => this.idlingService.isIdle()),
      switchMap(() => {
        return this.streamIdlingService.openIdlingDialog$().pipe(
          tap((idlingConfirmType: IdlingConfirmType) => {
            if (idlingConfirmType === IdlingConfirmType.PAUSE) {
              this.idlingService.pauseIdleCheck();
            }
          })
        );
      }),
      takeUntil(this.destroy$)
    );
  }
}
