import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Subject, takeUntil, tap, timer } from 'rxjs';
import { UserProfile } from '@teleboy/web.user';
import { ProfileListComponent } from '../profile-list/profile-list.component';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-profile-settings-listing',
  templateUrl: './profile-settings-listing.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ProfileListComponent, AsyncPipe, TranslateModule, NgIf]
})
export class ProfileSettingsListingComponent implements OnDestroy {
  readonly selectedProfile$: BehaviorSubject<UserProfile | null> = new BehaviorSubject<UserProfile | null>(null);

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(private router: Router) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigate(selectedUserProfile: UserProfile) {
    this.selectedProfile$.next(selectedUserProfile);
    timer(1500)
      .pipe(
        tap(() => void this.router.navigateByUrl('settings')),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
