import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Broadcast, LiveEpgService } from '@teleboy/web.epg';
import { AuthenticationService } from '@teleboy/web.user';
import {
  distinctUntilChanged,
  filter,
  fromEvent,
  merge,
  Observable,
  startWith,
  Subject,
  takeUntil,
  tap,
  throttleTime,
  withLatestFrom
} from 'rxjs';
import { MetaService } from '../../../core/services/meta.service';
import { ToggleablePageType, ViewTogglerService, ViewToggleType } from '../../services/view-toggler.service';
import { BitmovinService } from '@teleboy/web.player';
import { PlayerSize, PlayerSizeService } from '../../../player/services/player-size.service';
import { ScreenService } from '../../../core/services/screen.service';
import { PlayerUiService } from '../../../player/services/player-ui.service';
import { DOCUMENT, NgClass, AsyncPipe, SlicePipe } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DataLayerDirective } from '../../../shared/directives/data-layer.directive';
import { SearchComponent } from '../search/search.component';
import { InlineAlertComponent } from '../../../shared/components/inline-alert/inline-alert.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BroadcastItemComponent } from '../broadcast-item/broadcast-item.component';
import { WebUiHrefPipe } from '../../../shared/pipes/web-ui-href.pipe';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-live',
  templateUrl: './listing-live.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DataLayerDirective,
    NgClass,
    SearchComponent,
    InlineAlertComponent,
    InfiniteScrollDirective,
    BroadcastItemComponent,
    AsyncPipe,
    SlicePipe,
    TranslateModule,
    WebUiHrefPipe,
    IconComponent
  ]
})
export class ListingLiveComponent implements OnInit, OnDestroy {
  broadcastsLimit: number;
  liveListingType!: ViewToggleType;
  liveEpg$!: Observable<Broadcast[]>;
  reloadButton$!: Observable<boolean>;

  readonly ghosts = new Array(15);
  readonly isAuthenticated = this.authenticationService.isAuthenticated;
  readonly ListingType = ViewToggleType;

  private readonly BROADCASTS_PER_PAGE = 30;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authenticationService: AuthenticationService,
    private bitmovinService: BitmovinService,
    private liveEpgService: LiveEpgService,
    private metaTitleService: MetaService,
    private playerSizeService: PlayerSizeService,
    private playerUiService: PlayerUiService,
    private screenService: ScreenService,
    private viewTogglerService: ViewTogglerService,
    private translateService: TranslateService
  ) {
    this.broadcastsLimit = this.BROADCASTS_PER_PAGE;
  }

  ngOnInit(): void {
    this.metaTitleService.setTitle(['Live TV']);
    this.metaTitleService.setDescription(this.translateService.instant('live.meta.description'));
    this.liveListingType = this.viewTogglerService.setListingType(ToggleablePageType.LiveViewPage);

    this.liveEpg$ = this.liveEpgService.epg$;
    this.reloadButton$ = this.liveEpgService.requestLimitReached$;
    this.setupListingUpdater();
    this.activity$().pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngOnDestroy() {
    this.liveEpgService.stopReload();
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadMore(): void {
    this.broadcastsLimit += this.BROADCASTS_PER_PAGE;
  }

  reload() {
    this.liveEpgService.reload(true);
  }

  toggleListingType(): void {
    this.liveListingType = this.viewTogglerService.toggleListingType(ToggleablePageType.LiveViewPage);
  }

  private setupListingUpdater(): void {
    this.screenService.isInBackground$
      .pipe(
        tap((inBackground) => this.liveEpgService.setPaused(inBackground)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.bitmovinService.ready$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.playerUiService.shown) {
        return;
      }
      this.liveEpgService.stopReload();
    });
    this.bitmovinService.destroy$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.liveEpgService.reload();
    });

    this.playerSizeService.size$.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((size) => {
      this.liveEpgService.setPaused(size === PlayerSize.LARGE);
    });
  }

  private activity$(): Observable<unknown> {
    const mousemove$ = fromEvent(this.document, 'mousemove');
    const touchmove$ = fromEvent(this.document, 'touchmove');
    const keydown$ = fromEvent(this.document, 'keydown');

    return merge(mousemove$, touchmove$, keydown$).pipe(
      throttleTime(5000),
      withLatestFrom(this.liveEpgService.requestLimitReached$.pipe(startWith(false))),
      filter(() => this.bitmovinService.player === null),
      tap(([_, limitReached]) => this.liveEpgService.reload(limitReached))
    );
  }
}
