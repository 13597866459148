<app-popup outlet="broadcast" [large]="true" *ngIf="serie$ | async as serie">
  <div class="serie__header" header>
    <div class="serie__header__gradient">
      <tbui-media-image *ngIf="serie.images && serie.images[0]"
                        [image]="serie.images[0]" [size]="MediaImageStack.HD"
                        [alt]="serie.title"
                        class="ratio ratio__16x9"></tbui-media-image>
    </div>
    <div class="serie__header__info">
      <h1 class="collapse">{{ serie.title }}</h1>
      <p class="collapse">
        <b>
          <ng-container *ngIf="getGenreLabel(serie) as genre">{{ genre }} &bull;</ng-container>
          {{('serie.info.numSeasons.' + (serie.seasonsCount > 1 ? 'plural' : 'singular')) | translate: {
          seasons: serie.seasonsCount
        } }}
        </b></p>
    </div>
  </div>

  <div *ngIf="!serie.isSingleSeasonSerie()" class="serie__seasons swiper__container">
    <swiper [slidesPerView]="swiperOptions.slidesPerView" [breakpoints]="swiperOptions.breakpoints" [virtual]="false">
      <ng-template *ngFor="let season of serie.seasons" swiperSlide>
        <button class="btn btn--sm serie__season__button"
                [appScrollableItem]="season.id"
                [ngClass]="{
                  'busy': busy$ | async,
                  'btn__primary': isActiveSeason(season.id),
                  'btn__secondary': !isActiveSeason(season.id)
                }"
                (click)="onSeasonSelect(season.id)">
          {{ 'serie.details.seasons.buttonLabel' | translate: {seasonNumber: season.season_number} }}
        </button>
      </ng-template>
    </swiper>
  </div>

  <div *ngIf="episodes$ | async as episodes; else loading"
       class="serie__episodes grid" infiniteScroll
       (scrolled)="paginate()" [infiniteScrollContainer]="'.popup__backdrop--broadcast'" [fromRoot]="true"
       [immediateCheck]="true">
    <app-serie-episode-item *ngFor="let episode of episodes" [episode]="episode"></app-serie-episode-item>
    <app-serie-episode-item *ngIf="busy$ | async" class="ghost-listing"></app-serie-episode-item>
  </div>
</app-popup>

<ng-template #loading>
  <div class="ghost-listing serie__episodes grid">
    <app-serie-episode-item *ngFor="let ghost of ghosts"></app-serie-episode-item>
  </div>
</ng-template>
