<app-popup outlet="details" [full]="true" (closeClicked)="closeListTilesView()">

  <div [ngClass]="{'list-title': pageConfiguration.showStaralertNotificationsButton}">
    <h1>{{title}}</h1>
  </div>
  <div *ngIf="itemsList$ | async as itemsList"
       (scrolled)="loadMore()" infiniteScroll
       [infiniteScrollContainer]="'.popup__backdrop--details'"
       [fromRoot]="true"
       [immediateCheck]="true"
       [infiniteScrollDisabled]="itemsListType === ItemsListType.Station"
       class="grid" [ngClass]="{'grid__tiles': !toggleButtonVisible || listingType === ListingType.TILES}" [ngSwitch]="itemsListType">


    <ng-container *ngSwitchCase="ItemsListType.Serie">
      <app-serie-item *ngFor="let serie of itemsList; trackBy: trackByFn" [serie]="serie"></app-serie-item>
    </ng-container>

    <ng-container *ngSwitchCase="ItemsListType.Station">
        <app-station-item *ngFor="let station of itemsList; trackBy: trackByFn" [station]="station"></app-station-item>
    </ng-container>

    <ng-container *ngSwitchCase="ItemsListType.Search">
      <app-broadcast-item *ngFor="let broadcast of itemsList; trackBy: trackByFn" [broadcast]="broadcast"
                          [card]="listingType === ListingType.TILES"></app-broadcast-item>
    </ng-container>

    <ng-container *ngSwitchCase="ItemsListType.Teaser">
        <app-teaser-item *ngFor="let teaser of itemsList; trackBy: trackByFn" [teaser]="teaser"></app-teaser-item>
    </ng-container>

    <ng-container *ngSwitchCase="ItemsListType.StarAlert">
      <ng-container *ngFor="let staralert of itemsList; trackBy: trackByFn" >
        <app-staralert-item *ngIf="!staralert.deleted"
                            [starAlert]="staralert"
                            [card]="true"
                            [showDelete]="true"
                            (deleted)="onSuccessDeleteItem(staralert)">
        </app-staralert-item>
      </ng-container>

    </ng-container>

    <ng-container *ngSwitchDefault>
      <app-broadcast-item *ngFor="let broadcast of itemsList; trackBy: trackByFn"
                          [broadcast]="broadcast"
                          (heartbeatDeleted)="onSuccessDeleteHeartbeat()"
                          [card]="listingType === ListingType.TILES">

      </app-broadcast-item>
    </ng-container>

  </div>

  <ng-container *ngIf="(busy$ | async); then loading"></ng-container>

  <ng-template #loading>
    <div class="grid ghost-listing mt-base" [ngClass]="{'grid__tiles': listingType === ListingType.TILES}">
      <app-broadcast-item *ngFor="let ghost of ghosts"
                          [card]="listingType === ListingType.TILES"></app-broadcast-item>
    </div>
  </ng-template>

  <ng-container buttons>
    <ng-container *ngIf="pageConfiguration.showStaralertNotificationsButton">
      <a routerLink="/settings" class="btn"
         (click)="navigate('settings')"
      >
        {{ 'starAlert.changeNotificationsInterval' | translate }}
      </a>
    </ng-container>

    <ng-container *ngIf="toggleButtonVisible">

      <button class="buttons btn btn__icon btn--circle btn__toggle-view--{{ listingType }}"
              (click)="toggleListingType()">
        <tbui-icon iconId="view-list" [ngClass]="{'hidden': listingType === ListingType.LIST}"
                   class="view-icon-list">
        </tbui-icon>
        <tbui-icon iconId="view-tiles" [ngClass]="{'hidden': listingType !== ListingType.LIST}"
                   class="view-icon-tiles">
        </tbui-icon>
      </button>
    </ng-container>

  </ng-container>
</app-popup>




