import { Inject, Injectable } from '@angular/core';
import { ScreenService } from '../../core/services/screen.service';
import { DOCUMENT } from '@angular/common';
import { Observable, shareReplay, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayerUiService {
  shown!: boolean;
  readonly ui$: Observable<boolean>;

  private customControlHasFocus!: boolean;

  private readonly PLAYER_UI_HIDE_CSS_CLASS = 'player-ui-hidden';
  private readonly _ui$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private screenService: ScreenService
  ) {
    this.ui$ = this._ui$.asObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  hideUi(): void {
    if ((this.screenService.isDesktopSizeScreen() || this.screenService.isLandscape()) && this.shown) {
      this.shown = false;
      this._ui$.next(false);
    }

    this.document.body.classList.add(this.PLAYER_UI_HIDE_CSS_CLASS);
  }

  showUi(): void {
    this.shown = true;
    this._ui$.next(true);
    this.document.body.classList.remove(this.PLAYER_UI_HIDE_CSS_CLASS);
  }

  setCustomControlFocusedState(state: boolean): void {
    this.customControlHasFocus = state;
  }

  hasCustomControlFocused(): boolean {
    return this.customControlHasFocus;
  }
}
