import { ChangeDetectionStrategy, ChangeDetectorRef, Component, input, output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

export interface DropdownOption {
  label: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  value: any;
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownComponent
    }
  ],
  standalone: false
})
export class DropdownComponent implements ControlValueAccessor {
  label = '';
  isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly bordered = input<boolean>(false);
  readonly options = input<DropdownOption[]>([]);
  readonly highlightSelectedOption = input<boolean>(false);
  readonly roundedLeft = input<boolean>(false); // For usage together with input field
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  readonly valueChange = output<any>();

  selectedOption!: DropdownOption;
  private value = '';

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  // Stop default button click behavior in forms.
  onDropdownClick($event: Event): void {
    $event.preventDefault();
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  writeValue(option: any): void {
    if (option) {
      if (typeof option === 'object') {
        this.value = option.value;
        this.label = option.label;
        this.selectedOption = option;
      } else {
        const optionSelected = this.options().find((optionToFind: DropdownOption) => {
          return optionToFind.value === option;
        });
        if (optionSelected) {
          this.value = optionSelected.value;
          this.label = optionSelected.label;
          // this.selectedOption = optionSelected;
        }
      }
    } else {
      this.value = this.options()[0].value;
      this.label = this.options()[0].label;
    }

    this.changeDetectorRef.detectChanges();
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  registerOnChange(callbackFn: any): void {
    this.onChange = callbackFn;
  }

  registerOnTouched(callbackFn: () => void): void {
    this.onTouched = callbackFn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled$.next(isDisabled);
  }

  select(option: DropdownOption): void {
    if (!this.isDisabled$.value && option.value !== this.value) {
      this.selectedOption = option;
      this.value = option.value;
      this.label = option.label;
      this.valueChange.emit(option.value);
      this.onChange(this.value);
      this.onTouched();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange = (_value: unknown) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => {};
}
