import { Injectable } from '@angular/core';
import { BitmovinService, JumpSeekingService } from '@teleboy/web.player';
import { PlayerUiService } from './player-ui.service';
import { take, takeUntil } from 'rxjs';
import { PlayerSizeService } from './player-size.service';

enum PlayerKey {
  MUTE = 'KeyM',
  FULLSCREEN = 'KeyF',
  PAUSE = 'Space',
  PAUSE_ALT = 'KeyK',
  JUMP_BACK = 'ArrowLeft',
  JUMP_FORWARD = 'ArrowRight',
  VOLUME_UP = 'ArrowUp',
  VOLUME_DOWN = 'ArrowDown',
  CLOSE = 'Escape'
}

@Injectable({
  providedIn: 'root'
})
export class PlayerKeysService {
  constructor(
    private bitmovinService: BitmovinService,
    private jumpSeekingService: JumpSeekingService,
    private playerSizeService: PlayerSizeService,
    private playerUiService: PlayerUiService
  ) {}

  handleKeydown($event: KeyboardEvent): void {
    switch ($event.code) {
      case PlayerKey.PAUSE:
      case PlayerKey.PAUSE_ALT:
        $event.preventDefault();
        this.bitmovinService.playPause('ui');
        break;
      case PlayerKey.MUTE:
        this.bitmovinService.muteToggle();
        break;
      case PlayerKey.FULLSCREEN:
        this.bitmovinService.fullscreenToggle();
        this.playerSizeService.largePlayer();
        break;
      case PlayerKey.JUMP_BACK:
        this.uiToggle$();
        this.jumpSeekingService.jumpBack();
        break;
      case PlayerKey.JUMP_FORWARD:
        this.uiToggle$();
        this.jumpSeekingService.jumpForward();
        break;
      case PlayerKey.VOLUME_UP:
        this.bitmovinService.changeVolumeBy(5);
        break;
      case PlayerKey.VOLUME_DOWN:
        this.bitmovinService.changeVolumeBy(-5);
        break;
      case PlayerKey.CLOSE:
        if (this.bitmovinService.isAdPlaying()) {
          return;
        }

        this.playerSizeService.miniPlayer();
        break;
    }
  }

  /**
   * Displays the UI and removes it again as soon the jumpseek kicks in.
   * Completes if the ui got toggled from bitmovin while seeking
   */
  private uiToggle$(): void {
    if (this.bitmovinService.isPaused()) {
      return;
    }

    this.playerUiService.showUi();

    this.bitmovinService.seeked$
      .pipe(take(1), takeUntil(this.playerUiService.ui$))
      .subscribe(() => this.playerUiService.hideUi());
  }
}
