import { Injectable } from '@angular/core';
import { Upsell, UpsellCta } from '../../interfaces/upsell.interface';
import { PopupService } from '../popup.service';
import { AuthenticationService } from '@teleboy/web.user';
import { HrefService } from '../../../shared/services/href.service';
import { EventTrackingService } from '@teleboy/web.core';

@Injectable({
  providedIn: 'root'
})
export class AboUpsellService implements Upsell {
  readonly name = 'abo';
  readonly icon = 'navi-user';
  readonly cta: UpsellCta[];

  constructor(
    private authenticationService: AuthenticationService,
    private hrefService: HrefService,
    private popupService: PopupService,
    private eventTrackingService: EventTrackingService
  ) {
    this.cta = this._cta();
  }

  private _cta(): UpsellCta[] {
    const cta = [
      {
        key: 'upgrade',
        click: () => {
          this.eventTrackingService.trackEvent('subscriptionUpsell.upgrade');
          this.goToShop();
        }
      }
    ];

    if (!this.authenticationService.isAuthenticated) {
      cta.unshift({
        key: 'login',
        click: () => this.goToLogin()
      });
    }

    return cta;
  }

  private goToLogin(): void {
    this.popupService.closeModal();
    this.hrefService.goToUrl('/login');
    this.eventTrackingService.trackEvent('upsell_abo_login');
  }

  private goToShop(): void {
    this.popupService.closeModal();
    this.hrefService.goToUrl('/tv');
  }
}
