<router-outlet name="landingpage"></router-outlet>
<router-outlet name="person"></router-outlet>
<router-outlet name="details"></router-outlet>
<router-outlet name="broadcast"></router-outlet>
<router-outlet name="player"></router-outlet>
<router-outlet name="popup"></router-outlet>
<div class="layout-wrapper" [ngClass]="{'layout-wrapper--scrolled': scrolled$ | async}">
  <div class="layout-wrapper__sidenav">
    <app-main-nav></app-main-nav>
  </div>
  <div class="layout-wrapper__content">
    <router-outlet></router-outlet>
  </div>
</div>
