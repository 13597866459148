import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HrefService } from '../../../shared/services/href.service';
import { SubscriptionInfo } from '../../guards/profile.guard';
import { MediaImageStack } from '@teleboy/web.core';
import {
  AuthenticationService,
  UserProfileApiService,
  UserProfileInitilizeParams,
  UserService
} from '@teleboy/web.user';
import { BehaviorSubject, catchError, EMPTY, switchMap } from 'rxjs';

@Component({
  selector: 'app-profiles-upsell',
  templateUrl: './profiles-upsell.component.html',
  styleUrls: ['./profiles-upsell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ProfilesUpsellComponent {
  protected readonly MediaImageStack = MediaImageStack;
  protected readonly Subscription = SubscriptionInfo;
  protected readonly busy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly user = this.authenticationService.user;

  constructor(
    @Inject(MAT_DIALOG_DATA) public subscriptionInfo: SubscriptionInfo,
    private authenticationService: AuthenticationService,
    private dialogRef: MatDialogRef<ProfilesUpsellComponent>,
    private hrefService: HrefService,
    private userProfileApiService: UserProfileApiService,
    private userService: UserService
  ) {}

  close(result: boolean): void {
    this.dialogRef.close(result);
  }

  initializeProfiles(): void {
    if (!this.user.isProfileUser) {
      this.busy$.next(true);

      const initParams: UserProfileInitilizeParams = new UserProfileInitilizeParams(
        this.user.displayName,
        this.user.image.hash
      );

      this.userProfileApiService
        .initializeParentUserProfile(initParams)
        .pipe(
          switchMap(() => {
            return this.userService.refresh().pipe(
              catchError(() => {
                this.busy$.next(false);
                return EMPTY;
              })
            );
          })
        )
        .subscribe({
          complete: () => {
            this.busy$.next(false);
            this.dialogRef.close(true);
          }
        });
    } else {
      this.dialogRef.close(true);
    }
  }

  goToShop(): void {
    this.busy$.next(true);
    this.hrefService.goToUrl('/tv');
  }
}
