<app-popup outlet="details" [full]="true">

  <h1>{{ pageTitle() || ('pvr.list.title' | translate) }}</h1>

  <div class="recording-list">
      <form [formGroup]="form" class="recording-list__filters-main mb-base">
        <div class="recording-list__filters-main__buttons">
          <app-dropdown [options]="sortDateOptions" formControlName="dateSort"></app-dropdown>

          @if (pagetype === 'ready') {
            <div class="recording-list__filter-btn">
              <button class="btn" (click)="openGenregroupList()" [attr.aria-disabled]="recordingBatchDeleteService.enabled()">
                {{ genreId() ? (genreId() | genreLabel | async) : ('pvr.list.filter.genre.all' | translate) }}
              </button>
            </div>
          }
          @if (recordings().length) {
            <div class="recording-list__filter-btn">
              @if (recordingBatchDeleteService.enabled()) {
                <button class="btn" (click)="recordingBatchDeleteService.disableBatchDelete()">
                  {{ 'pvr.list.filter.batchDelete.disable' | translate }}
                </button>
              } @else {
                <button class="btn" (click)="recordingBatchDeleteService.enableBatchDelete()">
                  {{ 'pvr.list.filter.batchDelete.enable' | translate }}
                </button>
              }
            </div>
          }
        </div>

        <div class="recording-list__search">
          @if (showSearchPrefixDropdown()) {
            <app-dropdown [roundedLeft]="true" [options]="searchOptions" formControlName="searchPrefix"></app-dropdown>
          }

          <input id="pvr-search-query" type="search" class="form-control" autocomplete="off"
                 [ngClass]="{ 'recording-list__rounded-right': !this.title() }"
                 formControlName="searchText"
                 [placeholder]="'pvr.list.filter.text.placeholder' | translate">
        </div>

      </form>
  </div>

  <div class="grid" [ngClass]="{'grid__tiles': recordings().length && listingType() === ListingType.TILES}"
       (scrolled)="paginate()" infiniteScroll
       [infiniteScrollContainer]="'.popup__backdrop--details'"
       [fromRoot]="true"
       [immediateCheck]="true"
       [alwaysCallback]="true">

    @if (!this.isLoading()) {
      @for (recording of recordings(); track recording.id) {
        <div class="recording-list__batch-delete-container">
          <app-broadcast-item  [broadcast]="recording" [card]="listingType() === ListingType.TILES"
                               (recordDeleted)="onRecordingDeleted($event)"
                               [hideActions]="recordingBatchDeleteService.enabled()"
          ></app-broadcast-item>
          @if (recordingBatchDeleteService.enabled()) {
            <app-recording-batch-toggler
              [isCard]="listingType() === ListingType.TILES"
              [recording]="recording"
              (recordingSelect)="recordingBatchDeleteService.addRecording($event)"
              (recordingUnselect)="recordingBatchDeleteService.removeRecording($event)"
            ></app-recording-batch-toggler>
          }
        </div>
      } @empty {
        @if (!this.isPaginating()) {
          <app-pvr-empty-list></app-pvr-empty-list>
        }
      }
    }

  </div>

  @if (recordingBatchDeleteService.enabled()) {
    <app-recording-batch-action
      (batchDeleted)="batchDeleteRecordings()"
      (batchSelectAll)="recordingBatchDeleteService.addRecordings(recordings())"
    ></app-recording-batch-action>
  }


  @if (isBusy()) {
    <div class="grid ghost-listing mt-base" [ngClass]="{'grid__tiles': listingType() === ListingType.TILES}">
      @for (ghost of ghostPreviews; track $index) {
        <app-broadcast-item [card]="listingType() === ListingType.TILES"></app-broadcast-item>
      }
    </div>
  }

  <ng-container buttons>
    <button class="buttons btn btn__icon btn--circle btn__toggle-view--{{ listingType() }}"
            (click)="toggleListingType()">
      <tbui-icon iconId="view-list" [ngClass]="{'hidden': listingType() === ListingType.LIST}"
                 class="view-icon-list">
      </tbui-icon>
      <tbui-icon iconId="view-tiles" [ngClass]="{'hidden': listingType() !== ListingType.LIST}"
                 class="view-icon-tiles">
      </tbui-icon>
    </button>
  </ng-container>

</app-popup>

