import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListingLiveComponent } from './components/listing-live/listing-live.component';
import { StyleguideComponent } from './components/styleguide/styleguide.component';
import { HomeComponent } from './components/home/home.component';
import { BroadcastComponent } from './components/broadcast/broadcast.component';
import { NavigationLayoutComponent } from '../shared/components/layout/navigation-layout/navigation-layout.component';
import { StationComponent } from './components/station/station.component';
import { SerieComponent } from './components/serie/serie.component';
import { SettingsComponent } from './components/settings/settings.component';
import { EpgComponent } from './components/epg/epg.component';
import { EmptyComponent } from '../shared/components/empty/empty.component';
import { notFoundGuard } from '../core/guards/not-found.guard';
import { ItemsListPageComponent } from './components/items-list-page/items-list-page.component';
import { PersonComponent } from './components/person/person.component';
import { TagLandingpageComponent } from './components/landingpage/tag-landingpage/tag-landingpage.component';
import { TeaserLandingpageComponent } from './components/landingpage/teaser-landingpage/teaser-landingpage.component';
import { GenreLandingpageComponent } from './components/landingpage/genre-landingpage/genre-landingpage.component';
import { PlayerTrailerComponent } from '../player/components/player-trailer/player-trailer.component';
import { PlayerLiveComponent } from '../player/components/player-live/player-live.component';
import { anonymousDataGuard } from '../player/guards/anonymous-data.guard';
import { liveGuard } from '../player/guards/live.guard';
import { PlayerReplayComponent } from '../player/components/player-replay/player-replay.component';
import { replayGuard } from '../player/guards/replay.guard';
import { PlayerRecordingComponent } from '../player/components/player-recording/player-recording.component';
import { recordingGuard } from '../player/guards/recording.guard';
import { PlayerCommunityComponent } from '../player/components/player-community/player-community.component';
import { communityGuard } from '../player/guards/community.guard';
import { EditProfileComponent } from '../profiles/components/edit-profile/edit-profile.component';
import { profileGuard } from '../profiles/guards/profile.guard';
import { ProfileSettingsListingComponent } from '../profiles/components/profile-settings-listing/profile-settings-listing.component';
import { profileCreateGuard } from '../profiles/guards/profile-create.guard';
import { PvrRecordingListComponent } from '../recording/components/listings/pvr-recording-list/pvr-recording-list.component';
import { PvrComponent } from '../recording/components/pvr.component';
import { PvrTitlegroupListComponent } from '../recording/components/listings/pvr-titlegroup-list/pvr-titlegroup-list.component';
import { PvrGenregroupListComponent } from '../recording/components/listings/pvr-genregroup-list/pvr-genregroup-list.component';
import { PvrWatchlistListComponent } from '../recording/components/listings/pvr-watchlist-list/pvr-watchlist-list.component';
import { PvrRecordingSerialListComponent } from '../recording/components/listings/pvr-recording-serial-list/pvr-recording-serial-list.component';

const outlets = ['broadcast', 'landingpage', 'popup', 'details', 'person', 'player'];
const routes: Routes = [
  {
    path: '',
    component: NavigationLayoutComponent,
    children: [
      {
        path: 'themen/:title/:id',
        component: TeaserLandingpageComponent,
        outlet: 'landingpage'
      },
      {
        path: 'live',
        component: ListingLiveComponent
      },
      {
        path: 'items/list',
        component: ItemsListPageComponent,
        outlet: 'details'
      },
      {
        path: 'epg',
        component: EpgComponent
      },
      // Fallback for old recordings route
      {
        path: 'recordings',
        redirectTo: 'pvr'
      },
      {
        path: 'pvr',
        component: PvrComponent
      },
      {
        path: 'pvr/recordings',
        component: PvrRecordingListComponent,
        outlet: 'details'
      },
      {
        path: 'pvr/watchlist',
        component: PvrWatchlistListComponent,
        outlet: 'landingpage'
      },
      {
        path: 'pvr/titlegroups',
        component: PvrTitlegroupListComponent,
        outlet: 'landingpage'
      },
      {
        path: 'pvr/genregroups',
        component: PvrGenregroupListComponent,
        outlet: 'landingpage'
      },
      {
        path: 'pvr/seriallisting',
        component: PvrRecordingSerialListComponent,
        outlet: 'landingpage'
      },
      {
        path: 'styleguide',
        component: StyleguideComponent
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'broadcast/:broadcastId',
        component: BroadcastComponent,
        outlet: 'broadcast'
      },
      {
        path: 'serie/:serieId',
        component: SerieComponent,
        outlet: 'broadcast'
      },
      {
        path: 'serie/:serieId/:seasonId',
        component: SerieComponent,
        outlet: 'broadcast'
      },
      {
        path: 'station/:stationId',
        component: StationComponent,
        outlet: 'landingpage'
      },
      {
        path: 'settings',
        component: SettingsComponent
      },
      {
        path: 'cast/:name',
        component: PersonComponent,
        outlet: 'person'
      },
      {
        path: 'tag/:tag',
        component: TagLandingpageComponent,
        outlet: 'landingpage'
      },
      {
        path: 'genre/:genre',
        component: GenreLandingpageComponent,
        outlet: 'landingpage'
      },
      {
        path: 'trailer/:videoId',
        component: PlayerTrailerComponent,
        outlet: 'player'
      },
      {
        path: 'live/:stationId',
        component: PlayerLiveComponent,
        outlet: 'player',
        canActivate: [anonymousDataGuard, liveGuard]
      },
      {
        path: 'replay/:broadcastId',
        component: PlayerReplayComponent,
        outlet: 'player',
        canActivate: [replayGuard]
      },
      {
        path: 'recording/:broadcastId',
        component: PlayerRecordingComponent,
        outlet: 'player',
        canActivate: [recordingGuard]
      },
      {
        path: 'community/:broadcastId',
        component: PlayerCommunityComponent,
        outlet: 'player',
        canActivate: [communityGuard]
      },
      {
        path: 'profile-settings-list',
        component: ProfileSettingsListingComponent,
        canActivate: [profileGuard]
      },
      {
        path: 'user-profile-create',
        component: EditProfileComponent,
        canActivate: [profileGuard, profileCreateGuard]
      }
    ]
  }
];

outlets.forEach((outlet) => {
  routes[0].children?.push({
    path: '**',
    component: EmptyComponent,
    canActivate: [notFoundGuard],
    outlet: outlet
  });
});

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EpgRoutingModule {}
