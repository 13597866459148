<div class="popup__backdrop" [ngClass]="backdropClassList" (click)="backdropClick($event)" #backdrop
     role="alertdialog" aria-modal="true" cdkTrapFocus>
  <div class="popup__container" [ngClass]="{ 'popup__container--large': large, 'popup__container--full': full }">
    <div #scrollToTop></div>
    <ng-content select="[header]"></ng-content>
    <div class="popup__body" [ngClass]="{ 'popup__body--full': full }">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="popup__buttons">
    <ng-content select="[buttons]"></ng-content>
    <button class="btn btn__icon btn--circle btn--lg btn--opaque" (click)="close()">
      <tbui-icon iconId="close"></tbui-icon>
    </button>
  </div>
</div>
