import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DataSwiperDirective } from '../../../directives/swiper/data-swiper.directive';
import { Broadcast, HeartbeatApiService, HeartbeatParams } from '@teleboy/web.epg';
import { ApiListResponse } from '@teleboy/web.core';
import { catchError, EMPTY, map, Observable, switchMap, tap } from 'rxjs';
import { PopupService } from '../../../../core/services/popup.service';
import { ItemsListRouteData, EntityServiceType, EntityType } from '../../../interfaces/items-list-route-data';
import { HeartbeatswiperService } from './heartbeat-swiper.service';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { BroadcastItemComponent } from '../../broadcast-item/broadcast-item.component';

@Component({
  selector: 'app-heartbeat-swiper',
  templateUrl: './heartbeat-swiper.component.html',
  styleUrls: ['../swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, SharedModule, SwiperModule, NgFor, BroadcastItemComponent, AsyncPipe]
})
export class HeartbeatSwiperComponent extends DataSwiperDirective<Broadcast> {
  constructor(
    private heartbeatApiService: HeartbeatApiService,
    private heartbeatswiperService: HeartbeatswiperService,
    private popupService: PopupService
  ) {
    super();
  }

  itemLoader$(): Observable<ApiListResponse['data']> {
    const params = new HeartbeatParams().includeCommunity();

    return this.heartbeatswiperService.fetchHeartbeatLane$.pipe(
      switchMap(() => {
        return this.heartbeatApiService.getLatestHeartbeats(params).pipe(
          catchError(() => {
            return EMPTY;
          })
        );
      }),
      map((response) => {
        return response.items;
      }),
      map((items) => {
        return { total: items.length, items };
      }),
      tap((data) => {
        if (this._swiper) {
          this._swiper.currentVirtualData = data.items;
        }
      })
    );
  }

  openInListTilesView() {
    const routeData: ItemsListRouteData = {
      title: this.headline,
      params: new HeartbeatParams().includeCommunity(),
      serviceName: EntityServiceType.HeartbeatApiService,
      broadcastType: EntityType.Heartbeat
    };

    this.popupService.open('items/list', { outlet: 'details', data: routeData, skipLocationChange: true });
  }

  onSuccessDeleteHeartbeat() {
    this.heartbeatswiperService.fetchItems();
  }
}
