"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Spacer = void 0;
var component_1 = require("./component");
/**
 * A dummy component that just reserves some space and does nothing else.
 *
 * @category Components
 */
var Spacer = /** @class */function (_super) {
  __extends(Spacer, _super);
  function Spacer(config) {
    if (config === void 0) {
      config = {};
    }
    var _this = _super.call(this, config) || this;
    _this.config = _this.mergeConfig(config, {
      cssClass: 'ui-spacer'
    }, _this.config);
    return _this;
  }
  Spacer.prototype.onShowEvent = function () {
    // disable event firing by overwriting and not calling super
  };
  Spacer.prototype.onHideEvent = function () {
    // disable event firing by overwriting and not calling super
  };
  Spacer.prototype.onHoverChangedEvent = function (hovered) {
    // disable event firing by overwriting and not calling super
  };
  return Spacer;
}(component_1.Component);
exports.Spacer = Spacer;