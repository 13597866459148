<app-close-button (closed)="confirm()"></app-close-button>
<app-info icon="idling"
          [title]="'player.idling.title' | translate"
          [text]="'player.idling.text' | translate: { minutes: maxIdlingMinutes }"
          [collapse]="true" size="small">

  <div class="popup__footer btn__list-h btn__list-h--center" cta>
    <button (click)="confirm()" class="btn btn__primary">
      {{ 'player.idling.confirm' | translate }}
    </button>
    <button (click)="pause()" class="btn">
      {{ 'player.idling.pause' | translate }}
    </button>
  </div>
</app-info>
