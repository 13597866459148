import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  Broadcast,
  EpgApiService,
  EpgParams,
  SerieParams,
  SerieSort,
  TeaserDevice,
  TeaserParams
} from '@teleboy/web.epg';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AboLevel, AuthenticationService, LanguageService } from '@teleboy/web.user';
import { RouterLink } from '@angular/router';
import { NgTemplateOutlet, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TeaserSwiperComponent } from '../swiper/teaser-swiper/teaser-swiper.component';
import { BroadcastSwiperComponent } from '../swiper/broadcast-swiper/broadcast-swiper.component';
import { SerieSwiperComponent } from '../swiper/serie-swiper/serie-swiper.component';
import { BroadcastItemComponent } from '../broadcast-item/broadcast-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-styleguide',
  templateUrl: './styleguide.component.html',
  styleUrls: ['./styleguide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    NgTemplateOutlet,
    FormsModule,
    NgClass,
    TeaserSwiperComponent,
    BroadcastSwiperComponent,
    SerieSwiperComponent,
    NgFor,
    BroadcastItemComponent,
    AsyncPipe,
    TranslateModule,
    IconComponent
  ]
})
export class StyleguideComponent implements OnInit {
  epg$!: Observable<Broadcast[]>;
  active = false;

  iconsNavi = [
    'navi-service-home',
    'navi-service-tv',
    'navi-service-fixnet',
    'navi-service-internet',
    'navi-service-mobile',
    'navi-service-drive',
    'navi-blog',
    'navi-support',
    'navi-player',
    'navi-epg',
    'navi-user',
    'navi-settings',
    'navi-player-home',
    'navi-player-epg',
    'navi-player-pvr',
    'navi-notification',
    'navi-search'
  ];

  iconsInfo = ['stream-quality', 'start-screen', 'remote', 'dualaudio', 'captions', 'dolby', 'community', 'serial'];

  iconsActions = [
    'play',
    'pause',
    'stop',
    'record',
    'watchlist',
    'replay',
    'download',
    'settings',
    'info',
    'trailer',
    'trailer-outline',
    'edit',
    'print',
    'bin',
    'view-list',
    'view-tiles',
    'close',
    'arrow-down',
    'arrow-up',
    'arrow-right',
    'mute-off',
    'mute-on',
    'fullscreen-on',
    'fullscreen-off',
    'close',
    'jumpseek-back',
    'jumpseek-forward'
  ];

  topTeasersParams!: TeaserParams;
  topicTeasersParams!: TeaserParams;
  epgParams!: EpgParams;
  seriesParams!: SerieParams;

  private readonly buttonStates = {
    active: new BehaviorSubject(false),
    bordered: new BehaviorSubject(false),
    busy: new BehaviorSubject(false),
    opaque: new BehaviorSubject(false)
  };

  readonly broastcastListingItemStates = {
    card: false,
    tipp: true,
    active: false
  };

  readonly formStates = {
    invalid: false
  };

  readonly bgColor$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private authenticationService: AuthenticationService,
    private epgApiService: EpgApiService,
    private languageService: LanguageService
  ) {}

  get buttonClassList() {
    return {
      'btn--active': this.buttonStates.active.value,
      'btn--bordered': this.buttonStates.bordered.value,
      'btn--busy': this.buttonStates.busy.value,
      'btn--opaque': this.buttonStates.opaque.value
    };
  }

  ngOnInit(): void {
    const params = new EpgParams().setLimit(30).setReplayRange().orderByCharts().setGenre(1);
    this.epg$ = this.epgApiService.getBroadcasts(params.httpParams).pipe(map((r) => r.items));

    // TOP TEASER
    this.topTeasersParams = new TeaserParams(TeaserDevice.WEB, this.languageService.getLanguage())
      .setTags(['tv_topteaser'])
      .setAboLevel(this.authenticationService.user?.aboLevel || AboLevel.GUEST);

    // TOPIC TEASERS
    this.topicTeasersParams = new TeaserParams(TeaserDevice.WEB, this.languageService.getLanguage())
      .setTags(['tv_teaser_topics'])
      .setAboLevel(this.authenticationService.user?.aboLevel || AboLevel.GUEST);

    // TIPPS
    this.epgParams = new EpgParams()
      .setLimit(30)
      .setReplayRange()
      .setGenre(1)
      .orderByCharts()
      .setLocale(this.languageService.getLanguage());

    // SERIES
    this.seriesParams = new SerieParams()
      .setGenre(4)
      .setLanguage(this.languageService.getLanguage())
      .setSort(SerieSort.SCORE);
  }

  toggleFormStyle(state: 'invalid'): void {
    this.formStates[state] = !this.formStates[state];
  }

  toggleButtonStyle(state: 'active' | 'bordered' | 'busy' | 'opaque'): void {
    this.buttonStates[state].next(!this.buttonStates[state].value);
  }

  toggleBroadcastListingItems(state: 'card' | 'tipp' | 'active'): void {
    this.broastcastListingItemStates[state] = !this.broastcastListingItemStates[state];
  }

  setBgColor(color: 'white' | 'translucent' | ''): void {
    this.bgColor$.next(`bg-${color}`);
  }
}
