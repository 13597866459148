<app-close-button (closed)="close()"></app-close-button>

<app-info icon="close" [title]="'error.title' | translate"
          [text]="'error.text' | translate: {}" [collapse]="true">
  <p customContent>{{ 'error.details' | translate: {errorCode: errorCode} }}</p>
  <div class="popup__footer btn__list-h btn__list-h--end" cta>
    <button (click)="close()" class="btn btn__primary" rel="nofollow">
      {{ 'error.close' | translate }}
    </button>
  </div>
</app-info>
