import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { AppConfig, Language, WebDeviceTokenService } from '@teleboy/web.core';
import packageInfo from '../../../../package.json';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StreamQuality } from '@teleboy/web.player';

@Injectable()
export class AppConfigProvider implements AppConfig {
  bigScreen = false;
  defaultStreamQuality = StreamQuality.AUTO;
  useAnonymousStreamVerficationForGuests = true;
  gt12 = true;
  forceSingleTimelineMarkers = true;
  deviceToken: string;

  readonly deviceClient = 'web';
  readonly logErrorsToConsole = !environment.production;

  readonly cockpitUrl = environment.cockpit.url;
  readonly playerUrl = window.location.origin;
  readonly shopUrl = '';
  readonly staticUrl = environment.static.url;
  readonly webUrl = environment.webui.url;

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private webDeviceTokenService: WebDeviceTokenService
  ) {
    this.deviceToken = this.webDeviceTokenService.getDeviceToken();
  }

  get deviceOs(): string {
    return this.deviceDetectorService.os;
  }

  get deviceType(): string {
    return this.deviceDetectorService.deviceType;
  }

  get appVersion(): string {
    return packageInfo.version;
  }

  get apiKey(): string {
    return environment.api.key;
  }

  get apiUrl(): string {
    return environment.api.url;
  }

  get apiVersion(): string {
    return environment.api.version;
  }

  get baseUrl(): string {
    return this.playerUrl;
  }

  get locale(): Language {
    return Language.DE;
  }

  get anonymousSecret(): string {
    return environment.secrets.anonymousStreamSecret;
  }
}

export const APP_CONFIG_PROVIDER = {
  provide: 'APP_CONFIG',
  useClass: AppConfigProvider
};
