import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { AuthenticationService, User, UserService } from '@teleboy/web.user';
import { SnackbarService, SnackbarType } from '../../../../core/services/snackbar.service';
import moment, { Moment } from 'moment';
import { tap } from 'rxjs';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-replay-info',
  templateUrl: './replay-info.component.html',
  styleUrls: ['./replay-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, TranslateModule, IconComponent]
})
export class ReplayInfoComponent {
  @LocalStorage('hideReplayMessage', false)
  hideReplayMessage!: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private changeDetectorRef: ChangeDetectorRef,
    private snackbarService: SnackbarService,
    private userService: UserService
  ) {}

  get user(): User {
    return this.authenticationService.user;
  }

  activateReplay(): void {
    this.userService
      .activateReplay()
      .pipe(
        tap(() => {
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: () => {
          this.snackbarService.openSnackbar('settings.replayActivateSuccess', SnackbarType.SUCCESS);
        },
        error: () => {
          this.snackbarService.openSnackbar('settings.replayActivateError', SnackbarType.ERROR);
        }
      });
  }

  closeReplayMessage(): void {
    this.hideReplayMessage = true;
  }

  isReplayReady(): boolean {
    return this.isReplayActive() && moment().isAfter(this.getReplayActivationAvailabilityDate());
  }

  getReplayActivationAvailabilityDate(): Moment {
    return this.authenticationService.user.replay.clone().add(7, 'days');
  }

  isReplayActive(): boolean {
    return this.user.replay !== undefined;
  }
}
