import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { filter, map, Observable, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';
import { Teaser, TeaserApiService, TeaserParams } from '@teleboy/web.epg';
import { DOCUMENT, NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { ScreenService } from '../../../../core/services/screen.service';

@Component({
  selector: 'app-teaser-buttons',
  templateUrl: './teaser-buttons.component.html',
  styleUrls: ['./teaser-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgFor, NgClass, AsyncPipe]
})
export class TeaserButtonsComponent implements OnInit, OnDestroy {
  @Input() params!: TeaserParams;

  buttons$!: Observable<Teaser[]>;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
    private screenService: ScreenService,
    private teaserApiService: TeaserApiService
  ) {}

  ngOnInit(): void {
    this.buttons$ = this.buttonsTeaser$();

    this.screenService
      .orientationChange$()
      .pipe(
        withLatestFrom(this.buttons$),
        filter((_, buttons) => !!buttons),
        tap(() => {
          this.hideAllButtons();
          this.toggleButtons();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  buttonsTeaser$(): Observable<Teaser[]> {
    return this.teaserApiService.getTeasers(this.params).pipe(
      map((response) => response.items),
      filter((buttons) => !!buttons.length),
      tap(() => this.toggleButtons())
    );
  }

  private hideAllButtons() {
    const buttons = this.document.querySelectorAll('*[id^="teaser-button-"]');
    buttons.forEach((button) => {
      this.renderer2.setStyle(button, 'opacity', 0);
    });
  }

  private toggleButtons() {
    setTimeout(() => {
      const buttons = this.document.querySelectorAll('*[id^="teaser-button-"]');
      const container = this.document.getElementsByClassName('buttons-container')[0];
      const containerBottom = container.getBoundingClientRect().bottom;
      const buttonHorisontalOffset = 8;

      buttons.forEach((button) => {
        const buttonBottom = button.getBoundingClientRect().bottom;
        const notFullyVisible =
          button.clientWidth + buttonHorisontalOffset > container.clientWidth || buttonBottom > containerBottom;

        this.renderer2.setStyle(button, 'opacity', notFullyVisible ? 0 : 1);
      });
    }, 500);
  }
}
