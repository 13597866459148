import { Injectable } from '@angular/core';
import { Stream } from '@teleboy/web.player';
import { catchError, EMPTY, from, map, Observable, Subject, tap } from 'rxjs';
import { MetaService } from '../../core/services/meta.service';
import { Router } from '@angular/router';
import { LanguageService } from '@teleboy/web.user';
import { BitmovinLocalizationConfig } from '../../core/configs/bitmovin-localization.config';
import { LocalizationConfig } from 'bitmovin-player-ui/dist/js/framework/uimanager';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  private readonly _reloadStream$: Subject<void> = new Subject<void>();
  readonly reloadStream$: Observable<void> = this._reloadStream$.asObservable();

  constructor(
    private languageService: LanguageService,
    private metaTitleService: MetaService,
    private router: Router
  ) {}

  setPageTitle$(stream: Stream) {
    const broadcast = stream.epg.current;
    return broadcast.station$.pipe(
      tap((station) => this.metaTitleService.setTitle([broadcast.title, station.name])),
      map(() => stream),
      catchError(() => EMPTY)
    );
  }

  closePlayer(): Observable<boolean> {
    return from(this.router.navigate([{ outlets: { player: null } }]));
  }

  reloadStream(): void {
    this._reloadStream$.next();
  }

  getPlayerLocalizationConfig(): LocalizationConfig {
    return {
      language: this.languageService.getLanguage(),
      vocabularies: BitmovinLocalizationConfig
    };
  }
}
