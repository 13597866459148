import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { switchMap, withLatestFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CommunityPlayerService } from '@teleboy/web.player';

@Component({
  selector: 'app-player-community',
  templateUrl: './player-community.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PlayerCommunityComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private communityPlayerService: CommunityPlayerService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        withLatestFrom(this.activatedRoute.queryParams),
        switchMap(([params, queryParams]) =>
          this.communityPlayerService.play$(params['broadcastId'], queryParams['providerId'])
        )
      )
      .subscribe();
  }
}
