"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TvNoiseCanvas = void 0;
var component_1 = require("./component");
var dom_1 = require("../dom");
/**
 * Animated analog TV static noise.
 *
 * @category Components
 */
var TvNoiseCanvas = /** @class */function (_super) {
  __extends(TvNoiseCanvas, _super);
  function TvNoiseCanvas(config) {
    if (config === void 0) {
      config = {};
    }
    var _this = _super.call(this, config) || this;
    _this.canvasWidth = 160;
    _this.canvasHeight = 90;
    _this.interferenceHeight = 50;
    _this.lastFrameUpdate = 0;
    _this.frameInterval = 60;
    _this.useAnimationFrame = !!window.requestAnimationFrame;
    _this.config = _this.mergeConfig(config, {
      cssClass: 'ui-tvnoisecanvas'
    }, _this.config);
    return _this;
  }
  TvNoiseCanvas.prototype.toDomElement = function () {
    return this.canvas = new dom_1.DOM('canvas', {
      'class': this.getCssClasses()
    }, this);
  };
  TvNoiseCanvas.prototype.start = function () {
    this.canvasElement = this.canvas.get(0);
    this.canvasContext = this.canvasElement.getContext('2d');
    this.noiseAnimationWindowPos = -this.canvasHeight;
    this.lastFrameUpdate = 0;
    this.canvasElement.width = this.canvasWidth;
    this.canvasElement.height = this.canvasHeight;
    this.renderFrame();
  };
  TvNoiseCanvas.prototype.stop = function () {
    if (this.useAnimationFrame) {
      cancelAnimationFrame(this.frameUpdateHandlerId);
    } else {
      clearTimeout(this.frameUpdateHandlerId);
    }
  };
  TvNoiseCanvas.prototype.renderFrame = function () {
    // This code has been copied from the player controls.js and simplified
    if (this.lastFrameUpdate + this.frameInterval > new Date().getTime()) {
      // It's too early to render the next frame
      this.scheduleNextRender();
      return;
    }
    var currentPixelOffset;
    var canvasWidth = this.canvasWidth;
    var canvasHeight = this.canvasHeight;
    // Create texture
    var noiseImage = this.canvasContext.createImageData(canvasWidth, canvasHeight);
    // Fill texture with noise
    for (var y = 0; y < canvasHeight; y++) {
      for (var x = 0; x < canvasWidth; x++) {
        currentPixelOffset = canvasWidth * y * 4 + x * 4;
        noiseImage.data[currentPixelOffset] = Math.random() * 255;
        if (y < this.noiseAnimationWindowPos || y > this.noiseAnimationWindowPos + this.interferenceHeight) {
          noiseImage.data[currentPixelOffset] *= 0.85;
        }
        noiseImage.data[currentPixelOffset + 1] = noiseImage.data[currentPixelOffset];
        noiseImage.data[currentPixelOffset + 2] = noiseImage.data[currentPixelOffset];
        noiseImage.data[currentPixelOffset + 3] = 50;
      }
    }
    // Put texture onto canvas
    this.canvasContext.putImageData(noiseImage, 0, 0);
    this.lastFrameUpdate = new Date().getTime();
    this.noiseAnimationWindowPos += 7;
    if (this.noiseAnimationWindowPos > canvasHeight) {
      this.noiseAnimationWindowPos = -canvasHeight;
    }
    this.scheduleNextRender();
  };
  TvNoiseCanvas.prototype.scheduleNextRender = function () {
    if (this.useAnimationFrame) {
      this.frameUpdateHandlerId = window.requestAnimationFrame(this.renderFrame.bind(this));
    } else {
      this.frameUpdateHandlerId = window.setTimeout(this.renderFrame.bind(this), this.frameInterval);
    }
  };
  return TvNoiseCanvas;
}(component_1.Component);
exports.TvNoiseCanvas = TvNoiseCanvas;