import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appScrollableItem]',
  standalone: false
})
export class ScrollableItemDirective {
  @Input('appScrollableItem') public key!: number;

  constructor(private el: ElementRef<HTMLElement>) {}

  public scrollIntoView(options?: ScrollIntoViewOptions) {
    this.el.nativeElement.scrollIntoView(options);
  }
}
