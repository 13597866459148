import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { StarAlert, StarAlertType } from '@teleboy/web.epg';
import { StarAlertService } from '../../../services/star-alert.service';
import { Observable, Subject } from 'rxjs';
import { NgIf, AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-star-alert-button',
  templateUrl: './star-alert-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, AsyncPipe, TranslateModule, IconComponent]
})
export class StarAlertButtonComponent implements OnInit, OnChanges {
  @Input() words!: string;
  @Input() type!: StarAlertType;

  protected readonly isStarAlertBusy$!: Observable<boolean>;
  protected readonly isStarAlertLoading$!: Observable<boolean>;
  protected readonly starAlert$: Observable<StarAlert | null>;

  private readonly _starAlert$: Subject<StarAlert | null> = new Subject<StarAlert | null>();

  constructor(private starAlertService: StarAlertService) {
    this.isStarAlertBusy$ = this.starAlertService.isStarAlertBusy$;
    this.isStarAlertLoading$ = this.starAlertService.isStarAlertLoading$;
    this.starAlert$ = this._starAlert$.asObservable();
  }

  ngOnInit(): void {
    this.getStarAlertForWordsAndType();
  }

  ngOnChanges(changes: SimpleChanges) {
    this._starAlert$.next(null);
    if (!changes['words'] || changes['words'].isFirstChange() || !changes['words'].currentValue) {
      return;
    }
    this.getStarAlertForWordsAndType();
  }

  createStarAlert(): void {
    this.starAlertService.createStarAlert$(this.words, this.type).subscribe((starAlert: StarAlert) => {
      this._starAlert$.next(starAlert);
    });
  }

  deleteStarAlert(starAlert: StarAlert): void {
    this.starAlertService.deleteStarAlert$(starAlert).subscribe(() => {
      this._starAlert$.next(null);
    });
  }

  private getStarAlertForWordsAndType(): void {
    if (!this.words) {
      return;
    }
    this.starAlertService.getStarAlertForWordsAndType(this.words, this.type).subscribe((starAlert: StarAlert) => {
      this._starAlert$.next(starAlert);
    });
  }
}
