import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-expand-link',
  templateUrl: './expand-link.component.html',
  styleUrls: ['expand-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ExpandLinkComponent {
  @Input() linkText!: string;
  @Input() clickable = true;
  @Output() clicked = new EventEmitter<void>();

  onClick() {
    this.clicked.emit();
  }
}
