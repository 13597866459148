import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { AsyncPipe, NgStyle } from '@angular/common';
import { DurationPipe } from '@teleboy/web.ui';
import { TranslateModule } from '@ngx-translate/core';
import { RecordingStatistics } from '@teleboy/web.epg/lib/models/recording-statistics.model';
import { map, Observable, tap } from 'rxjs';
import { PvrRecordingStatisticsService } from '@teleboy/web.epg';

@Component({
  selector: 'app-pvr-recording-statistics',
  imports: [NgStyle, DurationPipe, TranslateModule, AsyncPipe],
  templateUrl: './pvr-recording-statistics.component.html',
  styleUrl: './pvr-recording-statistics.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PvrRecordingStatisticsComponent implements OnInit {
  plannedPercentage = signal(0);
  recordedPercentage = signal(0);
  statistics$!: Observable<RecordingStatistics>;

  private readonly minPercentage = 1;

  constructor(private pvrRecordingStatisticsService: PvrRecordingStatisticsService) {}

  ngOnInit(): void {
    this.statistics$ = this.pvrRecordingStatisticsService.statistics$.pipe(
      tap((data: RecordingStatistics) => {
        const recordedPercentage = (data.recordedTime / data.maxTime) * 100;
        const plannedPercentage = (data.scheduledTime / data.maxTime) * 100;

        if (recordedPercentage > 1) {
          this.recordedPercentage.set(recordedPercentage);
        } else {
          this.adjustRecordedPercentageForDisplay(recordedPercentage);
        }

        if (plannedPercentage > 1) {
          this.plannedPercentage.set(this.recordedPercentage() + plannedPercentage);
        } else {
          this.adjustPlannedPercentageForDisplay(plannedPercentage);
        }
      }),
      map((data: RecordingStatistics) => {
        data.availableTime = Math.max(data.availableTime, 0);

        return data;
      })
    );
  }

  private adjustRecordedPercentageForDisplay(recordedPercentage: number): void {
    if (recordedPercentage !== 0) {
      this.recordedPercentage.set(this.minPercentage);
    }
  }

  private adjustPlannedPercentageForDisplay(plannedPercentage: number): void {
    if (plannedPercentage !== 0) {
      this.plannedPercentage.set(this.recordedPercentage() + this.minPercentage);
    }
  }
}
