"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TitleBar = void 0;
var container_1 = require("./container");
var metadatalabel_1 = require("./metadatalabel");
/**
 * Displays a title bar containing a label with the title of the video.
 *
 * @category Components
 */
var TitleBar = /** @class */function (_super) {
  __extends(TitleBar, _super);
  function TitleBar(config) {
    if (config === void 0) {
      config = {};
    }
    var _this = _super.call(this, config) || this;
    _this.config = _this.mergeConfig(config, {
      cssClass: 'ui-titlebar',
      hidden: true,
      components: [new metadatalabel_1.MetadataLabel({
        content: metadatalabel_1.MetadataLabelContent.Title
      }), new metadatalabel_1.MetadataLabel({
        content: metadatalabel_1.MetadataLabelContent.Description
      })],
      keepHiddenWithoutMetadata: false
    }, _this.config);
    return _this;
  }
  TitleBar.prototype.configure = function (player, uimanager) {
    var _this = this;
    _super.prototype.configure.call(this, player, uimanager);
    var config = this.getConfig();
    var shouldBeShown = !this.isHidden();
    var hasMetadataText = true; // Flag to track if any metadata label contains text
    var checkMetadataTextAndUpdateVisibility = function () {
      hasMetadataText = false;
      // Iterate through metadata labels and check if at least one of them contains text
      for (var _i = 0, _a = _this.getComponents(); _i < _a.length; _i++) {
        var component = _a[_i];
        if (component instanceof metadatalabel_1.MetadataLabel) {
          if (!component.isEmpty()) {
            hasMetadataText = true;
            break;
          }
        }
      }
      if (_this.isShown()) {
        // Hide a visible titlebar if it does not contain any text and the hidden flag is set
        if (config.keepHiddenWithoutMetadata && !hasMetadataText) {
          _this.hide();
        }
      } else if (shouldBeShown) {
        // Show a hidden titlebar if it should actually be shown
        _this.show();
      }
    };
    // Listen to text change events to update the hasMetadataText flag when the metadata dynamically changes
    for (var _i = 0, _a = this.getComponents(); _i < _a.length; _i++) {
      var component = _a[_i];
      if (component instanceof metadatalabel_1.MetadataLabel) {
        component.onTextChanged.subscribe(checkMetadataTextAndUpdateVisibility);
      }
    }
    uimanager.onControlsShow.subscribe(function () {
      shouldBeShown = true;
      if (!(config.keepHiddenWithoutMetadata && !hasMetadataText)) {
        _this.show();
      }
    });
    uimanager.onControlsHide.subscribe(function () {
      shouldBeShown = false;
      _this.hide();
    });
    // init
    checkMetadataTextAndUpdateVisibility();
  };
  return TitleBar;
}(container_1.Container);
exports.TitleBar = TitleBar;