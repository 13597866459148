<div title="{{starAlert.words}}"
      class="staralert" (click)="itemClick(starAlert)"
     *ngIf="starAlert"
     [ngClass]="{ 'staralert__card': card }">

  <div class="ellipsis">
    {{starAlert.words}}
  </div>

  <button *ngIf="showDelete"
          class="btn btn__secondary btn__icon btn--circle staralert__delete"
          [ngClass]="{'btn--busy': busy$ | async}"
          (click)="deleteStarAlert(starAlert, $event)">
    <tbui-icon iconId="bin"></tbui-icon>
  </button>
</div>
