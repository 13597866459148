import { ChangeDetectionStrategy, Component, OnInit, signal, WritableSignal } from '@angular/core';
import {
  Broadcast,
  RecordingApiService,
  RecordingParams,
  RecordingType,
  Teaser,
  TeaserApiService,
  TeaserDevice,
  TeaserParams,
  WatchlistApiService,
  WatchlistParams
} from '@teleboy/web.epg';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PvrGenregroupPreviewListComponent } from './listings/pvr-genregroup-preview-list/pvr-genregroup-preview-list.component';
import { PvrRecordingPreviewListComponent } from './listings/pvr-recording-preview-list/pvr-recording-preview-list.component';
import { PvrWatchlistPreviewListComponent } from './listings/pvr-watchlist-preview-list/pvr-watchlist-preview-list.component';
import { PvrGridButtonComponent } from './mics/pvr-grid-button/pvr-grid-button.component';
import { PvrTitlegroupPreviewListComponent } from './listings/pvr-titlegroup-preview-list/pvr-titlegroup-preview-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { PvrRecordingStatisticsComponent } from './mics/pvr-recording-statistics/pvr-recording-statistics.component';
import { AuthenticationService, LanguageService } from '@teleboy/web.user';
import { AsyncPipe } from '@angular/common';
import { ApiListData, MediaImageFileExtension, MediaImageStack } from '@teleboy/web.core';
import { EMPTY, finalize, map, Observable, switchMap } from 'rxjs';
import { Recording } from '@teleboy/web.epg/lib/models/recording.model';
import { MediaImageComponent } from '@teleboy/web.ui';
import { SearchComponent } from '../../epg/components/search/search.component';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-pvr',
  imports: [
    TranslateModule,
    PvrGenregroupPreviewListComponent,
    PvrRecordingPreviewListComponent,
    PvrWatchlistPreviewListComponent,
    PvrGridButtonComponent,
    PvrTitlegroupPreviewListComponent,
    ReactiveFormsModule,
    SharedModule,
    PvrRecordingStatisticsComponent,
    AsyncPipe,
    MediaImageComponent,
    SearchComponent
  ],
  templateUrl: './pvr.component.html',
  styleUrl: './pvr.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PvrComponent implements OnInit {
  teaser$!: Observable<Teaser>;

  readonly hasRecordings: WritableSignal<boolean | null> = signal(null);
  readonly isAuthenticated = signal(this.authenticationService.isAuthenticated);
  readonly isLoading = signal(true);

  protected readonly MediaImageFileExtension = MediaImageFileExtension;
  protected readonly MediaImageStack = MediaImageStack;

  private readonly TEASER_TAG = 'web_records_view';

  constructor(
    private authenticationService: AuthenticationService,
    private languageService: LanguageService,
    private recordingApiService: RecordingApiService,
    private router: Router,
    private teaserApiService: TeaserApiService,
    private translateService: TranslateService,
    private watchlistApiService: WatchlistApiService
  ) {}

  ngOnInit(): void {
    this.getRecordingsOrWatchlistItems().subscribe();
    this.teaser$ = this.getTeaser$();
  }

  openPlannedRecordings(): void {
    void this.router.navigate([{ outlets: { ['details']: 'pvr/recordings' } }], {
      queryParams: {
        asc: true,
        recordingType: RecordingType.PLANNED,
        pageTitle: this.translateService.instant('pvr.list.titlePlanned')
      }
    });
  }

  openOldestRecordings(): void {
    void this.router.navigate([{ outlets: { ['details']: 'pvr/recordings' } }], {
      queryParams: { asc: true }
    });
  }

  openDuplicateRecordings(): void {
    void this.router.navigate([{ outlets: { ['landingpage']: 'pvr/titlegroups' } }], {
      queryParams: { moviesOnly: true, pageTitle: this.translateService.instant('pvr.list.titleDuplicated') }
    });
  }

  openSerialListRecordings(): void {
    void this.router.navigate([{ outlets: { ['landingpage']: 'pvr/seriallisting' } }]);
  }

  private getTeaser$(): Observable<Teaser> {
    const params = new TeaserParams(TeaserDevice.WEB, this.languageService.getLanguage())
      .setTags([this.TEASER_TAG])
      .setAboLevel(this.authenticationService.aboLevel);

    return this.teaserApiService.getTeasers(params).pipe(map((data: ApiListData<Teaser>) => data.items[0]));
  }

  private getRecordingsOrWatchlistItems(): Observable<ApiListData<Broadcast>> {
    const recordingParams = new RecordingParams().setLimit(1);
    const watchlistParams = new WatchlistParams().setLimit(1);

    return this.recordingApiService.query(recordingParams).pipe(
      tap((data) => this.hasRecordings.set(data.total > 0)),
      switchMap((data: ApiListData<Recording>) => {
        return data.total
          ? EMPTY
          : this.watchlistApiService.broadcasts(watchlistParams).pipe(
              tap((data) => {
                if (data.total) {
                  this.hasRecordings.set(true);
                }
              })
            );
      }),
      finalize(() => this.isLoading.set(false))
    );
  }
}
