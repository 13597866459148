import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Station, StationLogoSize, StationLogoComponent } from '@teleboy/web.epg';
import { NgIf, NgStyle } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-station-item',
  templateUrl: './station-item.component.html',
  styleUrls: ['./station-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, RouterLink, StationLogoComponent, NgStyle, TranslateModule]
})
export class StationItemComponent {
  StationLogoSize = StationLogoSize;

  @Input() station!: Station;
}
