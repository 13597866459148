import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { LandingpageLane, StarAlertType, TeaserDevice, TeaserUrlCreator, TeaserUrlParser } from '@teleboy/web.epg';
import { Observable } from 'rxjs';
import { AuthenticationService, LanguageService } from '@teleboy/web.user';
import { NgIf, AsyncPipe } from '@angular/common';
import { LandingpageComponent } from '../landingpage.component';
import { StarAlertButtonComponent } from '../../star-alert/star-alert-button/star-alert-button.component';

@Component({
  templateUrl: './tag-landingpage.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, LandingpageComponent, StarAlertButtonComponent, AsyncPipe]
})
export class TagLandingpageComponent implements OnInit {
  isAuthenticated!: boolean;
  protected content$!: Observable<{ title: string; lanes: LandingpageLane[] }>;
  protected readonly StarAlertType = StarAlertType;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.content$ = this.getContent$();
    this.isAuthenticated = this.authenticationService.isAuthenticated;
  }

  private getContent$(): Observable<{ title: string; lanes: LandingpageLane[] }> {
    return this.activatedRoute.paramMap.pipe(
      map((params) => params.get('tag') ?? ''),
      map((tag) => {
        const teaserUrl = new TeaserUrlCreator()
          .setTags([tag])
          .setSources(['tip', 'replay', 'content', 'community', 'programmtip', 'programm'])
          .allStations()
          .asTeaserUrl();

        return {
          title: tag,
          lanes: new TeaserUrlParser(teaserUrl).toParams(TeaserDevice.WEB, this.languageService.getLanguage())
        };
      })
    );
  }
}
