<ng-container *ngIf="broadcast$ | async as broadcast">
  <div class="cpvr-borrow__broadcast">
    <app-broadcast-item [broadcast]="broadcast" [forceImage]="true" [disableActions]="true"></app-broadcast-item>
  </div>

  <div class="popup__container">
    <app-close-button (closed)="close()"></app-close-button>
    <ng-container *ngIf="slotConfirmed$ | async; then slotConfirmed else slotPending"></ng-container>
  </div>

  <ng-template #slotPending>
    <app-info
      [title]="'cpvr.borrow.request.title' | translate: { nickname: broadcast.community!.provider.nickname }"></app-info>

    <div class="text-center">
      <app-cpvr-profile [communityProvider]="broadcast.community!.provider"></app-cpvr-profile>
    </div>

    <p class="cpvr-borrow__disclaimer"><small>{{ 'cpvr.borrow.disclaimer' | translate }}</small></p>

    <div class="popup__footer btn__list-h btn__list-h--apart">
      <button (click)="close()" class="btn btn__secondary">{{ 'cpvr.borrow.request.cta.close' | translate }}</button>
      <button (click)="confirmSlot()" class="btn btn__primary" appAutofocus>
        {{ 'cpvr.borrow.request.cta.confirm' | translate }}
      </button>
    </div>
  </ng-template>

  <ng-template #slotConfirmed>
    <app-info
      [title]="'cpvr.borrow.confirmed.title' | translate: {nickname: broadcast.community!.provider.nickname}"></app-info>

    <div class="text-center">
      <app-cpvr-profile [communityProvider]="broadcast.community!.provider"></app-cpvr-profile>
    </div>

    <p class="cpvr-borrow__disclaimer"><small>{{ 'cpvr.borrow.disclaimer' | translate }}</small></p>

    <div class="popup__footer btn__list-h btn__list-h--end">
      <button (click)="play()" class="btn btn__primary"
              appAutofocus>{{ 'cpvr.borrow.confirmed.cta.play' | translate }}</button>
    </div>
  </ng-template>
</ng-container>
