import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouteDataService } from '../../../core/services/route-data.service';
import { map, Observable, of, startWith, switchMap, tap } from 'rxjs';
import {
  Broadcast,
  BroadcastApiService,
  EpgParams,
  EpisodeParams,
  EpisodeSource,
  Genre,
  SearchParams,
  SearchPrefix,
  SearchSource,
  Station,
  StationLogoSize,
  StationLogoComponent,
  EpisodeSubtitlePipe
} from '@teleboy/web.epg';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MediaImage, MediaImageStack } from '@teleboy/web.core';
import { MetaService } from '../../../core/services/meta.service';
import { SlideConfig } from '../../providers/swiper.provider';
import { PopupService } from '../../../core/services/popup.service';
import { SharedModule } from '../../../shared/shared.module';
import { NgTemplateOutlet, NgClass, AsyncPipe } from '@angular/common';
import { CpvrProviderComponent } from '../cpvr/cpvr-provider/cpvr-provider.component';
import { DataLayerDirective } from '../../../shared/directives/data-layer.directive';
import { BroadcastPlayDirective } from '../../directives/broadcast/broadcast-play.directive';
import { BroadcastToggleRecordDirective } from '../../directives/broadcast/broadcast-toggle-record.directive';
import { BroadcastDeleteRecordDirective } from '../../directives/broadcast/broadcast-delete-record.directive';
import { BroadcastWatchlistDirective } from '../../directives/broadcast/broadcast-watchlist.directive';
import { MediaImageComponent, DurationPipe, ImdbRatingComponent, IconComponent } from '@teleboy/web.ui';
import { BroadcastFlagComponent } from '../broadcast-flag/broadcast-flag.component';
import { BroadcastTrailerDirective } from '../../directives/broadcast/broadcast-trailer.directive';
import { BroadcastProgressComponent } from '../broadcast-progress/broadcast-progress.component';
import { MatTooltip } from '@angular/material/tooltip';
import { SearchSwiperComponent } from '../swiper/search-swiper/search-swiper.component';
import { BroadcastSwiperComponent } from '../swiper/broadcast-swiper/broadcast-swiper.component';
import { EpisodesSwiperComponent } from '../swiper/episodes-swiper/episodes-swiper.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-broadcast',
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedModule,
    NgTemplateOutlet,
    CpvrProviderComponent,
    DataLayerDirective,
    BroadcastPlayDirective,
    BroadcastToggleRecordDirective,
    NgClass,
    BroadcastDeleteRecordDirective,
    BroadcastWatchlistDirective,
    MediaImageComponent,
    StationLogoComponent,
    BroadcastFlagComponent,
    BroadcastTrailerDirective,
    BroadcastProgressComponent,
    MatTooltip,
    RouterLink,
    SearchSwiperComponent,
    BroadcastSwiperComponent,
    EpisodesSwiperComponent,
    AsyncPipe,
    TranslateModule,
    DurationPipe,
    EpisodeSubtitlePipe,
    ImdbRatingComponent,
    IconComponent
  ]
})
export class BroadcastComponent implements OnInit {
  readonly MediaImageStack = MediaImageStack;
  readonly StationLogoSize = StationLogoSize;
  broadcast$!: Observable<Broadcast>;

  replayChartsParams?: EpgParams;
  searchParams?: SearchParams;
  episodeParams?: EpisodeParams;
  SlideConfig = SlideConfig['popup'];

  private readonly EPISODE_LIMIT = 20;

  constructor(
    private activatedRoute: ActivatedRoute,
    private broadcastApiService: BroadcastApiService,
    private metaService: MetaService,
    private popupService: PopupService,
    private routeDataService: RouteDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    let routeBroadcast = this.routeDataService.getDataForRoute<Broadcast>(this.router.url);
    this.broadcast$ = this.activatedRoute.params.pipe(
      switchMap((params) => this.broadcastApiService.get(params['broadcastId'])),
      map((broadcast) => {
        if (routeBroadcast) {
          // Community reference is needed for the view.
          const community = routeBroadcast.community;
          routeBroadcast = Object.assign(broadcast);

          if (community) {
            routeBroadcast.community = community;
          }

          return routeBroadcast;
        }

        return broadcast;
      }),
      switchMap((broadcast) => this.setRecommendationParams$(broadcast)),
      switchMap((broadcast) => this.setRecommendationEpisodeParams$(broadcast)),
      switchMap((broadcast) => this.setMetaTitle$(broadcast)),
      startWith(routeBroadcast)
    );
  }

  getImage(broadcast: Broadcast): MediaImage | undefined {
    return broadcast.previewImage ?? broadcast.primaryImage;
  }

  /**
   * Workaround to make use of ngTemplates
   */
  getStation$(broadcast: Broadcast): Observable<Station> {
    return broadcast.station$;
  }

  /**
   * Workaround to make use of ngTemplates
   */
  getGenre$(broadcast: Broadcast): Observable<Genre> {
    return broadcast.genre$;
  }

  private setMetaTitle$(broadcast: Broadcast): Observable<Broadcast> {
    return broadcast.station$.pipe(
      tap((station) => {
        this.metaService.setTitle([
          broadcast.title,
          station?.name ?? broadcast.stationSlug,
          broadcast.begin.format('DD. MMMM YYYY, HH:mm')
        ]);
      }),
      map(() => broadcast)
    );
  }

  openCastLP(name: string) {
    void this.popupService.close('broadcast').then(() => {
      void this.popupService.close('details').then(() => {
        this.popupService.open(`cast/${name}`, { outlet: 'person', skipLocationChange: false });
      });
    });
  }

  openGenreLP(genreId: number): unknown[] {
    return ['', { outlets: { broadcast: null, details: null, landingpage: ['genre', genreId] } }];
  }

  openTagLP(tag: string): unknown[] {
    return ['', { outlets: { broadcast: null, details: null, person: null, landingpage: ['tag', tag] } }];
  }

  private setRecommendationParams$(broadcast: Broadcast): Observable<Broadcast> {
    const genreId = broadcast.genreId;
    if (!genreId) {
      return of(broadcast);
    }

    if ([4, 39, 23, 24, 25, 26, 28, 14, 36].includes(genreId)) {
      this.searchParams = new SearchParams(
        `${broadcast.title}`,
        [SearchSource.REPLAY],
        SearchPrefix.TITLE
      ).allStations();
    } else {
      this.replayChartsParams = new EpgParams().setReplayRange().setGenre(genreId);
    }

    return of(broadcast);
  }

  private setRecommendationEpisodeParams$(broadcast: Broadcast): Observable<Broadcast> {
    const genreId = broadcast.genreId;
    if (!genreId) {
      return of(broadcast);
    }

    if (broadcast.contents) {
      this.episodeParams = new EpisodeParams()
        .setSource([EpisodeSource.REPLAY, EpisodeSource.EPG, EpisodeSource.CPVR, EpisodeSource.PVR])
        .setLimit(this.EPISODE_LIMIT)
        .streamableOnly();
    }

    return of(broadcast);
  }
}
