import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { AuthenticationService } from '@teleboy/web.user';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { PlayerSizeService } from '../../../player/services/player-size.service';
import { PopupService } from '../../../core/services/popup.service';
import { distinctUntilChanged, fromEvent, map, merge, scan, startWith, Subject, takeUntil, tap } from 'rxjs';
import { MediaImageStack } from '@teleboy/web.core';
import { AsyncPipe, NgIf, NgOptimizedImage } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AlertCounterComponent, IconComponent, StaticAssetPipe } from '@teleboy/web.ui';
import { WebUiHrefPipe } from '../../pipes/web-ui-href.pipe';
import { PlayerUiService } from '../../../player/services/player-ui.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    RouterLink,
    NgIf,
    RouterLinkActive,
    NgOptimizedImage,
    MatTooltip,
    TranslateModule,
    AsyncPipe,
    WebUiHrefPipe,
    AlertCounterComponent,
    IconComponent,
    StaticAssetPipe
  ]
})
export class MainNavComponent implements AfterViewInit, OnDestroy {
  @ViewChild('mainNav') mainNav!: ElementRef;

  readonly isAuthenticated = this.authenticationService.isAuthenticated;
  readonly user$ = this.authenticationService.user$;

  protected readonly MediaImageStack = MediaImageStack;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private authenticationService: AuthenticationService,
    private ngZone: NgZone,
    private playerSizeService: PlayerSizeService,
    private playerUiService: PlayerUiService,
    private popupService: PopupService,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      const mouseenter$ = fromEvent(this.mainNav.nativeElement, 'mouseenter').pipe(startWith(false));
      const mouseleave$ = fromEvent(this.mainNav.nativeElement, 'mouseleave').pipe(startWith(false));
      const hoveredWhilePlaying$ = merge(mouseenter$.pipe(map(() => true)), mouseleave$.pipe(map(() => false))).pipe(
        scan((acc, current) => current ?? !acc, false),
        tap((show: boolean) => this.playerUiService.setCustomControlFocusedState(show)),
        startWith(false),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      );

      hoveredWhilePlaying$.subscribe();
    });
  }

  navigate(path: string): void {
    void this.router.navigate([{ outlets: { primary: path, landingpage: null } }]).then(() => {
      this.popupService.closeAllOpenOutlets();
    });

    this.playerSizeService.miniPlayer();

    window.scroll(0, 0);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
