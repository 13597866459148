import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ReplayPlayerService } from '@teleboy/web.player';
import { BroadcastActionService } from '../../../epg/services/broadcast-action.service';
import { PlayerService } from '../../services/player.service';
import { IdlingConfirmType, IdlingService } from '@teleboy/web.user';
import { StreamIdlingService } from '../../services/stream-idling.service';

@Component({
  selector: 'app-player-replay',
  templateUrl: './player-replay.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IdlingService],
  standalone: false
})
export class PlayerReplayComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private broadcastActionService: BroadcastActionService,
    private idlingService: IdlingService,
    private playerService: PlayerService,
    private replayPlayerService: ReplayPlayerService,
    private streamIdlingService: StreamIdlingService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        withLatestFrom(this.activatedRoute.queryParams),
        switchMap(([params, queryParams]) =>
          this.replayPlayerService.play$(
            params['broadcastId'],
            parseInt(queryParams['position'], 10) || undefined,
            !!queryParams['isContinuousReplay']
          )
        ),
        switchMap((stream) => this.playerService.setPageTitle$(stream)),
        switchMap(() => this.complete$()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private complete$() {
    return this.replayPlayerService.complete$(true).pipe(
      switchMap((next) => {
        if (this.idlingService.isIdle()) {
          return this.streamIdlingService.openIdlingDialog$().pipe(
            tap((idlingConfirmType: IdlingConfirmType) => {
              if (idlingConfirmType === IdlingConfirmType.PAUSE) {
                this.idlingService.pauseIdleCheck();
              }
            }),
            switchMap(() => this.broadcastActionService.handlePlay$(next.broadcast, next.position, false, true))
          );
        }

        return this.broadcastActionService.handlePlay$(next.broadcast, next.position, false, true);
      })
    );
  }
}
