import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AnonymousVerificationApiService, Verification } from '@teleboy/web.user';
import { RouteDataService } from '../../../core/services/route-data.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, catchError, finalize, Observable, tap } from 'rxjs';
import { PlayerService } from '../../../player/services/player.service';
import { ErrorCode, TeleboyError } from '@teleboy/web.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-anonymous-verification',
  templateUrl: './anonymous-verification.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AnonymousVerificationComponent {
  readonly busy$: Observable<boolean>;
  readonly captchaSrc$: Observable<string>;
  readonly form = new UntypedFormGroup(
    { captchaSolveCode: new UntypedFormControl('', Validators.required) },
    { updateOn: 'submit' }
  );

  private readonly _captchaSrc$: BehaviorSubject<string>;
  private readonly _busy$: BehaviorSubject<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public verification: Verification,
    private dialogRef: MatDialogRef<AnonymousVerificationComponent>,
    private anonymousVerificationApiService: AnonymousVerificationApiService,
    private playerService: PlayerService,
    private routeDataService: RouteDataService,
    private router: Router
  ) {
    this._busy$ = new BehaviorSubject<boolean>(false);
    this._captchaSrc$ = new BehaviorSubject<string>(this.verification.captcha);

    this.busy$ = this._busy$.asObservable();
    this.captchaSrc$ = this._captchaSrc$.asObservable();
  }

  close(result: boolean): void {
    this.dialogRef.close(result);
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.get('captchaSolveCode')?.setErrors({ required: true });
      return;
    }

    this.form.disable();
    this._busy$.next(true);

    this.anonymousVerificationApiService
      .verify(this.verification.token, this.form.get('captchaSolveCode')?.value)
      .pipe(
        tap(() => this.onVerificationSuccess()),
        catchError((err) => this.handleVerificationError$(err)),
        finalize(() => this._busy$.next(false))
      )
      .subscribe();
  }

  private handleVerificationError$(err: TeleboyError | HttpErrorResponse): Observable<Verification> {
    if (err instanceof TeleboyError && err.code === ErrorCode.VERIFICATION_ANONYMOUS_USER_REQUIRED) {
      this.close(false);
      void this.router.navigateByUrl('/start', { skipLocationChange: true });
    } else {
      this.form.enable();
      this.form.get('captchaSolveCode')?.setValue('');
      this.form.get('captchaSolveCode')?.setErrors({ invalid: true });
    }

    return this.anonymousVerificationApiService.verificationToken().pipe(
      tap((verification) => {
        this.verification = verification;
        this._captchaSrc$.next(this.verification.captcha);
      })
    );
  }

  private onVerificationSuccess(): void {
    this.playerService.reloadStream();
    this.close(true);
  }
}
