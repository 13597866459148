import { ChangeDetectionStrategy, Component, signal, WritableSignal } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { BroadcastItemComponent } from '../../../../epg/components/broadcast-item/broadcast-item.component';
import { SwiperModule } from 'swiper/angular';
import { finalize, Observable } from 'rxjs';
import { ApiListResponse } from '@teleboy/web.core';
import { WatchlistApiService, WatchlistParams } from '@teleboy/web.epg';
import { Router } from '@angular/router';
import { DataSwiperDirective } from '../../../../epg/directives/swiper/data-swiper.directive';
import { Recording } from '@teleboy/web.epg/lib/models/recording.model';
import { SharedModule } from '../../../../shared/shared.module';
import { PvrGridButtonComponent } from '../../mics/pvr-grid-button/pvr-grid-button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-pvr-watchlist-preview-list',
  imports: [AsyncPipe, BroadcastItemComponent, SwiperModule, SharedModule, PvrGridButtonComponent, TranslateModule],
  templateUrl: './pvr-watchlist-preview-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PvrWatchlistPreviewListComponent extends DataSwiperDirective<Recording> {
  readonly busy: WritableSignal<boolean> = signal(true);

  constructor(
    private watchlistApiService: WatchlistApiService,
    private router: Router
  ) {
    super();
  }

  openWatchlistList(): void {
    void this.router.navigate([{ outlets: { ['landingpage']: 'pvr/watchlist' } }]);
  }

  override itemLoader$(): Observable<ApiListResponse['data']> {
    const watchlistParams = new WatchlistParams().setLimit(10);

    return this.watchlistApiService.broadcasts(watchlistParams).pipe(finalize(() => this.busy.set(false)));
  }
}
