import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteDataService {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private data: Record<string, any> = {};

  constructor(private router: Router) {}

  setData<T>(url: string, data: T): void {
    this.data[url] = data;
  }

  getDataForRoute<T>(route?: string): T {
    route = route ?? this.router.url;
    return this.data[route] ?? null;
  }
}
