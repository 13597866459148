import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  CommunityRecordParams,
  EpgParams,
  Selection,
  SelectionApiService,
  SelectionParams,
  SelectionSource
} from '@teleboy/web.epg';
import { map, Observable } from 'rxjs';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { BroadcastSwiperComponent } from '../swiper/broadcast-swiper/broadcast-swiper.component';
import { CommunitySwiperComponent } from '../swiper/community-swiper/community-swiper.component';

@Component({
  selector: 'app-selections',
  templateUrl: './selections.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, NgIf, BroadcastSwiperComponent, CommunitySwiperComponent, AsyncPipe]
})
export class SelectionsComponent implements OnInit {
  selections$!: Observable<Selection<EpgParams | CommunityRecordParams>[]>;
  SelectionSource = SelectionSource;

  @Input() params!: SelectionParams;

  constructor(private selectionApiService: SelectionApiService) {}

  ngOnInit(): void {
    this.selections$ = this.selectionApiService
      .getSelections<EpgParams | CommunityRecordParams>(this.params)
      .pipe(map((data) => data.items));
  }

  getCommunityParams(selection: Selection<EpgParams | CommunityRecordParams>): CommunityRecordParams {
    return selection.params as CommunityRecordParams;
  }

  getEpgParams(selection: Selection<EpgParams | CommunityRecordParams>): EpgParams {
    return selection.params as EpgParams;
  }
}
