import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Broadcast } from '@teleboy/web.epg';
import { NgIf } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-broadcast-icons',
  templateUrl: './broadcast-icons.component.html',
  styleUrls: ['./broadcast-icons.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [NgIf, MatTooltip, TranslateModule, IconComponent]
})
export class BroadcastIconsComponent {
  @Input() broadcast!: Broadcast;
}
