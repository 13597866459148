import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RecordingGroup, RecordingSortDirection } from '@teleboy/web.epg';
import { Router } from '@angular/router';
import { MediaImageComponent } from '@teleboy/web.ui';
import { TranslateModule } from '@ngx-translate/core';
import { MediaImageStack } from '@teleboy/web.core';

@Component({
  selector: 'app-pvr-titlegroup-preview',
  imports: [MediaImageComponent, TranslateModule],
  templateUrl: './pvr-titlegroup-preview.component.html',
  styleUrl: './pvr-titlegroup-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PvrTitlegroupPreviewComponent {
  readonly duplicatesPreview = input<boolean>();
  readonly titleGroup = input.required<RecordingGroup>();

  protected readonly MediaImageStack = MediaImageStack;

  constructor(private router: Router) {}

  openRecordingList(): void {
    void this.router.navigate([{ outlets: { ['details']: 'pvr/recordings' } }], {
      queryParams: { title: this.titleGroup().title, sortDirection: RecordingSortDirection.DESC }
    });
  }
}
