import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  map,
  Observable,
  startWith,
  Subject,
  throwError,
  withLatestFrom
} from 'rxjs';
import {
  StarAlert,
  StarAlertApiService,
  StarAlertCreateParams,
  StarAlertListParams,
  StarAlertType
} from '@teleboy/web.epg';
import { SnackbarService } from '../../core/services/snackbar.service';
import { ApiListResponse } from '@teleboy/web.core';

@Injectable({
  providedIn: 'root'
})
export class StarAlertService {
  readonly isStarAlertBusy$: Observable<boolean>;
  readonly isStarAlertLoading$: Observable<boolean>;
  readonly toggledStarAlertType$!: Observable<StarAlertType | null>;

  private readonly _isStarAlertBusy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _isStarAlertLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _toggledStarAlertType$: Subject<StarAlertType | null> = new Subject<StarAlertType | null>();

  constructor(
    private snackbarService: SnackbarService,
    private starAlertApiService: StarAlertApiService
  ) {
    this.isStarAlertBusy$ = this._isStarAlertBusy$.asObservable();
    this.isStarAlertLoading$ = this._isStarAlertLoading$.asObservable();
    this.toggledStarAlertType$ = this._toggledStarAlertType$.asObservable();
  }

  createStarAlert$(words: string, type: StarAlertType): Observable<StarAlert> {
    const starAlertCreateParams = new StarAlertCreateParams(words, type);

    this._toggledStarAlertType$.next(type);
    this._isStarAlertBusy$.next(true);

    return this.starAlertApiService.create(starAlertCreateParams).pipe(
      catchError((err) => {
        this.snackbarService.error('starAlert.create.error');
        return throwError(() => err);
      }),
      finalize(() => this._isStarAlertBusy$.next(false))
    );
  }

  deleteStarAlert$(starAlert: StarAlert): Observable<unknown> {
    this._toggledStarAlertType$.next(starAlert.type);
    this._isStarAlertBusy$.next(true);

    return this.starAlertApiService.delete(starAlert.id).pipe(
      catchError((err) => {
        this.snackbarService.error('starAlert.delete.error');
        return throwError(() => err);
      }),
      finalize(() => this._isStarAlertBusy$.next(false))
    );
  }

  getStarAlertForWordsAndType(words: string, type: StarAlertType): Observable<StarAlert> {
    this._isStarAlertLoading$.next(true);

    return this.starAlertApiService
      .getByWordsAndType(words, type)
      .pipe(finalize(() => this._isStarAlertLoading$.next(false)));
  }

  getStarAlertsList(params: StarAlertListParams): Observable<ApiListResponse['data']> {
    this._isStarAlertLoading$.next(true);
    return this.starAlertApiService.list(params).pipe(finalize(() => this._isStarAlertLoading$.next(false)));
  }

  starAlertListReloaded$(starAlertType: StarAlertType): Observable<boolean> {
    return this.isStarAlertBusy$.pipe(
      withLatestFrom(this.toggledStarAlertType$),
      filter(([_, type]) => type === starAlertType),
      map(([busy, _]) => busy),
      startWith(false)
    );
  }
}
