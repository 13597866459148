<app-popup outlet="landingpage" [full]="true">

  <ng-container buttons>
    <ng-content select="[staralert]"></ng-content>
  </ng-container>

  <ng-content select="[background]"></ng-content>

  <div class="header"><h1>{{ title | uppercase }}</h1></div>

  <div class="grid grid__sliders lanes">
    <ng-container *ngFor="let lane of lanes; index as index">

      <!-- BROADCAST -->
      <app-broadcast-swiper
        *ngIf="lane.endpoint === LandingpageSourceEndpoint.BROADCAST"
        (noResults)="removeLane(index)"
        [params]="lane.params"
        [headline]="lane.label ? ('landingpage.' + lane.label + '.title' | translate) : ''"></app-broadcast-swiper>

      <!-- RECORDINGS -->
      <app-recording-swiper
        *ngIf="isAuthenticated && lane.endpoint === LandingpageSourceEndpoint.RECORDING"
        (noResults)="removeLane(index)"
        [headline]="lane.label ? ('landingpage.' + lane.label + '.title' | translate) : ''"
        [params]="lane.params"></app-recording-swiper>

      <!-- COMMUNITY -->
      <app-community-swiper *ngIf="lane.endpoint === LandingpageSourceEndpoint.COMMUNITY"
                            (noResults)="removeLane(index)"
                            [params]="lane.params"
                            [headline]="lane.label ? ('landingpage.' + lane.label + '.title' | translate) : ''"></app-community-swiper>

      <!-- SERIES -->
      <app-serie-swiper *ngIf="lane.endpoint === LandingpageSourceEndpoint.SERIE"
                        (noResults)="removeLane(index)"
                        [params]="lane.params"
                        [headline]="lane.label ? ('landingpage.' + lane.label + '.title' | translate) : ''"></app-serie-swiper>

      <!-- TEASER -->
      <app-teaser-swiper *ngIf="lane.endpoint === LandingpageSourceEndpoint.TEASER || lane.endpoint === LandingpageSourceEndpoint.TOP_TEASER"
                         (noResults)="removeLane(index)"
                         [headline]="lane.label ? ('landingpage.' + lane.label + '.title' | translate) : ''" [params]="lane.params"
                         [topTeaser]="lane.endpoint === LandingpageSourceEndpoint.TOP_TEASER"></app-teaser-swiper>

      <!-- SELECTIONS -->
      <app-selections *ngIf="lane.endpoint === LandingpageSourceEndpoint.SELECTION" [params]="lane.params"></app-selections>
    </ng-container>
  </div>

  <div class="lanes--empty">
    <h2>{{ 'landingpage.noResults.title' | translate }}</h2>
    <p>{{ 'landingpage.noResults.text' | translate }}</p>
  </div>
</app-popup>
