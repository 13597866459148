"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClickOverlay = void 0;
var button_1 = require("./button");
/**
 * A click overlay that opens an url in a new tab if clicked.
 *
 * @category Components
 */
var ClickOverlay = /** @class */function (_super) {
  __extends(ClickOverlay, _super);
  function ClickOverlay(config) {
    if (config === void 0) {
      config = {};
    }
    var _this = _super.call(this, config) || this;
    _this.config = _this.mergeConfig(config, {
      cssClass: 'ui-clickoverlay',
      role: _this.config.role
    }, _this.config);
    return _this;
  }
  ClickOverlay.prototype.initialize = function () {
    _super.prototype.initialize.call(this);
    this.setUrl(this.config.url);
    var element = this.getDomElement();
    element.on('click', function () {
      if (element.data('url')) {
        window.open(element.data('url'), '_blank');
      }
    });
  };
  /**
   * Gets the URL that should be followed when the watermark is clicked.
   * @returns {string} the watermark URL
   */
  ClickOverlay.prototype.getUrl = function () {
    return this.getDomElement().data('url');
  };
  ClickOverlay.prototype.setUrl = function (url) {
    if (url === undefined || url == null) {
      url = '';
    }
    this.getDomElement().data('url', url);
  };
  return ClickOverlay;
}(button_1.Button);
exports.ClickOverlay = ClickOverlay;