<div class="user-profiles">

  <div class="user-profiles__title">
    <ng-content select="[title]"></ng-content>
  </div>

  <div class="user-profiles__profiles"
       [ngClass]="{ 'user-profiles__profiles-selected': (selectedProfile$ | async)}">

        @if (selectedProfile$ | async; as selectedProfile) {
          <div class="user-profiles__profiles-selected-user">
            <app-profile-image [clickable]="false" [image]="selectedProfile.profileImage" [size]="imageSize"></app-profile-image>
            <span>{{selectedProfile.name}}</span>
          </div>
        }
        @else {
          @for (userProfile of userProfiles$ | async; track userProfile.userId) {
            <div class="user-profiles__profiles-user" (click)="onUserProfileClick(userProfile)">
              <app-profile-image [showEdit]="isEditMode" [image]="userProfile.profileImage" [size]="imageSize" [clickable]="true" [busy]="isBusy(userProfile.userId)" [active]="userProfile.isCurrent"></app-profile-image>

              @if (isMobileScreen) {
                <span> <strong> {{userProfile.name}} </strong></span>
              } @else {
                <span><strong> {{userProfile.name || '&nbsp;' | truncate:13}} </strong> </span>
              }
            </div>
          }

          @if (userProfiles$ | async; as userProfiles) {
            @if (userProfiles.length < 5 && !(selectedProfile$ | async)) {
              <div class="user-profiles__profiles-add">
                <button class="btn btn__icon btn--circle " aria-labelledby="profile-add" (click)="addProfile()">
                  <tbui-icon [size]="imageAddSize" iconId="add"></tbui-icon>
                </button>

                <span class="mt-small">{{ 'userProfiles.add' | translate }}</span>
              </div>
            }
          }
        }
  </div>

  <div class="user-profiles__info">
    @if (!isEditMode) {
      @if (!(busy$ | async)) {
        <button class="btn" aria-labelledby="profile-edit" (click)="toggleEditMode()">
          {{ 'userProfiles.edit.label' | translate }}
        </button>
      }
    } @else {
      <button class="btn" aria-labelledby="profile-back" (click)="toggleEditMode()">
        {{ 'userProfiles.done.label' | translate }}
      </button>
    }
  </div>

</div>
