import { Injectable } from '@angular/core';
import { Broadcast, RecordingResult, RecordingToggleService } from '@teleboy/web.epg';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ErrorCode, TeleboyError } from '@teleboy/web.core';
import { SnackbarService, SnackbarType } from '../../core/services/snackbar.service';
import { RecordingSerialComponent } from '../../recording/components/mics/recording-serial/recording-serial.component';
import { PopupService } from '../../core/services/popup.service';
import { UpsellService } from '../../core/services/upsell.service';

@Injectable({
  providedIn: 'root'
})
export class BroadcastRecordService {
  constructor(
    private popupService: PopupService,
    private recordingToggleService: RecordingToggleService,
    private snackbarService: SnackbarService,
    private upsellService: UpsellService
  ) {}

  toggleRecording$(broadcast: Broadcast): Observable<RecordingResult> {
    return this.recordingToggleService.toggleRecording$(broadcast).pipe(
      tap((recordingResult: RecordingResult) => this.handleSuccess(broadcast, recordingResult)),
      catchError((err: TeleboyError) => this.handleError$(err))
    );
  }

  private handleSuccess(broadcast: Broadcast, recordingResult: RecordingResult): void {
    if (recordingResult === RecordingResult.RECORDING_CREATED && broadcast.isSeriable()) {
      this.popupService.openAsModal<RecordingSerialComponent>(RecordingSerialComponent, broadcast);
    } else {
      this.notifySuccess(recordingResult);
    }
  }

  private handleError$(err: TeleboyError): Observable<never> {
    switch (err.code) {
      case ErrorCode.USER_AUTHENTICATION_REQUIRED:
        this.upsellService.openLoginUpsell();
        return EMPTY;
      case ErrorCode.REPLAY_NOT_ACTIVATED:
        this.upsellService.openReplayActivationUpsell();
        return EMPTY;
      case ErrorCode.REPLAY_TOO_RECENT:
        this.upsellService.openReplayTooRecentUpsell();
        return EMPTY;
      case ErrorCode.RECORDING_NOT_ENOUGH_TIME:
      case ErrorCode.RECORDING_NOT_ENOUGH_TIME_PLUS:
      case ErrorCode.RECORDING_NOT_ENOUGH_TIME_COMFORT:
      case ErrorCode.RECORDING_NOT_ENOUGH_TIME_MAX:
      case ErrorCode.RECORDING_NOT_ENOUGH_TIME_ONE:
        this.upsellService.openRecordingTimeUpsell();
        return EMPTY;
    }

    return throwError(() => err);
  }

  private notifySuccess(recordingResult: RecordingResult): void {
    this.snackbarService.openSnackbar(
      recordingResult === RecordingResult.RECORDING_CREATED
        ? 'broadcast.action.record.success'
        : 'broadcast.action.recordDelete.success',
      SnackbarType.SUCCESS
    );
  }
}
