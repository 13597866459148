@if (editMode === EditMode.Name) {
  <form [formGroup]="form" class="edit-profile-name">
    <div class="edit-profile-name__title">
      <h1>
        {{ (isEditProfile ? 'userProfiles.edit.header.label' : 'userProfiles.create.header.label') | translate }}
      </h1>
    </div>

    <div class="edit-profile-name__images">
      <app-profile-image
        [image]="selectedImage"
        [clickable]="true"
        [showEdit]="true"
        (clicked)="setEditImageMode()">
      </app-profile-image>
    </div>

    <div class="edit-profile-name__input">
      <input id="profile-name" type="text" formControlName="profileName" maxlength="20" appAutofocus [placeholder]="'userProfiles.edit.name.placeholder' | translate">
      @if (userProfile?.isDeletable && user.isProfileParentUser) {
        <button class="btn btn__secondary mt-small" (click)="deleteUserProfile()">{{ 'userProfiles.delete.label' | translate }}</button>
      }
    </div>

    <div class="edit-profile-name__info">
      <button class="btn btn__primary" [ngClass]="{'btn--busy': (isLoading$ | async)}" (click)="saveProfile()">
          {{ (isEditProfile ? 'userProfiles.edit.button.label' : 'userProfiles.create.button.label') | translate }}
      </button>
      <button class="btn btn__secondary edit-profile-name__info__cancel" (click)="onBack()">{{ 'userProfiles.edit.cancel.label' | translate }}</button>
    </div>
  </form>
}

@if (editMode === EditMode.Image) {
  <div class="edit-profile-image">

    <div class="edit-profile-image__title">
      <h1>{{ 'userProfiles.edit.photo.choose' | translate }}</h1>
    </div>

    <div class="edit-profile-image__images">

      <div class="edit-profile-image__images-container">
        @for (teaser of images$ | async; track teaser.id) {
            @if (teaser.image) {
              <app-profile-image  [clickable]="true"
                                  [image]="teaser.image"
                                  (clicked)="setSelectedImage($event)">
              </app-profile-image>
            }
        }

      </div>
    </div>

    <div class="edit-profile-image__create">
      <button class="btn btn__secondary" [ngClass]="{'btn--busy': (isLoading$ | async)}" (click)="setEditNameMode()">{{ 'userProfiles.edit.photo.confirm' | translate }}</button>
    </div>

  </div>
}
