import { ChangeDetectorRef, Directive, HostListener, Input, OnInit } from '@angular/core';
import { Broadcast } from '@teleboy/web.epg';
import { BroadcastActionService } from '../../services/broadcast-action.service';
import { BroadcastActionDirective } from './broadcast-action.directive';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: 'button[appBroadcastDownload]'
})
export class BroadcastDownloadDirective extends BroadcastActionDirective implements OnInit {
  @Input('appBroadcastDownload') broadcast!: Broadcast;

  constructor(
    protected override changeDetectionRef: ChangeDetectorRef,
    private broadcastActionService: BroadcastActionService,
    private translateService: TranslateService
  ) {
    super(changeDetectionRef);
  }

  ngOnInit(): void {
    this.translateService.get('broadcast.action.download.label').subscribe((text) => (this._title = text));
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    this.performAction(() => this.broadcastActionService.handleDownload$(this.broadcast));
  }
}
