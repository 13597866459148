import { Injectable } from '@angular/core';
import { catchError, iif, Observable, of, switchMap, tap } from 'rxjs';
import { Genre, GenreStorageService } from '@teleboy/web.epg';
import {
  AuthenticationService,
  DarkmodeService,
  DatadogRumService,
  LoginService,
  SettingsService,
  User
} from '@teleboy/web.user';
import { BrandingdayService, BrandingdayStorage } from '@teleboy/web.player';
import { DatadogLoggingService, ErrorCode, EventTrackingService, TeleboyError } from '@teleboy/web.core';
import { ClientDataService } from './client-data.service';
import { HrefService } from '../../shared/services/href.service';

@Injectable({
  providedIn: 'root'
})
export class InitService {
  constructor(
    private authenticationService: AuthenticationService,
    private brandingdayService: BrandingdayService,
    private clientDataService: ClientDataService,
    private datadogLoggingService: DatadogLoggingService,
    private datadogRumService: DatadogRumService,
    private darkmodeService: DarkmodeService,
    private eventTrackingService: EventTrackingService,
    private genreStorageService: GenreStorageService,
    private hrefService: HrefService,
    private loginService: LoginService,
    private settingsService: SettingsService
  ) {}

  initGenres(): Observable<Genre[]> {
    return this.genreStorageService.getAll();
  }

  initAuthentication(): Observable<unknown> {
    return this.loginService.tryLoginstatus().pipe(
      tap((user: User) => {
        this.datadogRumService.setUser(user);
        this.datadogLoggingService.setUser(user.id);
      }),
      catchError(() => this.loginService.tryLoginByCookie()),
      catchError((err) => this.handleAuthenticationError$(err)),
      switchMap(() => this.clientDataService.refreshUserData({ allStations: true })),
      switchMap(() => this.clientDataService.refreshUserData({ userStations: true })),
      switchMap(() => iif(() => !this.authenticationService.user?.isPlusUser, this.getBrandingday(), of(true))),
      switchMap(() => this.clientDataService.refreshUserData({ locale: true })),
      tap(() => {
        this.initDarkMode();
        this.initTracking();
      })
    );
  }

  initSettings(): Observable<Record<string, unknown>> {
    return this.settingsService.loadAndStoreSettings();
  }

  private handleAuthenticationError$(err: TeleboyError): Observable<null> {
    if (err.code === ErrorCode.USER_BLOCKED) {
      this.hrefService.goToUrl('/blocked');
    } else {
      this.loginService.loginAnonymously();
    }

    return of(null);
  }

  private getBrandingday(): Observable<BrandingdayStorage | null> {
    return this.brandingdayService.getBrandingday$().pipe(
      tap((brandingdayStorage) => {
        if (brandingdayStorage.data) {
          this.eventTrackingService.setDatalayerVariable('brandingday', brandingdayStorage.data.serialize());
        }
      }),
      catchError(() => of(null))
    );
  }

  private initDarkMode(): void {
    this.darkmodeService.detectAndSetDarkmode();
  }

  private initTracking(): void {
    this.eventTrackingService.trackPageview$().subscribe();
    this.eventTrackingService.setDatalayerVariable('aboLevel', this.authenticationService.aboLevel);
  }
}
