import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaImageStack } from '@teleboy/web.core';
import { Teaser, TeaserRedirectService, TeaserRedirectType } from '@teleboy/web.epg';
import { Router } from '@angular/router';
import { PopupService } from '../../../core/services/popup.service';
import { AuthenticationService } from '@teleboy/web.user';
import { HrefService } from '../../../shared/services/href.service';
import { RouteDataService } from '../../../core/services/route-data.service';
import { NgIf, NgClass } from '@angular/common';
import { MediaImageComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-teaser-item',
  templateUrl: './teaser-item.component.html',
  styleUrls: ['./teaser-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, MediaImageComponent]
})
export class TeaserItemComponent {
  @Input() teaser!: Teaser;
  @Input() topTeaser = false;

  private readonly LANDINGPAGE_URL_SEGMENT = 'themen';

  constructor(
    private authenticationService: AuthenticationService,
    private hrefService: HrefService,
    private popupService: PopupService,
    private router: Router,
    private routeDataService: RouteDataService,
    private teaserRedirectService: TeaserRedirectService
  ) {}

  classList(): Record<string, boolean> {
    return {
      'teaser-item--top': this.topTeaser
    };
  }

  classListImage(): Record<string, boolean> {
    return {
      ratio__16x9: !this.topTeaser,
      ratio__4x1: this.topTeaser
    };
  }

  imageSize(): MediaImageStack {
    return this.topTeaser ? MediaImageStack.RAW : MediaImageStack.MEDIUM;
  }

  openLandingpage(): void {
    const teaserContext = this.teaserRedirectService.getContext(this.teaser);

    switch (teaserContext?.type) {
      case TeaserRedirectType.TOPIC_LANDINGPAGE:
        this.openTopicLandingpage();
        break;
      case TeaserRedirectType.BROADCAST:
        this.openBroadcast(teaserContext.context as number);
        break;
    }
  }

  private openTopicLandingpage(): void {
    const urlTree = this.router.createUrlTree(
      [
        {
          outlets: {
            ['landingpage']: this.teaser
              .getLinkUrlSegment(this.LANDINGPAGE_URL_SEGMENT)
              .replace(/\/[^/]+$/, `/${this.teaser.id}`)
          }
        }
      ],
      { fragment: 'scrollToTop' }
    );

    this.routeDataService.setData<Teaser>(urlTree.toString(), this.teaser);

    void this.router.navigateByUrl(urlTree);
  }

  private openBroadcast(broadcastId: number): void {
    this.popupService.open(`broadcast/${broadcastId}`, {
      outlet: 'broadcast'
    });
  }
}
