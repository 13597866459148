<app-popup outlet="landingpage" [full]="true">

    <h1>{{ 'pvr.serialgroups.title' | translate }}</h1>

    @if ((serialgroups$ | async)) {
      <div class="grid">
        @for (serialGroup of serialgroups$ | async; track $index) {
          <app-serialgroup-item [serialgroup]="serialGroup" (deleted)="onDeleted()"></app-serialgroup-item>
        } @empty {
          <app-pvr-empty-list></app-pvr-empty-list>
        }
      </div>
    }

    @if (busy()) {
      <div class="grid ghost-listing">
        @for (ghost of ghostPreviews; track $index) {
          <app-serialgroup-item></app-serialgroup-item>
        }
      </div>
    }
</app-popup>
