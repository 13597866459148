<div class="player-listing">
  <button class="btn btn__icon btn--lg btn--circle btn--opaque player-listing__btn"
          [ngClass]="{
            'btn--active': playerListIsExpanded,
            'btn-listing-connected-to-player-ui--open': connectedToPlayerUi && playerListIsExpanded,
            'btn-listing-connected-to-player-ui--closed': connectedToPlayerUi && !playerListIsExpanded
            }"
    (click)="toggleList(ToggleListSource.Button)">

    @if (connectedToPlayerUi || screenService.isLandscape()) {
      @if (!playerListIsExpanded) {
        <tbui-icon iconId="view-list"></tbui-icon>
      } @else {
        <tbui-icon iconId="arrow-right"></tbui-icon>
      }
    } @else {
      @if (!playerListIsExpanded) {
        @if (!playerListIsExpanded) {
          <tbui-icon iconId="view-list"></tbui-icon>
        }
      } @else {
        @if (playerListIsExpanded) {
          <tbui-icon iconId="close"></tbui-icon>
        }
      }
    }
  </button>

  @if ( (playerUiShown$ | async) && (reloadButton$ | async) && playerListIsExpanded) {
    <button class="btn btn__primary player-listing__reload-btn" (click)="reload()">{{ 'live.reload.btn' | translate }}</button>
  }

  <div class="player-listing__backdrop" (click)="toggleList()"></div>

  <div class="player-listing__wrapper" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()"
       [ngClass]="{
       'connected-to-player-ui': connectedToPlayerUi,
       'connected-to-player-ui--open': connectedToPlayerUi && playerListIsExpanded,
       'connected-to-player-ui--closed': connectedToPlayerUi && !playerListIsExpanded,
       'listing-brandingday': (isBrandingDay && connectedToPlayerUi),
       }">
    <div class="player-listing__scroll-view" #wrapper>
      @if (liveEpg$ | async; as epg) {
        <div class="grid">
          @for (broadcast of epg; track broadcast.id) {
            <app-broadcast-item
              [broadcast]="broadcast" [live]="true" [player]="true" [card]="false" origin="playList"
              [appScrollableItem]="broadcast.id"
            ></app-broadcast-item>
          }
        </div>
      }
    </div>
  </div>

</div>
