import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BitmovinService, JumpSeekingService, StreamInfoService, StreamType } from '@teleboy/web.player';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-jumpseek-buttons',
  templateUrl: './jumpseek-buttons.component.html',
  styleUrls: ['./jumpseek-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class JumpseekButtonsComponent implements OnInit, OnDestroy {
  secondsToJump = 0;
  isLive = false;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private bitmovinService: BitmovinService,
    private changeDetectorRef: ChangeDetectorRef,
    private jumpSeekingService: JumpSeekingService,
    private streamInfoService: StreamInfoService
  ) {}

  ngOnInit(): void {
    this.jumpSeekingService
      .jumpSeek$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (seconds) => {
          this.bitmovinService.seekBy(seconds, 'custom-seek');
          this.secondsToJump = 0;
          this.changeDetectorRef.detectChanges();
        }
      });

    this.jumpSeekingService
      .jumpSeekCounter$()
      .pipe(
        tap((seconds) => {
          this.isLive =
            this.streamInfoService.getStreamType() === StreamType.LIVE &&
            this.bitmovinService.getPosition() + seconds >= -10;
          this.secondsToJump = seconds;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  jumpForward(): void {
    this.isLive = this.bitmovinService.isLive();
    if (!this.isLive) {
      this.jumpSeekingService.jumpForward();
    }
  }

  jumpBackward(): void {
    this.jumpSeekingService.jumpBack();
  }

  mathAbs(number: number): number {
    return Math.abs(number);
  }
}
