import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { notFoundGuard } from './core/guards/not-found.guard';
import { EmptyComponent } from './shared/components/empty/empty.component';

const routes: Routes = [
  {
    path: '**',
    component: EmptyComponent,
    canActivate: [notFoundGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class FallbackRoutingModule {}
