import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Broadcast } from '@teleboy/web.epg';
import { NgIf, NgClass } from '@angular/common';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-broadcast-flag',
  templateUrl: './broadcast-flag.component.html',
  styleUrls: ['./broadcast-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, IconComponent]
})
export class BroadcastFlagComponent implements OnInit {
  showAsTipp!: boolean;
  showAsCommunityRecord!: boolean;

  @Input() broadcast!: Broadcast;
  @Input() large = false;

  ngOnInit(): void {
    this.showAsCommunityRecord = this.broadcast.flags.is_community_record && !this.broadcast.flags.isRecorded();
    this.showAsTipp = this.broadcast.flags.is_tip && !this.showAsCommunityRecord;
  }
}
