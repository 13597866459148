import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { catchError, map, Observable } from 'rxjs';
import { PopupService } from '../../core/services/popup.service';
import { AnonymousDataPolicy } from '@teleboy/web.user';
import { AnonymousAuthComponent } from '../../shared/components/anonymous-auth/anonymous-auth.component';

export const anonymousDataGuard = () => {
  const anonymousDataPolicy = inject(AnonymousDataPolicy);
  const popupService = inject(PopupService);
  const router = inject(Router);

  return anonymousDataPolicy.isGranted().pipe(catchError(() => openAnonymousDataModal$()));

  function openAnonymousDataModal$(): Observable<boolean | UrlTree> {
    return popupService
      .openAsModal<AnonymousAuthComponent>(AnonymousAuthComponent)
      .beforeClosed()
      .pipe(map((confirmed: boolean) => (confirmed ? true : router.createUrlTree(['live']))));
  }
};
