import { AfterViewInit, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { AuthenticationService, DarkmodeService } from '@teleboy/web.user';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DOCUMENT } from '@angular/common';
import { OnetrustService } from '@teleboy/web.core';
import { ScreenService } from './core/services/screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
  standalone: false
})
export class AppComponent implements OnInit, AfterViewInit {
  private touchEventListener!: () => void;

  private readonly INIT_BODY_CLASS = 'layout-init';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authenticationService: AuthenticationService,
    private darkmodeService: DarkmodeService,
    private deviceDetector: DeviceDetectorService,
    private onetrustService: OnetrustService,
    private renderer2: Renderer2,
    private router: Router,
    private screenService: ScreenService
  ) {}

  ngOnInit(): void {
    this.setBodyClasses();

    if (!this.authenticationService.user?.isPlusUser) {
      void this.router.navigate(['start'], { skipLocationChange: true });
    }
  }

  ngAfterViewInit() {
    if (!this.authenticationService.isAuthenticated || this.authenticationService.user?.isFreeUser) {
      this.onetrustService.init();
    }

    this.touchEventListener = this.renderer2.listen('body', 'touchstart', (_) => {
      this.screenService.setIsTouchDevice(true);

      // Unsubscribe.
      this.touchEventListener();
    });
  }

  private setBodyClasses(): void {
    this.renderer2.addClass(this.document.body, this.deviceDetector.deviceType.toLowerCase());
    this.renderer2.addClass(this.document.body, this.deviceDetector.os.toLowerCase());
    this.renderer2.removeClass(this.document.body, this.INIT_BODY_CLASS);

    if (this.darkmodeService.darkmodeEnabled) {
      this.document.querySelector('meta[name=theme-color]')?.setAttribute('content', '#1c1c1c');
    }
  }
}
