<div class="swiper__container swiper__container--broadcast" *ngIf="!(isEmptyResult$() | async)">

  <app-expand-link [linkText]="headline" (clicked)="openInListTilesView()"></app-expand-link>

  <swiper *ngIf="data$ | async as broadcasts; else loading" #swiper>
    <ng-template *ngFor="let broadcast of broadcasts" swiperSlide>
      <app-broadcast-item [broadcast]="broadcast" [card]="true"></app-broadcast-item>
    </ng-template>
    <ng-template swiperSlide>
      <app-broadcast-item *ngIf="isPaginating$ | async" [card]="true" class="ghost-listing"></app-broadcast-item>
    </ng-template>
  </swiper>
</div>

<ng-template #loading>
  <swiper class="ghost-listing ghost-listing--horizontal">
    <ng-template *ngFor="let ghost of ghosts" swiperSlide>
      <app-broadcast-item [card]="true"></app-broadcast-item>
    </ng-template>
  </swiper>
</ng-template>
