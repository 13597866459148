@if (!(isEmptyResult$() | async)) {
  <div class="swiper__container">
    <app-expand-link [linkText]="headline" (clicked)="openTitleGroupsList()"></app-expand-link>

    @if (data$ | async; as titleGroups) {
      <swiper #swiper>
        @for (titleGroup of titleGroups; track $index) {
          <ng-template swiperSlide>
            <app-pvr-titlegroup-preview [titleGroup]="titleGroup"></app-pvr-titlegroup-preview>
          </ng-template>
        }

        @let total = (total$ | async);
        @if (total && total > 5) {
          <ng-template swiperSlide>
            <app-pvr-grid-button
              [icon]="'grid'"
              [label]="'pvr.groups.series.showAll' | translate"
              (click)="openTitleGroupsList()"></app-pvr-grid-button>
          </ng-template>
        }
      </swiper>
    }
  </div>

  @if (busy()) {
    <swiper class="ghost-listing ghost-listing--horizontal">
      @for (ghost of ghosts; track $index) {
        <ng-template swiperSlide>
          <app-broadcast-item [card]="true"></app-broadcast-item>
        </ng-template>
      }
    </swiper>
  }
}
