import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { Broadcast, RecordingBatchDeleteService } from '@teleboy/web.epg';
import { Recording } from '@teleboy/web.epg/lib/models/recording.model';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-recording-batch-toggler',
  templateUrl: './recording-batch-toggler.component.html',
  styleUrls: ['./recording-batch-toggler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, NgClass, IconComponent]
})
export class RecordingBatchTogglerComponent {
  readonly recording = input.required<Recording>();

  /**
   * Click will select/unselect the current preview as long this signal is true
   */
  readonly selectable = input(false);

  /**
   * Tells the preview if it's select by checking the broadcast id within recordings marked for batch delete
   */
  readonly selected = computed(() => {
    return this.recordingBatchDeleteService
      .recordings()
      .some((recording: Broadcast) => recording.id === this.recording()?.id);
  });

  readonly isCard = input.required<boolean>();

  /**
   * Emits whenever the current recording is selected or unselected
   */
  readonly recordingSelect = output<Broadcast>();
  readonly recordingUnselect = output<Broadcast>();

  constructor(private recordingBatchDeleteService: RecordingBatchDeleteService) {}

  toggleSelect() {
    if (this.selected()) {
      this.recordingUnselect.emit(this.recording());
    } else {
      this.recordingSelect.emit(this.recording());
    }
  }
}
