<ng-container>
  <div [ngClass]="getBusyClassList()">
    @if (image) {
      <img (click)="onProfileImageClick()" [ngSrc]="image.getSrc(MediaImageStack.USER)" alt="User Profile" class="profile-image"
           [ngClass]="{'profile-image__clickable': clickable, 'profile-image__current': active}" [width]="getDimension()" [height]="getDimension()"/>
    } @else {
      <tbui-icon  class="btn btn__icon btn--circle" iconId="add"
                  [size]="'xxl'"
                  (click)="onProfileImageClick()">
      </tbui-icon>
    }
  </div>

  @if (showEdit) {
    <button class="btn btn__icon btn--circle profile-image__edit" (click)="onProfileImageClick()">
      <tbui-icon iconId="edit"></tbui-icon>
    </button>
  }

</ng-container>
