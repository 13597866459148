@if (!(loaded$ | async)) {
  <div class="player-overlay"></div>
}

<div class="player-wrapper" id="player-wrapper">

<!-- PLAYER Branding Day -->
  @if (brandingday$ | async; as brandingday) {
    <app-brandingday class="player-brandingday-wrapper"
                     [brandingday]="brandingday" [impressionTracking$]="enlargePlayer$"></app-brandingday>
  }

  <div id="player" class="player" #player>

    <!-- PLAYER HEADER -->
    <div class="player-header tabbar">
      <div class="tabbar__left">
        <ng-content select="[streamInfo]"></ng-content>
      </div>

      <div class="tabbar__right">
        <div class="btn-player__large__mini-player" (click)="largePlayer()"></div>

        <button class="btn btn__icon btn--circle btn--opaque btn-player__fullscreen" (click)="fullscreenToggle()">
          <tbui-icon class="btn-player__fullscreen--off" iconId="fullscreen-off"></tbui-icon>
          <tbui-icon class="btn-player__fullscreen--on" iconId="fullscreen-on"></tbui-icon>
        </button>

        <button class="btn btn__icon btn--circle btn--opaque btn-player__large" (click)="largePlayer()">
          <tbui-icon iconId="arrow-up"></tbui-icon>
        </button>

        <button class="btn btn__icon btn--circle btn--opaque btn-player__mini" (click)="miniPlayer()">
          <tbui-icon iconId="arrow-down"></tbui-icon>
        </button>

        @if (isPlusUser || ((playerSize$ | async) === PlayerSize.MINI) && !isMobile ) {
          <button (click)="closePlayer()"
                  [ngClass]="{ 'btn--lg': (playerSize$ | async) === PlayerSize.LARGE }"
                  class="btn btn__icon btn--circle btn--opaque btn-player__close">
            <tbui-icon iconId="close"></tbui-icon>
          </button>
        }
      </div>
    </div>

    <!-- AD UI -->
    <app-ad-ui></app-ad-ui>

    <!-- PLAYER JumpSeek -->
    <div class="jumpseek-container">
      @if (jumpseekEnabled) {
        <app-jumpseek-buttons></app-jumpseek-buttons>
      }
    </div>

    <!-- PLAYER Pause AD -->
    @if (creative$ | async; as creative) {
    <div (click)="play()" class="creative-overlay">
      <img [src]="creative" alt="">
    </div>
    }

    <!-- PLAYER SKIP BUTTON -->
    @if (skipButton$ | async; as schedule) {
      <a (click)="skipAd(schedule)"
         class="btn btn--opaque btn__icon btn--lg btn--circle btn__skip-ad">
        <tbui-icon iconId="skip-ad"></tbui-icon>
      </a>
    }

    <!-- PLAYER LIST -->
    <app-player-listing
      [closed]="listingClosed"
      [connectedToPlayerUi]="connectedToPlayerUI"
      [isBrandingDay]="!!(brandingday$ | async)">
    </app-player-listing>

    <ng-content select="[uiContainer]"></ng-content>
  </div>
</div>
