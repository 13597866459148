"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CastStatusOverlay = void 0;
var container_1 = require("./container");
var label_1 = require("./label");
var i18n_1 = require("../localization/i18n");
/**
 * Overlays the player and displays the status of a Cast session.
 *
 * @category Components
 */
var CastStatusOverlay = /** @class */function (_super) {
  __extends(CastStatusOverlay, _super);
  function CastStatusOverlay(config) {
    if (config === void 0) {
      config = {};
    }
    var _this = _super.call(this, config) || this;
    _this.statusLabel = new label_1.Label({
      cssClass: 'ui-cast-status-label'
    });
    _this.config = _this.mergeConfig(config, {
      cssClass: 'ui-cast-status-overlay',
      components: [_this.statusLabel],
      hidden: true
    }, _this.config);
    return _this;
  }
  CastStatusOverlay.prototype.configure = function (player, uimanager) {
    var _this = this;
    _super.prototype.configure.call(this, player, uimanager);
    player.on(player.exports.PlayerEvent.CastWaitingForDevice, function (event) {
      _this.show();
      // Get device name and update status text while connecting
      var castDeviceName = event.castPayload.deviceName;
      _this.statusLabel.setText(i18n_1.i18n.getLocalizer('connectingTo', {
        castDeviceName: castDeviceName
      }));
    });
    player.on(player.exports.PlayerEvent.CastStarted, function (event) {
      // Session is started or resumed
      // For cases when a session is resumed, we do not receive the previous events and therefore show the status panel
      // here too
      _this.show();
      var castDeviceName = event.deviceName;
      _this.statusLabel.setText(i18n_1.i18n.getLocalizer('playingOn', {
        castDeviceName: castDeviceName
      }));
    });
    player.on(player.exports.PlayerEvent.CastStopped, function (event) {
      // Cast session gone, hide the status panel
      _this.hide();
    });
  };
  return CastStatusOverlay;
}(container_1.Container);
exports.CastStatusOverlay = CastStatusOverlay;