<div *ngIf="teaser; else ghost" class="card card--rounded card--rounded--hidden teaser-item" [ngClass]="classList()">
  <a (click)="openLandingpage()">
    <tbui-media-image class="ratio" [ngClass]="classListImage()"
                      [image]="teaser.getImage(topTeaser)" [size]="imageSize()" [alt]="teaser?.text ?? 'teaser'"
                      [priority]="topTeaser"></tbui-media-image>
    <div *ngIf="!topTeaser" class="teaser-item__overlay">
      <span class="teaser-item__title">{{ teaser.title }}</span>
    </div>
  </a>
</div>

<ng-template #ghost>
  <div class="card card--rounded teaser-item" [ngClass]="classList()">
    <div class="ratio" [ngClass]="classListImage()">&nbsp;</div>
  </div>
</ng-template>
