<div class="recording-preview--selectable" (click)="toggleSelect()">

  @if (!isCard()) {
    <b class="hidden-mobile">{{ 'pvr.batch.select' | translate }}</b>
  }

  <div class="recording-preview__delete-checkbox" [ngClass]="{'recording-preview__delete-checkbox__selected': selected(), 'recording-preview__delete-checkbox__card-view': isCard()}">

    @if (selected()) {
      <tbui-icon iconId="bin" size="xl"></tbui-icon>
    }
  </div>
</div>
