import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorage } from 'ngx-webstorage';

export enum ViewToggleType {
  LIST = 'list',
  TILES = 'tiles'
}

export enum ToggleablePageType {
  LiveViewPage,
  BroadcastsPage,
  StationsPage,
  RecordingsPage
}

export enum ViewToggleLocalStorageKey {
  GenericListingType = 'generic-listing-type',
  LiveListingType = 'live-listing-type',
  StationsListingType = 'stations-listing-type',
  RecordingsListingType = 'recordings-listing-type'
}

@Injectable({
  providedIn: 'root'
})
export class ViewTogglerService {
  @LocalStorage(ViewToggleLocalStorageKey.GenericListingType)
  private genericListingType!: ViewToggleType;
  @LocalStorage(ViewToggleLocalStorageKey.LiveListingType)
  private liveListingType!: ViewToggleType;
  @LocalStorage(ViewToggleLocalStorageKey.StationsListingType)
  private stationsListingType!: ViewToggleType;
  @LocalStorage(ViewToggleLocalStorageKey.RecordingsListingType)
  private recordingsListingType!: ViewToggleType;

  constructor(private deviceDetector: DeviceDetectorService) {}

  setListingType(pageType: ToggleablePageType): ViewToggleType {
    switch (pageType) {
      case ToggleablePageType.LiveViewPage:
        this.liveListingType =
          this.liveListingType || (this.deviceDetector.isMobile() ? ViewToggleType.LIST : ViewToggleType.TILES);
        return this.liveListingType;
      case ToggleablePageType.BroadcastsPage:
        this.genericListingType =
          this.genericListingType || (this.deviceDetector.isMobile() ? ViewToggleType.LIST : ViewToggleType.TILES);
        return this.genericListingType;
      case ToggleablePageType.StationsPage:
        this.stationsListingType =
          this.stationsListingType || (this.deviceDetector.isMobile() ? ViewToggleType.LIST : ViewToggleType.TILES);
        return this.stationsListingType;
      case ToggleablePageType.RecordingsPage:
        this.recordingsListingType =
          this.recordingsListingType || (this.deviceDetector.isMobile() ? ViewToggleType.LIST : ViewToggleType.TILES);
        return this.recordingsListingType;
      default:
        return ViewToggleType.TILES;
    }
  }

  toggleListingType(pageType: ToggleablePageType): ViewToggleType {
    switch (pageType) {
      case ToggleablePageType.LiveViewPage: {
        const currentListingType =
          this.liveListingType === ViewToggleType.LIST ? ViewToggleType.TILES : ViewToggleType.LIST;
        this.liveListingType = currentListingType;
        return currentListingType;
      }
      case ToggleablePageType.BroadcastsPage: {
        const currentListingType =
          this.genericListingType === ViewToggleType.LIST ? ViewToggleType.TILES : ViewToggleType.LIST;
        this.genericListingType = currentListingType;
        return currentListingType;
      }
      case ToggleablePageType.StationsPage: {
        const currentListingType =
          this.stationsListingType === ViewToggleType.LIST ? ViewToggleType.TILES : ViewToggleType.LIST;
        this.stationsListingType = currentListingType;
        return currentListingType;
      }
      case ToggleablePageType.RecordingsPage: {
        const currentListingType =
          this.recordingsListingType === ViewToggleType.LIST ? ViewToggleType.TILES : ViewToggleType.LIST;
        this.recordingsListingType = currentListingType;
        return currentListingType;
      }
      default:
        throw new Error(`Provide supported page type of type ${pageType}`);
    }
  }
}
