<app-popup outlet="landingpage" [full]="true">
  <div #scrollToTop></div>
  <ng-container *ngIf="station$ | async as station">
    <div class="header">
      <h1 class="visually-hidden">{{ station.name }}</h1>
      <tbui-station-logo *ngIf="station" [station]="station" [size]="StationLogoSize.L"></tbui-station-logo>
    </div>

    <div class="grid grid__sliders">
      <ng-container *ngFor="let lane of collection">
        <app-broadcast-swiper [headline]="lane.label | translate"
                              [fetchAllItems]="listToggleTypeSelected"
                              [listingType]="listingType"
                              [isLinkClickable]="false"
                              [params]="lane.params"></app-broadcast-swiper>
      </ng-container>
    </div>
  </ng-container>

  <ng-container buttons>

    <button class="buttons btn btn__icon btn--circle btn__toggle-view--{{ listingType }}" (click)="toggleListingType()">
      <tbui-icon iconId="view-list" [ngClass]="{'hidden': listingType === ListingType.LIST}"
                 class="view-icon-list"></tbui-icon>
      <tbui-icon iconId="view-tiles" [ngClass]="{'hidden': listingType !== ListingType.LIST}"
                 class="view-icon-tiles"></tbui-icon>

    </button>


    <ng-container *ngIf="station$ | async as station">
      <app-stations-dropdown [station]="station"
                             (stationClicked$)="changeStation($event)"></app-stations-dropdown>

    </ng-container>

    <button *ngIf="reloadButton$ | async" (click)="reload()"
            class="btn">{{ 'station.details.reload' | translate }}</button>
  </ng-container>

</app-popup>
