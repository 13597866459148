<div *ngIf="user?.isPlusUser && !isReplayReady() && !hideReplayMessage"
     class="card card--gray card--rounded card--flat replay__message">

  <!-- REPLAY NOT ACTIVATED -->
  <div *ngIf="!isReplayActive(); else replayActive">
    <span class="h2">{{ 'home.replayInfo.titleInactive' | translate }}</span>
    <p>{{ 'home.replayInfo.messageInactive' | translate }}</p>
    <button (click)="activateReplay()" class="btn btn__primary replay__link">
      {{ 'home.replayInfo.ctaActivate' | translate }}
    </button>
  </div>

  <!-- REPLAY ACTIVATED -->
  <ng-template #replayActive>
    <span class="h2">{{ 'home.replayInfo.titleActive' | translate }}</span>
    <p>
      {{ 'home.replayInfo.messageActive' | translate: {
      dateActive: user.replay.format('DD.MM.YYYY - HH:MM'),
      dateAvailable: getReplayActivationAvailabilityDate().format('DD.MM.YYYY - HH:MM')
    } }}
    </p>
    <tbui-icon (click)="closeReplayMessage()" iconId="close" class="replay__close" size="xl"></tbui-icon>
    <a (click)="closeReplayMessage()"
       class="visible-mobile replay__link">{{ 'home.replayInfo.ctaHide' | translate }}</a>
  </ng-template>

</div>
