import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { ToggleListSource } from './player-listing.component';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ScreenService } from '../../../../core/services/screen.service';
import { BitmovinService } from '@teleboy/web.player';

@Injectable({
  providedIn: 'root'
})
export class PlayerListingService {
  @LocalStorage('playerListingShown', true)
  playerListingShown!: boolean;

  playerListingTrackMouseMove!: boolean;

  private readonly _listingActiveIdSet$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  readonly listingActiveIdSet$: Observable<number | null> = this._listingActiveIdSet$.asObservable();

  constructor(
    private bitmovinService: BitmovinService,
    private screenService: ScreenService
  ) {
    if (this.playerListingShown) {
      this.setTrackMouseMoveFlag(true, ToggleListSource.Button);
    }

    if (this.screenService.isDesktopSizeScreen()) {
      this.bitmovinService.adBreakActive$
        .pipe(tap((active) => this.setTrackMouseMoveFlag(active, ToggleListSource.Button)))
        .subscribe();
    }
  }

  setTrackMouseMoveFlag(shown: boolean, source = ToggleListSource.Backdrop): void {
    if (!this.screenService.isDesktopSizeScreen()) {
      return;
    }

    if (source === ToggleListSource.Button) {
      this.playerListingTrackMouseMove = shown;
    }
  }

  setListingActiveId(id: number) {
    this._listingActiveIdSet$.next(id);
  }
}
