import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MediaImageComponent } from '@teleboy/web.ui';
import { MediaImageStack } from '@teleboy/web.core';
import { TranslateModule } from '@ngx-translate/core';
import { RecordingGroup, RecordingSortDirection } from '@teleboy/web.epg';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pvr-genregroup-preview',
  imports: [MediaImageComponent, TranslateModule, NgClass],
  templateUrl: './pvr-genregroup-preview.component.html',
  styleUrl: './pvr-genregroup-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PvrGenregroupPreviewComponent {
  genreGroup = input.required<RecordingGroup>();
  protected readonly MediaImageStack = MediaImageStack;

  constructor(private router: Router) {}

  classListImage(): Record<string, boolean> {
    return {
      ratio__16x9: true
    };
  }

  imageSize(): MediaImageStack {
    return MediaImageStack.MEDIUM;
  }

  openRecordingsList(): void {
    void this.router.navigate([{ outlets: { ['details']: 'pvr/recordings' } }], {
      queryParams: { genreId: this.genreGroup().genre.id, sortDirection: RecordingSortDirection.DESC }
    });
  }
}
