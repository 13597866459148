@if (genreGroup()) {
  <div class="card card--rounded card--rounded--hidden pvr-genre-group">
    <a (click)="openRecordingsList()">
      <tbui-media-image class="ratio" [ngClass]="classListImage()"
                        [image]="genreGroup().image" [size]="imageSize()" [alt]="genreGroup().title"></tbui-media-image>
      <div class="pvr-genre-group__overlay">
        <h3 class="pvr-genre-group__title">{{ genreGroup().title }}</h3>
        <p
          class="pvr-genre-group__count">{{ 'pvr.groups.recordings.count' | translate:{ count: genreGroup().count } }}
        </p>
      </div>
    </a>
  </div>
} @else {
  <div class="card card--rounded pvr-genre-group">
    <div class="ratio__16x9"></div>
  </div>
}

