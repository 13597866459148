import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  EpgParams,
  Station,
  StationEpgParamsCollection,
  StationLogoSize,
  StationStorageService,
  StationLogoComponent
} from '@teleboy/web.epg';
import { ActivatedRoute } from '@angular/router';
import { map, Observable, repeat, startWith, Subject, switchMap, tap, timer } from 'rxjs';
import moment from 'moment';
import { ParamsCollectionItem } from '@teleboy/web.core';
import { PopupService } from '../../../core/services/popup.service';
import { ToggleablePageType, ViewTogglerService, ViewToggleType } from '../../services/view-toggler.service';
import { StationsAutocompleteDropdownComponent } from '../dropdown/stations-dropdown/stations-autocomplete-dropdown.component';
import { SharedModule } from '../../../shared/shared.module';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { BroadcastSwiperComponent } from '../swiper/broadcast-swiper/broadcast-swiper.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@teleboy/web.ui';

@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedModule,
    NgIf,
    StationLogoComponent,
    NgFor,
    BroadcastSwiperComponent,
    NgClass,
    StationsAutocompleteDropdownComponent,
    AsyncPipe,
    TranslateModule,
    IconComponent
  ]
})
export class StationComponent implements OnInit {
  @ViewChild(StationsAutocompleteDropdownComponent) autocomplete!: StationsAutocompleteDropdownComponent;
  @ViewChild('scrollToTop', { static: true }) scrollToTop!: ElementRef;
  listingType!: ViewToggleType;
  listToggleTypeSelected!: boolean;
  collection!: ParamsCollectionItem<EpgParams>[];
  reloadButton$!: Observable<boolean>;
  station$!: Observable<Station>;
  StationLogoSize = StationLogoSize;

  readonly ListingType = ViewToggleType;

  private readonly RELOAD_BUTTON_TIMEOUT = 5 * 60 * 1000;
  private readonly reload$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private popupService: PopupService,
    private stationStorageService: StationStorageService,
    private viewTogglerService: ViewTogglerService
  ) {
    this.listToggleTypeSelected = this.listingType === ViewToggleType.LIST;
  }

  ngOnInit(): void {
    this.station$ = this._station$();
    this.reloadButton$ = this._reloadButton$();
    this.listingType = this.viewTogglerService.setListingType(ToggleablePageType.StationsPage);
  }

  reload(): void {
    this.reload$.next();
  }

  changeStation(station: Station) {
    this.popupService.open(`station/${station.id}`, { outlet: 'landingpage' });
    this.scrollToTop.nativeElement.scrollIntoView();
  }

  toggleListingType(): void {
    this.listingType = this.viewTogglerService.toggleListingType(ToggleablePageType.StationsPage);

    if (this.listToggleTypeSelected) {
      return;
    }

    this.station$ = this._station$();
    this.listToggleTypeSelected = true;
  }

  private _station$(): Observable<Station> {
    return this.activatedRoute.params.pipe(
      map((params) => parseInt(params['stationId'], 10)),
      switchMap((stationId) => {
        return this.stationStorageService.getById(stationId).pipe(repeat({ delay: () => this.reload$ }));
      }),
      tap((station) => {
        this.collection = new StationEpgParamsCollection(station.id).getCollection().map((item) => {
          item.label = this.getHeadlineFromLabel(item.label);
          return item;
        });
      })
    );
  }

  private _reloadButton$(): Observable<boolean> {
    return timer(this.RELOAD_BUTTON_TIMEOUT).pipe(
      tap(() => this.closeAutocomplete()),
      repeat({ delay: () => this.reload$ }),
      map(() => true),
      switchMap(() =>
        this.reload$.pipe(
          map(() => false),
          startWith(true)
        )
      )
    );
  }

  private closeAutocomplete() {
    this.autocomplete.dropdownListShown = false;
    this.autocomplete.trigger.closePanel();
  }

  private getHeadlineFromLabel(label: string): string {
    let headline: string;
    const date = moment(label);

    if (date.isSame(moment().startOf('day'))) {
      headline = 'station.detail.today';
    } else if (date.isSame(moment().subtract(1, 'day').startOf('day'))) {
      headline = 'station.detail.yesterday';
    } else {
      headline = date.format('dddd, D. MMMM YYYY');
    }

    return headline;
  }
}
