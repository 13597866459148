import { inject } from '@angular/core';

import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';
import { PopupService } from '../../core/services/popup.service';
import { ProfilesUpsellComponent } from '../components/profiles-upsell/profiles-upsell.component';
import { AuthenticationService, ProfileAllowedPolicy, ProfileUserPolicy } from '@teleboy/web.user';
import { Router, UrlTree } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';

export enum SubscriptionInfo {
  BuySubscription = 'buy-subscription',
  UpdateSubscription = 'update-subscription',
  Onboarding = 'onboarding'
}

export const profileGuard = (): Observable<boolean | UrlTree> => {
  const popupService = inject(PopupService);
  const profileAllowedPolicy = inject(ProfileAllowedPolicy);
  const profileUserPolicy = inject(ProfileUserPolicy);
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);
  const location = inject(Location);

  if (!environment.features.profiles) {
    return of(router.createUrlTree(['home']));
  }

  return profileUserPolicy.isGranted().pipe(
    catchError(() => {
      return profileAllowedPolicy.isGranted().pipe(
        switchMap(() => openProfileUpsell$(SubscriptionInfo.Onboarding)),
        catchError(() => {
          if (authenticationService.user?.isProfileUser) {
            return handleLegacyProfile$();
          } else {
            return handleProfile$();
          }
        })
      );
    })
  );

  function handleProfile$() {
    if (!authenticationService.user?.isFreeUser) {
      return openProfileUpsell$(SubscriptionInfo.UpdateSubscription);
    } else {
      return openProfileUpsell$(SubscriptionInfo.BuySubscription);
    }
  }

  function handleLegacyProfile$() {
    return openProfileUpsell$(SubscriptionInfo.BuySubscription);
  }

  function openProfileUpsell$(subscription: SubscriptionInfo): Observable<boolean> {
    return popupService
      .openAsModal<ProfilesUpsellComponent>(ProfilesUpsellComponent, subscription)
      .beforeClosed()
      .pipe(
        map((result) => !!result),
        tap(() => {
          // Access from cockpit when no user is initialized or user is free user.
          const url = location.path();
          if (url === '/profile-settings-list') {
            void router.navigate(['home']);
          }
        })
      );
  }
};
