<div class="tabbar">
  <div class="tabbar__left">
    <h1 class="collapse tabbar__title">{{ 'live.title' | translate }}</h1>
  </div>

  <div class="tabbar__right">
    @if (!isAuthenticated) {
      <a [href]="'/login' | webUiHref: {appendTarget: true}" class="btn"
         appDataLayer
         dataLayerEventName="live_nav_login">
        {{ 'live.nav.login' | translate }}
      </a>
    }

    @if (reloadButton$ | async) {
      <button class="btn btn__primary" (click)="reload()">{{ 'live.reload.btn' | translate }}</button>
    }

    <button class="btn btn__icon btn--circle btn__toggle-view--{{ liveListingType }}" (click)="toggleListingType()">
      <tbui-icon iconId="view-list" [ngClass]="{'hidden': liveListingType === ListingType.LIST}"
                 class="view-icon-list"></tbui-icon>
      <tbui-icon iconId="view-tiles" [ngClass]="{'hidden': liveListingType !== ListingType.LIST}"
                 class="view-icon-tiles"></tbui-icon>
    </button>

    <app-search></app-search>
  </div>
</div>

<!-- INLINE USER ALERT -->
@if (isAuthenticated) {
  <app-inline-alert class="mb-base"></app-inline-alert>
}

@if (liveEpg$ | async; as epg) {
  <div
    (scrolled)="loadMore()" infiniteScroll
    class="grid" [ngClass]="{'grid__tiles': liveListingType === ListingType.TILES}">
    @for (broadcast of epg | slice:0:broadcastsLimit; track broadcast.id) {
      <app-broadcast-item [broadcast]="broadcast" [live]="true"
      [card]="liveListingType === ListingType.TILES"></app-broadcast-item>
    }
    <div class="text-center">
      <br>
      <br>
      <a href="https://www.teleboy.ch/sender" target="_blank" class="btn btn__primary">
        {{ 'live.info.station.btn' | translate }}
      </a>
    </div>
  </div>
} @else {
  <div class="grid ghost-listing" [ngClass]="{'grid__tiles': liveListingType === ListingType.TILES}">
    @for (ghost of ghosts; track $index) {
      <app-broadcast-item [live]="true"
                          [card]="liveListingType === ListingType.TILES"></app-broadcast-item>
    }
  </div>
}



