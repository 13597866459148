import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchingService {
  readonly searchString$: Observable<string>;
  readonly showSearch$: Observable<boolean>;
  private readonly _searchString$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private readonly _showSearch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.searchString$ = this._searchString$.asObservable();
    this.showSearch$ = this._showSearch$.asObservable();
  }

  executeSearch(searchTerm: string) {
    if (searchTerm) {
      this._searchString$.next(searchTerm);
    }
  }

  resetSearch() {
    this._searchString$.next('');
  }

  setShowSearch(shown: boolean) {
    this._showSearch$.next(shown);
  }
}
