import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MediaImageStack } from '@teleboy/web.core';
import { CommunityProvider, Genre, GenreStorageService } from '@teleboy/web.epg';
import { forkJoin, Observable } from 'rxjs';
import { NgClass, NgIf, NgFor, AsyncPipe } from '@angular/common';
import { IconComponent, MediaImageComponent } from '@teleboy/web.ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cpvr-profile',
  templateUrl: './cpvr-profile.component.html',
  styleUrls: ['./cpvr-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MediaImageComponent, NgIf, NgFor, AsyncPipe, TranslateModule, IconComponent]
})
export class CpvrProfileComponent implements OnInit {
  @Input() communityProvider!: CommunityProvider;
  readonly MediaImageStack = MediaImageStack;
  genres$!: Observable<Genre[]>;

  constructor(private genreStorageService: GenreStorageService) {}

  ngOnInit(): void {
    this.genres$ = forkJoin(this.communityProvider.genres.map((genreId) => this.genreStorageService.getById(genreId)));
  }
}
