<app-close-button (closed)="close()"></app-close-button>
<app-info icon="serial" [collapse]="true"
          [title]="'pvr.serial.title' | translate">

  <ng-container customContent>
    <p class="small">
      {{ 'pvr.serial.details' | translate: {
      date: broadcast.begin.format('dddd, DD. MMMM'),
      begin: broadcast.begin.format('HH:mm'),
      end: broadcast.end.format('HH:mm'),
      station: (broadcast.station$ | async)?.name
    } }}
    </p>
    <p><strong>{{ 'pvr.serial.text' | translate: {title: broadcast.title} }}</strong></p>
  </ng-container>

  <p class="btn__list btn__list-h btn__list-h--apart" cta>
    <button class="btn btn__secondary"
            (click)="close()"
            [attr.aria-disabled]="busy$ | async">
      {{ 'pvr.serial.cta.close' | translate }}
    </button>

    <button class="btn btn__primary"
            (click)="subscribeToSerialgroup()"
            [attr.aria-disabled]="busy$ | async" appAutofocus>
      {{ 'pvr.serial.cta.subscribe' | translate }}
    </button>
  </p>
</app-info>

<p class="small collapse">{{ 'pvr.serial.info' | translate }}</p>
