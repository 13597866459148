<div class="tabbar">
  <div class="tabbar__left">
    <h1 class="collapse tabbar__title">{{ 'settings.title' | translate }}</h1>
  </div>
  <div class="tabbar__right">
    <app-search></app-search>
  </div>
</div>

<div class="settings__wrapper">

  <!-- INFO -->
  <div class="settings__info text-center">
    <!-- INFOBOX ANONYMOUS -->
    @if (!isAuthenticated) {
      <div class="settings__info">
        <tbui-icon iconId="user" size="xxl"></tbui-icon>
        <h2>{{ 'settings.info.login' | translate }}</h2>
        <p>
          {{ 'settings.info.desc' | translate }}
        </p>
        <a [href]="'/login' | webUiHref: {appendTarget: true}" class="btn full-width mt-tiny" id="settings_login"
           appDataLayer dataLayerEventName="settings_login">
          {{ 'settings.info.loginButton' | translate }}
        </a>
      </div>
    }

    @if (isAuthenticated) {
      <!-- INFOBOX LOGGED IN -->
      <div>
        <div>
          <div class="settings__info__userimage">

            <!-- User Profile-->
            @if (environment.features.profiles) {
              @if ((userProfiles$ | async)?.length) {
                <div class="settings__profile">
                  @if ((userProfiles$ | async); as userProfiles) {
                    <div class="settings__profile__switch" (click)="goToProfilesOverview()">
                      @if (isProfileUser) {
                        <b class="settings__profile__label">{{ 'userProfiles.settings.change' | translate }}</b>
                      }
                      @for (item of userProfiles; track $index) {
                        <div class="settings__profile__avatars">
                          <app-profile-image class="settings__profile__item" [clickable]="false"
                                             [image]="item.profileImage"
                                             [size]="ProfileImageSize.Small"></app-profile-image>
                        </div>
                      }
                    </div>
                  }
                </div>
              } @else {
                <div class="settings__profile">
                  <div class="settings__profile__create" (click)="goToProfilesOverview()">
                    <b class="settings__profile__label">{{ 'userProfiles.settings.add' | translate }}</b>
                    <button class="btn btn__icon btn--circle">
                      <tbui-icon iconId="add"></tbui-icon>
                    </button>
                  </div>
                </div>
              }
            }
            <!-- User Profile End -->

            <img [src]="userProfileImage" class="main-nav__item-user" alt="Profile"
                 width="119" height="119">
            <a class="settings__info__userimage__edit btn btn__icon btn--circle"
               routerLinkActive="btn--active"
               target="_blank"
               [href]="'/cockpit/profile/image' | webUiHref">
              <tbui-icon iconId="edit"></tbui-icon>
            </a>
          </div>

          <div class="settings__info__username text-center card">

            @if (!user?.isProfileUser || user?.isProfileParentUser) {
              <b>{{ user.username }}</b><br>
              {{ 'settings.info.clientNumber' | translate }}: {{ user.id }}<br>
              {{ user.email }}<br>
            } @else {
              <b>{{ (userProfile$ | async)?.name }}</b><br>
            }
          </div>

          @if (!user?.isProfileChildUser) {
            <div class="settings__subinfo card">
              <div class="text-left">
                <small>{{ 'settings.info.currentAbo' | translate }}:</small>
              </div>
              <div class="text-left">
                <b>TELEBOY</b>
                @if (subscriptionLevel !== AboLevel.ONE) {
                  <span class="settings__subinfo__title"> {{ subscriptionLevel }}</span>
                }

                @if (subscriptionLevel === AboLevel.ONE && !user.hasSubscriptionFeatureSkipAd()) {
                  <span class="settings__subinfo__title"> TV</span>
                }

                @if (subscriptionLevel === AboLevel.ONE && user.hasSubscriptionFeatureSkipAd()) {
                  <span class="settings__subinfo__title"> TV+</span>
                }

                <div class="settings__subinfo__editlink">
                  @if (subscriptionLevel !== AboLevel.ONE) {
                    <a [href]="'/tv' | webUiHref"
                       target="_blank">{{ 'settings.info.upgrade' | translate }}</a>
                  }

                  @if (subscriptionLevel === AboLevel.ONE) {
                    <a [href]="'/service/tv' | cockpitHref"
                       target="_blank">{{ 'settings.info.manage' | translate }}</a>
                  }
                </div>
              </div>
            </div>
          }
          <a [href]="'/user' | cockpitHref" target="_blank"
             class="btn full-width mt-small">{{ 'settings.info.editProfileButton' | translate }}</a>
          <a [href]="'/link' | webUiHref" target="_blank"
             class="btn full-width mt-tiny">{{ 'settings.info.connectTVButton' | translate }}</a>
          <a [href]="'/logout' | webUiHref: {appendLocale: false}" (click)="logoutClear()"
             class="btn full-width mt-tiny">{{ 'settings.info.logoutButton' | translate }}</a>

        </div>
      </div>
    }

    <!-- USEFUL LINKS -->
    <div class="settings__usefullinks">
      <h2>{{ 'settings.info.usefulLinks' | translate }}</h2>
      @if (!isAboUser) {
        <a [href]="'/voucher' | webUiHref" target="_blank">{{ 'settings.info.voucher' | translate }}</a>
      }
      <a [href]="'/status' | webUiHref" target="_blank">{{ 'settings.info.status' | translate }}</a>
      <a [href]="'https://support.teleboy.ch/' + language + '/support/home'"
         target="_blank">{{ 'settings.info.faq' | translate }}</a>
      <a [href]="'/contact' | webUiHref" target="_blank">{{ 'settings.info.support' | translate }}</a>
      <a [href]="'/agb' | webUiHref" target="_blank">{{ 'settings.info.agb' | translate }}</a>
      <a [href]="'/privacy' | webUiHref" target="_blank">{{ 'settings.info.privacy' | translate }}</a>
      <a [href]="'/impressum' | webUiHref" target="_blank">{{ 'settings.info.impressum' | translate }}</a>
    </div>
  </div>

  <!-- SETTINGS -->
  <div class="settings__settings">
    <!-- CONTENT SETTINGS -->

    <form [formGroup]="form">
      <table>
        <caption class="h2 text-left">{{ 'settings.content.title' | translate }}</caption>
        <tr>
          <th>
            {{ 'settings.content.language' | translate }}
          </th>
          <td>
            <div class="settings__settings__languagedropdown">
              <app-dropdown [options]="languageOptions" formControlName="language"></app-dropdown>
            </div>
          </td>
        </tr>

        <tr>
          <th>
            {{ 'settings.content.manageChannels' | translate }}
          </th>
          <td>
            <a [href]="'/sender' | webUiHref" target="_blank"
               class="btn">{{ 'settings.content.manageChannelsButton' | translate }}</a>
          </td>
        </tr>

        <tr>
          <th>
            <label [for]="'darkmode'">{{ 'settings.content.darkMode' | translate }}</label>
          </th>
          <td>
            <input class="switch" type="checkbox" id="darkmode"
                   [formControlName]="'darkmode'" />
            <label [for]="'darkmode'"></label>
          </td>
        </tr>

        <tr>
          <th>
            {{ 'settings.content.replay' | translate }}
          </th>
          <td>
            @if (!isAuthenticated || !user.isPlusUser) {
              <a class="btn"
                 [href]="'/voucher/redeem' | webUiHref: { queryParams: { identifier: oneVoucher }}" target="_blank" rel="noopener">
                {{ 'settings.content.replayFreeTrialButton' | translate }}
              </a>
            }
            @if (isAuthenticated) {
              <div>
                @if (user.isPlusUser && !replayActivated && !isReplayActive()) {
                  <button class="btn btn__primary" (click)="activateReplay()">
                    {{ 'settings.content.replayActivateButton' | translate }}
                  </button>
                }

                @if (getReplayActivationDate() && user.isPlusUser) {
                  <div>
                    {{ 'settings.content.replayActiveSince' | translate: { date: user.replay.format('DD.MM.YYYY - HH:MM') } }}
                  </div>
                }
              </div>
            }
          </td>
        </tr>

        <tr>
          <th>
            {{ 'settings.content.downloadQuality.title' | translate }}<br>
            <small class="hidden-mobile">{{ 'settings.content.downloadQuality.subtitle' | translate }}</small>
          </th>
          <td>
            <div class="settings__settings__languagedropdown">
              <app-dropdown [options]="downloadQualityOptions" formControlName="downloadQuality"></app-dropdown>
            </div>
          </td>
        </tr>

        @if (this.isAuthenticated && (settings$ | async)) {
          <tr>
            <th>
              <label [for]="'ecomode'">{{ 'settings.content.streamingEcoMode.title' | translate }}</label>
              <a
                [href]="'https://www.teleboy.ch/magazin/tv-und-streaming/der-eco-mode-nachhaltiger-streamen-mit-teleboy-tv/'"
                target="_blank">
                {{ 'settings.content.streamingEcoMode.linkText' | translate }}
              </a>

            </th>
            <td>
              <input class="switch" type="checkbox" id="ecomode"
                     [formControlName]="'streamingEcoMode'" />
              <label [for]="'ecomode'"></label>
            </td>
          </tr>
        }
      </table>

      @if (isAuthenticated && (settings$ | async)) {
        <!-- EMAIL NOTIFICATION -->
        <div class="settings__email">
          <table>
            <caption class="h2 text-left">{{ 'settings.email.title' | translate }}</caption>
            <tr>
              <th>
                <label [for]="'recordNewNotifyEmail'">{{ 'settings.email.recordings' | translate }}</label>
                <small class="hidden-mobile">{{ 'settings.email.recordingsSubtitle' | translate }}</small>
              </th>
              <td>
                <input class="switch" type="checkbox" id="recordNewNotifyEmail"
                       [formControlName]="'recordNewNotifyEmail'" />
                <label [for]="'recordNewNotifyEmail'"></label>
              </td>
            </tr>

            <tr>
              <th>
                <p class="collapse">{{ 'settings.email.watchlist' | translate }}</p>
                <small class="hidden-mobile">{{ 'settings.email.watchlistSubtitle' | translate }}</small>
              </th>
              <td>
                <div class="settings__settings__watchlistdropdown">
                  <app-dropdown [formControlName]="'watchlistNotifyEmail'" [options]="watchlistOptions"></app-dropdown>
                </div>
              </td>
            </tr>

            <tr>
              <th>
                <p class="collapse">{{ 'settings.email.staralert' | translate }}</p>
                <small class="hidden-mobile">{{ 'settings.email.staralertSubtitle' | translate }}</small>
              </th>
              <td>
                <div class="settings__settings__staralertdropdown">
                  <app-dropdown [formControlName]="'staralertNotifyEmail'" [options]="staralertOptions"></app-dropdown>
                </div>
              </td>
            </tr>

            @if (user?.isCommunityMember && !communityLeaveSuccess) {
              <tr>
                <th>
                  <label
                    [for]="'communityFriendshipMail'">{{ 'settings.email.communityfriendshipmail' | translate }}</label>
                  <small class="hidden-mobile">{{ 'communityfriendshipmailSubtitle' | translate }}</small>
                </th>
                <td>
                  <input class="switch" type="checkbox" id="communityFriendshipMail"
                         [formControlName]="'communityFriendshipMail'" />
                  <label [for]="'communityFriendshipMail'"></label>
                </td>
              </tr>
            }

            <tr>
              <th>
                <label [for]="'newsletterTvDaily'">{{ 'settings.email.tvNewsletter' | translate }}</label>
                <small class="hidden-mobile">{{ 'settings.email.tvNewsletterSubtitle' | translate }}</small>
              </th>
              <td>
                <input class="switch" type="checkbox" id="newsletterTvDaily"
                       [formControlName]="'newsletterTvDaily'" />
                <label [for]="'newsletterTvDaily'"></label>
              </td>
            </tr>

            <tr>
              <th>
                <label [for]="'newsletterProductinfo'">{{ 'settings.email.productNewsletter' | translate }}</label>
                <small class="hidden-mobile">{{ 'settings.email.productNewsletterSubtitle' | translate }}
                </small>
              </th>
              <td>
                <input class="switch" type="checkbox" id="newsletterProductinfo"
                       [formControlName]="'newsletterProductinfo'" />
                <label [for]="'newsletterProductinfo'"></label>
              </td>
            </tr>
          </table>

          <!-- COMMUNITY SETTINGS -->
          @if (user?.isCommunityMember && !communityLeaveSuccess) {
            <div class="settings__community">
              <table>
                <caption class="h2 text-left">{{ 'settings.community.title' | translate }}</caption>
                <tr>
                  <th>
                    <label
                      [for]="'communityFriendshipAutoAccept'">{{ 'settings.community.autoAcceptFriendship' | translate }}</label>
                  </th>
                  <td>
                    <input class="switch" type="checkbox" id="communityFriendshipAutoAccept"
                           [formControlName]="'communityFriendshipAutoAccept'" />
                    <label [for]="'communityFriendshipAutoAccept'"></label>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ 'settings.community.friendships' | translate }}
                  </th>
                  <td>
                    <a [href]="'/cockpit/community/my-friends' | webUiHref" class="btn" target="_blank">
                      {{ 'settings.community.friendshipsButton' | translate }}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ 'settings.community.nickname' | translate }}<br>
                    <small>{{ user.nickname }}</small>
                  </th>
                  <td>
                    <a [href]="'/profile' | cockpitHref" class="btn"
                       target="_blank">{{ 'settings.community.nicknameButton' | translate }}</a>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ 'settings.community.terms' | translate }}
                  </th>
                  <td>
                    <a
                      [href]="'/shared/downloads/cpvr/2022_teleboy_community_conditions_' + language + '.pdf' | staticAsset"
                      class="btn" target="_blank">
                      {{ 'settings.community.termsButton' | translate }}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ 'settings.community.cancel' | translate }}<br>
                    <small class="hidden-mobile">{{ 'settings.community.cancelSubtitle' | translate }}</small>
                  </th>
                  <td>
                    <button (click)="leaveCommunity()" class="btn">{{ 'settings.community.cancelButton' | translate }}
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          }
        </div>
      }
    </form>
  </div>
</div>
