@if (!(isEmptyResult$() | async)) {
  <div class="swiper__container">
    <app-expand-link [linkText]="headline" (clicked)="openGenregroupList()"></app-expand-link>

    @if (data$ | async; as genreGroup) {
      <swiper [slidesPerView]="swiperOptions.slidesPerView"
              [breakpoints]="swiperOptions.breakpoints">
        @for (genre of genreGroup; track $index) {
          <ng-template swiperSlide>
            <app-pvr-genregroup-preview [genreGroup]="genre"></app-pvr-genregroup-preview>
          </ng-template>
        }

        @let total = (total$ | async);
        @if (total && total > 5) {
          <ng-template swiperSlide>
            <app-pvr-grid-button
              [label]="'pvr.groups.genres.showAll' | translate"
              [rounded]="true" (click)="openGenregroupList()"
            ></app-pvr-grid-button>
          </ng-template>
        }
      </swiper>
    }

    @if (busy()) {
      <swiper class="ghost-listing ghost-listing--horizontal">
        @for (ghost of ghosts; track $index) {
          <ng-template swiperSlide>
            <app-broadcast-item [card]="true"></app-broadcast-item>
          </ng-template>
        }
      </swiper>
    }
  </div>
}
