import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { AlertCounterComponent, IconComponent, MediaImageComponent } from '@teleboy/web.ui';
import { RouterModule } from '@angular/router';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { InfoComponent } from './components/info/info.component';
import { PopupComponent } from './components/popup/popup.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TruncatePipe } from './pipes/truncate.pipe';
import { BottomsheetDirective } from './directives/bottomsheet.directive';
import { A11yModule } from '@angular/cdk/a11y';
import { NavigationLayoutComponent } from './components/layout/navigation-layout/navigation-layout.component';
import { UpsellModalComponent } from './components/upsell-modal/upsell-modal.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { SharedRoutingModule } from './shared-routing.module';
import { CockpitHrefPipe } from './pipes/cockpit-href.pipe';
import { EmptyComponent } from './components/empty/empty.component';
import { AnonymousVerificationComponent } from './components/anonymous-verification/anonymous-verification.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AnonymousAuthComponent } from './components/anonymous-auth/anonymous-auth.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CloseButtonComponent } from './components/close-button/close-button.component';
import { ExpandLinkComponent } from './components/expand-link/expand-link.component';
import { IntersectionObserverDirective } from './directives/intersectionObserver.directive';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ScrollableItemDirective } from './directives/scrollable-item.directive';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { WebUiHrefPipe } from './pipes/web-ui-href.pipe';
import { DataLayerDirective } from './directives/data-layer.directive';

@NgModule({
  declarations: [
    InfoComponent,
    PopupComponent,
    AutofocusDirective,
    TruncatePipe,
    BottomsheetDirective,
    NavigationLayoutComponent,
    UpsellModalComponent,
    ErrorModalComponent,
    CockpitHrefPipe,
    EmptyComponent,
    AnonymousVerificationComponent,
    AnonymousAuthComponent,
    CloseButtonComponent,
    ExpandLinkComponent,
    IntersectionObserverDirective,
    DropdownComponent,
    ScrollableItemDirective
  ],
  exports: [
    InfoComponent,
    PopupComponent,
    AutofocusDirective,
    TruncatePipe,
    BottomsheetDirective,
    CockpitHrefPipe,
    CloseButtonComponent,
    ExpandLinkComponent,
    IntersectionObserverDirective,
    DropdownComponent,
    ScrollableItemDirective,
    DataLayerDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    SharedRoutingModule,
    TranslateModule,
    NgbDropdownModule,
    A11yModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgOptimizedImage,
    MediaImageComponent,
    MainNavComponent,
    WebUiHrefPipe,
    AlertCounterComponent,
    DataLayerDirective,
    IconComponent
  ],
  providers: [
    // "NullInjectorError: No provider for MatDialogRef"
    // https://stackoverflow.com/questions/47270324/nullinjectorerror-no-provider-for-matdialogref
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        disableTooltipInteractivity: true,
        touchendHideDelay: 300
      }
    }
  ]
})
export class SharedModule {}
