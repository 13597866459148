import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Broadcast, StationLogoSize, StationLogoComponent } from '@teleboy/web.epg';
import { MediaImage, MediaImageStack } from '@teleboy/web.core';
import { map, Observable } from 'rxjs';
import { LanguageService } from '@teleboy/web.user';
import { EpgService } from '../../services/epg.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgIf, NgClass, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { BroadcastPlayDirective } from '../../directives/broadcast/broadcast-play.directive';
import { BroadcastDetailDirective } from '../../directives/broadcast/broadcast-detail.directive';
import { IconComponent, MediaImageComponent } from '@teleboy/web.ui';
import { MatTooltip } from '@angular/material/tooltip';
import { BroadcastIconsComponent } from '../broadcast-icons/broadcast-icons.component';
import { BroadcastFlagComponent } from '../broadcast-flag/broadcast-flag.component';
import { CpvrProviderComponent } from '../cpvr/cpvr-provider/cpvr-provider.component';
import { DataLayerDirective } from '../../../shared/directives/data-layer.directive';
import { BroadcastDownloadDirective } from '../../directives/broadcast/broadcast-download.directive';
import { BroadcastDeleteRecordDirective } from '../../directives/broadcast/broadcast-delete-record.directive';
import { BroadcastToggleRecordDirective } from '../../directives/broadcast/broadcast-toggle-record.directive';
import { BroadcastDeleteRecordHeartbeatDirective } from '../../directives/broadcast/broadcast-delete-record-heartbeat.directive';
import { BroadcastWatchlistDirective } from '../../directives/broadcast/broadcast-watchlist.directive';
import { BroadcastTrailerDirective } from '../../directives/broadcast/broadcast-trailer.directive';
import { BroadcastProgressComponent } from '../broadcast-progress/broadcast-progress.component';
import { TranslateModule } from '@ngx-translate/core';
import { BroadcastInfoPipe } from '../../pipes/broadcast-info.pipe';

@Component({
  selector: 'app-broadcast-item',
  templateUrl: './broadcast-item.component.html',
  styleUrls: ['./broadcast-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    NgIf,
    NgClass,
    BroadcastPlayDirective,
    BroadcastDetailDirective,
    MatTooltip,
    BroadcastIconsComponent,
    BroadcastFlagComponent,
    StationLogoComponent,
    MediaImageComponent,
    NgTemplateOutlet,
    CpvrProviderComponent,
    DataLayerDirective,
    BroadcastDownloadDirective,
    BroadcastDeleteRecordDirective,
    BroadcastToggleRecordDirective,
    BroadcastDeleteRecordHeartbeatDirective,
    BroadcastWatchlistDirective,
    BroadcastTrailerDirective,
    BroadcastProgressComponent,
    AsyncPipe,
    TranslateModule,
    BroadcastInfoPipe,
    IconComponent
  ]
})
export class BroadcastItemComponent implements OnInit {
  @Input() broadcast!: Broadcast;
  @Input() heartbeat!: number;
  @Input() live!: boolean;
  @Input() player!: boolean;
  @Input() card = false;
  @Input() active = false;
  @Input() hightlightTipp = true;
  @Input() forceImage = false;
  @Input() hideProgress = false;
  @Input() hideStation = false;
  @Input() badgeLabel = '';
  @Input() disableActions = false;
  @Input() hideActions = false;
  @Input() origin: 'playList' | '' = '';
  @Input() episode = false;

  @Output() detailClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() recordDeleted: EventEmitter<number> = new EventEmitter<number>();
  @Output() heartbeatDeleted: EventEmitter<void> = new EventEmitter<void>();

  StationLogoSize = StationLogoSize;
  MediaImageStack = MediaImageStack;

  isPlaying$!: Observable<boolean>;

  constructor(
    private deviceDetector: DeviceDetectorService,
    private epgService: EpgService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.isPlaying$ = this._isPlaying$();
  }

  showImage(): boolean {
    return this.card || this.isTipp() || this.forceImage;
  }

  showStation(): boolean {
    return !this.hideStation && !this.showImage();
  }

  imageToShow(): MediaImage | undefined {
    return this.broadcast.thumbnail;
  }

  isTipp(): boolean {
    return (
      this.hightlightTipp && !this.isInCommunity() && this.broadcast.flags.is_tip && !this.deviceDetector.isMobile()
    );
  }

  isInCommunity(): boolean {
    return this.broadcast.flags.is_community_record;
  }

  subtitle$(): Observable<string> {
    return this.broadcast.getSubtitle$(this.languageService.getLanguage().toUpperCase());
  }

  onRecordDeleted(id: number): void {
    this.recordDeleted.emit(id);
  }

  onHeartbeatDeleted(): void {
    this.heartbeatDeleted.emit();
  }

  onDetailClick(): void {
    this.detailClick.emit();
  }

  private _isPlaying$(): Observable<boolean> {
    return this.epgService.playingBroadcastId$.pipe(map((broadcastId) => broadcastId === this.broadcast.id));
  }
}
