import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { BroadcastStreamInfoService, RecordingPlayerService } from '@teleboy/web.player';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { PlayerService } from '../../services/player.service';

@Component({
  selector: 'app-player-recording',
  templateUrl: './player-recording.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PlayerRecordingComponent implements AfterViewInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private broadcastStreamInfoService: BroadcastStreamInfoService,
    private playerService: PlayerService,
    private recordingPlayerService: RecordingPlayerService
  ) {}

  ngAfterViewInit(): void {
    this.activatedRoute.params
      .pipe(
        switchMap((params) => this.recordingPlayerService.play$(params['broadcastId'])),
        switchMap((stream) => this.playerService.setPageTitle$(stream)),
        switchMap(() => this.complete$()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private complete$(): Observable<boolean> {
    return this.recordingPlayerService.complete$().pipe(switchMap(() => this.playerService.closePlayer()));
  }
}
