import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AuthenticationService } from '@teleboy/web.user';
import { Broadcast } from '@teleboy/web.epg';
import { PopupService } from '../../../core/services/popup.service';
import { environment } from '../../../../environments/environment';
import { BroadcastActionDirective } from './broadcast-action.directive';
import { DOCUMENT } from '@angular/common';
import { PlayerSizeService } from '../../../player/services/player-size.service';
import { BitmovinService } from '@teleboy/web.player';

@Directive({
  selector: 'a[appBroadcastDetail]'
})
export class BroadcastDetailDirective extends BroadcastActionDirective implements OnInit {
  @Input('appBroadcastDetail') broadcast!: Broadcast;
  @Input() origin: 'playList' | '' = '';

  @Output() detailClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    protected override changeDetectionRef: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private bitmovinService: BitmovinService,
    private playerSizeService: PlayerSizeService,
    private popupService: PopupService
  ) {
    super(changeDetectionRef);
  }

  @HostBinding('attr.href')
  get href(): string {
    return `${environment.webui.url}/programm/${this.broadcast.stationSlug}/${this.broadcast.id}/${this.broadcast.slug}`;
  }

  @HostBinding('attr.target')
  readonly target = '_blank';

  ngOnInit(): void {
    this._title = this.broadcast.title;
  }

  @HostListener('click', ['$event'])
  onClick($event: MouseEvent): void {
    if (!this.canPerformAction) {
      return;
    }

    this.detailClick.emit();

    /**
     * Open popup for all users for the moment
     * @see https://teleboy.atlassian.net/browse/WEB-31657
     */

    if (this.origin !== 'playList' && !this.authenticationService.user?.isPlusUser) return;

    $event.preventDefault();
    $event.stopPropagation();

    this.playerSizeService.miniPlayer();
    if (this.bitmovinService.player && this.bitmovinService.isFullscreen()) {
      this.bitmovinService.fullscreenToggle();
    }

    this.popupService.open(`broadcast/${this.broadcast.id}`, {
      outlet: 'broadcast',
      data: this.broadcast
    });
  }
}
