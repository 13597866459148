@if (titleGroup()) {
  <div class="title-group card" (click)="openRecordingList()">
    @if (titleGroup().image) {
      <tbui-media-image [image]="titleGroup().image"
                        [alt]="titleGroup().title"
                        [size]="MediaImageStack.MEDIUM"
                        class="ratio ratio__16x9"></tbui-media-image>
    }
    <div class="card__body">
      <h3 class="ellipsis collapse">
        <a>{{ titleGroup().title }}</a>
      </h3>
      @if (duplicatesPreview()) {
        <p class="preview__subtitle">{{ (titleGroup().count > 1) ?
          ('pvr.groups.recordings.countDuplicates' | translate: {count: titleGroup().count - 1}) :
          'pvr.groups.recordings.noDuplicates' | translate }}</p>
      } @else {
        <p class="preview__subtitle">{{ 'pvr.groups.recordings.count' | translate: {count: titleGroup().count} }}</p>
      }

    </div>
  </div>
} @else {
  <div class="title-group card">
    <div class="ratio ratio__16x9"></div>
    <div class="card__body">
      <h3 class="ellipsis collapse">&nbsp;</h3>
      <p class="collapse">&nbsp;</p>
    </div>
  </div>
}
