<header class="header--container">
  <img [src]="logoSrc" class="start__logo" alt="Teleboy Logo" width="150">

  <div class="login-progress--container">
    <div class="login--container" *ngIf="!isUserAuthenticated">
      <span class="login__text">{{ 'start.loadingad.haveAccount' | translate }}</span>
      <a [href]="'/login' | webUiHref: {appendTarget: true}" class="btn mt-tiny" appDataLayer
         dataLayerEventName="upsell_loading_ad_login">
        {{ 'start.loadingad.login' | translate }}
      </a>
    </div>
    <div class="progress--container">
      {{ 'start.loadingad.progress' | translate: { progress: progress$ | async } }}
    </div>
  </div>
</header>

<div class="loading__progress">
  <div class="loading__progress__bar" [ngStyle]="{ 'animation-duration': (duration + 's') }"></div>
</div>

<iframe src="https://www.teleboy.ch/loading" title=""></iframe>
