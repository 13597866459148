<app-popup outlet="landingpage" [full]="true">

  <h1>{{ 'pvr.watchlist.title' | translate }}</h1>

  <div class="grid grid__tiles" [ngClass]="{'grid__tiles': listingType() === ListingType.TILES}"
       (scrolled)="paginate()" infiniteScroll
       [infiniteScrollContainer]="'.popup__backdrop--landingpage'"
       [fromRoot]="true"
       [immediateCheck]="true">

    @for (broadcast of broadcasts$ | async; track $index) {
      <app-broadcast-item [broadcast]="broadcast" [card]="listingType() === ListingType.TILES"></app-broadcast-item>
    }
  </div>

  @if (busy()) {
    <div class="grid ghost-listing grid__tiles" [ngClass]="{'grid__tiles': listingType() === ListingType.TILES}">
      @for (ghost of ghostPreviews; track $index) {
        <app-broadcast-item [card]="listingType() === ListingType.TILES"></app-broadcast-item>
      }
    </div>
  }

  <ng-container buttons>
    <button class="buttons btn btn__icon btn--circle btn__toggle-view--{{ listingType() }}"
            (click)="toggleListingType()">
      <tbui-icon iconId="view-list" [ngClass]="{'hidden': listingType() === ListingType.LIST}"
                 class="view-icon-list">
      </tbui-icon>
      <tbui-icon iconId="view-tiles" [ngClass]="{'hidden': listingType() !== ListingType.LIST}"
                 class="view-icon-tiles">
      </tbui-icon>
    </button>
  </ng-container>

</app-popup>
