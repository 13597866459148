import { Injectable } from '@angular/core';
import { Upsell, UpsellCta } from '../../interfaces/upsell.interface';
import { PopupService } from '../popup.service';
import { AuthenticationService } from '@teleboy/web.user';

@Injectable({
  providedIn: 'root'
})
export class ReplayTooRecentUpsellService implements Upsell {
  readonly name = 'replayTooRecent';
  readonly icon = 'replay';
  readonly placeholders = {};
  readonly cta: UpsellCta[];

  constructor(
    private authenticationService: AuthenticationService,
    private popupService: PopupService
  ) {
    this.cta = this._cta();
    this.placeholders = { date: this.authenticationService.user?.replay.format('DD.MM.YYYY - HH:MM') };
  }

  private _cta(): UpsellCta[] {
    return [
      {
        key: 'close',
        click: () => this.popupService.closeModal()
      }
    ];
  }
}
